import {useMemo} from "react";
import {fromSignal, Ref} from "common/ref";
import {Optional} from "common/types/generic/index.ts";
import {NodeId} from "common/legends/node/index.ts";
import {NodeSelectionRef} from "../../../panel/nav/editor/state/selection-ref.ts";
import {EditorVision} from "../../scene/editor-vision.ts";
import {useAccess} from "../../../../routes/game/model/store-context.tsx";
import {computed} from "common/signal";

export function useActiveNodeID(editorVisionRef: Ref<EditorVision>, selectedNodeIDsRef: Ref<NodeSelectionRef[]>): Ref<Optional<NodeId>> {
  const access = useAccess();
  return useMemo(() => fromSignal(computed(() => {
    if (editorVisionRef.value.override) return undefined;
    const selectedNodeIDs = selectedNodeIDsRef.value;
    if (selectedNodeIDs.length === 0) return undefined;
    return selectedNodeIDs.find(({elementID}) => {
      const element = access.element(elementID).value;
      if (element?.type === "token") {
        const {assetID, tokenID} = element.data.tokenReference;
        const asset = access.asset(assetID).value;
        if (!asset) return false;
        const token = asset.tokens.find(token => token.tokenID === tokenID);
        if (!token) return false;
        if (token.vision.length === 0) return false;
        return true;
      } else {
        return false;
      }
    })?.elementID;
  })).distinct(), [access, editorVisionRef, selectedNodeIDsRef]);
}
