import {authClient} from "#lib/auth/auth-client.ts";
import {PropsWithChildren, useEffect, useMemo} from "react";
import {AuthAuthorize} from "#lib/auth/auth-authorize.tsx";
import {Route, Routes} from "react-router";
import {AuthCallback} from "#lib/auth/auth-callback.tsx";
import {AuthLogout} from "#lib/auth/auth-logout.tsx";
import {DefaultQLabClient, DefaultQLabInstance, InstanceProvider, QLabInstance, useObservable} from "#lib/qlab/index.ts";
import {userIDRef} from "#lib/auth/use-get-user-id.ts";
import * as Sentry from "@sentry/react";

function useInstance(): QLabInstance | undefined {
  const authToken = useObservable(authClient.tokenObservable, authClient.getToken, [authClient.tokenObservable]);
  return useMemo(() => {
    if (authToken === undefined) return undefined;
    const userID = userIDRef.value;
    if (userID === undefined) return undefined;
    return new DefaultQLabInstance(userID, new DefaultQLabClient(userID, authToken, []));
  }, [authToken]);
}

export function AuthProvider({children}: PropsWithChildren<object>) {
  useEffect(() => {
    return userIDRef.observe({
      next: (userID) => {
        Sentry.getGlobalScope().setUser({
          id: userID
        });
      },
      error: () => {},
      complete: () => {}
    });
  }, [userIDRef]);

  const instance = useInstance();
  if (instance === undefined) {
    return (
      <Routes>
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="*" element={<AuthAuthorize />} />
      </Routes>
    );
  } else {
    return (
      <InstanceProvider value={instance}>
        <Routes>
          <Route path="/sign-out" element={<AuthLogout />} />
          <Route path="*" element={children} />
        </Routes>
      </InstanceProvider>
    );
  }
}