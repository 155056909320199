import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {NameField} from "../../field/name-field.tsx";
import {VisibleField} from "../../field/visible-field.tsx";
import {OpacityField} from "../../field/opacity-field.tsx";
import {SelectionField} from "../../field/selection-field.tsx";
import {TransformField} from "../../field/transform-field.tsx";
import {SizeField} from "../../field/size-field.tsx";
import {useMemo} from "react";
import {InputFile} from "#lib/components/input/input-file.tsx";
import {OriginField} from "../../field/origin-field.tsx";
import {ConditionsSection} from "../../panel/properties/node/conditions/conditions-section.tsx";
import {MountableField} from "../../field/mountable-field.tsx";
import {AttachableField} from "../../panel/properties/attachable-field.tsx";
import {MutableRef} from "common/ref";
import {PivotField} from "../../field/pivot-field.tsx";
import {SelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {VideoElement, VideoElementFn, VideoElementOperation} from "common/legends/node/video-element.ts";
import {Field, FieldLabel, Panel, Section} from "#lib/components/panel-header.tsx";
import {faVideo} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import {RepeatField} from "../../panel/properties/node/input-repeat.tsx";
import {useElementGrid} from "../../common/use-element-grid.ts";
import {GridProvider} from "../../viewport/common/context/grid-context.ts";
import {BooleanField} from "../../field/generic/boolean-field.tsx";

export function VideoElementProperties({valueRef, reference, pinned}: {
  valueRef: MutableRef<VideoElement, VideoElementOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const {idRef, name, fileRef, normalRef, sizeRef, opacityRef, selectionMaskRef, transformRef, originRef, pivotRef, mountableRef, attachableRef, visibilityLayerRef, conditionsRef, flipHorizontalRef, flipVerticalRef, repeatXRef, repeatYRef} = useMemo(() => VideoElementFn.expand(valueRef), [valueRef]);
  const elementGrid = useElementGrid(idRef);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <GridProvider value={elementGrid}>
      <Panel title="Video Properties">
        <Section>
          <NameField value={name} reference={reference} pinned={pinned} />
          <Field>
            <FieldLabel>Video</FieldLabel>
            <InputFile placeholder="Video" icon={faVideo} accept="video/*" value={fileRef} />
          </Field>

          <Field>
            <FieldLabel>Normal</FieldLabel>
            <InputFile accept="video/*" icon={faVideo} className="flex-1" value={normalRef} />
          </Field>

          <VisibleField value={visibilityLayerRef} />
          <SelectionField value={selectionMaskRef} />
          <OpacityField value={opacityRef} />
          <TransformField value={transformRef} />
          <OriginField valueRef={originRef} />
          <PivotField valueRef={pivotRef} />
          <SizeField valueRef={sizeRef} />
          <BooleanField label="Flip Horizontal" valueRef={flipHorizontalRef} />
          <BooleanField label="Flip Vertical" valueRef={flipVerticalRef} />
          <RepeatField repeatXRef={repeatXRef} repeatYRef={repeatYRef}/>
          <MountableField value={mountableRef} />
          <AttachableField value={attachableRef} />
        </Section>

        <ConditionsSection value={conditionsRef} />
      </Panel>
    </GridProvider>
  </div>
}