import {Node, NodeOperation, ElementTypes} from "common/legends/index.ts";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {useTypedRef} from "../common/use-typed-ref.ts";
import {MutableRef} from "common/ref";
import {SelectionRef} from "../panel/nav/editor/state/selection-ref.ts";

import {ImageElementProperties} from "./image/image-element-properties.tsx";
import {GroupElementProperties} from "./group/group-element-properties.tsx";
import {TokenElementProperties} from "./token/token-element-properties.tsx";
import {GridElementProperties} from "./grid/grid-element-properties.tsx";
import {WallElementProperties} from "./wall/wall-element-properties.tsx";
import {AreaElementProperties} from "./area/area-element-properties.tsx";
import {TextElementProperties} from "./text/text-element-properties.tsx";
import {ShapeNodeProperties} from "./shape/shape-node-properties.tsx";
import {ParallaxElementProperties} from "./parallax/parallax-element-properties.tsx";
import {VideoElementProperties} from "./video/video-element-properties.tsx";
import {CameraElementProperties} from "./camera/camera-element-properties.tsx";
import {LightElementProperties} from "./light/light-element-properties.tsx";
import {AudioElementProperties} from "./audio/audio-element-properties.tsx";

export function ElementProperties({value, reference, pinned}: {
  value: MutableRef<Node, NodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const [type, typedElement] = useTypedRef<ElementTypes>(value);
  if (type === "image") return <ImageElementProperties value={typedElement} reference={reference} pinned={pinned} />;
  else if (type === "group") return <GroupElementProperties value={typedElement} reference={reference} pinned={pinned}  />;
  else if (type === "token") return <TokenElementProperties value={typedElement} reference={reference} pinned={pinned} />;
  else if (type === "grid") return <GridElementProperties value={typedElement} reference={reference} pinned={pinned} />;
  else if (type === "wall") return <WallElementProperties value={typedElement} reference={reference} pinned={pinned} />
  else if (type === "area") return <AreaElementProperties value={typedElement} reference={reference} pinned={pinned} />
  else if (type === "shape") return <ShapeNodeProperties value={typedElement} reference={reference} pinned={pinned} />
  else if (type === "text") return <TextElementProperties value={typedElement} reference={reference} pinned={pinned} />
  else if (type === "parallax") return <ParallaxElementProperties value={typedElement} reference={reference} pinned={pinned} />
  else if (type === "light") return <LightElementProperties valueRef={typedElement} reference={reference} pinned={pinned} />
  else if (type === "video") return <VideoElementProperties valueRef={typedElement} reference={reference} pinned={pinned} />
  else if (type === "camera") return <CameraElementProperties valueRef={typedElement} reference={reference} pinned={pinned} />
  else if (type === "audio") return <AudioElementProperties valueRef={typedElement} reference={reference} pinned={pinned} />

  return <></>
}
