import {ColorOperation, ConstantOperation, HSLA, ValueOperation} from "common/types/index.ts";
import {Scene, SceneFn, SceneOperation} from "common/legends/index.ts";
import {NameField} from "../../../field/name-field.tsx";
import {useMemo} from "react";
import {InputGroup, InputGroupColorButton} from "#lib/components/index.ts";
import {GridSection} from "./grid-button/grid-section.tsx";
import {useScene} from "../../../viewport/token/use-scene.ts";
import {usePresent} from "../../../common/use-optional-signal.ts";
import {MutableRef} from "common/ref";
import {LightShapeGlobalFields} from "../node/image/light-shape-global-fields.tsx";
import {Field, FieldLabel, Panel, Section} from "#lib/components/panel-header.tsx";
import {SceneSelectionRef, SelectionRef} from "../../nav/editor/state/selection-ref.ts";

export type LayerOptionsProps = {
  reference: SceneSelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};

export function ScenePropertiesView({reference, pinned}: LayerOptionsProps) {
  const value = usePresent(useScene(reference.sceneID));
  if (!value) return <></>;
  return <LoadedEditorScenePropertiesView value={value} reference={reference} pinned={pinned} />
}

type LoadedEditorScenePropertiesViewProps = {
  value: MutableRef<Scene, SceneOperation[]>;
  reference: SceneSelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};

function LoadedEditorScenePropertiesView({value, reference, pinned}: LoadedEditorScenePropertiesViewProps) {
  const {name, grid, backgroundColor, lightRef} = useMemo(() => SceneFn.expand(value), [value]);
  return (<div className="tab-content flex flex-col py-2 gap-2">
    <Panel title="Scene Properties">
      <Section>
        <NameField value={name} reference={reference} pinned={pinned} />
        <BackgroundField valueRef={backgroundColor} />
      </Section>
      <GridSection value={grid} />
      <Section title="Light">
        <LightShapeGlobalFields valueRef={lightRef} />
      </Section>
    </Panel>
  </div>);
}

type BackgroundFieldProps = {
  valueRef: MutableRef<HSLA, ColorOperation[]>;
};

function BackgroundField({valueRef}: BackgroundFieldProps) {
  return (<Field>
    <FieldLabel>Background Color</FieldLabel>
    <InputGroup className="mx-2 rounded-md overflow-hidden">
      <InputGroupColorButton valueRef={valueRef} />
    </InputGroup>
  </Field>);
}