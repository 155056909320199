import {NodeId} from "./node-id.ts";
import {ConstantOperation, ConstantType, ListOperation, ListType, StringOperation, stringType, Tag, tagListType} from "../../types/index.ts";
import {NodeCondition, NodeConditionOperation, nodeConditionType} from "#common/legends/node/condition/node-condition.ts";
import {MutableRef} from "#common/ref";
import {Node, nodeType} from "./node.ts";

export type BaseNode = {
  id: NodeId;
  name: string;
  tags: Tag[];
  conditions: NodeCondition[];
  children: Node[];
};

export type BaseNodeOperation =
  | {type: "update-id", operations: ConstantOperation[]}
  | {type: "update-name", operations: StringOperation[]}
  | {type: "update-tags", operations: ListOperation<Tag, ConstantOperation>[]}
  | {type: "update-conditions", operations: ListOperation<NodeCondition, NodeConditionOperation>[]}
  | {type: "update-children", operations: ConstantOperation[]}
  ;

export const baseNodeTypePropTypes = () => ({
  id: new ConstantType<NodeId>(),
  name: stringType,
  tags: tagListType,
  conditions: new ListType(nodeConditionType),
  children: new ConstantType<Node[]>(new ListType(nodeType)),
});

export function defaultBaseNode(): BaseNode {
  return {
    id: NodeId.generate(),
    name: "BaseNode",
    tags: [],
    conditions: [],
    children: []
  };
}

export function BaseNodeSignals(value: MutableRef<BaseNode, BaseNodeOperation[]>) {
  return {
    idRef: value.map<NodeId, ConstantOperation[]>(value => value.id, (_, operations) => [{type: "update-id", operations}]),
    name: value.map<string, StringOperation[]>(value => value.name, (_, operations) => [{type: "update-name", operations}]),
    tags: value.map<Tag[], ListOperation<Tag, ConstantOperation>[]>(value => value.tags, (_, operations) => [{type: "update-tags", operations}]),
    conditionsRef: value.map<NodeCondition[], ListOperation<NodeCondition, NodeConditionOperation>[]>(value => value.conditions, (_, operations) => [{type: "update-conditions", operations}])
  };
}
