import {useEffect} from "react";

export function AuthAuthorize() {
  useEffect(() => {
    localStorage.setItem("authRedirect", window.location.href);
    window.location.href = `${import.meta.env.VITE_AUTH_URL}/authorize?provider=cognito&redirect_uri=${encodeURIComponent(`${import.meta.env.VITE_SITE_URL}/auth/callback`)}&response_type=token&client_id=vtt`;
  }, []);

  return <div className="flex flex-1 justify-center pt-10 w-full">
    <div className="bg-zinc-800 py-12 rounded shadow-xl h-max w-full max-w-screen-sm text-center italic text-white/80">
      Redirecting...
    </div>
  </div>
}