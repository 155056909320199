import {
  BooleanOperation,
  booleanType,
  FileReference,
  FileReferenceOperation,
  fileReferenceType,
  NumberOperation,
  numberType,
  ObjectType,
  Size,
  SizeOperation,
  sizeType,
  Type,
  ValueOperation,
  ValueType
} from "../../types/index.ts";
import {MutableRef} from "#common/ref";
import {LocalNode, LocalNodeOperation, LocalNodeSignals, localNodeTypePropTypes, localNodeUpdater} from "./local-node.ts";
import {Nullable, NullableType} from "../../types/generic/nullable/index.ts";

export type VideoElement = LocalNode & {
  file: FileReference;
  normal: FileReference;
  opacity: number;
  size: Size;
  mountable: boolean;
  attachable: boolean;
  flipHorizontal: boolean;
  flipVertical: boolean;
  repeatX: Nullable<number>;
  repeatY: Nullable<number>;
};
export type VideoElementOperation =
  | LocalNodeOperation
  | {type: "update-file", operations: FileReferenceOperation[]}
  | {type: "update-normal", operations: FileReferenceOperation[]}
  | {type: "update-opacity", operations: NumberOperation[]}
  | {type: "update-size", operations: SizeOperation[]}
  | {type: "update-mountable", operations: BooleanOperation[]}
  | {type: "update-attachable", operations: BooleanOperation[]}
  | {type: "update-flip-horizontal", operations: BooleanOperation[]}
  | {type: "update-flip-vertical", operations: BooleanOperation[]}
  | {type: "update-repeat-x", operations: ValueOperation<number | null, NumberOperation>[]}
  | {type: "update-repeat-y", operations: ValueOperation<number | null, NumberOperation>[]}
  ;
export const videoElementType: Type<VideoElement, VideoElementOperation> = new ObjectType(() => ({
  ...localNodeTypePropTypes(),
  file: fileReferenceType,
  normal: fileReferenceType,
  opacity: numberType,
  size: sizeType,
  mountable: booleanType,
  attachable: booleanType,
  flipHorizontal: booleanType,
  flipVertical: booleanType,
  repeatX: new ValueType(new NullableType(numberType)),
  repeatY: new ValueType(new NullableType(numberType))
}), (value) => {
  value = localNodeUpdater(value);
  if (value.repeatX === undefined) value.repeatX = 1;
  if (value.repeatY === undefined) value.repeatY = 1;
  if (value.flipHorizontal === undefined) value.flipHorizontal = false;
  if (value.flipVertical === undefined) value.flipVertical = false;
  return value;
});

export const VideoElementFn = {
  expand(value: MutableRef<VideoElement, VideoElementOperation[]>) {
    return {
      ...LocalNodeSignals(value),
      fileRef: value.map<FileReference, FileReferenceOperation[]>(value => value.file, (_, operations) => [{type: "update-file", operations}]),
      normalRef: value.map<FileReference, FileReferenceOperation[]>(value => value.normal, (_, operations) => [{type: "update-normal", operations}]),
      sizeRef: value.map<Size, SizeOperation[]>(value => value.size, (_, operations) => [{type: "update-size", operations}]),
      opacityRef: value.map<number, NumberOperation[]>(value => value.opacity, (_, operations) => [{type: "update-opacity", operations}]),
      mountableRef: value.map<boolean, BooleanOperation[]>(value => value.mountable, (_, operations) => [{type: "update-mountable", operations}]),
      attachableRef: value.map<boolean, BooleanOperation[]>(value => value.attachable, (_, operations) => [{type: "update-attachable", operations}]),
      flipHorizontalRef: value.map<boolean, BooleanOperation[]>(value => value.flipHorizontal, (_, operations) => [{type: "update-flip-horizontal", operations}]),
      flipVerticalRef: value.map<boolean, BooleanOperation[]>(value => value.flipVertical, (_, operations) => [{type: "update-flip-vertical", operations}]),
      repeatXRef: value.map<Nullable<number>, ValueOperation<Nullable<number>, NumberOperation>[]>(value => value.repeatX, (_, operations) => [{type: "update-repeat-x", operations}]),
      repeatYRef: value.map<Nullable<number>, ValueOperation<Nullable<number>, NumberOperation>[]>(value => value.repeatY, (_, operations) => [{type: "update-repeat-y", operations}])
    };
  }
};
