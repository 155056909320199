import React, {useMemo} from "react";
import {Color, HSLA, ListOperation, Point, Transform, ValueFn} from "common/types/index.ts";
import {ElementHUDPass} from "../element-h-u-d-pass.tsx";
import {SelectionIndicator} from "../../viewport/common/selection-indicator/selection-indicator.tsx";
import {ModelProvider, useModel} from "../../viewport/common/context/pvm-context.ts";
import {LightNode} from "common/legends/node/light/light-node.ts";
import {LightShapeFn} from "common/legends/node/light/light-shape.ts";
import {useIsActiveSelection} from "../../viewport/common/context/use-is-active-selection.ts";
import {Spline, SplineFn, SplineOperation} from "common/types/generic/spline/index.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {CircleShader} from "../../viewport/common/shader/circle-shader.tsx";
import {AreasEditor} from "../area/areas-editor.tsx";
import {computed} from "common/signal";
import {useIsTokenController} from "../../viewport/common/context/use-is-token-controller.ts";
import {useAccess} from "../../../routes/game/model/store-context.tsx";
import {useComputed} from "#lib/signal/react/use-computed.ts";
import {useSignalValue} from "#lib/signal/index.ts";
import {NodeId} from "common/legends/node/index.ts";

function LightElementFreeformHUDPass({elementID}: {
  elementID: NodeId
}) {
  const access = useAccess();
  const elementRef = useMemo(() => access.element(elementID), [access, elementID])
  const areasRef = useMemo(() => computed(
    () => {
      const element = elementRef.value;
      if (element?.type !== "light" || element?.data.shape?.type !== "freeform") return [];
      return element.data.shape.data.areas;
    },
    (operations: ListOperation<Spline, SplineOperation>[]) => {
      elementRef.apply(prev => {
        if (prev?.type !== "light" || prev?.data.shape?.type !== "freeform") return [];
        return [{type: "light", operations: [{type: "update-shape", operations: ValueFn.apply([{type: "freeform", operations: [{
          type: "update-areas", operations
        }]}])}]}];
      });
    }
  ), [elementRef])

  const origin = useComputed((): Point => elementRef.value?.data.origin ?? [0, 0], [elementRef]);
  const color = useComputed((): HSLA => {
    if (elementRef.value?.type !== "light") return Color.GREEN;
    return elementRef.value.data.shape?.data.color ?? Color.GREEN;
  }, [elementRef]);

  const areas = useSignalValue(areasRef);
  return (<>
    <AreasEditor origin={origin} color={color} valueRef={areasRef} />
    {areas.map((area, index) => {
      const splineOrigin = Vector2.subtract(origin, SplineFn.getSplineOrigin(area));
      const splineSize = Vector2.multiply(SplineFn.getSplineSize(area), 0.5);
      const center = Vector2.subtract(splineOrigin, splineSize);
      return <CircleShader key={index} color={color} origin={Vector2.add(center, [4, 4])} size={[8, 8]} />
    })}
  </>);
}

export function LightElementHUDPass({value: {id, children, transform, shape, origin}}: {
  value: LightNode;
}) {
  const model = useModel();
  const valueModel = Transform.divide(transform, model);

  const isEditor = !useIsTokenController();
  const isSelected = useIsActiveSelection(id);

  const shapeOrigin = LightShapeFn.getLightOrigin(shape, origin);
  const shapeSize = LightShapeFn.getLightSize(shape);
  const color = shape?.type !== undefined ? [shape.data.color[0], shape.data.color[1], shape.data.color[2], shape.data.intensity] as HSLA : Color.GREEN;

  return (<binder>
    <ModelProvider value={valueModel}>
      {[...children].reverse().map((element) => <ElementHUDPass key={element.data.id} value={element} />)}
      {isEditor && isSelected && <SelectionIndicator origin={shapeOrigin} size={shapeSize} color={Color.WHITE} />}
      {isEditor && isSelected && <>
        {shape?.type === "freeform" && <LightElementFreeformHUDPass elementID={id} />}
        {(shape?.type === "spotlight" || shape?.type === "sprite") && <CircleShader color={color} origin={[4, 4]} size={[8, 8]} />}
      </>}
    </ModelProvider>
  </binder>);
}