import {RefObject} from "react";
import {Transform, TransformOperation, TreeOperation} from "common/types/index.ts";
import {usePanHandlers} from "../use-pan-handlers.ts";
import {useZoomHandlers} from "../use-zoom-tool-handlers.ts";
import {useDropHandlers} from "../use-drop-handlers.ts";
import {useTouchHandlers} from "../use-touch-handlers.ts";
import {Node, NodeOperation} from "common/legends/index.ts";
import {MutableRef} from "common/ref";
import {useMouseEventHandlers, useWheelEventHandlers} from "../../../panel/nav/common/tool/tool-selector/use-mouse-event-handlers.ts";
import {useKeyEventHandlers} from "../../../panel/nav/common/tool/tool-selector/use-key-event-handlers.ts";

export function usePanToolHandlers(
  canvasRef: RefObject<HTMLCanvasElement>,
  view: MutableRef<Transform, TransformOperation[]>,
  nodes: MutableRef<Node[], TreeOperation<Node, NodeOperation>[]>,
) {
  const {onPanMouseDown, onPanMouseMove, onPanMouseUp, onPanMouseEnter, onPanWheel, onPanContextMenu, onPanKeyDown, onPanKeyUp} = usePanHandlers(canvasRef, view, ev => ev.buttons !== 0);
  const {onZoomWheel, onZoomKeyDown} = useZoomHandlers(canvasRef, view);
  const {onTouchStart, onTouchMove, onTouchEnd} = useTouchHandlers(canvasRef, view);

  const onMouseDown = useMouseEventHandlers(onPanMouseDown);
  const onMouseMove = useMouseEventHandlers(onPanMouseMove);
  const onMouseUp = useMouseEventHandlers(onPanMouseUp);
  const onMouseEnter = onPanMouseEnter;
  const onKeyDown = useKeyEventHandlers(onPanKeyDown, onZoomKeyDown);
  const onKeyUp = useKeyEventHandlers(onPanKeyUp);
  const onContextMenu = useMouseEventHandlers(onPanContextMenu);
  const onWheel = useWheelEventHandlers(onZoomWheel, onPanWheel);

  const {onDrop} = useDropHandlers(view, nodes);
  return {
    onMouseUp,
    onMouseMove,
    onMouseDown,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    onContextMenu,
    onMouseEnter,
    onWheel,
    onKeyDown,
    onKeyUp,
    onDrop
  };
}
