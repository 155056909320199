import {Point, PointOperation, pointType, Transform, TransformOperation, transformType} from "#common/types/index.ts";
import {BaseNode, BaseNodeOperation, BaseNodeSignals, baseNodeTypePropTypes, defaultBaseNode} from "./base-node.ts";
import {VisibilityLayer, VisibilityLayerOperation, visibilityLayerType} from "#common/legends/visibility/index.ts";
import {Mask, MaskOperation, maskType} from "#common/types/generic/mask/mask.ts";
import {MutableRef} from "#common/ref";
import {VisibilityLayerFn} from "../visibility/index.ts";

export type LocalNode = BaseNode & {
  transform: Transform;
  visibilityLayer: VisibilityLayer;
  selectionMask: Mask;
  origin: Point;
  pivot: Point;
};

export type LocalNodeOperation =
  | BaseNodeOperation
  | {type: "update-origin", operations: PointOperation[]}
  | {type: "update-pivot", operations: PointOperation[]}
  | {type: "update-transform", operations: TransformOperation[]}
  | {type: "update-visibility-layer", operations: VisibilityLayerOperation[]}
  | {type: "update-selection-mask", operations: MaskOperation[]}
  ;


export const localNodeTypePropTypes = () => ({
  ...baseNodeTypePropTypes(),
  origin: pointType,
  pivot: pointType,
  transform: transformType,
  selectionMask: maskType,
  visibilityLayer: visibilityLayerType
});

export const localNodeUpdater = (v: any) => {
  if (v.visibilityLayer === undefined) v.visibilityLayer = [];
  if (v.selectionMask === undefined) v.selectionMask = [];
  if (v.pivot === undefined) v.pivot = v.origin;
  return v;
}

export function defaultLocalNode(): LocalNode {
  return {
    ...defaultBaseNode(),
    name: "LocalNode",
    origin: [0, 0],
    pivot: [0, 0],
    transform: Transform.DEFAULT,
    selectionMask: ["GM"],
    visibilityLayer: VisibilityLayerFn.DEFAULT,
  };
}

export function LocalNodeSignals(value: MutableRef<LocalNode, LocalNodeOperation[]>) {
  return {
    ...BaseNodeSignals(value),
    originRef: value.map<Point, PointOperation[]>(value => value.origin, (_, operations) => [{type: "update-origin", operations}]),
    pivotRef: value.map<Point, PointOperation[]>(value => value.pivot, (_, operations) => [{type: "update-pivot", operations}]),
    transformRef: value.map<Transform, TransformOperation[]>(value => value.transform, (_, operations) => [{type: "update-transform", operations}]),
    selectionMaskRef: value.map<Mask, MaskOperation[]>(value => value.selectionMask, (_, operations) => [{type: "update-selection-mask", operations}]),
    visibilityLayerRef: value.map<VisibilityLayer, VisibilityLayerOperation[]>(value => value.visibilityLayer, (_, operations) => [{type: "update-visibility-layer", operations}])
  };
}
