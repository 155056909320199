import {Node} from "common/legends/index.ts";
import {useIsElementAccessible, useIsElementVisible} from "../viewport/common/context/visibility-context.ts";
import {useConditionsMet} from "../viewport/common/context/use-conditions-met.ts";
import React from "react";
import {Point, Transform} from "common/types/index.ts";
import {ModelProvider, useModel} from "../viewport/common/context/pvm-context.ts";
import {AudioElementAudioPass} from "./audio/audio-element-audio-pass.tsx";
import {TokenElementAudioPass} from "./token/token-element-audio-pass.tsx";

export const ElementAudioPass = React.memo(function ElementRenderPass({value, origin}: {
  value: Node;
  origin: Point | undefined
}) {
  const isVisible = useIsElementVisible()(value);
  const isAccessible = useIsElementAccessible()(value);
  const conditionsMet = useConditionsMet(value.data.conditions);
  const model = useModel();

  if ((!conditionsMet || !isVisible) && !isAccessible) return <></>;
  if (value.type === "audio") return <AudioElementAudioPass value={value.data} origin={origin} />
  else if (value.type === "token") return <TokenElementAudioPass value={value.data} origin={origin} />

  const valueModel = Transform.divide(value.data.transform, model);
  return (<ModelProvider value={valueModel}>
    {[...value.data.children].reverse().map((element) => <ElementAudioPass key={element.data.id} value={element} origin={origin} />)}
  </ModelProvider>);
});
