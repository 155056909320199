import {NameField} from "../../field/name-field.tsx";
import {VisibleField} from "../../field/visible-field.tsx";
import {TransformField} from "../../field/transform-field.tsx";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {useMemo} from "react";
import {AreaNode, AreaNodeOperation, AreaNodeSignals} from "common/legends/node/area-node.ts";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";
import {InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {ColorField} from "#lib/components/input/color-field.tsx";
import {OpacityField} from "../../field/opacity-field.tsx";
import {InputInteractions} from "../../panel/properties/node/interactions/input-interactions.tsx";
import {MutableRef} from "common/ref";
import {SelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {Panel, Section} from "#lib/components/panel-header.tsx";
import {useElementGrid} from "../../common/use-element-grid.ts";
import {GridProvider} from "../../viewport/common/context/grid-context.ts";

export function AreaElementProperties({value, reference, pinned}:  {
  value: MutableRef<AreaNode, AreaNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const {idRef, name, visibilityLayer, transform, suppressWalls, color, opacity, interactions} = useMemo(() => AreaNodeSignals(value), [value]);
  const elementGrid = useElementGrid(idRef);
  return <div className="tab-content flex flex-col py-2 gap-1">
    <GridProvider value={elementGrid}>
      <Panel title="Area Properties">
        <Section>
          <NameField value={name} reference={reference} pinned={pinned}/>
          <VisibleField value={visibilityLayer}/>
          <TransformField value={transform}/>

          <InputGroup className="pl-0 mx-2 rounded-md overflow-hidden">
            <InputCheckbox value={suppressWalls}/>
            <InputGroupLabel>Suppress Walls</InputGroupLabel>
          </InputGroup>

          <ColorField label="Area Color" value={color}/>
          <OpacityField value={opacity}/>
        </Section>
        <div className="flex flex-col gap-2 py-2">
          <Section>
            <InputInteractions value={interactions}/>
          </Section>
        </div>
      </Panel>
    </GridProvider>
  </div>
}