import {MutableSignal} from "common/signal";
import {Color, NumberFn, Optional, Point, PointFn, Size, Transform, TransformOperation, ValueFn} from "common/types/generic/index.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import React from "react";
import {useSignalValue} from "#lib/signal/index.ts";
import {ModelProvider, usePVM} from "../../viewport/common/context/pvm-context.ts";
import {MouseInteraction} from "../../viewport/mouse-interaction.tsx";
import {getWorldPositionFromScreenPosition} from "../../viewport/tool/use-get-world-pos.ts";
import {SelectionIndicator} from "../../viewport/common/selection-indicator/selection-indicator.tsx";
import {useGrid} from "../../viewport/common/context/grid-context.ts";
import {Grid} from "common/legends/scene/index.ts";

export type TransformEditorValue = {
  size: Size;
  origin: Point;
  pivot: Point;
  transform: Transform;
};
export type TransformEditorOperation =
  | {type: "update-transform", operations: TransformOperation[]}
  ;

function getTopRightScaleOperations(value: TransformEditorValue, delta: Size, grid: Grid, snapToGrid: boolean): TransformEditorOperation[] {
  const initialPoint: Point = [
    -value.origin[0]+value.size[0],
    -value.origin[1]+value.size[1]
  ];
  const targetPoint = Vector2.add(initialPoint, delta);
  const snapPoint = snapToGrid ? Vector2.divideTransform(Grid.snap(grid, Vector2.multiplyTransform(targetPoint, value.transform)), value.transform) : targetPoint;
  const snapDelta = Vector2.subtract(snapPoint, initialPoint);

  const newScaleX = value.transform.scale * ((value.size[0] + snapDelta[0]) / value.size[0]);
  const newScaleY = value.transform.scale * ((value.size[1] + snapDelta[1]) / value.size[1]);
  const newScale = Math.max(0.1, newScaleX, newScaleY);
  const positionOffset = Vector2.subtract(
    Vector2.multiplyTransform(initialPoint, value.transform),
    Vector2.multiplyTransform(initialPoint, {...value.transform, scale: newScale})
  );
  return [{
    type: "update-transform", operations: ValueFn.apply([
      {type: "update-position", operations: PointFn.set(
          value.transform.position,
          Vector2.subtract(value.transform.position, positionOffset)
        )},
      {type: "update-scale", operations: NumberFn.set(value.transform.scale, newScale)}
    ])
  }];
}
function getTopLeftScaleOperations(value: TransformEditorValue, delta: Size, grid: Grid, snapToGrid: boolean): TransformEditorOperation[] {
  const initialPoint: Point = [
    -value.origin[0],
    -value.origin[1]+value.size[1]
  ];
  const targetPoint = Vector2.add(initialPoint, delta);
  const snapPoint = snapToGrid ? Vector2.divideTransform(Grid.snap(grid, Vector2.multiplyTransform(targetPoint, value.transform)), value.transform) : targetPoint;
  const snapDelta = Vector2.subtract(snapPoint, initialPoint);

  const newScaleX = value.transform.scale * ((value.size[0] - snapDelta[0]) / value.size[0]);
  const newScaleY = value.transform.scale * ((value.size[1] + snapDelta[1]) / value.size[1]);
  const newScale = Math.max(0.1, newScaleX, newScaleY);
  const positionOffset = Vector2.subtract(
    Vector2.multiplyTransform(initialPoint, value.transform),
    Vector2.multiplyTransform(initialPoint, {...value.transform, scale: newScale})
  );
  return [{
    type: "update-transform", operations: ValueFn.apply([
      {type: "update-position", operations: PointFn.set(
          value.transform.position,
          Vector2.subtract(value.transform.position, positionOffset)
        )},
      {type: "update-scale", operations: NumberFn.set(value.transform.scale, newScale)}
    ])
  }];
}
function getBottomLeftScaleOperations(value: TransformEditorValue, delta: Size, grid: Grid, snapToGrid: boolean): TransformEditorOperation[] {
  const initialPoint: Point = [
    -value.origin[0],
    -value.origin[1]
  ];
  const targetPoint = Vector2.add(initialPoint, delta);
  const snapPoint = snapToGrid ? Vector2.divideTransform(Grid.snap(grid, Vector2.multiplyTransform(targetPoint, value.transform)), value.transform) : targetPoint;
  const snapDelta = Vector2.subtract(snapPoint, initialPoint);

  const newScaleX = value.transform.scale * ((value.size[0] - snapDelta[0]) / value.size[0]);
  const newScaleY = value.transform.scale * ((value.size[1] - snapDelta[1]) / value.size[1]);
  const newScale = Math.max(0.1, newScaleX, newScaleY);
  const positionOffset = Vector2.subtract(
    Vector2.multiplyTransform(initialPoint, value.transform),
    Vector2.multiplyTransform(initialPoint, {...value.transform, scale: newScale})
  );
  return [{
    type: "update-transform", operations: ValueFn.apply([
      {type: "update-position", operations: PointFn.set(
        value.transform.position,
        Vector2.subtract(value.transform.position, positionOffset)
      )},
      {type: "update-scale", operations: NumberFn.set(value.transform.scale, newScale)}
    ])
  }];
}
function getBottomRightScaleOperations(value: TransformEditorValue, delta: Size, grid: Grid, snapToGrid: boolean): TransformEditorOperation[] {
  const initialPoint: Point = [
    -value.origin[0]+value.size[0],
    -value.origin[1]
  ];
  const targetPoint = Vector2.add(initialPoint, delta);
  const snapPoint = snapToGrid ? Vector2.divideTransform(Grid.snap(grid, Vector2.multiplyTransform(targetPoint, value.transform)), value.transform) : targetPoint;
  const snapDelta = Vector2.subtract(snapPoint, initialPoint);

  const newScaleX = value.transform.scale * ((value.size[0] + snapDelta[0]) / value.size[0]);
  const newScaleY = value.transform.scale * ((value.size[1] - snapDelta[1]) / value.size[1]);
  const newScale = Math.max(0.1, newScaleX, newScaleY);
  const positionOffset = Vector2.subtract(
    Vector2.multiplyTransform(initialPoint, value.transform),
    Vector2.multiplyTransform(initialPoint, {...value.transform, scale: newScale})
  );
  return [{
    type: "update-transform", operations: ValueFn.apply([
      {type: "update-position", operations: PointFn.set(
          value.transform.position,
          Vector2.subtract(value.transform.position, positionOffset)
        )},
      {type: "update-scale", operations: NumberFn.set(value.transform.scale, newScale)}
    ])
  }];
}

export function TransformEditor({valueRef, speculativeValueRef}: {
  valueRef: MutableSignal<Optional<TransformEditorValue>, TransformEditorOperation[]>;
  speculativeValueRef: MutableSignal<Optional<TransformEditorValue>, TransformEditorOperation[]>;
}) {
  const value = useSignalValue(valueRef);
  const {model, view} = usePVM();
  const speculativeValue = useSignalValue(speculativeValueRef);
  const grid = useGrid();
  if (value === undefined || speculativeValue === undefined) return <></>;

  const speculativeValueModel = Transform.divide(speculativeValue?.transform ?? Transform.DEFAULT, model);

  const initialResizeHandleSize: Size = [
    Math.max(8, speculativeValue.size[0] / 8),
    Math.max(8, speculativeValue.size[1] / 8)
  ];
  const resizeHandleSize: Size = [
    Math.min(initialResizeHandleSize[0], initialResizeHandleSize[1]),
    Math.min(initialResizeHandleSize[0], initialResizeHandleSize[1])
  ];

  const transform = (screenPos: Point) => Vector2.add(
    Vector2.divideTransform(getWorldPositionFromScreenPosition(view, screenPos), Transform.divide(value.transform, model)), value.origin
  );

  return <>
    <ModelProvider value={speculativeValueModel}>
      <MouseInteraction
        transform={transform}
        isInBounds={localPos => localPos[0] >= -resizeHandleSize[0] / 2 && localPos[0] <= resizeHandleSize[0] / 2 && localPos[1] >= -resizeHandleSize[1] / 2 && localPos[1] <= resizeHandleSize[1] / 2}
        draggable
        onDragMove={(event, startPos, endPos) => {
          speculativeValueRef.apply(_ => getBottomLeftScaleOperations(value!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey));
        }}
        onDragEnd={(event, startPos, endPos) => {
          valueRef.apply(prev => getBottomLeftScaleOperations(prev!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey))
          speculativeValueRef.apply(_ => []);
        }}
        onMouseDown={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }} />
      <SelectionIndicator
        origin={Vector2.add(speculativeValue.origin, Vector2.divide(resizeHandleSize, 2))}
        size={resizeHandleSize}
        color={Color.WHITE} />

      <MouseInteraction
        transform={transform}
        isInBounds={localPos => localPos[0] >= value.size[0] - resizeHandleSize[0] / 2 && localPos[0] <= value.size[0] + resizeHandleSize[0] / 2 && localPos[1] >= -resizeHandleSize[1] / 2 && localPos[1] <= resizeHandleSize[1] / 2}
        draggable
        onDragMove={(event, startPos, endPos) => {
          speculativeValueRef.apply(_ => getBottomRightScaleOperations(value!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey));
        }}
        onDragEnd={(event, startPos, endPos) => {
          valueRef.apply(prev => getBottomRightScaleOperations(prev!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey));
          speculativeValueRef.apply(_ => []);
        }}
        onMouseDown={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }} />
      <SelectionIndicator
        origin={[
          speculativeValue.origin[0] - speculativeValue.size[0] + resizeHandleSize[0] / 2,
          speculativeValue.origin[1] + resizeHandleSize[1] / 2
        ]}
        size={resizeHandleSize}
        color={Color.WHITE} />

      <MouseInteraction
        transform={transform}
        isInBounds={localPos => localPos[0] >= -resizeHandleSize[0]/2 && localPos[0] <= resizeHandleSize[0]/2 && localPos[1] >= value.size[1] - resizeHandleSize[1]/2 && localPos[1] <= value.size[1] + resizeHandleSize[1]/2}
        draggable
        onDragMove={(event, startPos, endPos) => {
          speculativeValueRef.apply(_ => getTopLeftScaleOperations(value!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey));
        }}
        onDragEnd={(event, startPos, endPos) => {
          valueRef.apply(prev => getTopLeftScaleOperations(prev!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey));
          speculativeValueRef.apply(_ => []);
        }}
        onMouseDown={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }} />
      <SelectionIndicator
        origin={[speculativeValue.origin[0] + resizeHandleSize[0] / 2, speculativeValue.origin[1] - speculativeValue.size[1] + resizeHandleSize[1] / 2]}
        size={resizeHandleSize}
        color={Color.WHITE} />

      <MouseInteraction
        transform={transform}
        isInBounds={localPos => localPos[0] >= value.size[0] - resizeHandleSize[0] / 2 && localPos[0] <= value.size[0] + resizeHandleSize[0]/2 && localPos[1] >= value.size[1] - resizeHandleSize[1]/2 && localPos[1] <= value.size[1] + resizeHandleSize[1] /2}
        draggable
        onDragMove={(event, startPos, endPos) => {
          speculativeValueRef.apply(_ => getTopRightScaleOperations(value!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey));
        }}
        onDragEnd={(event, startPos, endPos) => {
          valueRef.apply(prev => getTopRightScaleOperations(prev!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey));
          speculativeValueRef.apply(_ => []);
        }}
        onMouseDown={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }} />
      <SelectionIndicator
        origin={[speculativeValue.origin[0] - speculativeValue.size[0] + resizeHandleSize[0]/2, speculativeValue.origin[1] - speculativeValue.size[1] + resizeHandleSize[1]/2]}
        size={resizeHandleSize}
        color={Color.WHITE} />
    </ModelProvider>
  </>
}
