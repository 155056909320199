import {MutableRef} from "common/ref";
import {LightShapeFreeform, LightShapeFreeformOperation, LightShapeFreeformRef} from "common/legends/node/light/light-shape-freeform.ts";
import {useMemo} from "react";
import {ColorField} from "#lib/components/input/color-field.tsx";
import {SectionBody} from "#lib/components/panel-header.tsx";
import {NumberField} from "../../../../field/generic/number-field.tsx";
import {GridWidthNumberField} from "../../../../field/generic/grid-width-number-field.tsx";

export function LightShapeFreeformFields({valueRef}: {
  valueRef: MutableRef<LightShapeFreeform, LightShapeFreeformOperation[]>
}) {
  const {colorRef, intensityRef, falloffRef, falloffStrengthRef} = useMemo(() => LightShapeFreeformRef(valueRef), [valueRef]);

  return <SectionBody className="pt-2">
    <ColorField label="Color" value={colorRef}/>

    <NumberField label="Intensity" valueRef={intensityRef} min={0} max={Number.POSITIVE_INFINITY} />
    <NumberField label="Falloff Strength" valueRef={falloffStrengthRef} min={0} max={Number.POSITIVE_INFINITY} />
    <GridWidthNumberField label="Falloff Radius" valueRef={falloffRef} min={0} max={Number.POSITIVE_INFINITY} />
  </SectionBody>
}