import {
  BooleanOperation, booleanType, ListOperation, ListType,
  ObjectType,
  Point,
  PointOperation,
  SetOperation,
  SetType,
  Size,
  SizeOperation,
  sizeType,
  StringOperation,
  Transform,
  TransformOperation,
  Type
} from "#common/types/index.ts";
import {LocalNode, LocalNodeOperation, localNodeTypePropTypes, localNodeUpdater} from "#common/legends/node/local-node.ts";
import {MutableRef} from "#common/ref";
import {Mask, MaskOperation} from "#common/types/generic/mask/mask.ts";
import {NodeId, UserID} from "#common/legends/index.ts";
import {StageID} from "#common/legends/stage/stage-i-d.js";
import {TokenVision, TokenVisionOperation, tokenVisionType} from "#common/legends/asset/token/token-vision.js";

export type CameraElement = LocalNode & {
  selectableByOwner: boolean;
  size: Size;
  ownerIDs: UserID[];
  stageIDs: StageID[];
  vision: TokenVision[];
};

export type CameraElementOperation =
  | LocalNodeOperation
  | {type: "update-size", operations: SizeOperation[]}
  | {type: "update-selectable-by-owner", operations: BooleanOperation[]}
  | {type: "update-owner-i-ds", operations: SetOperation<UserID>[]}
  | {type: "update-stage-i-ds", operations: SetOperation<StageID>[]}
  | {type: "update-vision", operations: ListOperation<TokenVision, TokenVisionOperation>[]}
  ;

export const cameraElementType: Type<CameraElement, CameraElementOperation> = new ObjectType(() => ({
  ...localNodeTypePropTypes(),
  size: sizeType,
  selectableByOwner: booleanType,
  ownerIDs: new SetType<UserID>(),
  stageIDs: new SetType<StageID>(),
  vision: new ListType(tokenVisionType)
}), v => {
  v = localNodeUpdater(v);
  if (!v.transform) v.transform = Transform.DEFAULT;
  if (v.selectableByOwner === undefined) v.selectableByOwner = false;
  if (v.ownerIDs === undefined) v.ownerIDs = [];
  if (v.stageIDs === undefined) v.stageIDs = [];
  if (v.vision === undefined) v.vision = [];
  return v;
});

export const CameraElementFn = {
  withinPoint: (value: CameraElement, x: number, y: number): boolean => {
    const [w, h] = value.size;
    const [nx, ny] = value.origin;
    return ((0 - nx) <= x && x <= (w - nx) && (0 - ny) <= y && y <= (h - ny));
  },
  expand(value: MutableRef<CameraElement, CameraElementOperation[]>) {
    return {
      idRef: value.map<NodeId>(value => value.id),
      nameRef: value.map<string, StringOperation[]>(
        value => value.name,
        (_, operations) => [{type: "update-name", operations}]
      ),
      originRef: value.map<Point, PointOperation[]>(
        value => value.origin,
        (_, operations) => [{type: "update-origin", operations}]
      ),
      pivotRef: value.map<Point, PointOperation[]>(
        value => value.pivot,
        (_, operations) => [{type: "update-pivot", operations}]
      ),
      transformRef: value.map<Transform, TransformOperation[]>(
        value => value.transform,
        (_, operations) => [{type: "update-transform", operations}]
      ),
      selectionMaskRef: value.map<Mask, MaskOperation[]>(
        value => value.selectionMask,
        (_, operations) => [{type: "update-selection-mask", operations}]
      ),
      sizeRef: value.map<Size, SizeOperation[]>(
        value => value.size,
        (_, operations) => [{type: "update-size", operations}]
      ),
      ownerIDsRef: value.map<UserID[], SetOperation<UserID>[]>(
        value => value.ownerIDs,
        (_, operations) => [{type: "update-owner-i-ds", operations}]
      ),
      stageIDsRef: value.map<StageID[], SetOperation<StageID>[]>(
        value => value.stageIDs,
        (_, operations) => [{type: "update-stage-i-ds", operations}]
      ),
      visionRef: value.map<TokenVision[], ListOperation<TokenVision, TokenVisionOperation>[]>(
        value => value.vision,
        (_, operations) => [{type: "update-vision", operations}]
      )
    } as const;
  }

} as const;