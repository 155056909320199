import {
  BooleanOperation,
  booleanType,
  Color,
  ColorOperation,
  constantType,
  HSLA,
  ListOperation,
  ListPropertyRef,
  ListType,
  NumberOperation,
  numberType,
  ObjectType,
  PropertyRef,
  StringOperation,
  Transform,
  TransformOperation,
  Type,
  ValueType
} from "#common/types/index.ts";
import {VisibilityLayer, VisibilityLayerOperation} from "#common/legends/visibility/index.ts";
import {NodeCondition, NodeConditionOperation} from "#common/legends/node/condition/node-condition.ts";
import {Spline, SplineOperation, splineType} from "#common/types/generic/spline/index.ts";
import {Interaction, InteractionOperation, interactionType} from "#common/legends/node/interaction/interaction.ts";
import {MutableRef} from "#common/ref";
import {LocalNode, LocalNodeOperation, localNodeTypePropTypes, localNodeUpdater} from "./local-node.ts";
import {NodeId} from "#common/legends/index.ts";

export type AreaNode = LocalNode & {
  opacity: number;
  suppressWalls: boolean;
  color: HSLA;
  areas: Spline[];
  interactions: Interaction[];
};

export type AreaNodeOperation =
  | LocalNodeOperation
  | {type: "update-opacity", operations: NumberOperation[]}
  | {type: "update-suppress-walls", operations: BooleanOperation[]}
  | {type: "update-color", operations: ColorOperation[]}
  | {type: "update-areas", operations: ListOperation<Spline, SplineOperation>[]}
  | {type: "update-interactions", operations: ListOperation<Interaction, InteractionOperation>[]}
  ;

export const areaNodeType: Type<AreaNode, AreaNodeOperation> = new ObjectType(() => ({
  ...localNodeTypePropTypes(),
  opacity: numberType,
  suppressWalls: booleanType,
  color: new ValueType(constantType),
  areas: new ListType(splineType),
  interactions: new ListType(interactionType)
}), (value) => {
  value = localNodeUpdater(value);
  if (value.color === undefined) value.color = Color.GREEN;
  if (value.opacity === undefined) value.opacity = 0.25;
  if (value.spline !== undefined) {
    value.areas = [value.spline];
    delete value["spline"];
  }
  if (value.interactions === undefined) {
    value.interactions = [];
  }
  if (value.origin === undefined) value.origin = [0, 0];
  return value;
});

export function AreaNodeSignals(value: MutableRef<AreaNode, AreaNodeOperation[]>) {
  return {
    idRef: value.map<NodeId>(value => value.id),
    name: PropertyRef<AreaNode, AreaNodeOperation, string, StringOperation>(
      value => value.name,
      operations => [{type: "update-name", operations}]
    )(value),
    transform: PropertyRef<AreaNode, AreaNodeOperation, Transform, TransformOperation>(
      value => value.transform,
      operations => [{type: "update-transform", operations}]
    )(value),
    opacity: PropertyRef<AreaNode, AreaNodeOperation, number, NumberOperation>(
      value => value.opacity,
      operations => [{type: "update-opacity", operations}]
    )(value),
    visibilityLayer: PropertyRef<AreaNode, AreaNodeOperation, VisibilityLayer, VisibilityLayerOperation>(
      value => value.visibilityLayer,
      operations => [{type: "update-visibility-layer", operations}]
    )(value),
    conditions: ListPropertyRef<AreaNode, AreaNodeOperation, NodeCondition, NodeConditionOperation>(
      value => value.conditions,
      operations => [{type: "update-conditions", operations}]
    )(value),
    suppressWalls: PropertyRef<AreaNode, AreaNodeOperation, boolean, BooleanOperation>(
      value => value.suppressWalls,
      operations => [{type: "update-suppress-walls", operations}]
    )(value),
    color: PropertyRef<AreaNode, AreaNodeOperation, HSLA, ColorOperation>(
      value => value.color,
      operations => [{type: "update-color", operations}]
    )(value),
    areas: ListPropertyRef<AreaNode, AreaNodeOperation, Spline, SplineOperation>(
      value => value.areas,
      operations => [{type: "update-areas", operations}]
    )(value),
    interactions: ListPropertyRef<AreaNode, AreaNodeOperation, Interaction, InteractionOperation>(
      value => value.interactions,
      operations => [{type: "update-interactions", operations}]
    )(value)
  };
}
