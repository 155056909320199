import {MutableRef} from "common/ref";
import {Arc, ArcOperation, ArcRef, Cone, ConeOperation, ConeRef, Shape, ShapeOperation, ShapeTypes} from "common/types/generic/shape/index.ts";
import {Button, ButtonBar, InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleThreeQuartersStroke, faRectangle, faTriangle} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import {useTypedRef} from "../../../common/use-typed-ref.ts";
import {Rectangle, RectangleOperation, RectangleRef, ValueFn} from "common/types/generic/index.ts";
import {useMemo} from "react";
import {useUnwrapValueRef} from "../../../common/use-unwrap-value-ref.ts";
import {Field, FieldLabel, SectionBody} from "#lib/components/panel-header.tsx";
import {GridWidthNumberField} from "../../../field/generic/grid-width-number-field.tsx";
import {GridHeightNumberField} from "../../../field/generic/grid-height-number-field.tsx";

export function RectangleField({valueRef}: {valueRef: MutableRef<Rectangle, RectangleOperation[]>}) {
  const {widthRef, heightRef} = useMemo(() => RectangleRef(valueRef), [valueRef]);
  return <SectionBody>
    <Field>
      <FieldLabel>Size</FieldLabel>
      <div className="flex flex-row gap-0.5 mx-2 rounded-md overflow-hidden">
        <GridWidthNumberField pt={{group: "mx-0", label: "px-0"}} label="W" valueRef={widthRef} />
        <GridHeightNumberField pt={{group: "mx-0", label: "px-0"}} label="H" valueRef={heightRef} />
      </div>
    </Field>
  </SectionBody>
}

export function ConeField({valueRef}: {valueRef: MutableRef<Cone, ConeOperation[]>}) {
  const {lengthRef, sizeAngleRef} = useMemo(() => ConeRef(valueRef), [valueRef]);
  return <SectionBody>
    <GridWidthNumberField label="Length" valueRef={lengthRef} min={0} />

    <Field>
      <FieldLabel>Angle</FieldLabel>
      <InputGroup className="mx-2 rounded-md overflow-hidden">
        <InputNumber value={sizeAngleRef} />
      </InputGroup>
    </Field>
  </SectionBody>
}

export function ArcField({valueRef}: {valueRef: MutableRef<Arc, ArcOperation[]>}) {
  const {radiusRef, startAngleRef, endAngleRef} = useMemo(() => ArcRef(valueRef), [valueRef]);
  return <SectionBody>
    <GridWidthNumberField label="Radius" valueRef={radiusRef} min={0} />

    <Field>
      <FieldLabel>Angle</FieldLabel>
      <div className="flex flex-row gap-0.5 mx-2 rounded-md overflow-hidden">
        <InputGroup className="flex-1">
          <InputGroupLabel>Start</InputGroupLabel>
          <InputNumber value={startAngleRef}/>
        </InputGroup>
        <InputGroup className="flex-1">
          <InputGroupLabel>End</InputGroupLabel>
          <InputNumber value={endAngleRef}/>
        </InputGroup>
      </div>
    </Field>
  </SectionBody>
}

export function ShapeField({valueRef}: { valueRef: MutableRef<Shape, ShapeOperation[]> }) {
  const [type, typedRef] = useTypedRef<ShapeTypes>(useUnwrapValueRef(valueRef));
  const setRectangle = () => {
    valueRef.apply(prev => ValueFn.set(prev, {
      type: "rectangle",
      data: {width: 64, height: 64}
    }));
  };
  const setCone = () => {
    valueRef.apply(prev => ValueFn.set(prev, {
      type: "cone",
      data: {length: 64, sizeAngle: 2 * Math.atan(0.5) * 360 / (2 * Math.PI)}
    }));
  };
  const setArc = () => {
    valueRef.apply(prev => ValueFn.set(prev, {
      type: "arc",
      data: {radius: 64, startAngle: -22.5, endAngle: 22.5}
    }));
  };

  return <Field>
    <FieldLabel>Shape</FieldLabel>
    <div className="bg-zinc-800/50 mx-2 rounded-md overflow-hidden">
      <ButtonBar>
        <Button variant={type === "rectangle" ? "primary" : undefined} className="flex-1" onClick={type !== "rectangle" ? setRectangle : undefined}>
          <FontAwesomeIcon icon={faRectangle} /> Rectangle
        </Button>
        <Button variant={type === "cone" ? "primary" : undefined} className="flex-1" onClick={type !== "cone" ? setCone : undefined}>
          <FontAwesomeIcon icon={faTriangle} /> Cone
        </Button>
        <Button variant={type === "arc" ? "primary" : undefined} className="flex-1" onClick={type !== "arc" ? setArc : undefined}>
          <FontAwesomeIcon icon={faCircleThreeQuartersStroke} /> Arc
        </Button>
      </ButtonBar>
      <div className="pt-2">
        {type === "rectangle" && <RectangleField valueRef={typedRef} />}
        {type === "cone" && <ConeField valueRef={typedRef} />}
        {type === "arc" && <ArcField valueRef={typedRef} />}
      </div>
    </div>
  </Field>
}