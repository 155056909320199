import {Button, IconButton, Spacer} from "#lib/components/index.ts";
import {Menu} from "@headlessui/react";
import React, {useMemo} from "react";
import {UUID} from "common/utils";
import {GameSignals} from "common/legends/index.ts";
import {usePlayerController} from "./player-controller-provider.ts";
import {useGame} from "../../../../routes/game";
import {PlayerStackValue} from "./player-stack-type.ts";
import {usePlayerToolMode} from "./use-player-tool-mode.ts";
import {useSelectedSheetReference} from "../../sheet/editor/dnd-5e-character/use-selected-sheet.ts";
import {LegendsIcon} from "../../../../legends-icon.tsx";
import {QuickAccessMenu} from "../common/quick-access-menu/dnd-5e/quick-access-menu.tsx";
import {PlayerStacks} from "./player-stacks.ts";
import {PlayerTurnIndicator} from "./player-turn-indicator.tsx";
import {PlayersStatus} from "./players-status.tsx";
import {PlayerCharacterSelector} from "./player-character-selector.tsx";
import {FaEllipsisVertical} from "react-icons/fa6";
import {useRefValue} from "#lib/signal/index.ts";
import {DialogIDFn} from "#lib/container/index.ts";
import {usePresent} from "../../../common/use-optional-signal.ts";
import {ToolModeInput} from "../common/tool/tool-mode-input.tsx";
import {MasterVolumeControl} from "../../../common/master-volume/components/master-volume-control.tsx";

export function PlayerNavigation() {
  const playerController = usePlayerController();
  const game = useGame();
  const {name} = useMemo(() => GameSignals(game), [game]);
  const gameName = useRefValue(name);

  const onAddStackItem = (stackItem: PlayerStackValue) => {
    const dialogId = DialogIDFn.generate();
    const stackId = UUID.generate();
    playerController.container.apply(prev => [{
      type: "create-dialog",
      content: {
        id: dialogId,
        value: {
          type: "layout",
          layout: {
            type: "stack",
            activeId: stackId,
            items: [{
              id: stackId,
              content: stackItem
            }]
          },
        },
        minimized: false,
        external: false,
        position: [48 * prev.dialogs.length + 48, 24 * prev.dialogs.length + 24],
        size: [720, 480]
      }
    }]);
  }

  const toolRef = usePlayerToolMode();
  const sheetReference = usePresent(useSelectedSheetReference());

  return (<div className="basis-14 flex flex-row h-14 text-white items-center gap-2 bg-zinc-700/20">
    <LegendsIcon className="w-12 h-12 p-1" />
    <span className="font-bold">{gameName}</span>

    {sheetReference && <QuickAccessMenu sheetRef={sheetReference} />}

    <Spacer />

    <PlayerTurnIndicator />

    <PlayersStatus />

    <PlayerCharacterSelector />

    <ToolModeInput valueRef={toolRef} />

    <MasterVolumeControl />

    <Menu as="div" className={"flex-0"}>
      <Menu.Button as={IconButton} size="medium" title="Add Dialog">
        <FaEllipsisVertical />
      </Menu.Button>
      <Menu.Items className="absolute z-10 right-2 mt-2 w-48 origin-top-right divide-y divide-zinc-900/20 rounded-md overflow-hidden bg-zinc-800/50 backdrop-blur shadow-lg focus:outline-none flex flex-col">
        {Object.entries(PlayerStacks).map(([stackType, stack]) => {
          const TabIcon = stack.Icon;
          return <Menu.Item key={stackType}>{({active}) => (
            <Button className={`flex justify-flex-end items-center gap-2 px-4 py-2 font-bold ${active ? "bg-zinc-900/50" : ""}`} onClick={() => onAddStackItem(stack.defaultContent)}>
              <span className="flex-1">{stack.label}</span>
              <TabIcon />
            </Button>
          )}</Menu.Item>
        })}
        <Menu.Item>{({active}) => (
          <Button className={`flex justify-flex-end items-center gap-2 px-4 py-2 font-bold ${active ? "bg-zinc-900/50" : ""}`} onClick={() => {
            playerController.resetContainer();
          }}>
            <span className="flex-1">Reset Panels</span>
          </Button>
        )}</Menu.Item>
      </Menu.Items>
    </Menu>
  </div>);
}