import {Node} from "common/legends/index.ts";
import {useIsElementAccessible, useIsElementVisible} from "../viewport/common/context/visibility-context.ts";
import {useConditionsMet} from "../viewport/common/context/use-conditions-met.ts";
import {AreaElementHUDPass} from "./area/area-element-h-u-d-pass.tsx";
import {GroupElementHUDPass} from "./group/group-element-h-u-d-pass.tsx";
import {ShapeElementHUDPass} from "./shape/shape-element-h-u-d-pass.tsx";
import {TextElementHUDPass} from "./text/text-element-h-u-d-pass.tsx";
import {TokenElementHUDPass} from "./token/token-element-h-u-d-pass.tsx";
import {WallElementHUDPass} from "./wall/wall-element-h-u-d-pass.tsx";
import {ParallaxElementHUDPass} from "./parallax/parallax-element-h-u-d-pass.tsx";
import {ImageElementHUDPass} from "./image/image-element-h-u-d-pass.tsx";
import {LightElementHUDPass} from "./light/light-element-h-u-d-pass.tsx";
import React from "react";
import {VideoElementHUDPass} from "./video/video-element-h-u-d-pass.tsx";
import {CameraElementHUDPass} from "./camera/camera-element-h-u-d-pass.tsx";
import {AudioElementHUDPass} from "./audio/audio-element-h-u-d-pass.tsx";

export const ElementHUDPass = React.memo(function ElementHUDPass({value}: {
  value: Node;
}) {
  const isVisible = useIsElementVisible()(value);
  const isAccessible = useIsElementAccessible()(value);
  const conditionsMet = useConditionsMet(value.data.conditions);

  if ((!conditionsMet || !isVisible) && !isAccessible) return <></>;

  if (value.type === "area") return <AreaElementHUDPass value={value.data}/>
  else if (value.type === "group") return <GroupElementHUDPass value={value.data} />
  else if (value.type === "image") return <ImageElementHUDPass value={value.data} />
  else if (value.type === "shape") return <ShapeElementHUDPass value={value.data}/>
  else if (value.type === "text") return <TextElementHUDPass value={value.data}/>
  else if (value.type === "token") return <TokenElementHUDPass value={value.data}/>
  else if (value.type === "wall") return <WallElementHUDPass value={value.data}/>
  else if (value.type === "parallax") return <ParallaxElementHUDPass value={value.data}/>
  else if (value.type === "light") return <LightElementHUDPass value={value.data} />
  else if (value.type === "video") return <VideoElementHUDPass value={value.data} />
  else if (value.type === "camera") return <CameraElementHUDPass value={value.data} />
  else if (value.type === "audio") return <AudioElementHUDPass value={value.data} />

  return <binder>
    {[...value.data.children].reverse().map(child => <ElementHUDPass key={child.data.id} value={child} />)}
  </binder>
});
