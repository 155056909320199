import {TokenNode} from "common/legends/index.ts";
import React, {useMemo} from "react";
import {Point, Transform} from "common/types/index.ts";
import {useTokenSheetReference} from "../../viewport/common/context/use-token-sheet.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {ModelProvider, useModel} from "../../viewport/common/context/pvm-context.ts";
import {useAsset} from "../../common/character/use-asset.ts";
import {SheetReferenceProvider} from "../../common/sheet/sheet-reference-context.ts";
import {OpacityProvider, useOpacity} from "../../viewport/common/context/opacity-context.ts";
import {CurrentTokenNodeProvider} from "../../viewport/common/context/token-node-context.ts";
import {ElementAudioPass} from "../element-audio-pass.tsx";

export function TokenElementAudioPass({value, origin}: {
  value: TokenNode;
  origin: Point | undefined
}) {
  const model = useModel();
  const asset = useRefValue(useAsset(value.tokenReference.assetID));
  const sheetReference = useTokenSheetReference(value);
  const token = !asset ? undefined : asset.tokens.find(token => token.tokenID === value.tokenReference.tokenID);
  const opacity = useOpacity();
  const valueOpacity = useMemo(() => value.opacity * opacity, [value.opacity, opacity]);
  const valueModel = useMemo(() => Transform.divide(value.transform, model), [value.transform, model]);
  return (<CurrentTokenNodeProvider value={value}>
    <SheetReferenceProvider value={sheetReference}>
      <ModelProvider value={valueModel}>
        <OpacityProvider value={valueOpacity}>
          {token && <React.Fragment key={value.id}>
            {[...token.children].reverse().map((element) => <ElementAudioPass key={element.data.id} value={element} origin={origin} />)}
          </React.Fragment>}
          {[...value.children].reverse().map((element) => <ElementAudioPass key={element.data.id} value={element} origin={origin} />)}
        </OpacityProvider>
      </ModelProvider>
    </SheetReferenceProvider>
  </CurrentTokenNodeProvider>);
}
