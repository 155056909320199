import {Dnd5eFeature, Dnd5eHealthOperation, Sheet, SheetInterface, SheetOperation} from "common/legends/index.ts";
import {useMemo} from "react";
import {applyAll, ConstantOperation, constantType, NumberFn, NumberOperation, numberType, ObjectType, Optional, PropertyRef} from "common/types/index.ts";
import {fromSignal, MutableRef} from "common/ref";
import {z} from "zod";
import {useGlobalFeatures} from "./use-global-features.ts";
import {computed} from "common/signal";

export const HealthIndicator = z.object({
  current: z.number(),
  temp: z.number(),
  max: z.number()
});
export type HealthIndicator = z.infer<typeof HealthIndicator>;
export const HealthIndicatorOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-current"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-temp"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-max"), operations: z.array(ConstantOperation)})
]);
export type HealthIndicatorOperation = z.infer<typeof HealthIndicatorOperation>;
const healthIndicatorType = new ObjectType({
  current: numberType,
  temp: numberType,
  max: constantType
});

export function HealthIndicatorRef(ref: MutableRef<HealthIndicator, HealthIndicatorOperation[]>) {
  return {
    currentRef: PropertyRef<HealthIndicator, HealthIndicatorOperation, number, NumberOperation>(
      value => value.current,
      operations => [{type: "update-current", operations}]
    )(ref),
    tempRef: PropertyRef<HealthIndicator, HealthIndicatorOperation, number, NumberOperation>(
      value => value.temp,
      operations => [{type: "update-temp", operations}]
    )(ref),
    maxRef: PropertyRef<HealthIndicator, HealthIndicatorOperation, number, ConstantOperation>(
      value => value.max,
      operations => [{type: "update-max", operations}]
    )(ref)
  };
}

export function useSheetHitPointsSignals(sheetRef: MutableRef<Sheet | undefined, SheetOperation[]>): MutableRef<HealthIndicator, HealthIndicatorOperation[]> {
  const globalFeaturesRef = useGlobalFeatures();
  return useMemo(() => {
    const valueFn = (sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[]) => {
      if (sheet?.type === "dnd-5e-character") {
        const max = SheetInterface.getMaxHP(sheet, globalFeatures)
        const {current, temp} = sheet.data.hitPoints;
        return {current: max + current, temp, max};
      } else if (sheet?.type === "dnd-5e-stat-block") {
        const max = SheetInterface.getMaxHP(sheet, globalFeatures)
        const {current, temp} = sheet.data.hitPoints;
        return {current: max + current, temp, max};
      } else {
        return {current: 0, temp: 0, max: 0};
      }
    }

    return fromSignal(computed(() => {
      const sheet = sheetRef.value;
      const globalFeatures = globalFeaturesRef.value;
      return valueFn(sheet, globalFeatures);
    }, (actions) => {
      const globalFeatures = globalFeaturesRef.value;
      sheetRef.apply(prev => {
        const current = valueFn(prev, globalFeatures);
        const next = applyAll(healthIndicatorType, current, actions);

        const operations: SheetOperation[] = [];
        if (prev?.type === "dnd-5e-character") {
          const max = SheetInterface.getMaxHP(prev, globalFeatures);
          const hitPointOperations: Dnd5eHealthOperation[] = [];
          if (next.current !== current.current) {
            hitPointOperations.push({type: "update-current", operations: NumberFn.set(
                prev.data.hitPoints.current,
                -max+next.current
              )});
          }
          if (next.temp !== current.temp) {
            hitPointOperations.push({type: "update-temp", operations: NumberFn.set(
                prev.data.hitPoints.temp,
                next.temp
              )});
          }
          if (hitPointOperations.length > 0) {
            operations.push({
              type: "dnd-5e-character", operations: [{
                type: "update-hit-points", operations: hitPointOperations
              }]
            });
          }
        } else if (prev?.type === "dnd-5e-stat-block") {
          const max = SheetInterface.getMaxHP(prev, globalFeatures);
          const hitPointOperations: Dnd5eHealthOperation[] = [];
          if (next.current !== current.current) {
            hitPointOperations.push({type: "update-current", operations: NumberFn.set(
                prev.data.hitPoints.current,
                -max+next.current
              )});
          }
          if (next.temp !== current.temp) {
            hitPointOperations.push({type: "update-temp", operations: NumberFn.set(
                prev.data.hitPoints.temp,
                next.temp
              )});
          }
          if (hitPointOperations.length > 0) {
            operations.push({
              type: "dnd-5e-stat-block", operations: [{
                type: "update-hit-points", operations: hitPointOperations
              }]
            });
          }
        }
        return operations;
      });
    }))
  }, [sheetRef]);
}
