import {TokenNode, TokenNodeOperation, tokenNodeType} from "common/legends/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import React, {useMemo} from "react";
import {Color, Optional, Transform} from "common/types/index.ts";
import {TextShader} from "#lib/gl-react/component/text-shader.tsx";
import {ElementHUDPass} from "../element-h-u-d-pass.tsx";
import {HealthIndicatorView} from "./health-indicator-view.tsx";
import {StatusIndicatorsView} from "./status-indicators-view.tsx";
import {SelectionIndicator} from "../../viewport/common/selection-indicator/selection-indicator.tsx";
import {useRefValue, useSignalValue} from "#lib/signal/index.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {ModelProvider, useModel} from "../../viewport/common/context/pvm-context.ts";
import {useAsset} from "../../common/character/use-asset.ts";
import {useIsNodeTurn} from "../../common/turn-tracker/use-is-current-turn.ts";
import {SheetReferenceProvider} from "../../common/sheet/sheet-reference-context.ts";
import {OpacityProvider, useOpacity} from "../../viewport/common/context/opacity-context.ts";
import {useTokenSheet, useTokenSheetReference} from "../../viewport/common/context/use-token-sheet.ts";
import {useIsActiveSelection} from "../../viewport/common/context/use-is-active-selection.ts";
import {CurrentTokenNodeProvider} from "../../viewport/common/context/token-node-context.ts";
import {useIsTokenController} from "../../viewport/common/context/use-is-token-controller.ts";
import {computed, MutableSignal} from "common/signal";
import {useAccess} from "../../../routes/game/model/store-context.tsx";
import {TransformEditor, TransformEditorOperation, TransformEditorValue} from "../common/transform-editor.tsx";
import {RotationEditor} from "../common/rotation-editor.tsx";
import {useTypedElementById} from "../common/use-typed-element-by-id.ts";
import {useSpeculativeValue} from "../common/use-speculative-value.ts";

function useTokenNodeTransformEditorRef(valueRef: MutableSignal<Optional<TokenNode>, TokenNodeOperation[]>): MutableSignal<Optional<TransformEditorValue>, TransformEditorOperation[]> {
  const access = useAccess();
  return useMemo(() => {
    return computed(
      (): Optional<TransformEditorValue> => {
        const tokenElement = valueRef.value;
        if (!tokenElement) return undefined;
        const assetToken = access.assetToken(tokenElement.tokenReference.assetID, tokenElement.tokenReference.tokenID).value;
        if (!assetToken) return undefined;

        return {
          size: assetToken.size,
          origin: assetToken.origin,
          pivot: assetToken.pivot,
          transform: tokenElement.transform,
        };
      },
      (operations) => {
        valueRef.apply(prev => {
          if (!prev) return [];
          return operations.flatMap((operation): TokenNodeOperation[] =>
            (operation.type === "update-transform")
              ? [operation]
              : []
          )
        });
      }
    )
  }, [access, valueRef])
}

export function TokenElementHUDPass({value}: {
  value: TokenNode;
}) {
  const model = useModel();
  const asset = useRefValue(useAsset(value.tokenReference.assetID));

  const sheetReference = useTokenSheetReference(value);
  const sheet = useTokenSheet(value);
  const isEditing = !useIsTokenController();

  const isTokenTurn = useObservable(useIsNodeTurn(value.id), false, [value.id]);

  const isSelected = useIsActiveSelection(value.id);
  const token = !asset ? undefined : asset.tokens.find(token => token.tokenID === value.tokenReference.tokenID);
  const opacity = useOpacity();
  const valueOpacity = useMemo(() => value.opacity * opacity, [value.opacity, opacity]);
  const valueModel = useMemo(() => Transform.divide(value.transform, model), [value.transform, model]);
  const interfaceScale = (token?.interfaceScale ?? 1);
  const interfaceModel = {...valueModel, scale: valueModel.scale * interfaceScale};

  const valueRef = useTypedElementById("token", value.id);
  const speculativeValueRef = useSpeculativeValue(tokenNodeType, valueRef);

  const speculativeValue = useSignalValue(speculativeValueRef);
  const speculativeValueModel = useMemo(() => Transform.divide(speculativeValue?.transform ?? Transform.DEFAULT, model), [speculativeValue, model]);

  const transformEditorRef = useTokenNodeTransformEditorRef(valueRef);
  const speculativeTransformEditorRef = useTokenNodeTransformEditorRef(speculativeValueRef);

  return (<CurrentTokenNodeProvider value={value}>
    <SheetReferenceProvider value={sheetReference}>
      <ModelProvider value={valueModel}>
        <OpacityProvider value={valueOpacity}>
          {token && <React.Fragment key={value.id}>
            {token.children.map((child) => <ElementHUDPass key={child.data.id} value={child} />)}
            <ModelProvider value={interfaceModel}>
              {value.displayHealthBar && <HealthIndicatorView
                sheetRef={sheet}
                origin={Vector2.multiply(token.origin, 1 / interfaceScale)}
                size={Vector2.multiply(token.size, 1 / interfaceScale)}
                displayNumbers={value.displayHealthNumbers}
              />}
              <StatusIndicatorsView
                value={sheet}
                opacity={valueOpacity}
                origin={Vector2.multiply(token.origin, 1 / interfaceScale)}
                size={Vector2.multiply(token.size, 1 / interfaceScale)} />
            </ModelProvider>

            {value.displayName && <ModelProvider value={Transform.divide({
              position: [
                ((token.size[0] || 0)/2 - (token.origin[0] || 0)),
                -(token.origin[1] || 32)-2
              ],
              scale: interfaceScale,
              rotation: 0
            }, valueModel)}>
              <TextShader size={22} hTextAlign="center" vTextAlign="top" value={value.name} />
            </ModelProvider>}
          </React.Fragment>}
          {[...value.children].reverse().map((child) => <ElementHUDPass key={child.data.id} value={child} />)}
        </OpacityProvider>
      </ModelProvider>
      {(isSelected || isTokenTurn) && token && value && <ModelProvider value={speculativeValueModel}>
        <SelectionIndicator origin={token.origin} size={token.size} color={isSelected ? Color.WHITE : Color.GREEN} />
      </ModelProvider>}
      {isSelected && <>
        <RotationEditor valueRef={transformEditorRef} speculativeValueRef={speculativeTransformEditorRef} />
        {isEditing && <>
          <TransformEditor valueRef={transformEditorRef} speculativeValueRef={speculativeTransformEditorRef} />
        </>}
      </>}
    </SheetReferenceProvider>
  </CurrentTokenNodeProvider>);
}
