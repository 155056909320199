import {Node, NodeOperation} from "common/legends/index.ts";
import {ConstantOperation, Optional, ValueOperation} from "common/types/index.ts";
import {useMemo} from "react";
import {ElementProperties} from "../../../element/element-properties.tsx";
import {useAccess} from "../../../../routes/game/model/store-context.tsx";
import {usePresent} from "../../../common/use-optional-signal.ts";
import {fromSignal, MutableRef} from "common/ref";
import {ElementEdgeSelectionRef, ElementSelectionRef, ElementVertexSelectionRef, SelectionRef} from "../../nav/editor/state/selection-ref.ts";

export function ElementPropertiesView({reference, pinned}: {
  reference: ElementSelectionRef | ElementEdgeSelectionRef | ElementVertexSelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const access = useAccess();
  const elementRef = useMemo((): MutableRef<Optional<Node>, NodeOperation[]> => {
    return fromSignal(access.element(reference.elementID));
  }, [access, reference.elementID]);
  const node = usePresent(elementRef);
  if (!node) return <></>;
  return <ElementProperties key={reference.elementID} value={node} reference={reference} pinned={pinned} />
}