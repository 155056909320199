import {useAccess} from "../../routes/game/model/store-context.tsx";
import {useMemo} from "react";
import {NodeId} from "common/legends/node/index.ts";
import {computed} from "common/signal";
import {useSignalValue} from "#lib/signal/index.ts";

export function useIsAssetElement(elementID: NodeId) {
  const access = useAccess();
  return useSignalValue(useMemo(() => computed(() => {
    const value = access.elementPath(elementID).value;
    return (value?.type === "asset-node");
  }), [access, elementID]));
}