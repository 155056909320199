import {GridNode, GridNodeOperation, GridNodeSignals} from "common/legends/index.ts";
import {ConstantOperation, Optional, OptionalOperation, Size, SizeOperation, ValueFn, ValueOperation} from "common/types/index.ts";
import {useMemo} from "react";
import {ButtonBar, ExpandableCheckbox, InputGroup, InputGroupRange} from "#lib/components/index.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {useObservable} from "#lib/qlab/index.ts";
import {NameField} from "../../field/name-field.tsx";
import {VisibleField} from "../../field/visible-field.tsx";
import {TransformField} from "../../field/transform-field.tsx";
import {SizeField} from "../../field/size-field.tsx";
import {MutableRef} from "common/ref";
import {ColorAlphaField} from "#lib/components/input/color-alpha-field.tsx";
import {SelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {Field, FieldLabel, Panel, Section, SectionHeader} from "#lib/components/panel-header.tsx";
import {useElementGrid} from "../../common/use-element-grid.ts";
import {GridProvider} from "../../viewport/common/context/grid-context.ts";

export function GridElementProperties({value, reference, pinned}: {
  value: MutableRef<GridNode, GridNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const {idRef, name, visibilityLayer, color, size, thickness, noise, transform} = useMemo(() => GridNodeSignals(value), [value]);
  const elementGrid = useElementGrid(idRef);

  return (<div className="tab-content flex flex-col py-2 gap-1">
    <GridProvider value={elementGrid}>
      <Panel title="Grid Properties">
        <Section>
          <NameField value={name} reference={reference} pinned={pinned} />

          <VisibleField value={visibilityLayer} />

          <ColorAlphaField label="Color" valueRef={color} />

          <TransformField value={transform} />

          <LimitsField value={size} />

          <Field>
            <FieldLabel>Thickness</FieldLabel>
            <InputGroup className="mx-2 rounded-md overflow-hidden">
              <InputGroupRange min={0} max={1} step={1/64} value={thickness} />
            </InputGroup>
          </Field>
          <Field>
            <FieldLabel>Noise</FieldLabel>
            <InputGroup className="mx-2 rounded-md overflow-hidden">
              <InputGroupRange min={0} max={1} step={1/64} value={noise} />
            </InputGroup>
          </Field>
        </Section>
      </Panel>
    </GridProvider>
  </div>);
}

export type LimitsFieldProps = {
  value: MutableRef<Optional<Size>, ValueOperation<Optional<Size>, OptionalOperation<SizeOperation>>[]>;
};

export function LimitsField({value}: LimitsFieldProps) {
  const size = useMemo((): MutableRef<Size, SizeOperation[]> =>
    value.map(
      value => value || [0, 0],
      (prev: Optional<Size>, operations: SizeOperation[]): ValueOperation<Optional<Size>, OptionalOperation<SizeOperation>>[] => {
        if (prev === undefined || operations.length === 0) return [];
        return ValueFn.apply(operations);
    }), [value]);
  const expanded = useObservable(pipe(
    value.observe,
    map(v => v !== undefined),
    distinct()
  ), false, [value.observe]);
  const toggleExpanded = () => {
    value.apply(prev => {
      if (prev === undefined) return ValueFn.set(prev, [64, 64])
      return ValueFn.set(prev, undefined);
    });
  };

  return (<div className="flex flex-col mx-2 rounded-md overflow-hidden bg-zinc-800/50">
    <ButtonBar>
      <ExpandableCheckbox expanded={expanded} toggleExpand={toggleExpanded}>
        <SectionHeader className="flex-1 bg-zinc-800/50" onClick={toggleExpanded}>Limit Size?</SectionHeader>
      </ExpandableCheckbox>
    </ButtonBar>
    {expanded && <Fieldset>
      <SizeField valueRef={size} />
    </Fieldset>}
  </div>)
}