import {Point} from "#common/types/generic/point/index.ts";
import {Matrix4x4, Matrix4x4Fn} from "../matrix/matrix4x4.ts";
import {Transform} from "../../types/index.ts";

export type Vector2 = [number, number];

export const Vector2 = {
  add([x1, y1]: Vector2, [x2, y2]: Vector2): Vector2 {
    return [x1+x2, y1+y2];
  },
  left(o: Vector2, s: Vector2, e: Vector2): boolean {
    return (e[0]-o[0])*(s[1]-o[1])-(e[1]-o[1])*(s[0]-o[0]) < 0;
  },
  abs([x, y]: Vector2): Vector2 {
    return [Math.abs(x), Math.abs(y)];
  },
  subtract([x1, y1]: Vector2, [x2, y2]: Vector2): Vector2 {
    return [x1-x2, y1-y2];
  },
  divide([x, y]: Vector2, s: number): Vector2 {
    return [x/s, y/s];
  },
  multiply([x, y]: Vector2, s: number): Vector2 {
    return [x*s, y*s];
  },
  multiplyMatrix4x4([x, y]: Vector2, mat4x4: Matrix4x4): Vector2 {
    const [nx, ny, _w, _z] = Matrix4x4Fn.multiplyVector(mat4x4, [x, y, 0, 1]);
    return [nx, ny];
  },
  multiplyTransform([x, y]: Vector2, transform: Transform): Vector2 {
    const scaledX = x*transform.scale;
    const scaledY = y*transform.scale;

    let rotatedX, rotatedY;
    if (transform.rotation !== 0) {
      const r = transform.rotation * Math.PI / 180;
      rotatedX = Math.cos(r) * scaledX - Math.sin(r) * scaledY;
      rotatedY = Math.sin(r) * scaledX + Math.cos(r) * scaledY;
    } else {
      rotatedX = scaledX;
      rotatedY = scaledY;
    }

    const translatedX = transform.position[0] + rotatedX;
    const translatedY = transform.position[1] + rotatedY;

    return [translatedX, translatedY];
  },
  divideTransform([x, y]: Vector2, transform: Transform): Vector2 {
    const translatedX = x - transform.position[0];
    const translatedY = y - transform.position[1];

    let rotatedX, rotatedY;
    if (transform.rotation === 0) {
      rotatedX = translatedX;
      rotatedY = translatedY;
    } else {
      const r = -(transform.rotation * Math.PI / 180);
      rotatedX = Math.cos(r) * translatedX - Math.sin(r) * translatedY;
      rotatedY = Math.sin(r) * translatedX + Math.cos(r) * translatedY;
    }

    const scaledX = rotatedX / transform.scale;
    const scaledY = rotatedY / transform.scale;

    return [scaledX, scaledY];
  },
  lerp(a: Vector2, b: Vector2, t: number): Vector2 {
    return [
      a[0] + (b[0] - a[0]) * t,
      a[1] + (b[1] - a[1]) * t
    ];
  },
  length([x, y]: Vector2): number {
    return Math.pow(x*x+y*y, 0.5);
  },
  normalize(a: Vector2): Vector2 {
    return Vector2.divide(a, Vector2.length(a));
  },
  dot([x1, y1]: Vector2, [x2, y2]: Vector2): number {
    return (x1*x2)+(y1*y2);
  },
  cross([x1, y1]: Vector2, [x2, y2]: Vector2): number {
    return x1*y2-y1*x2;
  },
  distance(a: Point, b: Point): number {
    return Vector2.length(Vector2.subtract(b, a))
  },
  pointIntersect(a: Point, b: Point, c: Point): number {
    const line = Vector2.subtract(b, a);
    const length = Vector2.length(line);
    let unit = Vector2.multiply(line, 1 / length);
    return Vector2.dot(unit, Vector2.subtract(c, a)) / length;
  },
  lineIntersect(a: Point, b: Point, c: Point, d: Point): number {
    const abX = b[0] - a[0];
    const abY = b[1] - a[1];
    const cdX = d[0] - c[0];
    const cdY = d[1] - c[1];
    const denominator = abX * cdY - abY * cdX;
    if (denominator == 0) return Number.POSITIVE_INFINITY;
    const caX = a[0] - c[0];
    const caY = a[1] - c[1];
    return (cdX * caY - cdY * caX) / denominator;
  },
  /** counterclockwise */
  ccw(a: Point, b: Point, c: Point): boolean {
    return (c[1]-a[1])*(b[0]-a[0]) > (b[1]-a[1])*(c[0]-a[0]);
  },
  isLineIntersect(a: Point, b: Point, c: Point, d: Point): boolean {
    return Vector2.ccw(a,c,d) != Vector2.ccw(b,c,d) && Vector2.ccw(a,b,c) != Vector2.ccw(a,b,d);
  },
  closestPointToLine(point: Point, start: Point, end: Point): Point {
    const p = Vector2.perpendicular(start, end);
    const d = Vector2.lineIntersect(start, end, point, Vector2.add(point, p));
    if (d >= 0 && d <= 1) {
      return Vector2.lerp(start, end, d);
    } else {
      return (Vector2.distance(point, start) > Vector2.distance(point, end))
        ? start
        : end;
    }
  },
  distanceToLine(point: Point, start: Point, end: Point): number {
    return Vector2.distance(point, Vector2.closestPointToLine(point, start, end));
  },
  perpendicular(a: Point, b: Point): Vector2 {
    const line = Vector2.subtract(b, a);
    return Vector2.normalize([line[1], -line[0]])
  },
  rotate([x, y]: Point, r: number): Point {
    if (r === 0) return [x, y];
    return [
      Math.cos(r) * x - Math.sin(r) * y,
      Math.sin(r) * x + Math.cos(r) * y
    ]
  },
  equals([x1, y1]: Vector2, [x2, y2]: Vector2): boolean {
    return x1 === x2 && y1 === y2;
  }
};
