import React, {useCallback, useRef, useState} from "react";
import {ListOperation, ListSignal} from "common/types/index.ts";
import {DropTargetMonitor, useDrag, useDragLayer, useDrop} from "react-dnd";
import {twMerge} from "tailwind-merge";
import {useListSignals} from "../../../legends/common/use-list-signals.ts";
import {MutableRef, Ref} from "common/ref";

type PlaceholderProps = { active: boolean; };
function Placeholder({active}: PlaceholderProps) {
  return <div className={twMerge(
    "border-white/30 border-dashed border-0 border-t-[0.25rem] h-0",
    active && "border-white"
  )} />
}

function getPlaceholderIndex(monitor: DropTargetMonitor, listElement: HTMLElement) {
  const offset = monitor.getClientOffset();
  if (offset === null) return -1;

  let index = 0;
  const {y} = offset;
  const isOver = monitor.isOver({shallow:false});
  if (isOver) {
    const children = listElement.children;
    for (let i = 0; i < children.length; i++) {
      const child = children.item(i);
      if (child?.role === "list-item") {
        const {top, bottom} = child.getBoundingClientRect();
        const height = bottom - top;

        if (top + height / 2 > y) break;
        index++;
      }
    }
  }
  return index;
}

export type InputListItemProps<T, O> = {
  item: MutableRef<T, O[]>;
  remove: () => void;
  duplicate: () => void;
};

export type InputListProps<T, O> = {
  accept: string;
  items: ListSignal<T, O>;
  itemKey: (item: T, index: number) => string | number;
  copy: (value: T) => T;
  ListItem: (props: InputListItemProps<T, O>) => JSX.Element;
};

function DroppableEmptyArea() {
  return <div className="h-10" />
}

export const InputList = function InputList<T, O>({className, emptyMessage, accept, items, itemKey, copy, ListItem}: {
  className?: string
  emptyMessage?: JSX.Element
} & InputListProps<T, O>) {
  const ref = useRef<HTMLDivElement>(null);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [{isOver}, dropRef] = useDrop<T, unknown, {isOver: boolean}>(() => ({
    accept,
    canDrop: () => true,
    hover: (_, monitor) => setHoverIndex(getPlaceholderIndex(monitor, ref.current!)),
    drop: (item, monitor) => {
      const index = getPlaceholderIndex(monitor, ref.current!);
      if (index === -1) return;
      let action = "move";
      return {
        action: items.apply(prev => {
          const itemIndex = prev.findIndex((listItem, listIndex) => itemKey(listItem, listIndex) === itemKey(item, -1));
          if (itemIndex !== -1) {
            if (itemIndex === index || itemIndex + 1 === index) return [];
          }

          action = itemIndex === -1 ? "copy" : "move";
          return itemIndex === -1
            ? ListOperation.insert(index, copy(item))
            : ListOperation.move(itemIndex, itemIndex < index ? index - 1 : index);
        }).then(() => action)
      };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({shallow: false})
    })
  }), [ref, items, setHoverIndex]);
  const isDragging = useDragLayer(useCallback(monitor => monitor.isDragging() && monitor.getItemType() === accept, [accept]));
  const itemsSignal = useListSignals(items, itemKey, copy);

  dropRef(ref);
  return <div ref={ref} role={"list"} className={twMerge(
    "relative flex flex-col gap-0 py-0 min-h-11 -my-1",
    !isDragging && "gap-1 py-1",
    isDragging && !isOver && "relative",
    className
  )}>
    {itemsSignal.map(([key, {itemRef, remove, duplicate}], index) => <React.Fragment key={key}>
      {isDragging && <Placeholder key="placeholder" active={hoverIndex === index} />}
      <ListItem key="item" item={itemRef} remove={remove} duplicate={duplicate} />
    </React.Fragment>)}
    {itemsSignal.length === 0 && emptyMessage}
    {isDragging && <Placeholder key="placeholder" active={hoverIndex === itemsSignal.length} />}
    {isDragging && itemsSignal.length === 0 && <DroppableEmptyArea />}
    {isDragging && !isOver && <div className="bg-gray-400/30 absolute pointer-events-none flex items-stretch justify-stretch top-0 bottom-0 left-0 right-0">
      <div className="m-1.5 border-dashed border-4 border-white text-black font-bold flex items-center justify-center flex-1 rounded-xl text-h400" />
    </div>}
  </div>
}

export function useDragListItem<T>(type: string, item: Ref<T>, remove: () => void) {
  const [, dragHandlerRef, dragRefPreview] = useDrag<T, {dropEffect: "copy" | "move", action: Promise<"copy" | "move">}>({
    type: type,
    item: () => item.value,
    end: (_, monitor) => {
      if (monitor.didDrop()) {
        const result = monitor.getDropResult();
        result?.action.then(response => {
          if (response === "copy" && result?.dropEffect !== "copy") remove();
        });
      }
    }
  }, [item, remove]);
  return [dragHandlerRef, dragRefPreview];
}
