import {ConstantOperation, ObjectType, Type} from "../../../types/index.ts";
import {z} from "zod";

export const AudioShapeGlobal = z.object({
});
export type AudioShapeGlobal = z.infer<typeof AudioShapeGlobal>;
export const AudioShapeGlobalOperation = ConstantOperation;
export type AudioShapeGlobalOperation = z.infer<typeof AudioShapeGlobalOperation>;

export type AudioShapeGlobalType = Type<AudioShapeGlobal, AudioShapeGlobalOperation>;

export const audioShapeGlobalType: AudioShapeGlobalType = new ObjectType({});
