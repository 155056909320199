import {KeyboardEvent, MouseEvent} from "react";

export abstract class WebGL2Node {
  children: WebGL2Node[] = [];

  appendChild(child: WebGL2Node): void {
    if (this.children.indexOf(child) !== -1)
      this.children.splice(this.children.indexOf(child), 1);
    this.children.push(child);
  }
  insertBefore(child: WebGL2Node, beforeChild: WebGL2Node): void {
    if (this.children.indexOf(child) !== -1) {
      this.children.splice(this.children.indexOf(child), 1);
    }
    this.children.splice(this.children.indexOf(beforeChild), 0, child);
  }
  removeChild(child: WebGL2Node): void {
    this.children.splice(this.children.indexOf(child), 1);
  }

  bind(): void {
    // no-op
  }

  action(): void {
    // no-op
  }

  unbind(): void {
    // no-op
  }

  handleMouseDown(event: MouseEvent): boolean {
    for (const child of [...this.children].reverse()) {
      if (event.isDefaultPrevented()) return true;
      if (event.isPropagationStopped()) return false;
      if (child.handleMouseDown(event)) return true;
    }
    return event.isDefaultPrevented();
  }

  handleMouseUp(event: MouseEvent): boolean {
    for (const child of [...this.children].reverse()) {
      if (event.isDefaultPrevented()) return true;
      if (event.isPropagationStopped()) return false;
      if (child.handleMouseUp(event)) return true;
    }
    return event.isDefaultPrevented();
  }

  handleMouseMove(event: MouseEvent): boolean {
    for (const child of [...this.children].reverse()) {
      if (event.isDefaultPrevented()) return true;
      if (event.isPropagationStopped()) return false;
      if (child.handleMouseMove(event)) return true;
    }
    return event.isDefaultPrevented();
  }

  handleKeyDown(event: KeyboardEvent): boolean {
    for (const child of [...this.children].reverse()) {
      if (event.isDefaultPrevented()) return true;
      if (event.isPropagationStopped()) return false;
      if (child.handleKeyDown(event)) return true;
    }
    return event.isDefaultPrevented();
  }

  handleKeyUp(event: KeyboardEvent): boolean {
    for (const child of [...this.children].reverse()) {
      if (event.isDefaultPrevented()) return true;
      if (event.isPropagationStopped()) return false;
      if (child.handleKeyUp(event)) return true;
    }
    return event.isDefaultPrevented();
  }

  visit(): void {
    this.bind();
    this.action();
    for (let i = 0; i < this.children.length; i ++) {
      this.children[i].visit();
    }
    this.unbind();
  }
}












