import {useMemo} from "react";
import {fromSignal} from "common/ref";
import {computed, effect, valueSignal} from "common/signal";
import {applyAll} from "common/types/type/index.ts";
import {MasterVolume, MasterVolumeOperation, masterVolumeType} from "../data/master-volume.ts";

function getMasterVolume(): MasterVolume {
  const value = localStorage.getItem("master-volume");
  if (value === null) return {muted: false, volume: 0.25};
  return JSON.parse(value);
}

const masterVolumeSignal = valueSignal(getMasterVolume());
effect(() => {
  localStorage.setItem("master-volume", JSON.stringify(masterVolumeSignal.value));
});

export function useMasterVolume() {
  return useMemo(() => fromSignal(computed(() => masterVolumeSignal.value, (operations: MasterVolumeOperation[]) => {
    masterVolumeSignal.apply(prev => applyAll(masterVolumeType, prev, operations));
  })), []);
}
