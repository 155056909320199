import {useCallback} from "react";
import {useContainer} from "#lib/container/react/container-context.ts";
import {DialogIDFn, StackId} from "#lib/container/index.ts";
import {PlayerStackValue} from "../../../panel/nav/player/player-stack-type.ts";
import {ListOperation, ValueFn} from "common/types/index.ts";
import {ContainerOperation} from "#lib/container/modal/container-operation.ts";
import {SheetReference, SheetReferenceFn} from "../../../common/sheet/sheet-reference.ts";
import {EditorStackValue} from "../../../panel/nav/editor/editor-stack.ts";

export function useOpenSheetReference() {
  const containerRef = useContainer();
  return useCallback((sheetReference: SheetReference) => {
    containerRef?.apply((prev): ContainerOperation<any, any>[] => {
      // Check if the sheet is already open... If it is, activate it and push the dialog to the top.
      for (let i = 0; i < prev.dialogs.length; i ++) {
        const dialog = prev.dialogs[i];
        const items = dialog.value.layout.items;
        const itemIndex = items.findIndex(item => item.content.type === "sheet" && SheetReferenceFn.equals(item.content.data.reference, sheetReference));
        if (itemIndex === -1) continue;
        const itemID = items[itemIndex].id;
        return [
          {type: "activate-dialog", id: dialog.id},
          {type: "apply-to-dialog", id: dialog.id, operations: [{type: "apply-to-layout", operations: [
            {type: "update-active-id", operations: ValueFn.set(dialog.value.layout.activeId, itemID)}
          ]}]}
        ];
      }

      // Add sheet to panel with only sheets
      const stackId = StackId.generate();
      const stackItem = {type: "sheet", data: {reference: sheetReference}} satisfies PlayerStackValue & EditorStackValue;

      for (let i = 0; i < prev.dialogs.length; i++) {
        const dialog = prev.dialogs[i];
        const items = dialog.value.layout.items;
        if (items.every(item => item.content.type === "sheet")) {
          return [
            {type: "activate-dialog", id: dialog.id},
            {type: "apply-to-dialog", id: dialog.id, operations: [{type: "apply-to-layout", operations: [
              {type: "update-items", operations: ListOperation.insert(items.length, {id: stackId, content: stackItem})},
              {type: "update-active-id", operations: ValueFn.set(dialog.value.layout.activeId, stackId)}
            ]}]}
          ];
        }
      }

      // Create new panel for sheets
      return [{
        type: "create-dialog",
        content: {
          id: DialogIDFn.generate(),
          value: {type: "layout", layout: {type: "stack", activeId: stackId, items: [{id: stackId, content: stackItem}]}},
          minimized: false,
          external: false,
          position: [48 * prev.dialogs.length + 48, 24 * prev.dialogs.length + 24],
          size: [720, 480]
        }
      }];
    });
  }, [containerRef]);
}