import {useMemo} from "react";
import {listIdentity} from "common/observable";
import {Optional} from "common/types/index.ts";
import {NodeId} from "common/legends/index.ts";
import {useAccess} from "../../../routes/game/model/store-context.tsx";
import {fromSignal, Ref} from "common/ref";
import {computed} from "common/signal";

export function useNodeTreeIDs(nodeRef: Ref<Optional<NodeId>>): Ref<NodeId[]> {
  const access = useAccess();
  return useMemo((): Ref<NodeId[]> => fromSignal(computed(() => {
    const path = access.elementPath(nodeRef.value).value;
    if (path?.type === "scene-node") {
      const ancestorElementIDs: NodeId[] = [];
      const scene = access.scene(path.sceneID).value;
      if (scene === undefined) return [];

      let children = scene.children;
      for (let i = 0; i < path.path.length; i ++) {
        const child = children[path.path[i]];
        if (child === undefined) break;
        ancestorElementIDs.push(child.data.id);

        children = child.data.children;
      }
      return ancestorElementIDs;
    } else {
      return [];
    }
  })).distinct(listIdentity), [access, nodeRef]);
}
