import {useArrayBuffer, useAttribLocation, useBindVertexArribArray, useProgram, useShader, useUniformLocation, useVertexBuffer} from "#lib/gl-react/index.ts";
import React, {useMemo} from "react";
import {Color, HSLA, Point} from "common/types/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {Line} from "../../scene/scene-view.tsx";
import {Vector2} from "common/math/vector/vector2.ts";
import {usePVM} from "../context/pvm-context.ts";

const vertexShader = `#version 300 es
precision highp float;
in vec2 a_position;
uniform mat4 u_projection;
uniform mat4 u_view;
uniform mat4 u_model;

out vec2 fragCoord;

void main()
{
  gl_Position = u_projection * u_view * vec4(a_position, 0, 1);
}
`;

const fragmentShader = `#version 300 es
precision highp float;
precision highp sampler2DArray;

uniform vec4 u_color;

out vec4 outColor;

void main() {
  outColor = u_color;
}
`;

function computeVboArray(o: Vector2, [topLeft, topRight, bottomRight, bottomLeft]: Vector2[], walls: Line[]): Float32Array {
  const floats: number[] = [];
  for (const {start, end, viewLeft, viewRight} of walls) {
    const s = start;
    const e = end;

    const left = Vector2.left(o, s, e);
    if (left && viewLeft) continue;
    if (!left && viewRight) continue;

    const leftStartT = Vector2.lineIntersect(o, s, bottomLeft, topLeft);
    const leftEndT = Vector2.lineIntersect(o, e, bottomLeft, topLeft);
    const rightStartT = Vector2.lineIntersect(o, s, topRight, bottomRight);
    const rightEndT = Vector2.lineIntersect(o, e, topRight, bottomRight);

    if (leftStartT > rightStartT && leftEndT > rightEndT) {
      const startIntersect = Vector2.lerp(o, s, Math.max(1, leftStartT));
      const endIntersect = Vector2.lerp(o, e, Math.max(1, leftEndT));
      floats.push(...s, ...e, ...startIntersect);
      floats.push(...e, ...endIntersect, ...startIntersect);
      continue;
    }

    const topStartT = Vector2.lineIntersect(o, s, topLeft, topRight);
    const topEndT = Vector2.lineIntersect(o, e, topLeft, topRight);
    const bottomStartT = Vector2.lineIntersect(o, s, bottomLeft, bottomRight);
    const bottomEndT = Vector2.lineIntersect(o, e, bottomLeft, bottomRight);
    if (topStartT > bottomStartT && topEndT > bottomEndT) {
      const startIntersect = Vector2.lerp(o, s, Math.max(1, topStartT));
      const endIntersect = Vector2.lerp(o, e, Math.max(1, topEndT));
      floats.push(...s, ...e, ...startIntersect);
      floats.push(...e, ...endIntersect, ...startIntersect);
      continue;
    }

    if (rightStartT > leftStartT && rightEndT > leftEndT) {
      const startIntersect = Vector2.lerp(o, s, Math.max(1, rightStartT));
      const endIntersect = Vector2.lerp(o, e, Math.max(1, rightEndT));
      floats.push(...s, ...e, ...startIntersect);
      floats.push(...e, ...endIntersect, ...startIntersect);
      continue;
    }
    if (bottomStartT > topStartT && bottomEndT > topEndT) {
      const startIntersect = Vector2.lerp(o, s, Math.max(1, bottomStartT));
      const endIntersect = Vector2.lerp(o, e, Math.max(1, bottomEndT));
      floats.push(...s, ...e, ...startIntersect);
      floats.push(...e, ...endIntersect, ...startIntersect);
      continue;
    }

    if (leftStartT > rightStartT && bottomStartT > topStartT && rightEndT > leftEndT && topEndT > bottomEndT) {
      const startIntersect = Vector2.lerp(o, s, Math.max(1, bottomStartT, leftStartT));
      const endIntersect = Vector2.lerp(o, e, Math.max(1, topEndT, rightEndT));
      const startEndIntersect = left ? bottomRight : topLeft;
      floats.push(...s, ...e, ...startEndIntersect);
      floats.push(...s, ...startIntersect, ...startEndIntersect);
      floats.push(...e, ...endIntersect, ...startEndIntersect);
      continue;
    }
    if (leftEndT > rightEndT && bottomEndT > topEndT && rightStartT > leftStartT && topStartT > bottomStartT) {
      const startIntersect = Vector2.lerp(o, s, Math.max(1, topStartT, rightStartT));
      const endIntersect = Vector2.lerp(o, e, Math.max(1, bottomEndT, leftEndT));
      const startEndIntersect = left ? topLeft : bottomRight;
      floats.push(...s, ...e, ...startEndIntersect);
      floats.push(...s, ...startIntersect, ...startEndIntersect);
      floats.push(...e, ...endIntersect, ...startEndIntersect);
      continue;
    }
    if (rightEndT > leftEndT && bottomEndT > topEndT && leftStartT > rightStartT && topStartT > bottomStartT) {
      const startIntersect = Vector2.lerp(o, s, Math.max(1, topStartT, leftStartT));
      const endIntersect = Vector2.lerp(o, e, Math.max(1, bottomEndT, rightEndT));
      const startEndIntersect = left ? bottomLeft : topRight;
      floats.push(...s, ...e, ...startEndIntersect);
      floats.push(...s, ...startIntersect, ...startEndIntersect);
      floats.push(...e, ...endIntersect, ...startEndIntersect);
      continue;
    }
    if (leftEndT > rightEndT && topEndT > bottomEndT && rightStartT > leftStartT && bottomStartT > topStartT) {
      const startIntersect = Vector2.lerp(o, s, Math.max(1, bottomStartT, rightStartT));
      const endIntersect = Vector2.lerp(o, e, Math.max(1, topEndT, leftEndT));
      let startEndIntersect = left ? topRight : bottomLeft;
      floats.push(...s, ...e, ...startEndIntersect);
      floats.push(...s, ...startIntersect, ...startEndIntersect);
      floats.push(...e, ...endIntersect, ...startEndIntersect);
      continue;
    }

    if (topEndT > bottomEndT && rightStartT < leftStartT) {
      const startIntersect = Vector2.lerp(o, s, Math.max(1, leftStartT));
      const endIntersect = Vector2.lerp(o, e, Math.max(1, topEndT));
      let startEndIntersect = left ? bottomRight : topLeft;
      floats.push(...s, ...e, ...startEndIntersect);
      floats.push(...s, ...startIntersect, ...startEndIntersect);
      floats.push(...e, ...endIntersect, ...startEndIntersect);
      continue;
    } else if (topEndT > bottomEndT && rightStartT > leftStartT) {
      const startIntersect = Vector2.lerp(o, s, Math.max(1, rightStartT));
      const endIntersect = Vector2.lerp(o, e, Math.max(1, topEndT));
      let startEndIntersect = left ? topRight : bottomLeft;
      floats.push(...s, ...e, ...startEndIntersect);
      floats.push(...s, ...startIntersect, ...startEndIntersect);
      floats.push(...e, ...endIntersect, ...startEndIntersect);
      continue;
    } else if (topEndT < bottomEndT && rightStartT < leftStartT) {
      const startIntersect = Vector2.lerp(o, s, Math.max(1, leftStartT));
      const endIntersect = Vector2.lerp(o, e, Math.max(1, bottomEndT));
      let startEndIntersect = left ? bottomLeft : topRight;
      floats.push(...s, ...e, ...startEndIntersect);
      floats.push(...s, ...startIntersect, ...startEndIntersect);
      floats.push(...e, ...endIntersect, ...startEndIntersect);
      continue;
    } else if (topEndT < bottomEndT && rightStartT > leftStartT) {
      const startIntersect = Vector2.lerp(o, s, Math.max(1, rightStartT));
      const endIntersect = Vector2.lerp(o, e, Math.max(1, bottomEndT));
      let startEndIntersect = left ? topLeft : bottomRight;
      floats.push(...s, ...e, ...startEndIntersect);
      floats.push(...s, ...startIntersect, ...startEndIntersect);
      floats.push(...e, ...endIntersect, ...startEndIntersect);
      continue;
    }

    if (leftEndT > rightEndT && topStartT < bottomStartT) {
      const startIntersect = Vector2.lerp(o, s, Math.max(1, bottomStartT));
      const endIntersect = Vector2.lerp(o, e, Math.max(1, leftEndT));
      let startEndIntersect = left ? topRight : bottomLeft;
      floats.push(...s, ...e, ...startEndIntersect);
      floats.push(...s, ...startIntersect, ...startEndIntersect);
      floats.push(...e, ...endIntersect, ...startEndIntersect);
      continue;
    } else if (leftEndT > rightEndT && topStartT > bottomStartT) {
      const startIntersect = Vector2.lerp(o, s, Math.max(1, topStartT));
      const endIntersect = Vector2.lerp(o, e, Math.max(1, leftEndT));
      let startEndIntersect = left ? topLeft : bottomRight;
      floats.push(...s, ...e, ...startEndIntersect);
      floats.push(...s, ...startIntersect, ...startEndIntersect);
      floats.push(...e, ...endIntersect, ...startEndIntersect);
      continue;
    } else if (leftEndT < rightEndT && topStartT < bottomStartT) {
      const startIntersect = Vector2.lerp(o, s, Math.max(1, bottomStartT));
      const endIntersect = Vector2.lerp(o, e, Math.max(1, rightEndT));
      let startEndIntersect = left ? bottomRight : topLeft;
      floats.push(...s, ...e, ...startEndIntersect);
      floats.push(...s, ...startIntersect, ...startEndIntersect);
      floats.push(...e, ...endIntersect, ...startEndIntersect);
      continue;
    } else if (leftEndT < rightEndT && topStartT > bottomStartT) {
      const startIntersect = Vector2.lerp(o, s, Math.max(1, topStartT));
      const endIntersect = Vector2.lerp(o, e, Math.max(1, rightEndT));
      let startEndIntersect = left ? bottomLeft : topRight;
      floats.push(...s, ...e, ...startEndIntersect);
      floats.push(...s, ...startIntersect, ...startEndIntersect);
      floats.push(...e, ...endIntersect, ...startEndIntersect);
      continue;
    }

    if (topStartT > bottomStartT && rightEndT > leftEndT) {
      const startIntersect = Vector2.lerp(o, s, Math.max(1, topStartT));
      const endIntersect = Vector2.lerp(o, e, Math.max(1, rightEndT));
      let startEndIntersect = left ? bottomLeft : topRight;
      floats.push(...s, ...e, ...startEndIntersect);
      floats.push(...s, ...startIntersect, ...startEndIntersect);
      floats.push(...e, ...endIntersect, ...startEndIntersect);
      continue;
    } else if (topStartT > bottomStartT && rightEndT < leftEndT) {
      const startIntersect = Vector2.lerp(o, s, Math.max(1, topStartT));
      const endIntersect = Vector2.lerp(o, e, Math.max(1, leftEndT));
      let startEndIntersect = left ? topLeft : bottomRight;
      floats.push(...s, ...e, ...startEndIntersect);
      floats.push(...s, ...startIntersect, ...startEndIntersect);
      floats.push(...e, ...endIntersect, ...startEndIntersect);
      continue;
    }
  }
  return new Float32Array(floats);
}

export const WallShader = React.memo(function WallShader({origin, bounds, walls, color = Color.BLACK}: {
  origin: Point
  bounds: Vector2[],
  walls: Line[],
  color?: HSLA
}) {
  const {projection, view, model} = usePVM();
  const program = useProgram(
    useShader(WebGL2RenderingContext.VERTEX_SHADER, vertexShader),
    useShader(WebGL2RenderingContext.FRAGMENT_SHADER, fragmentShader)
  );

  const colorLocation = useUniformLocation(program, "u_color");
  const valueOrigin = useMemo(() => Vector2.multiplyTransform(origin, model), [origin, model]);
  const vboArray = useMemo(() => computeVboArray(valueOrigin, bounds, walls), [bounds, valueOrigin, walls]);

  const vbo = useArrayBuffer(vboArray);
  const vao = useVertexBuffer();
  useBindVertexArribArray(vao, useAttribLocation(program, "a_position"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 2 * 4, 0);

  const projectionMatrix4f = useMemo(() => new Float32Array(projection), [projection]);
  const viewMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(view)), [view]);
  const color4f = useMemo(() => new Float32Array(Color.toRGBA(color)), [color])
  return (<program value={program}>
    <uniformMat4fv location={useUniformLocation(program, "u_projection")} transpose={true} data={projectionMatrix4f}/>
    <uniformMat4fv location={useUniformLocation(program, "u_view")} transpose={true} data={viewMatrix4f}/>
    <uniform4fv location={colorLocation} data={color4f}/>

    <vertexArray value={vao}>
      <drawArrays mode={WebGL2RenderingContext.TRIANGLES} first={0} count={vboArray.length / 2}/>
    </vertexArray>
  </program>);
});
