import {MutableRef} from "common/ref";
import {Optional} from "common/types/generic/index.ts";
import {NodeId} from "common/legends/node/index.ts";
import {Grid} from "common/legends/scene/index.ts";
import {useAccess} from "../../routes/game/model/store-context.tsx";
import {useSignalValue} from "#lib/signal/index.ts";
import {useMemo} from "react";

export function useElementGrid(idRef: MutableRef<Optional<NodeId>, never>): Grid {
  const access = useAccess();
  return useSignalValue(useMemo(() => access.elementGrid(idRef.value), [access, idRef]));
}
