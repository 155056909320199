import {Color, NumberFn, Optional, Point, PointFn, PointOperation, Size, SizeOperation, Transform, TransformOperation, ValueFn} from "common/types/generic/index.ts";
import {Grid} from "common/legends/scene/index.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {MutableSignal} from "common/signal";
import {ModelProvider, usePVM} from "../../viewport/common/context/pvm-context.ts";
import {useSignalValue} from "#lib/signal/index.ts";
import React from "react";
import {useGrid} from "../../viewport/common/context/grid-context.ts";
import {SelectionIndicator} from "../../viewport/common/selection-indicator/selection-indicator.tsx";
import {MouseInteraction} from "../../viewport/mouse-interaction.tsx";
import {getWorldPositionFromScreenPosition} from "../../viewport/tool/use-get-world-pos.ts";

export type SizeEditorValue = {
  pivot: Point;
  origin: Point;
  size: Size;
  transform: Transform;
}
export type SizeEditorOperation =
  | {type: "update-origin", operations: PointOperation[]}
  | {type: "update-pivot", operations: PointOperation[]}
  | {type: "update-size", operations: SizeOperation[]}
  | {type: "update-transform", operations: TransformOperation[]}
  ;

function getResizeBottomLeftOperations(value: SizeEditorValue, delta: Size, grid: Grid, snapToGrid: boolean): SizeEditorOperation[] {
  const offset = Vector2.subtract(value.transform.position, value.origin);
  let snapDelta = snapToGrid ? Vector2.subtract(Grid.snap(grid, Vector2.add(delta, offset)), offset) : delta;
  snapDelta = [
    Math.min(value.size[0]-16, snapDelta[0]),
    Math.min(value.size[1]-16, snapDelta[1])
  ];
  return getResizeOperations(value, snapDelta, [-snapDelta[0], -snapDelta[1]]);
}

function getResizeTopLeftOperations(value: SizeEditorValue, delta: Size, grid: Grid, snapToGrid: boolean): SizeEditorOperation[] {
  const offset = Vector2.add(
    Vector2.subtract(value.transform.position, value.origin),
    [0, value.size[1]]
  );
  let snapDelta = snapToGrid ? Vector2.subtract(Grid.snap(grid, Vector2.add(delta, offset)), offset) : delta;
  snapDelta = [
    Math.min(value.size[0]-16, snapDelta[0]),
    Math.max(-value.size[1]+16, snapDelta[1])
  ];
  return getResizeOperations(value, [snapDelta[0], 0], [-snapDelta[0], snapDelta[1]]);
}

function getResizeTopRightOperations(value: SizeEditorValue, delta: Size, grid: Grid, snapToGrid: boolean): SizeEditorOperation[] {
  const offset = Vector2.add(
    Vector2.subtract(value.transform.position, value.origin),
    value.size
  );
  let snapDelta = snapToGrid ? Vector2.subtract(Grid.snap(grid, Vector2.add(delta, offset)), offset) : delta;
  snapDelta = [
    Math.max(-value.size[0]+16, snapDelta[0]),
    Math.max(-value.size[1]+16, snapDelta[1])
  ];
  return getResizeOperations(value, [0, 0], snapDelta);
}

function getResizeBottomRightOperations(value: SizeEditorValue, delta: Size, grid: Grid, snapToGrid: boolean): SizeEditorOperation[] {
  const offset = Vector2.add(
    Vector2.subtract(value.transform.position, value.origin),
    [value.size[0], 0]
  );
  let snapDelta = snapToGrid ? Vector2.subtract(Grid.snap(grid, Vector2.add(delta, offset)), offset) : delta;
  snapDelta = [
    Math.max(-value.size[0]+16, snapDelta[0]),
    Math.min(value.size[1]-16, snapDelta[1])
  ];
  return getResizeOperations(value, [0, snapDelta[1]], [snapDelta[0], -snapDelta[1]]);
}

function getResizeOperations(prev: SizeEditorValue, positionOffset: Point, sizeAdjust: Point): SizeEditorOperation[] {
  const newOriginX = prev.origin[0] / prev.size[0] * (prev.size[0] + sizeAdjust[0]);
  const newOriginY = prev.origin[1] / prev.size[1] * (prev.size[1] + sizeAdjust[1]);

  const newPivotX = prev.pivot[0] / prev.size[0] * (prev.size[0] + sizeAdjust[0]);
  const newPivotY = prev.pivot[1] / prev.size[1] * (prev.size[1] + sizeAdjust[1]);

  return [
    {type: "update-transform", operations: ValueFn.apply([
        {type: "update-position", operations: PointFn.set(
            prev.transform.position,
            Vector2.add(prev.transform.position, Vector2.multiply(
              Vector2.rotate(Vector2.add(
                positionOffset,
                Vector2.subtract([newOriginX, newOriginY], prev.origin)
              ), prev.transform.rotation * Math.PI / 180),
              prev.transform.scale
            ))
          )}
      ])},
    {type: "update-origin", operations: [
        {type: "update-x", operations: NumberFn.set(prev.origin[0], newOriginX)},
        {type: "update-y", operations: NumberFn.set(prev.origin[1], newOriginY)}
      ]},
    {type: "update-pivot", operations: [
        {type: "update-x", operations: NumberFn.set(prev.pivot[0], newPivotX)},
        {type: "update-y", operations: NumberFn.set(prev.pivot[1], newPivotY)}
      ]},
    {type: "update-size", operations: [
        {type: "update-width", operations: NumberFn.increment(sizeAdjust[0])},
        {type: "update-height", operations: NumberFn.increment(sizeAdjust[1])}
      ]}
  ];
}

export function SizeEditor({valueRef, speculativeValueRef}: {
  valueRef: MutableSignal<Optional<SizeEditorValue>, SizeEditorOperation[]>,
  speculativeValueRef: MutableSignal<Optional<SizeEditorValue>, SizeEditorOperation[]>
}) {
  const {view, model} = usePVM();
  const value = useSignalValue(valueRef);
  const speculativeValue = useSignalValue(speculativeValueRef);
  const grid = useGrid();

  if (value === undefined || speculativeValue === undefined) return <></>;

  const speculativeValueModel = Transform.divide(speculativeValue.transform, model);
  const initialResizeHandleSize: Size = [
    Math.max(8, speculativeValue.size[0] / 8),
    Math.max(8, speculativeValue.size[1] / 8)
  ];
  const resizeHandleSize: Size = [
    Math.min(initialResizeHandleSize[0], initialResizeHandleSize[1]),
    Math.min(initialResizeHandleSize[0], initialResizeHandleSize[1])
  ];

  return <ModelProvider value={speculativeValueModel}>
    <SelectionIndicator origin={speculativeValue.origin} size={speculativeValue.size} color={Color.WHITE} />

    <MouseInteraction
      transform={screenPos => Vector2.add(
        Vector2.divideTransform(getWorldPositionFromScreenPosition(view, screenPos), speculativeValueModel), value.origin
      )}
      isInBounds={localPos => localPos[0] >= -resizeHandleSize[0] / 2 && localPos[0] <= resizeHandleSize[0] / 2 && localPos[1] >= -resizeHandleSize[1] / 2 && localPos[1] <= resizeHandleSize[1] / 2}
      draggable
      onDragMove={(event, startPos, endPos) => {
        speculativeValueRef.apply(_ => getResizeBottomLeftOperations(value!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey));
      }}
      onDragEnd={(event, startPos, endPos) => {
        valueRef.apply(prev => getResizeBottomLeftOperations(prev!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey))
        speculativeValueRef.apply(_ => []);
      }}
      onMouseDown={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }} />
    <SelectionIndicator
      origin={Vector2.add(speculativeValue.origin, Vector2.divide(resizeHandleSize, 2))}
      size={resizeHandleSize}
      color={Color.WHITE} />

    <MouseInteraction
      transform={screenPos => Vector2.add(
        Vector2.divideTransform(getWorldPositionFromScreenPosition(view, screenPos), speculativeValueModel), value.origin
      )}
      isInBounds={localPos => localPos[0] >= value.size[0] - resizeHandleSize[0] / 2 && localPos[0] <= value.size[0] + resizeHandleSize[0] / 2 && localPos[1] >= -resizeHandleSize[1] / 2 && localPos[1] <= resizeHandleSize[1] / 2}
      draggable
      onDragMove={(event, startPos, endPos) => {
        speculativeValueRef.apply(_ => getResizeBottomRightOperations(value!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey));
      }}
      onDragEnd={(event, startPos, endPos) => {
        valueRef.apply(prev => getResizeBottomRightOperations(prev!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey));
        speculativeValueRef.apply(_ => []);
      }}
      onMouseDown={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }} />
    <SelectionIndicator
      origin={[
        speculativeValue.origin[0] - speculativeValue.size[0] + resizeHandleSize[0] / 2,
        speculativeValue.origin[1] + resizeHandleSize[1] / 2
      ]}
      size={resizeHandleSize}
      color={Color.WHITE} />

    <MouseInteraction
      transform={screenPos => Vector2.add(
        Vector2.divideTransform(getWorldPositionFromScreenPosition(view, screenPos), speculativeValueModel), value.origin
      )}
      isInBounds={localPos => localPos[0] >= -resizeHandleSize[0]/2 && localPos[0] <= resizeHandleSize[0]/2 && localPos[1] >= value.size[1] - resizeHandleSize[1]/2 && localPos[1] <= value.size[1] + resizeHandleSize[1]/2}
      draggable
      onDragMove={(event, startPos, endPos) => {
        speculativeValueRef.apply(_ => getResizeTopLeftOperations(value!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey));
      }}
      onDragEnd={(event, startPos, endPos) => {
        valueRef.apply(prev => getResizeTopLeftOperations(prev!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey));
        speculativeValueRef.apply(_ => []);
      }}
      onMouseDown={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }} />
    <SelectionIndicator
      origin={[speculativeValue.origin[0] + resizeHandleSize[0] / 2, speculativeValue.origin[1] - speculativeValue.size[1] + resizeHandleSize[1] / 2]}
      size={resizeHandleSize}
      color={Color.WHITE} />

    <MouseInteraction
      transform={screenPos => Vector2.add(
        Vector2.divideTransform(getWorldPositionFromScreenPosition(view, screenPos), speculativeValueModel), value.origin
      )}
      isInBounds={localPos => localPos[0] >= value.size[0] - resizeHandleSize[0] / 2 && localPos[0] <= value.size[0] + resizeHandleSize[0]/2 && localPos[1] >= value.size[1] - resizeHandleSize[1]/2 && localPos[1] <= value.size[1] + resizeHandleSize[1] /2}
      draggable
      onDragMove={(event, startPos, endPos) => {
        speculativeValueRef.apply(_ => getResizeTopRightOperations(value!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey));
      }}
      onDragEnd={(event, startPos, endPos) => {
        valueRef.apply(prev => getResizeTopRightOperations(prev!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey));
        speculativeValueRef.apply(_ => []);
      }}
      onMouseDown={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }} />
    <SelectionIndicator
      origin={[speculativeValue.origin[0] - speculativeValue.size[0] + resizeHandleSize[0]/2, speculativeValue.origin[1] - speculativeValue.size[1] + resizeHandleSize[1]/2]}
      size={resizeHandleSize}
      color={Color.WHITE} />
  </ModelProvider>
}
