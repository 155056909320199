import {useMemo} from "react";
import {ListOperation, Optional} from "common/types/index.ts";
import {Dnd5eFeature, Dnd5eFeatureOperation, Game, GameOperation} from "common/legends/index.ts";
import {fromSignal, MutableRef} from "common/ref";
import {useAccess} from "../../../../../../routes/game/model/store-context.tsx";
import {computed} from "common/signal";

export function useGlobalFeatures(): MutableRef<Dnd5eFeature[], ListOperation<Dnd5eFeature, Dnd5eFeatureOperation>[]> {
  const access = useAccess();
  return useMemo(() => {
    return fromSignal(computed(() => {
      const game = access.game.value;
      if (!game) return []
      return game.systems.dND5e.globalFeatures;
    }, (actions: ListOperation<Dnd5eFeature, Dnd5eFeatureOperation>[]) => {
      access.game.apply((prev: Optional<Game>): GameOperation[] => {
        if (prev === undefined) return [];
        return [{type: "update-systems", operations: [{
          type: "update-d-n-d-5e", operations: [{
            type: "update-global-features", operations: actions
          }]
        }]}];
      });
    }));
  }, [access]);
}
