import {FaUser} from "react-icons/fa";
import {NodeId, UserID} from "common/legends/index.ts";
import {FileReference, Optional} from "common/types/index.ts";
import {twMerge} from "tailwind-merge";
import {usePlayerName} from "../../../routes/game/use-player-name.ts";
import {usePlayerIcon} from "../../../routes/game/use-player-icon.ts";
import {useGoToNode} from "../nav/editor/use-go-to-node.ts";
import {useAccess} from "../../../routes/game/model/store-context.tsx";
import {useMemo} from "react";
import {fromSignal} from "common/ref";
import {computed} from "common/signal";
import {useRefValue} from "#lib/signal/index.ts";

export function useTokenName(elementID: Optional<NodeId>) {
  const access = useAccess();
  return useRefValue(useMemo(() => fromSignal(computed(() => {
    const node = access.element(elementID).value;
    if (node?.type !== "token") return undefined;
    if (!node.data.displayName) return undefined;
    return node.data.name;
  })), [access, elementID]));
}

export type UserNodeIconProps = {
  userID: UserID;
  nodeID?: NodeId;
  icon: FileReference;
};

export function UserNodeIcon({userID, nodeID, icon}: UserNodeIconProps) {
  const playerName = usePlayerName(userID);
  const playerIcon = usePlayerIcon(userID);
  const displayIcon = icon || playerIcon;
  const name = useTokenName(nodeID);
  const goToNode = useGoToNode();
  return (<div className="absolute -top-5 w-full flex items-center justify-center">
    <div className={twMerge(
      "rounded-full bg-zinc-900 w-10 h-10 inline-flex items-center justify-center pointer-events-auto",
      nodeID && "cursor-pointer"
    )} title={name ? `${name} (${playerName})` : playerName} onClick={() => {
      if (nodeID) goToNode(nodeID);
    }}>
      {displayIcon && <img alt={name ? `${name} (${playerName})` : playerName} src={displayIcon} crossOrigin="anonymous" className="rounded-full w-10 h-10 ring-1 ring-zinc-900" />}
      {!displayIcon && <FaUser size={20} />}
    </div>
  </div>);
}