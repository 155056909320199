import React, {CSSProperties, Fragment, useCallback, useMemo, useRef} from "react";
import {useSample} from "#lib/components/index.ts";
import {ApplyAction, Optional} from "common/types/index.ts";
import {UUID} from "common/utils";
import {DialogID, Layout, LayoutOperation, LayoutPath, LayoutView, Splitter, SplitterId} from "#lib/container/index.ts";
import {StackContentView} from "../../stack/stack-content-view.tsx";
import {StackTabView} from "../../stack/stack-tab-view.tsx";
import "./splitter-view.css";
import {twMerge} from "tailwind-merge";
import {DragLayerMonitor, useDrag, useDragLayer, useDrop} from "react-dnd";
import {EMPTY_IMAGE} from "#lib/container/react/dialog/resizer/empty-image.tsx";
import {Stacks} from "#lib/container/react/stack/stacks.ts";

const itemStyle: CSSProperties = {
  flexBasis: 0,
  minHeight: 0
};

export type SplitterViewProps<Value, Operation> = {
  style?: CSSProperties;
  dialogId?: DialogID,
  layoutPath: LayoutPath,
  value: Splitter<Value>,
  apply: (action: ApplyAction<Optional<Layout<Value>>, LayoutOperation<Value, Operation>[]>) => Promise<Optional<Layout<Value>>>;
  StackContentView: StackContentView<Value, Operation>;
  StackTabView: StackTabView<Value>;
  Stacks: Stacks<Value>;
};

function MemoizedSplitterView<Value, Operation>({style, dialogId, layoutPath, value: {type, items}, apply, StackContentView, StackTabView, Stacks}: SplitterViewProps<Value, Operation>) {
  const splitterId = useMemo(() => UUID.generate(), []);

  const {isDragging} = useDragLayer<{isDragging: boolean}>((monitor: DragLayerMonitor) => ({
    isDragging: monitor.getItemType() === "legends/splitter" && monitor.getItem<{splitterId: SplitterId}>().splitterId === splitterId
  }));

  const splitterRef = useRef<HTMLDivElement>(null);
  const onUpdateSplit = useSample(useCallback(([index, [clientX, clientY]]: [number, [number, number]]) => {
    const {top, left, width, height} = splitterRef.current!.getBoundingClientRect();
    let splitPoint = type === "row" ? (clientY - top) / height : (clientX - left) / width;
    const adjustOperation: LayoutOperation<Value, Operation> = {type: "adjust-split", path: layoutPath, index, splitPoint};
    apply(_ => [adjustOperation]);
  }, [type, layoutPath, apply]), 16);

  const [, dropRef] = useDrop<{splitterId: SplitterId, index: number}>(() => ({
    accept: "legends/splitter",
    canDrop: (item) => item.splitterId === splitterId,
    hover: (item, monitor) => {
      if (item.splitterId !== splitterId) return;
      const {x, y} = monitor.getClientOffset()!;
      onUpdateSplit([item.index, [x, y]]);
    }
  }), [splitterId, onUpdateSplit]);

  const layoutPaths = useMemo(() => {
    return items.map((_, index) => [...layoutPath, index]);
  }, [items.length]);
  const itemStyles = useMemo((): CSSProperties[] => {
    return items.map(item => ({flexGrow: item.weight, ...itemStyle}));
  }, [items])

  dropRef(splitterRef);
  return (<div ref={splitterRef} className={`splitter-view ${type === "column" ? "flex-row" : "flex-col"}`} style={{
    ...style,
    ...(isDragging ? {pointerEvents: "all"} : {})
  }}>
    {items.map((item, index) => {
      return <Fragment key={item.id}>
        <LayoutView dialogId={dialogId} layoutPath={layoutPaths[index]} value={item.content} style={itemStyles[index]}
                    apply={apply}
                    StackContentView={StackContentView} StackTabView={StackTabView} Stacks={Stacks}
        />
        {items.length !== index + 1 && <SplitterResizer type={type} dialogId={dialogId} splitterId={splitterId} index={index} />}
      </Fragment>
    })}
  </div>);
}

export const SplitterView = React.memo(MemoizedSplitterView) as typeof MemoizedSplitterView;

type SplitterResizerProps = {
  type: "row" | "column",
  dialogId?: DialogID,
  splitterId: SplitterId,
  index: number
};

function SplitterResizer({type, dialogId, splitterId, index}: SplitterResizerProps) {
  const [, dragRef, dragPreviewRef] = useDrag(() => ({
    type: "legends/splitter",
    item: {splitterId, index}
  }), [splitterId, index]);
  dragPreviewRef(EMPTY_IMAGE);

  return <div ref={dragRef} className={twMerge(
    "basis-2 flex-shrink-0 flex-grow-0 pointer-events-auto hover:backdrop-blur ",
    type === "row" ? "cursor-ns-resize" : "cursor-ew-resize",
    "bg-zinc-900/50",
    !!dialogId ? "hover:bg-zinc-900/10" : "hover:bg-zinc-900/60"
  )} />
}