export class WeakCache<Key, Value extends WeakKey> {
  private cache = new Map<Key, Value>();
  constructor(private readonly getter: (key: Key) => Value) {
  }
  get(key: Key): Value {
    if (this.cache.has(key)) {
      const value = this.cache.get(key);
      if (value) {
        return value;
      }
    }
    const value = this.getter(key);
    this.cache.set(key, value);
    return value;
  }
}
