import {useCallback} from "react";
import {TriggerContext, useApplyInteractionAction} from "./use-apply-interaction-action.ts";
import {Node, NodeId, NodeOperation} from "common/legends/node/index.ts";
import {Optional, Point, PointFn, Transform, TransformFn, Tree, TreeOperation} from "common/types/generic/index.ts";
import {AssetTokenSelectionRef, NodeSelectionRef, SceneSelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {getParentNode} from "../../common/legends/get-parent-node.ts";
import {Vector2f} from "#lib/math/index.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {getMoveInteractions} from "./get-move-interactions.ts";
import {MutableRef, Ref} from "common/ref";
import {useWorldPosToNodePos} from "./use-world-pos-to-node-pos.ts";
import {useNodePosToWorldPos} from "./use-node-pos-to-world-pos.ts";
import {useDatabase} from "../../../routes/game/model/store-context.tsx";
import {useElementWalls} from "../scene/scene-view.tsx";

export function useMoveElement(
  nodesRef: MutableRef<Node[], TreeOperation<Node, NodeOperation>[]>,
  rootSelectionRef: SceneSelectionRef | AssetTokenSelectionRef,
  activeNodeIdRef: Ref<Optional<NodeId>>,
  isVisible: (node: Node) => boolean,
  isAccessible: (node: Node) => boolean,
) {
  const wallsRef = useElementWalls(
    nodesRef,
    isVisible,
    isAccessible
  );
  const worldPosToNodePos = useWorldPosToNodePos(nodesRef);
  const nodePosToWorldPos = useNodePosToWorldPos(nodesRef);
  const databaseRef = useDatabase();
  const applyInteractionAction = useApplyInteractionAction();

  return useCallback((
    selectedNodeIDs: NodeSelectionRef[],
    view: Transform,
    movementPath: Point[],
    isActingPlayer: boolean
  ) => {
    if (movementPath.length < 2) return;

    const interactions: TriggerContext[] = [];
    nodesRef.apply((nodes: Node[]): TreeOperation<Node, NodeOperation>[] => {
      return selectedNodeIDs.flatMap((dragLayerNodeId: NodeSelectionRef): TreeOperation<Node, NodeOperation>[] => {
        const path = Tree.getPath(nodes, value => value.data.id === dragLayerNodeId.elementID)!;
        const node = Tree.getNode(nodes, path);
        if (node.type === "grid" || node.type === "parallax" || node.type === "wall" || node.type === "area") return [];

        // block movement if wall
        if (isActingPlayer) {
          const offset = Vector2f.subtract(
            movementPath[0],
            nodePosToWorldPos(node.data.id, [0, 0])
          );
          movementPath = movementPath.map(p => Vector2f.subtract(p, offset))
          const walls = wallsRef.value;
          for (let i = 0; i < movementPath.length - 1; i ++) {
            for (const wall of walls) {
              if (!wall.blockMovementLeft && !wall.blockMovementRight) continue;

              const a = Vector2.lineIntersect(wall.start, wall.end, movementPath[i], movementPath[i+1]);
              if (a >= 0 && a <= 1) {
                const b = Vector2.lineIntersect(movementPath[i], movementPath[i+1], wall.start, wall.end);
                if (b >= 0 && b <= 1) {
                  const left = Vector2.cross(Vector2.subtract(wall.end, movementPath[i]), Vector2.subtract(wall.start, movementPath[i])) < 0;
                  if (left && wall.blockMovementLeft) return [];
                  if (!left && wall.blockMovementRight) return [];
                }
              }
              const c = Vector2.distance(wall.start, wall.end) - (Vector2.distance(wall.start, movementPath[i+1]) + Vector2.distance(movementPath[i + 1], wall.end));
              if (Math.abs(c) <= 0.001) {
                return [];
              }
            }
          }
        }

        const parentNodeID = getParentNode(nodes, node.data.id)?.data.id;
        const delta = Vector2f.subtract(
          worldPosToNodePos(parentNodeID, movementPath[movementPath.length - 1]),
          worldPosToNodePos(parentNodeID, movementPath[0])
        );
        const prevPosition = node.data.transform.position;
        const newPosition = Vector2f.add(prevPosition, delta);

        interactions.push(...getMoveInteractions(
          databaseRef.value,
          rootSelectionRef.type === "scene"
            ? {type: "scene", sceneID: rootSelectionRef.sceneID, path: []}
            : {type: "asset", assetID: rootSelectionRef.assetID, tokenID: rootSelectionRef.tokenID, path: []},
          activeNodeIdRef.value,
          isVisible,
          isAccessible,
          view,
          nodePosToWorldPos(parentNodeID, prevPosition),
          nodePosToWorldPos(parentNodeID, newPosition)
        ));

        if (PointFn.equals(prevPosition, newPosition)) return [];
        return TreeOperation.apply(path, [{
          type: node.type, operations: [{
            type: "update-transform", operations: TransformFn.updatePosition(PointFn.set(prevPosition, newPosition))
          }]
        }]);
      });
    });
    interactions.forEach(applyInteractionAction);
  }, [
    worldPosToNodePos,
    nodePosToWorldPos,
    databaseRef,
    applyInteractionAction,
    nodesRef,
    rootSelectionRef,
    activeNodeIdRef,
    isVisible,
    isAccessible,
    wallsRef
  ])
}