import {ClearCanvas} from "../../../routes/game/view/main/clear-canvas.tsx";
import {Color} from "common/types/generic/index.ts";
import {RootProvider} from "../common/context/root-context.ts";
import {ModelProvider, ProjectionProvider, usePVM, ViewProvider} from "../common/context/pvm-context.ts";
import {AccessibilityProvider, VisibilityProvider} from "../common/context/visibility-context.ts";
import {MeasurementView} from "../common/measurement/measurement-view.tsx";
import {GridProvider} from "../common/context/grid-context.ts";
import React, {useCallback, useMemo} from "react";
import {useComputedValue, useRefValue, useSignalValue} from "#lib/signal/index.ts";
import {Node} from "common/legends/node/index.ts";
import {MutableRef} from "common/ref";
import {Token, TokenOperation, TokenSignals, tokenType} from "common/legends/asset/index.ts";
import {useResolution} from "#lib/gl-react/hooks/resolution-context.ts";
import {Measurement, MeasurementOperation} from "common/legends/measurement/index.ts";
import {useSheetSignal} from "../../common/sheet/use-sheet-signal.ts";
import {useSheetReference} from "../../common/sheet/sheet-reference-context.ts";
import {useRenderingContext, useTexture2D} from "#lib/gl-react/index.ts";
import {useFramebuffer} from "#lib/gl-react/hooks/use-framebuffer.ts";
import {ElementLightPass} from "../../element/element-light-pass.tsx";
import {RenderPassTextureProvider} from "../common/context/render-pass-texture-context.ts";
import {LightElementLightPass} from "../../element/light/light-element-light-pass.tsx";
import {defaultLocalNode} from "common/legends/node/local-node.ts";
import {LightNode} from "common/legends/node/light/light-node.ts";
import {HealthIndicatorView} from "../../element/token/health-indicator-view.tsx";
import {ElementHUDPass} from "../../element/element-h-u-d-pass.tsx";
import {BackgroundShader} from "../common/shader/background-shader.tsx";
import {Vision, VisionFn} from "common/legends/asset/token/vision/vision.ts";
import {CircleShader} from "../common/shader/circle-shader.tsx";
import {Vector2} from "common/math/vector/vector2.ts";
import {AssetTokenSelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {ElementRenderPass} from "../../element/element-render-pass.tsx";
import {useAccess} from "../../../routes/game/model/store-context.tsx";
import {useSpeculativeValue} from "../../element/common/use-speculative-value.ts";
import {TokenSizeEditor} from "../../element/common/token-size-editor.tsx";
import {useNothingSelected} from "../common/context/use-is-active-selection.ts";
import {SelectionIndicator} from "../common/selection-indicator/selection-indicator.tsx";

export function AssetView({token, vision, measurement, rootRef}: {
  token: MutableRef<Token, TokenOperation[]>;
  vision: Vision[];
  measurement: MutableRef<Measurement, MeasurementOperation[]>;
  rootRef: AssetTokenSelectionRef;
}) {
  const {projection, view, model} = usePVM();

  const tokenLight = useComputedValue(token, token => token.light);
  const interfaceScale = useComputedValue(token, token => token.interfaceScale);
  const grid = useComputedValue(token, token => token.grid);
  const size = useResolution();

  const {children} = useMemo(() => TokenSignals(token), [token]);
  const childrenValue = useRefValue(children);

  const isElementVisible = useCallback((element: Node) => vision.some(vision => VisionFn.canSee(vision, element)), [vision]);
  const isElementAccessible = useCallback((_: Node) => false, []);
  const sheet = useSheetSignal(useSheetReference());
  const context = useRenderingContext();

  const diffuseTexture = useTexture2D();
  useMemo(() => {
    if (size[0] > 0 && size[1] > 0) {
      const prev = context.getParameter(WebGL2RenderingContext.TEXTURE_BINDING_2D);
      context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, diffuseTexture);
      context.texImage2D(WebGL2RenderingContext.TEXTURE_2D, 0, WebGL2RenderingContext.RGBA, size[0], size[1], 0, WebGL2RenderingContext.RGBA, WebGL2RenderingContext.UNSIGNED_BYTE, null);
      context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_MIN_FILTER, WebGL2RenderingContext.LINEAR);
      context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_MAG_FILTER, WebGL2RenderingContext.LINEAR);
      context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, prev);
    }
  }, [diffuseTexture, size]);

  const normalTexture = useTexture2D();
  useMemo(() => {
    if (size[0] > 0 && size[1] > 0) {
      const prev = context.getParameter(WebGL2RenderingContext.TEXTURE_BINDING_2D);
      context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, normalTexture);
      context.texImage2D(WebGL2RenderingContext.TEXTURE_2D, 0, WebGL2RenderingContext.RGBA, size[0], size[1], 0, WebGL2RenderingContext.RGBA, WebGL2RenderingContext.UNSIGNED_BYTE, null);
      context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_MIN_FILTER, WebGL2RenderingContext.LINEAR);
      context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_MAG_FILTER, WebGL2RenderingContext.LINEAR);
      context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, prev);
    }
  }, [normalTexture, size]);

  const framebuffer = useFramebuffer();
  useMemo(() => {
    if (size[0] > 0 && size[1] > 0) {
      const prev = context.getParameter(WebGL2RenderingContext.FRAMEBUFFER_BINDING);
      context.bindFramebuffer(WebGL2RenderingContext.FRAMEBUFFER, framebuffer);
      context.framebufferTexture2D(WebGL2RenderingContext.FRAMEBUFFER, WebGL2RenderingContext.COLOR_ATTACHMENT0, WebGL2RenderingContext.TEXTURE_2D, diffuseTexture, 0);
      context.framebufferTexture2D(WebGL2RenderingContext.FRAMEBUFFER, WebGL2RenderingContext.COLOR_ATTACHMENT1, WebGL2RenderingContext.TEXTURE_2D, normalTexture, 0);
      context.drawBuffers([context.COLOR_ATTACHMENT0, context.COLOR_ATTACHMENT1]);
      context.bindFramebuffer(WebGL2RenderingContext.FRAMEBUFFER, prev);
    }
  }, [framebuffer, diffuseTexture, size]);

  const assetLight = useMemo((): LightNode => ({
    ...defaultLocalNode(),
    accessMask: undefined,
    shape: {
      type: "global",
      data: tokenLight
    },
    blinkLength: 1,
    blinkOffset: 0,
    blinkInterval: 1
  }), [tokenLight]);

  const resetBlend = useCallback((context: WebGL2RenderingContext) => {
    context.blendEquation(WebGL2RenderingContext.FUNC_ADD);
    context.blendFunc(WebGL2RenderingContext.SRC_ALPHA, WebGL2RenderingContext.ONE_MINUS_SRC_ALPHA);
  }, []);

  const access = useAccess();
  const valueRef = useMemo(() => access.assetToken(rootRef.assetID, rootRef.tokenID), [access, rootRef.assetID, rootRef.tokenID]);
  const speculativeValueRef = useSpeculativeValue(tokenType, valueRef);
  const speculativeValue = useSignalValue(speculativeValueRef)!;
  const interfaceModel = {...model, scale: model.scale * interfaceScale};

  const isEditing = useNothingSelected()

  if (size[0] === 0 || size[1] === 0) return <></>
  return <>
  <RootProvider value={children}>
    <ProjectionProvider value={projection}>
      <ViewProvider value={view}>
        <VisibilityProvider value={isElementVisible}>
          <AccessibilityProvider value={isElementAccessible}>
            <GridProvider value={grid}>
              <action onAction={resetBlend}/>
              <framebuffer value={framebuffer}>
                <ClearCanvas width={size[0]} height={size[1]}/>
                <BackgroundShader color={Color.GRAY}/>
                {[...childrenValue].reverse().map((element) => <ElementRenderPass key={element.data.id} value={element}/>)}
              </framebuffer>
              <RenderPassTextureProvider value={[diffuseTexture, normalTexture]}>
                <LightElementLightPass value={assetLight}/>
                <binder>
                  {[...childrenValue].reverse().map((element) => <ElementLightPass key={element.data.id} value={element}/>)}
                </binder>
              </RenderPassTextureProvider>
              <binder>
                {[...childrenValue].reverse().map((element) => <ElementHUDPass key={element.data.id} value={element}/>)}

                {isEditing && <TokenSizeEditor valueRef={valueRef} speculativeValueRef={speculativeValueRef} />}
                <ModelProvider value={interfaceModel}>
                  <HealthIndicatorView sheetRef={sheet} origin={Vector2.multiply(speculativeValue.origin, 1 / interfaceScale)} size={Vector2.multiply(speculativeValue.size, 1 / interfaceScale)} displayNumbers={true}/>
                  <SelectionIndicator origin={[3, 3]} size={[6, 6]} color={Color.WHITE} />
                  <SelectionIndicator origin={speculativeValue.origin} size={speculativeValue.size} color={Color.WHITE} />
                </ModelProvider>

                <CircleShader
                    origin={Vector2.add(
                      Vector2.subtract(speculativeValue.origin, speculativeValue.pivot),
                      Vector2.multiply([2, 2], interfaceScale)
                    )}
                    size={Vector2.multiply([4, 4], interfaceScale)}
                    color={Color.BLACK75} />
                <CircleShader
                  origin={Vector2.add(
                    Vector2.subtract(speculativeValue.origin, speculativeValue.pivot),
                    Vector2.multiply([1, 1], interfaceScale)
                  )}
                  size={Vector2.multiply([2, 2], interfaceScale)}
                    color={Color.GREEN} />
                <MeasurementView rootRef={rootRef} measurementRef={measurement}/>
              </binder>
            </GridProvider>
          </AccessibilityProvider>
        </VisibilityProvider>
      </ViewProvider>
    </ProjectionProvider>
  </RootProvider>
  </>
}