import {NumberFn, NumberOperation, numberType} from "common/types/generic/index.ts";
import {fromSignal, MutableRef} from "common/ref";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";
import {IconButton, InputGroup, InputNumber, InputNumberProps} from "#lib/components/index.ts";
import {twMerge} from "tailwind-merge";
import {useGrid} from "../../viewport/common/context/grid-context.ts";
import {toggleGridMode, useIsGridMode} from "./grid-mode.ts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFrame, faGrid5} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import {useMemo} from "react";
import {computed} from "common/signal";
import {applyAll} from "common/types/type/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {ulid} from "ulid";

export function GridHeightNumberField({icon, label, valueRef, pt, ...props}: {
  icon?: string;
  label?: string;
  valueRef: MutableRef<number, NumberOperation[]>,
  pt?: {
    root?: string;
    label?: string;
    group?: string;
    input?: string;
  }
} & Omit<InputNumberProps, "value">) {
  const grid = useGrid();
  const isGridMode = useIsGridMode();
  const value = useRefValue(valueRef);
  const heightValueRef = useMemo(() => fromSignal(computed(
    () => isGridMode
      ? value / grid.height
      : value,
    (operations: NumberOperation[]) => valueRef.apply(prev => {
      if (isGridMode) {
        const next = applyAll(numberType, prev / grid.height, operations);
        return NumberFn.set(prev, next * grid.height)
      } else {
        const next = applyAll(numberType, prev, operations);
        return NumberFn.set(prev, next);
      }
    })
  )), [value, isGridMode, grid]);

  const inputId = props?.id ?? ulid();
  return <Field className={pt?.root}>
    {label && <FieldLabel htmlFor={inputId} className={pt?.label}>{label}</FieldLabel>}
    <InputGroup className={twMerge("mx-2 rounded-md overflow-hidden pr-0", pt?.group)}>
      {icon && <InputGroupIcon>{icon}</InputGroupIcon>}
      <InputNumber id={inputId} className={pt?.input} {...props} value={heightValueRef} />
      <IconButton tabIndex={-1} title={isGridMode ? "Grid Mode" : "Pixel Mode"} onClick={toggleGridMode}>
        {isGridMode ? <FontAwesomeIcon icon={faFrame} /> : <FontAwesomeIcon icon={faGrid5} />}
      </IconButton>
    </InputGroup>
  </Field>
}