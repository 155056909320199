import {RefObject, useCallback} from "react";
import {HSLA, Optional, Point, SetOperation, Transform, TransformOperation, TreeOperation} from "common/types/index.ts";
import {Measurement, MeasurementOperation} from "common/legends/measurement/index.ts";
import {Grid, Node, NodeId, NodeOperation} from "common/legends/index.ts";
import {ContextMenu, ContextMenuOperation} from "../../../../routes/game/context-menu/context-menu.ts";
import {usePanHandlers} from "../use-pan-handlers.ts";
import {usePingHandlers} from "./use-ping-handlers.ts";
import {useZoomHandlers} from "../use-zoom-tool-handlers.ts";
import {useTouchHandlers} from "../use-touch-handlers.ts";
import {useDropHandlers} from "../use-drop-handlers.ts";
import {useSelectToolHandlers} from "./use-select-tool-handlers.ts";
import {onMovementHandlers} from "../use-movement-handlers.ts";
import {MutableRef, Ref} from "common/ref";
import {AssetTokenSelectionRef, NodeSelectionRef, SceneSelectionRef} from "../../../panel/nav/editor/state/selection-ref.ts";
import {useMouseEventHandlers, useWheelEventHandlers} from "../../../panel/nav/common/tool/tool-selector/use-mouse-event-handlers.ts";
import {useKeyEventHandlers} from "../../../panel/nav/common/tool/tool-selector/use-key-event-handlers.ts";
import {useIsSpaceDown} from "#lib/components/use-is-space-down.ts";

export function useSelectionToolHandlers(
  canvasRef: RefObject<HTMLCanvasElement>,
  rootSelectionRef: SceneSelectionRef | AssetTokenSelectionRef,
  view: MutableRef<Transform, TransformOperation[]>,
  measurement: MutableRef<Measurement, MeasurementOperation[]>,
  grid: Grid,
  nodes: MutableRef<Node[], TreeOperation<Node, NodeOperation>[]>,
  activeNodeIdRef: Ref<Optional<NodeId>>,
  selectedNodeIds: MutableRef<NodeSelectionRef[], SetOperation<NodeSelectionRef>[]>,
  ping: (p: Point, focus: boolean, color: HSLA) => void,
  color: HSLA,
  contextMenu: MutableRef<ContextMenu, ContextMenuOperation[]>,
  isAccessible: (node: Node) => boolean,
  isVisible: (node: Node) => boolean
) {
  const spaceDownRef = useIsSpaceDown();
  const {onPanMouseDown, onPanMouseMove, onPanMouseUp, onPanMouseEnter, onPanWheel, onPanContextMenu, onPanKeyDown, onPanKeyUp} = usePanHandlers(canvasRef, view,
    useCallback(ev => (ev.buttons === 1 && spaceDownRef.value) || ev.buttons === 2 || ev.buttons === 4, [spaceDownRef])
  );
  const {onSelectMouseDown, onSelectMouseUp, onSelectMouseMove, onSelectContextMenu, onDragKeyUp, onDragContextMenu} = useSelectToolHandlers(canvasRef, rootSelectionRef, activeNodeIdRef, view, measurement, nodes, selectedNodeIds, color, contextMenu, isAccessible, isVisible);

  const {onPingMouseDown, onPingMouseMove, onPingMouseUp} = usePingHandlers(canvasRef, view, ping, color);
  const {onZoomWheel, onZoomKeyDown} = useZoomHandlers(canvasRef, view);
  const {onMovementKeyUp} = onMovementHandlers(rootSelectionRef, activeNodeIdRef, selectedNodeIds, grid, nodes, isAccessible, isVisible, view);
  const {onTouchStart, onTouchMove, onTouchEnd} = useTouchHandlers(canvasRef, view);

  const onMouseDown = useMouseEventHandlers(onPanMouseDown, onSelectMouseDown, onPingMouseDown);
  const onMouseMove = useMouseEventHandlers(onPanMouseMove, onSelectMouseMove, onPingMouseMove);
  const onMouseUp = useMouseEventHandlers(onPingMouseUp, onPanMouseUp, onSelectMouseUp);
  const onKeyDown = useKeyEventHandlers(onZoomKeyDown, onPanKeyDown);
  const onKeyUp = useKeyEventHandlers(onDragKeyUp, onPanKeyUp, onMovementKeyUp);
  const onContextMenu = useMouseEventHandlers(onDragContextMenu, onSelectContextMenu, onPanContextMenu);
  const onWheel = useWheelEventHandlers(onZoomWheel, onPanWheel);

  const {onDrop} = useDropHandlers(view, nodes);
  return {
    onMouseUp,
    onMouseMove,
    onMouseDown,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    onContextMenu,
    onMouseEnter: onPanMouseEnter,
    onWheel,
    onKeyDown,
    onKeyUp,
    onDrop
  };
}

