import {Button, ButtonBar, InputGroup, Select} from "#lib/components/index.ts";
import {SquareGridTypeIcon} from "./square-grid-type-icon.tsx";
import {HexagonHorizontalGridTypeIcon} from "./hexagon-horizontal-grid-type-icon.tsx";
import {HexagonVerticalGridTypeIcon} from "./hexagon-vertical-grid-type-icon.tsx";
import {IsometricGridTypeIcon} from "./isometric-grid-type-icon.tsx";
import {Grid, GridOperation, GridRuler, GridShape} from "common/legends/index.ts";
import {useCallback, useMemo} from "react";
import {MutableRef} from "common/ref";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";
import {NumberField} from "../../../../field/generic/number-field.tsx";
import {StringField} from "#lib/components/input/string-field.tsx";
import {ValueFn} from "common/types/generic/index.ts";
import {useRefValue} from "#lib/signal/index.ts";

const defaultDimensions: {[shape: string]: {width: number, height: number}} = {
  "square": {width: 64, height: 64},
  "hexagon-vertical": {width: 56, height: 64},
  "hexagon-horizontal": {width: 64, height: 56},
  "isometric": {width: 128, height: 64}
};

export function GridOptions({value}: {
  value: MutableRef<Grid, GridOperation[]>;
}) {
  const {widthRef, heightRef, subdivisionsRef, rulerRef, shapeRef, unitDistanceRef, unitLabelRef} = useMemo(() => Grid.expand(value), [value]);
  const setGridShape = useCallback((shape: GridShape) => {
    value.apply(prevValue => [{
      type: "update-shape",
      operations: [{type: "set", prevValue: prevValue.shape, nextValue: shape}]
    }, {
      type: "update-width",
      operations: [{type: "set", prevValue: prevValue.width, nextValue: defaultDimensions[shape].width}]
    }, {
      type: "update-height",
      operations: [{type: "set", prevValue: prevValue.height, nextValue: defaultDimensions[shape].height}]
    }]);
  }, [value.apply]);

  const shape = useRefValue(shapeRef);
  const ruler = useRefValue(rulerRef);
  return (
    <>
      <Field>
        <FieldLabel>Shape</FieldLabel>
        <ButtonBar className="mx-2 rounded-md overflow-hidden">
          <Button className="flex-1" variant={shape === "square" ? "primary" : "tertiary"} onClick={() => setGridShape("square")}>
            <SquareGridTypeIcon size={"40px"} />
          </Button>
          <Button className="flex-1" variant={shape === "hexagon-horizontal" ? "primary" : "tertiary"} onClick={() => setGridShape("hexagon-horizontal")}>
            <HexagonHorizontalGridTypeIcon size={"40px"} />
          </Button>
          <Button className="flex-1" variant={shape === "hexagon-vertical" ? "primary" : "tertiary"} onClick={() => setGridShape("hexagon-vertical")}>
            <HexagonVerticalGridTypeIcon size={"40px"} />
          </Button>
          <Button className="flex-1" variant={shape === "isometric" ? "primary" : "tertiary"} onClick={() => setGridShape("isometric")}>
            <IsometricGridTypeIcon size={"40px"} />
          </Button>
        </ButtonBar>
      </Field>

      <Field>
        <FieldLabel>Size</FieldLabel>
        <div className="flex gap-0.5 mx-2">
          <NumberField icon="W" pt={{group: "mx-0"}} valueRef={widthRef} min={1} max={300} step={1} />
          <NumberField icon="H" pt={{group: "mx-0"}} valueRef={heightRef} min={1} max={300} step={1} />
        </div>
      </Field>

      <NumberField label="Subdivisions" valueRef={subdivisionsRef} min={0} max={4} step={1} />

      <Field>
        <FieldLabel>Units</FieldLabel>
        <div className="flex gap-0.5 mx-2">
          <NumberField pt={{group: "mx-0"}} valueRef={unitDistanceRef}/>
          <StringField pt={{group: "mx-0"}} valueRef={unitLabelRef}/>
        </div>
      </Field>

      <Field>
        <FieldLabel>Rulers</FieldLabel>
        <InputGroup className="mx-2 rounded-md overflow-hidden pr-0">
          <Select value={ruler} onChange={ev => rulerRef.apply(prev => ValueFn.set(prev, ev.target.value as GridRuler))}>
            <option value="manhattan">Manhattan</option>
            <option value="euclidean">Euclidean</option>
            <option value="dnd-standard">D&D Standard</option>
            <option value="dnd-variant">D&D Variant</option>
          </Select>
        </InputGroup>
      </Field>
    </>
  )
}