import {LegendsIcon} from "../../../../legends-icon.tsx";
import {twMerge} from "tailwind-merge";
import React from "react";
import {useConnected} from "#lib/qlab/index.ts";

export function LegendsConnectionIndicator() {
  const connected = useConnected();
  return <LegendsIcon className={twMerge(
    "w-12 h-12 p-1",
    "text-white",
    !connected ? "text-red-300" : ""
  )} />;
}