import {Field, FieldLabel} from "#lib/components/panel-header.tsx";
import {InputGroup, InputNumber} from "#lib/components/index.ts";
import {MutableRef} from "common/ref";
import {NumberOperation} from "common/types/generic/index.ts";
import {twMerge} from "tailwind-merge";
import {ReactNode} from "react";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {ulid} from "ulid";

export function NumberField({icon, label, valueRef, min, max, step, pt}: {
  icon?: ReactNode;
  label?: string;
  valueRef: MutableRef<number, NumberOperation[]>,
  min?: number,
  max?: number,
  step?: number;
  pt?: {
    root?: string,
    label?: string,
    group?: string,
    input?: string;
  }
}) {
  const inputId = ulid();
  return <Field className={pt?.root}>
    {label && <FieldLabel htmlFor={inputId} className={pt?.label}>{label}</FieldLabel>}
    <InputGroup className={twMerge("mx-2 rounded-md overflow-hidden", pt?.group)}>
      {icon && <InputGroupIcon>{icon}</InputGroupIcon>}
      <InputNumber id={inputId} className={pt?.input} value={valueRef} min={min} max={max} step={step} />
    </InputGroup>
  </Field>
}