import {Node} from "common/legends/index.ts";
import {TokenElementRenderPass} from "./token/token-element-render-pass.tsx";
import {ImageElementRenderPass} from "./image/image-element-render-pass.tsx";
import {TextElementRenderPass} from "./text/text-element-render-pass.tsx";
import {ShapeElementRenderPass} from "./shape/shape-element-render-pass.tsx";
import {ParallaxElementRenderPass} from "./parallax/parallax-element-render-pass.tsx";
import {useIsElementAccessible, useIsElementVisible} from "../viewport/common/context/visibility-context.ts";
import {useConditionsMet} from "../viewport/common/context/use-conditions-met.ts";
import {GridElementRenderPass} from "./grid/grid-element-render-pass.tsx";
import React from "react";
import {VideoElementRenderPass} from "./video/video-element-render-pass.tsx";
import {ModelProvider, useModel} from "../viewport/common/context/pvm-context.ts";
import {Transform} from "common/types/generic/index.ts";

export const ElementRenderPass = React.memo(function ElementRenderPass({value}: {
  value: Node;
}) {
  const isVisible = useIsElementVisible()(value);
  const isAccessible = useIsElementAccessible()(value);
  const conditionsMet = useConditionsMet(value.data.conditions);
  const model = useModel();

  if ((!conditionsMet || !isVisible) && !isAccessible) return <></>;
  if (value.type === "grid") return <GridElementRenderPass value={value.data}/>
  else if (value.type === "image") return <ImageElementRenderPass value={value.data} />
  else if (value.type === "shape") return <ShapeElementRenderPass value={value.data}/>
  else if (value.type === "text") return <TextElementRenderPass value={value.data}/>
  else if (value.type === "token") return <TokenElementRenderPass value={value.data}/>
  else if (value.type === "parallax") return <ParallaxElementRenderPass value={value.data}/>
  else if (value.type === "video") return <VideoElementRenderPass value={value.data}/>

  const valueModel = Transform.divide(value.data.transform, model);
  return (<ModelProvider value={valueModel}>
    {[...value.data.children].reverse().map((element) => <ElementRenderPass key={element.data.id} value={element} />)}
  </ModelProvider>);
});
