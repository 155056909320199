import {ButtonBar, useToggle} from "#lib/components/index.ts";
import {SectionBody, SectionHeader} from "#lib/components/panel-header.tsx";
import {AddVisionButton} from "./add-vision-button.tsx";
import {InputList} from "#lib/components/list/input-list.tsx";
import {TokenVision, TokenVisionFn, TokenVisionOperation} from "common/legends/asset/token/token-vision.ts";
import {TokenVisionListItem} from "./token-vision-list-item.tsx";
import {MutableRef} from "common/ref";
import {ListOperation} from "common/types/generic/index.ts";

export function VisionField({valueRef}: {
  valueRef: MutableRef<TokenVision[], ListOperation<TokenVision, TokenVisionOperation>[]>
}) {
  const [toggled, toggle] = useToggle(true);
  return (
    <div className="flex flex-col gap-2 py-2">
      <ButtonBar className="flex flex-row bg-transparent">
        <SectionHeader onClick={toggle} className="flex-1">Vision</SectionHeader>
        <AddVisionButton valueRef={valueRef}/>
      </ButtonBar>
      {toggled && <SectionBody>
        <div className="mx-2 rounded-md overflow-hidden">
          <InputList accept="legends/token-vision" items={valueRef} itemKey={vision => vision.tokenVisionID} copy={TokenVisionFn.copy} ListItem={TokenVisionListItem}/>
        </div>
      </SectionBody>}
    </div>
  );
}