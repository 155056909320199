import {Size, SizeOperation, SizeSignals} from "common/types/index.ts";
import {useMemo} from "react";
import {MutableRef} from "common/ref";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";
import {GridWidthNumberField} from "./generic/grid-width-number-field.tsx";
import {GridHeightNumberField} from "./generic/grid-height-number-field.tsx";

export type SizeInputProps = {
  valueRef: MutableRef<Size, SizeOperation[]>;
};

export function SizeField({valueRef}: SizeInputProps) {
  const {width, height} = useMemo(() => SizeSignals(valueRef), [valueRef]);

  return (<Field>
    <FieldLabel>Size</FieldLabel>
    <div className="flex flex-row gap-0.5 mx-2 rounded-md overflow-hidden">
      <GridWidthNumberField icon="W" pt={{group: "mx-0"}} valueRef={width} />
      <GridHeightNumberField icon="H" pt={{group: "mx-0"}} valueRef={height} />
    </div>
  </Field>)
}