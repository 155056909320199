import {EditorProperties, EditorPropertiesOperation} from "./editor-properties.ts";
import {useApplyCallback} from "#lib/qlab/index.ts";
import {ElementPropertiesView, ScenePropertiesView} from "./scene/index.ts";
import {AssetTokenPropertiesView} from "./asset/index.ts";
import {useMemo} from "react";
import {MutableRef} from "common/ref";
import {useRefValue} from "#lib/signal/index.ts";
import {useSelectionRef} from "./use-properties.ts";
import {ConstantOperation, ValueOperation} from "common/types/generic/index.ts";
import {SelectionRef} from "../nav/editor/state/selection-ref.ts";

export type PropertiesStackViewProps = {
  valueRef: MutableRef<EditorProperties, EditorPropertiesOperation[]>;
};
export function EditorPropertiesStackView({valueRef}: PropertiesStackViewProps) {
  const reference = useSelectionRef(useRefValue(valueRef).reference);
  const applyToPinned = useApplyCallback(valueRef.apply, EditorProperties.getReference, EditorPropertiesOperation.updateReference);
  const pinned = useMemo(() => new MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>({
    value() {return valueRef.value.reference},
    observe: valueRef.map(v => v.reference).observe,
    apply: applyToPinned
  }), [valueRef, reference, applyToPinned]);

  if (reference?.type === "asset-token") {
    return <AssetTokenPropertiesView reference={reference} pinned={pinned} />
  } else if (reference?.type === "scene") {
    return <ScenePropertiesView reference={reference} pinned={pinned} />
  } else if (reference?.type === "element" || reference?.type === "element-edge" || reference?.type === "element-vertex") {
    return <ElementPropertiesView reference={reference} pinned={pinned} />
  } else {
    return <></>;
  }
}
