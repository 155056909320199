import {NodeId} from "common/legends/index.ts";
import {useMemo} from "react";
import {from} from "common/observable";
import {useObservable} from "#lib/qlab/index.ts";
import {twMerge} from "tailwind-merge";
import {FaQuestion} from "react-icons/fa6";
import {useNodeIcon} from "../../../../common/use-get-node-icon.ts";
import {useIsGameMaster} from "../../../../common/game/use-is-game-master.ts";
import {useGoToNode} from "../../../nav/editor/use-go-to-node.ts";
import {useAccess} from "../../../../../routes/game/model/store-context.tsx";
import {fromSignal} from "common/ref";
import {useComputed} from "#lib/signal/react/use-computed.ts";
import {isTokenElementOwner} from "../../../../common/node/use-is-party-node.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {useCurrentStage} from "../../../../viewport/token/use-current-stage.ts";

export type UserNodeIconProps = {
  nodeID?: NodeId;
};

export function NodeIcon({nodeID}: UserNodeIconProps) {
  const access = useAccess();
  const nodeRef = useMemo(() => fromSignal(access.element(nodeID)), [access]);

  const nodeIconRef = useNodeIcon(useMemo(() => from(nodeID), [nodeID]));
  const nodeIcon = useObservable(nodeIconRef, undefined, [nodeIconRef]);

  const isGameMaster = useIsGameMaster();
  const displayName = useComputed(() => nodeRef.value?.type === "token" ? nodeRef.value.data.displayName : false, [nodeRef]) || isGameMaster;
  const displayIcon = useComputed(() => nodeRef.value?.type === "token" ? nodeRef.value.data.displayIcon : false, [nodeRef]) || isGameMaster;

  const name = useComputed(() => nodeRef.value?.data.name, [nodeRef]);
  const userID = useUserID()!;
  const stageID = useCurrentStage(userID);
  const isOwner = useComputed(() => isTokenElementOwner(nodeRef.value, userID, stageID), [nodeRef, userID, stageID]);

  const goToNode = useGoToNode();
  return (<div className="flex items-center justify-center">
    <div className={twMerge(
      "rounded-full bg-zinc-900 w-8 h-8 inline-flex items-center justify-center pointer-events-auto",
      isOwner && "cursor-pointer"
    )} title={displayName ? name : "??"} onClick={() => {if (isOwner && nodeID) goToNode(nodeID);}}>
      {(displayIcon && nodeIcon)
        ? <img alt={name} src={nodeIcon} crossOrigin="anonymous" className="rounded-full w-8 h-8 ring-1 ring-zinc-900" />
        : <FaQuestion size={20} />}
    </div>
  </div>);
}