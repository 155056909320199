import {Codec} from "common/codec";
import {AccessToken} from "common/access-token";
import {CloudRequest, CloudResponse} from "common/qlab/index.ts";

export type PersistentWebSocketHandler = {
  onOpen: () => void;
  onMessage: (message: CloudResponse) => void;
  onClose: () => void;
  onReconnect: () => void;
};

const RETRY_TIMEOUTS = [0, 5000, 10000, 60000, 300000];

export class PersistentWebSocket {
  private webSocket?: WebSocket;
  private previouslyConnected: boolean = false;
  private failedAttempt: number = 0;
  private reconnectAttempt?: NodeJS.Timeout;
  private sendQueue: CloudRequest[] = [];
  constructor(
    private readonly apiUrl: string,
    private readonly wsUrl: string,
    private readonly authToken: string,
    private readonly accessTokens: AccessToken[],
    private readonly handler: PersistentWebSocketHandler
  ) {
    this.connect();
  }

  private retryConnect = () => {
    // attempt to connect later
    const nextRetryTimeout = RETRY_TIMEOUTS[Math.min(this.failedAttempt, RETRY_TIMEOUTS.length - 1)];
    this.failedAttempt ++;
    this.webSocket = undefined;
    // if (navigator.onLine) {
      this.reconnectAttempt = setTimeout(() => this.connect(), nextRetryTimeout);
    // } else {
      // const connectOnOnline = () => {
      //   this.connect();
      //   window.removeEventListener("online", connectOnOnline);
      // };
      // window.addEventListener("online", connectOnOnline);
    // }
  };

  private connect = () => {
    if (this.webSocket !== undefined) return;
    // if (!navigator.onLine) {
    //   this.retryConnect();
    // } else {
      const webSocket = new WebSocket(`${this.wsUrl}?${this.accessTokens.map(token => `token=${encodeURIComponent(JSON.stringify(token))}`).join("&")}`, [this.authToken, "access_token"]);
      webSocket.addEventListener("open", () => {
        this.failedAttempt = 0;
        if (!this.previouslyConnected) {
          setTimeout(() => this.handler.onOpen(), 0);
          this.previouslyConnected = true;
        } else {
          setTimeout(() => this.handler.onReconnect(), 0);
        }
        webSocket.addEventListener("close", () => setTimeout(() => this.handler.onClose(), 0));

        // Send any messages queued while offline
        const previousSendQueue = this.sendQueue;
        this.sendQueue = [];
        for (const message of previousSendQueue) {
          this.send(message);
        }
      });
      webSocket.addEventListener("error", ev => {
        console.error(ev);
      });
      webSocket.addEventListener("message", async (ev) => {
        const response = (await Codec.decode(ev.data)) as CloudResponse;
        if (response.type === "STORE-AVAILABLE") {
            fetch(`${this.apiUrl}/store/${response.storeId}`, {
              credentials: "include",
              headers: {
                "Authorization": `Bearer ${this.authToken}`
              }
            })
              .then(response => response.text())
              .then(response => Codec.decode(response))
              .then(value => this.handler.onMessage({
                type: "STORE-INITIALIZED",
                storeId: response.storeId,
                store: value
              } as CloudResponse));
        } else if (response.type === "STORE-CHANGESETS-AVAILABLE") {
          response.revisions.map(revision => {
            fetch(`${this.apiUrl}/store/${response.storeId}/changeset/${revision}`, {
              credentials: "include",
              headers: {
                "Authorization": `Bearer ${this.authToken}`
              }
            })
              .then(response => response.text())
              .then(response => Codec.decode(response))
              .then(changeset => this.handler.onMessage({
                type: "STORE-CHANGESET-APPLIED",
                storeId: response.storeId,
                storeChangesets: [changeset]
              } as CloudResponse));
          });
        } else if (response.type === "RESOURCE-AVAILABLE") {
          fetch(`${this.apiUrl}/store/${response.storeId}/resource/${response.resourceId}`, {
            credentials: "include",
            headers: {
              "Authorization": `Bearer ${this.authToken}`
            }
          })
            .then(response => response.text())
            .then(response => Codec.decode(response))
            .then(value => this.handler.onMessage({
              type: "RESOURCE-INITIALIZED",
              storeId: response.storeId,
              resourceId: response.resourceId,
              resource: value
            } as CloudResponse));
        } else if (response.type === "RESOURCE-CHANGESETS-AVAILABLE") {
          response.revisions.map(revision => {
            fetch(`${this.apiUrl}/store/${response.storeId}/resource/${response.resourceId}/changeset/${revision}`, {
              credentials: "include",
              headers: {
                "Authorization": `Bearer ${this.authToken}`
              }
            })
              .then(response => response.text())
              .then(response => Codec.decode(response))
              .then(changeset => this.handler.onMessage({
                type: "RESOURCE-CHANGESET-APPLIED",
                storeId: response.storeId,
                resourceId: response.resourceId,
                resourceChangesets: [changeset]
              } as CloudResponse));
          });
        } else if (response.type === "MESSAGE-AVAILABLE") {
          fetch(`${this.apiUrl}/store/${response.storeId}/message/${response.messageId}`, {
            credentials: "include",
            headers: {
              "Authorization": `Bearer ${this.authToken}`
            }
          })
            .then(response => response.text())
            .then(response => Codec.decode(response))
            .then(value => this.handler.onMessage({
              type: "MESSAGE-INITIALIZED",
              storeId: response.storeId,
              messageId: response.messageId,
              message: value
            } as CloudResponse));
        } else if (response.type === "MESSAGE-CHANGESETS-AVAILABLE") {
          response.revisions.map(revision => {
            fetch(`${this.apiUrl}/store/${response.storeId}/message/${response.messageId}/changeset/${revision}`, {
              credentials: "include",
              headers: {
                "Authorization": `Bearer ${this.authToken}`
              }
            })
              .then(response => response.text())
              .then(response => Codec.decode(response))
              .then(changeset => this.handler.onMessage({
                type: "MESSAGE-CHANGESETS-APPLIED",
                storeId: response.storeId,
                messageId: response.messageId,
                messageChangesets: [changeset]
              } as CloudResponse));
          });
        } else {
          this.handler.onMessage(response);
        }
      });
      webSocket.addEventListener("close", this.retryConnect);

      // const closeWhenOffline = () => {
      //   webSocket.close();
      //   window.removeEventListener("offline", closeWhenOffline);
      // };
      // window.addEventListener("offline", closeWhenOffline);
      this.webSocket = webSocket;
    // }
  }

  close = () => {
    if (this.webSocket !== undefined) {
      this.webSocket.removeEventListener("close", this.retryConnect);
      if (this.webSocket.readyState === WebSocket.OPEN) {
        this.webSocket.close();
      }
    }
    if (this.reconnectAttempt) {
      clearTimeout(this.reconnectAttempt);
    }
    this.webSocket = undefined;
  }

  send = async (message: CloudRequest): Promise<void> => {
    if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN) {
      console.debug("   > %o", message);
      const encodedMessage = await Codec.encode(message);
      if (encodedMessage.length < 32768) {
        this.webSocket.send(encodedMessage);
      } else {
        fetch(this.apiUrl, {
          method: "POST",
          credentials: "include",
          headers: {
            "Authorization": `Bearer ${this.authToken}`
          },
          body: encodedMessage
        })
          .then(ev => ev.text())
          .then(ev => Codec.decode(ev) as Promise<CloudResponse>)
          .then(response => {
            console.debug("<    %o", response);
            setTimeout(() => this.handler.onMessage(response), 0);
          })
          .catch(console.error);
      }
    } else {
      this.sendQueue.push(message);
    }
  }

  isOpen = (): boolean => {
    if (!this.webSocket) return false;
    return this.webSocket.readyState === WebSocket.OPEN;
  }
}
