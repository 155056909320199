import {Line, WallLineFn} from "../../viewport/scene/scene-view.tsx";
import {Node} from "common/legends/index.ts";
import {Vector2f} from "#lib/math/index.ts";
import {WallGraph} from "common/legends/node/wall-node.ts";
import {Transform} from "common/types/generic/transform/transform.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {cubicBezier} from "common/math/bezier/bezier.ts";
import {SplineFn} from "common/types/generic/spline/index.ts";
import {Color} from "common/types/generic/index.ts";
import {LegendsAccess} from "common/access";

const wallsCache = new WeakMap();
export function getLinesFromWallGraph(graph: WallGraph, transform: (line: Line) => Line): Line[] {
  if (!wallsCache.has(graph)) {
    const value = graph.edges.flatMap(edge => {
      const start = graph.vertices[edge.start];
      const end = graph.vertices[edge.end];
      return WallLineFn.toLines(cubicBezier(start.coordinate, end.coordinate, edge.controlPoint1, edge.controlPoint2, edge.resolution), !edge.data.blockVisibilityLeft, !edge.data.blockVisibilityRight, edge.data.blockMovementLeft, edge.data.blockMovementRight, edge.data.tint);
    });
    wallsCache.set(graph, value);
    return value.map(transform);
  }
  return wallsCache.get(graph).map(transform);
}


export function getWalls(
  walls: Line[],
  model: Transform,
  access: LegendsAccess,
  node: Node,
  isVisible: (node: Node) => boolean,
  isAccessible: (node: Node) => boolean
): Line[] {
  if (!isVisible(node)) return walls;
  const valueModel = Transform.divide(node.data.transform, model);
  const transform = (line: Line): Line => {
    let newStart = Vector2.multiplyTransform(line.start, valueModel);
    let newEnd = Vector2.multiplyTransform(line.end, valueModel);
    return {...line, start: newStart, end: newEnd};
  };

  if (node.type === "wall") {
    const lines = getLinesFromWallGraph(node.data.graph, transform);
    return [...node.data.children].reverse().reduce((walls, child) => getWalls(walls, valueModel, access, child, isVisible, isAccessible), [...walls, ...lines]);
  } else if (node.type === "token") {
    const tokenReference = node.data.tokenReference;
    const resource = access.asset(tokenReference.assetID).value;
    if (!resource) return [...node.data.children].reverse().reduce((walls, child) => getWalls(walls, valueModel, access, child, isVisible, isAccessible), walls);
    const token = resource.tokens.find(token => token.tokenID === tokenReference.tokenID);
    if (token === undefined) return [...node.data.children].reverse().reduce((walls, child) => getWalls(walls, valueModel, access, child, isVisible, isAccessible), walls);

    return [
      ...[...token.children].reverse(),
      ...[...node.data.children].reverse()
    ].reduce((walls, child) => getWalls(walls, valueModel, access, child, isVisible, isAccessible), walls);
  } else if (node.type === "area") {
    let areaWalls = node.data.suppressWalls ? node.data.areas.reduce((walls, area) => {
      const areaLines = WallLineFn.toLines(SplineFn.getLines(area), false, false, false, false, Color.WHITE).map(transform);
      return walls.flatMap((line): Line[] => {
        const t = areaLines.map((oLine) => {
          const t = Vector2f.distanceToLine(oLine.start, oLine.end, line.start, line.end);
          if (t === undefined || t + Number.EPSILON*1000 < 0 || t - Number.EPSILON*1000 > 1) return undefined;
          if (t - Number.EPSILON*1000 < 0 || t + Number.EPSILON*1000 > 1) {
            const d = Vector2f.dot(Vector2f.subtract(oLine.end, oLine.start), Vector2f.subtract(line.end, line.start));
            if (d + Number.EPSILON*1000 > 0) return undefined;
          }
          return Vector2f.distanceToLine(line.start, line.end, oLine.start, oLine.end);
        }).filter(t => t !== undefined && t > 0).sort() as number[];

        if (t.length % 2 === 0 && t.filter(t => t <= 1).length === 0) {
          return [line];
        } else {
          const lines: Line[] = [];
          let iteration = t.length % 2 === 1 ? 1 : 0;
          for (let i = -1; i < t.length; i ++) {
            if (i >= 0 && t[i] + Number.EPSILON > 1) break;
            const p1 = Vector2f.lerp(line.start, line.end, i >= 0 ? t[i] : 0);
            const p2 = Vector2f.lerp(line.start, line.end, i + 1 < t.length ? t[i+1] : 1);
            if (iteration % 2 === 0) lines.push({start: p1, end: p2, viewLeft: line.viewLeft, viewRight: line.viewRight, blockMovementLeft: line.blockMovementLeft, blockMovementRight: line.blockMovementRight, tint: line.tint});
            iteration ++;
          }
          return lines;
        }
      });
    }, walls) : walls;

    return [...node.data.children].reverse().reduce((walls, child) => getWalls(walls, valueModel, access, child, isVisible, isAccessible), areaWalls);
  }

  return [...node.data.children].reverse().reduce((walls, child) => getWalls(walls, valueModel, access, child, isVisible, isAccessible), walls);
}
