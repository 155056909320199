import React, {useMemo} from "react";
import {PlayerContainer} from "./player-container.tsx";
import {PlayerControllerProvider} from "./player-controller-provider.ts";
import {PlayerController} from "./player-controller.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {GameSignals, Scene, SceneFn, SceneID, SceneOperation} from "common/legends/index.ts";
import {useGame, useGameReference} from "../../../../routes/game";
import {TokenViewport} from "../../../viewport/token/token-viewport.tsx";
import {useControllerElementsSceneIDs} from "../../../viewport/token/use-controller-node-ids.ts";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {useScene} from "../../../viewport/token/use-scene.ts";
import {usePresent} from "../../../common/use-optional-signal.ts";
import {from} from "common/observable";
import {MutableRef} from "common/ref";
import {Beyond20Integration} from "../../../../routes/game/view/main/beyond-20-integration.tsx";

const DEFAULT_SCENE_REF: MutableRef<Scene, SceneOperation[]> = new MutableRef<Scene, SceneOperation[]>({
  value(): Scene {
    return SceneFn.DEFAULT;
  },
  observe: from(SceneFn.DEFAULT),
  apply: async () => SceneFn.DEFAULT
});

export function PlayerGameView() {
  const gameRef = useGameReference();
  const game = useGame();
  const {defaultSceneID} = useMemo(() => GameSignals(game), [game]);

  const userID = useUserID()!;
  const nodeScenesRef = useControllerElementsSceneIDs(userID);

  const playerController = useMemo(() => new PlayerController(gameRef.storeId), [gameRef.storeId]);
  const controllerNodeID = useComputedValue(playerController.state, state => state.activeController?.controllerNodeID);
  const currentDefaultSceneID = useRefValue(defaultSceneID);
  const controllerSceneID = useComputedValue(nodeScenesRef, nodeScenes => controllerNodeID ? nodeScenes[controllerNodeID] : undefined, [controllerNodeID]);
  const sceneID = controllerSceneID ?? currentDefaultSceneID;
  const scene = usePresent(useScene(sceneID));
  return <PlayerControllerProvider value={playerController}>
    <Beyond20Integration/>
    <PlayerContainer>
      <TokenViewport storeId={gameRef.storeId} sceneID={sceneID ?? "" as SceneID} scene={scene ?? DEFAULT_SCENE_REF} />
    </PlayerContainer>
  </PlayerControllerProvider>;
}
