import React, {PropsWithChildren, useContext, useMemo} from "react";
import {useRenderingContext} from "#lib/gl-react/index.ts";

export class ShaderManager {
  private shaders: {[type: GLenum]: {[source: string]: WebGLShader}} = {};
  constructor(private readonly context: WebGL2RenderingContext) {}
  getShader(type: GLenum, source: string): WebGLShader {
    if (this.shaders[type] === undefined || this.shaders[type][source] === undefined) {
      const shader = this.context.createShader(type);
      if (shader === null) throw new Error("Cannot create shader.");
      this.context.shaderSource(shader, source);
      this.context.compileShader(shader);
      const shaderError = this.context.getShaderInfoLog(shader);
      if (shaderError) {
        throw new Error(shaderError);
      }
      if (this.shaders[type] === undefined) this.shaders[type] = {};
      this.shaders[type][source] = shader;
    }
    return this.shaders[type][source];
  }
}

const ShaderManagerContext = React.createContext<ShaderManager | undefined>(undefined);
export function ShaderManagerProvider({children}: PropsWithChildren<object>) {
  const context = useRenderingContext();
  const textureManager = useMemo(() => new ShaderManager(context), [context]);
  return <ShaderManagerContext.Provider value={textureManager}>
    {children}
  </ShaderManagerContext.Provider>
}

export function useShaderManager() {
  return useContext(ShaderManagerContext)!;
}
