import {RefObject, useCallback} from "react";
import {Point} from "common/types/index.ts";

export function useGetScreenPosition(canvasRef: RefObject<HTMLCanvasElement>) {
  return useCallback((p: Point): Point => getScreenPosition(canvasRef.current!, p), [canvasRef]);
}

export function getScreenPosition(canvas: HTMLCanvasElement, [clientX, clientY]: Point): Point {
  const {left, right, top, bottom} = canvas.getBoundingClientRect();
  const [px, py] = [clientX - left, clientY - top];
  const [cw, ch] = [right - left, bottom - top];
  return [px - cw / 2, -(py - ch / 2)];
}
