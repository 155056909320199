import {KeyboardEvent, MouseEvent, RefObject, useCallback, useRef} from "react";
import {HSLA, Optional, Point, PointFn, SetFn, SetOperation, Transform, TransformOperation, Tree, TreeOperation, ValueFn, walkTree} from "common/types/index.ts";
import {generateMeasureID, Measurement, MeasurementOperation} from "common/legends/measurement/index.ts";
import {Grid, Node, NodeId, NodeOperation, UserID} from "common/legends/index.ts";
import {ContextMenu, ContextMenuOperation} from "../../../../routes/game/context-menu/context-menu.ts";
import {useQLabStoreStateRef} from "#lib/qlab/react/use-q-lab-store-state-ref.ts";
import {useObservableRef} from "#lib/qlab/index.ts";
import {useGetScreenPosition} from "../use-get-screen-pos.ts";
import {useGetWorldPositionFromScreenPosition} from "../use-get-world-pos.ts";
import {userIDRef} from "#lib/auth/use-get-user-id.ts";
import {useNodePosToWorldPos} from "../use-node-pos-to-world-pos.ts";
import {useWorldPosToNodePos} from "../use-world-pos-to-node-pos.ts";
import {useApplyInteractionAction} from "../use-apply-interaction-action.ts";
import {useOpenSheetReference} from "./use-open-sheet-reference.ts";
import {MutableRef, Ref} from "common/ref";
import {useDatabase} from "../../../../routes/game/model/store-context.tsx";
import {getAreasAtPoint, getElementsAtPoint, NodePath} from "../../common/context/get-elements-at-point.ts";
import {useIsGameMaster} from "../../../common/game/use-is-game-master.ts";
import {SheetReference} from "../../../common/sheet/sheet-reference.ts";
import {getParentNode} from "../../../common/legends/get-parent-node.ts";
import {AssetTokenSelectionRef, NodeSelectionRef, SceneSelectionRef, SelectionRef} from "../../../panel/nav/editor/state/selection-ref.ts";
import {useIsActingPlayer} from "../use-is-acting-player.ts";
import {useMoveElement} from "../use-move-element.ts";
import {useGetGrid} from "../../../panel/properties/use-get-grid.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {useMouseEventHandlers} from "../../../panel/nav/common/tool/tool-selector/use-mouse-event-handlers.ts";

export function useSelectToolHandlers(
  canvasRef: RefObject<HTMLCanvasElement>,
  rootSelectionRef: SceneSelectionRef | AssetTokenSelectionRef,
  activeNodeIdRef: Ref<Optional<NodeId>>,
  viewRef: MutableRef<Transform, TransformOperation[]>,
  measurementRef: MutableRef<Measurement, MeasurementOperation[]>,
  nodes: MutableRef<Node[], TreeOperation<Node, NodeOperation>[]>,
  selectedNodeIds: MutableRef<NodeSelectionRef[], SetOperation<NodeSelectionRef>[]>,
  color: HSLA,
  contextMenu: MutableRef<ContextMenu, ContextMenuOperation[]>,
  isAccessible: (node: Node) => boolean,
  isVisible: (node: Node) => boolean
) {
  const storeRef = useQLabStoreStateRef();
  const nodesRef = useObservableRef(nodes.observe, [nodes.observe]);
  const getScreenPos = useGetScreenPosition(canvasRef);
  const getWorldPos = useGetWorldPositionFromScreenPosition(viewRef);
  const startWorldPosRef = useRef<Point | undefined>(undefined);
  const rightMousePosRef = useRef<Point | undefined>(undefined);
  const isDragging = useRef(false);
  const selectedNodeIdsRef = useObservableRef(selectedNodeIds.observe, [selectedNodeIds.observe]);
  const getGrid = useGetGrid();

  const timeRef = useRef<number | undefined>(undefined);

  const addPointToMeasurement = useCallback(() => {
    measurementRef.apply(prevValue => {
      if (prevValue?.type === "path") {
        return ValueFn.set(prevValue, {
          ...prevValue,
          type: "path",
          nodes: [
            ...prevValue.nodes.slice(0, prevValue.nodes.length),
            prevValue.nodes[prevValue.nodes.length - 1]
          ]
        });
      }
      return [];
    });
  }, [measurementRef]);

  const onDragContextMenu = useCallback((ev: MouseEvent) => {
    if (rightMousePosRef.current === undefined) return true;
    if (Math.abs(rightMousePosRef.current[0] - ev.clientX) > 4 || Math.abs(rightMousePosRef.current[1] - ev.clientY) > 4) return true;
    if (isDragging.current === undefined || !isDragging.current) return true;
    addPointToMeasurement();
    ev.preventDefault();
    return false;
  }, [isDragging, addPointToMeasurement, rightMousePosRef]);

  const onSelectContextMenu = useCallback((ev: MouseEvent) => {
    if (!selectedNodeIdsRef.current?.isSuccess) return true;
    if (!nodesRef.current?.isSuccess) return true;
    if (rightMousePosRef.current === undefined) return true;

    if (Math.abs(rightMousePosRef.current[0] - ev.clientX) > 4 || Math.abs(rightMousePosRef.current[1] - ev.clientY) > 4) return true;

    const worldPos = getWorldPos(getScreenPos([ev.clientX, ev.clientY]));
    const targetNodeRefs = getElementsAtPoint(storeRef.current, isVisible, isVisible, viewRef.value, worldPos, nodesRef.current.data);
    const selectedNodeIds = selectedNodeIdsRef.current.data;
    contextMenu.apply(prev => ValueFn.set(prev, {
      type: "token",
      data: {
        clientPos: [ev.clientX, ev.clientY],
        worldPos,
        selectedNodeID: selectedNodeIds.length > 0 ? selectedNodeIds[0].elementID : undefined,
        targetNodeIDs: targetNodeRefs
          .filter(nodeRef => {
            const node = Tree.getItemById(nodesRef.current?.data || [], nodeRef.elementID);
            if (node === undefined) return false;
            if (node.type === "token" || node.type === "image" || node.type === "video") {
              return node.data.mountable || node.data.attachable;
            }
            return true;
          })
          .map(nodeRef => nodeRef.elementID)
      }
    }));
    ev.preventDefault();
    return false;
  }, [isDragging, addPointToMeasurement, rightMousePosRef, getWorldPos, getScreenPos, nodesRef, selectedNodeIdsRef, viewRef, contextMenu]);

  const selectedOnDownRef = useRef(false);
  const onSelectMouseDown = useCallback((ev: MouseEvent) => {
    if (!selectedNodeIdsRef.current?.isSuccess) return true;
    if (!nodesRef.current?.isSuccess) return true;

    if (ev.button === 2) {
      rightMousePosRef.current = [ev.clientX, ev.clientY];
    }
    if (ev.button !== 0) return true;
    timeRef.current = new Date().getTime();
    selectedOnDownRef.current = false;
    const worldPos = getWorldPos(getScreenPos([ev.clientX, ev.clientY]));

    const clickNodes = getElementsAtPoint(storeRef.current, isAccessible, isVisible, viewRef.value, worldPos, nodesRef.current.data);
    const clickedOnSelected = clickNodes.some(node => (selectedNodeIdsRef.current?.data || []).some(o => o.elementID === node.elementID));
    if (!clickedOnSelected) {
      const nodesValue = nodesRef.current.data;
      const rootPath: NodePath = rootSelectionRef.type === "scene"
        ? {type: "scene", sceneID: rootSelectionRef.sceneID, path: []}
        : {type: "asset", assetID: rootSelectionRef.assetID, tokenID: rootSelectionRef.tokenID, path: []};
      const areas = getAreasAtPoint(rootPath, storeRef.current, isAccessible, isVisible, worldPos, viewRef.value, nodesValue)
        .filter(([_, area]) => area.type === "area" && area.data.interactions.some(i => i.triggers.some(t => t.type === "click")));
      if (areas.length > 0) {
        return true;
      }

      if (clickNodes.length !== 0) {
        selectedNodeIds.apply(prevValue => SetFn.set(prevValue, [clickNodes[0]]));
        selectedNodeIdsRef.current.data = [clickNodes[0]];
        selectedOnDownRef.current = true;
      }
    }
    return true;
  }, [timeRef, getWorldPos, getScreenPos, storeRef, nodesRef, selectedNodeIdsRef, rootSelectionRef, rightMousePosRef, isAccessible, isVisible, viewRef]);

  const applyInteractionAction = useApplyInteractionAction();
  const isGameMaster = useIsGameMaster();
  const openSheetReference = useOpenSheetReference();

  const onSelectMouseUp = useCallback((ev: MouseEvent): boolean => {
    if (ev.button !== 0) return true;
    if (!timeRef.current) return true;
    const worldPos = getWorldPos(getScreenPos([ev.clientX, ev.clientY]));
    if (new Date().getTime() - 200 >= timeRef.current || isDragging.current) return true;
    if (selectedOnDownRef.current) {
      selectedOnDownRef.current = false;
      return true;
    }

    const nodesValue = nodes.value;
    const rootPath: NodePath = rootSelectionRef.type === "scene"
      ? {type: "scene", sceneID: rootSelectionRef.sceneID, path: []}
      : {type: "asset", assetID: rootSelectionRef.assetID, tokenID: rootSelectionRef.tokenID, path: []};
    const areas = getAreasAtPoint(rootPath, storeRef.current, isAccessible, isVisible, worldPos, viewRef.value, nodesValue)
      .filter(([_, area]) => area.type === "area" && area.data.interactions.some(i => i.triggers.some(t => t.type === "click")));
    if (areas.length > 0) {
      const [path, area] = areas[areas.length - 1];
      if (area.type !== "area") return false;
      area.data.interactions.flatMap(i => i.actions).flatMap(action => applyInteractionAction({
        triggerPath: path,
        triggerNode: area,
        activeNodeID: activeNodeIdRef.value,
        action: action
      }));
      return true;
    }

    const clickNodes = getElementsAtPoint(storeRef.current, isAccessible, isVisible, viewRef.value, worldPos, nodesValue);
    if (clickNodes.length === 0) {
      selectedNodeIds.apply(prevNodeIds => SetFn.set(prevNodeIds, []));
      return false;
    } else {
      const shiftKey = ev.shiftKey;
      selectedNodeIds.apply((prevNodeIds): SetOperation<NodeSelectionRef>[] => {
        const nextActiveLayerNodeIndex = (Math.max(-1, ...prevNodeIds.map(prev => clickNodes.findIndex(node => node.elementID === prev.elementID))) + 1) % clickNodes.length;
        const nextActiveLayerNodeId: NodeSelectionRef = clickNodes[nextActiveLayerNodeIndex];

        if (ev.altKey) {
          if (ev.detail !== 2) return [];
          const selectedNodeIDs = prevNodeIds.filter(nodeRef => clickNodes.some(node => node.elementID === nodeRef.elementID)).map(nodeRef => nodeRef.elementID);
          const selectedNodes: Node[] = [];
          walkTree(nodesValue, {
            visit(value) {
              if (selectedNodeIDs.includes(value.data.id)) selectedNodes.push(value);
            }
          });
          for (const selectedNode of selectedNodes) {
            if (selectedNode.type !== "token") continue;
            if (!isGameMaster && !selectedNode.data.ownerIDs.includes("GLOBAL" as UserID) && !selectedNode.data.ownerIDs.includes(userIDRef.value!)) continue;
            const tokenID = selectedNode.data.tokenReference.tokenID;
            const sheet = selectedNode.data.tokenSheets[tokenID];
            if (!sheet) continue;
            let sheetReference: SheetReference = (sheet.type === "link")
              ? {type: "link", assetID: selectedNode.data.tokenReference.assetID, sheetID: sheet.data}
              : {type: "copy", nodeID: selectedNode.data.id, tokenID: selectedNode.data.tokenReference.tokenID};
            openSheetReference(sheetReference);
          }
          return [];
        } else if (shiftKey) {
          if (prevNodeIds.find(node => SelectionRef.equals(node, nextActiveLayerNodeId))) {
            return [{type: "delete", item: nextActiveLayerNodeId}];
          } else {
            return [{type: "insert", item: nextActiveLayerNodeId}];
          }
        } else {
          if (prevNodeIds.find(node => SelectionRef.equals(node, nextActiveLayerNodeId))) {
            return [{type: "set", prevItems: prevNodeIds, nextItems: []}];
          } else {
            return [{type: "set", prevItems: prevNodeIds, nextItems: [nextActiveLayerNodeId]}];
          }
        }
      });
    }
    timeRef.current = undefined;
    return true;
  }, [
    rootSelectionRef,
    selectedNodeIds,
    storeRef,
    nodes,
    getScreenPos,
    getWorldPos,
    timeRef,
    isDragging,
    applyInteractionAction,
    openSheetReference,
    isGameMaster,
    isVisible,
    isAccessible,
    viewRef
  ]);

  const nodePosToWorldPos = useNodePosToWorldPos(nodes);
  const worldPosToNodePos = useWorldPosToNodePos(nodes);

  const onDragMouseDown = useCallback((ev: MouseEvent): boolean => {
    if (!selectedNodeIdsRef.current?.isSuccess) return true;
    if (!nodesRef.current?.isSuccess) return true;

    let worldPos = getWorldPos(getScreenPos([ev.clientX, ev.clientY]));
    const clickNodes = getElementsAtPoint(storeRef.current, isAccessible, isVisible, viewRef.value, worldPos, nodesRef.current.data);

    if (ev.button === 0) {
      const clickedOnSelected = clickNodes.some(node => (selectedNodeIdsRef.current?.data || []).some(o => o.elementID === node.elementID));
      if (clickedOnSelected) {
        const nodeRef = selectedNodeIdsRef.current.data![0];
        const grid = getGrid(nodeRef);

        const parentNodeId = getParentNode(nodesRef.current.data, nodeRef.elementID)?.data.id;
        const nodePosOnParent = Tree.getItemById(nodesRef.current.data, nodeRef.elementID)!.data.transform.position;
        const nodeGridOffset = Vector2.subtract(Grid.snap(grid, nodePosOnParent), nodePosOnParent);
        startWorldPosRef.current = !ev.shiftKey
          ? nodePosToWorldPos(parentNodeId, Vector2.subtract(
              Grid.snap(grid, worldPosToNodePos(parentNodeId, worldPos)),
              nodeGridOffset
            ))
          : worldPos;
      }
      isDragging.current = false;
    }
    return true;
  }, [rootSelectionRef, getGrid, selectedNodeIds, startWorldPosRef, getScreenPos, getWorldPos, isDragging, isAccessible, nodePosToWorldPos, worldPosToNodePos, isVisible, viewRef, nodesRef]);


  const onDragMouseMove = useCallback((ev: MouseEvent) => {
    if (ev.button !== 0) return true;
    if (startWorldPosRef.current === undefined) return true;
    if (!nodesRef.current?.isSuccess) return true;
    if (!selectedNodeIdsRef.current?.isSuccess) return true;
    let selectedNodeIDs = selectedNodeIdsRef.current!.data!;
    if (selectedNodeIDs.length === 0) return true;

    const nodeId = selectedNodeIDs[0].elementID;
    const parentNodeId = getParentNode(nodesRef.current.data, nodeId)?.data.id;
    const snapToGrid = !ev.shiftKey;

    let worldPos = getWorldPos(getScreenPos([ev.clientX, ev.clientY]));

    const nodeRef = selectedNodeIDs[0];
    const grid = getGrid(nodeRef);

    worldPos = snapToGrid
      ? nodePosToWorldPos(parentNodeId, Grid.snap(grid, worldPosToNodePos(parentNodeId, worldPos)))
      : worldPos;
    if (!PointFn.equals(startWorldPosRef.current, worldPos) || isDragging.current) {
      isDragging.current = true;
      measurementRef.apply((prevValue) => {
        if (selectedNodeIDs.length === 0) return []
        if (!nodesRef.current?.isSuccess) return [];
        if (prevValue?.type !== "path") {
          return ValueFn.set<Measurement, never>(prevValue, {
            type: "path",
            id: generateMeasureID(),
            resourceRef: rootSelectionRef.type === "scene" ? {type: "scene", sceneID: rootSelectionRef.sceneID}
              : {type: "asset-token", assetID: rootSelectionRef.assetID, tokenID: rootSelectionRef.tokenID},
            nodeID: selectedNodeIDs[0].elementID,
            color,
            nodes: [
              startWorldPosRef.current!,
              worldPos
            ]
          });
        } else {
          if (PointFn.equals(prevValue.nodes[prevValue.nodes.length - 1], worldPos)) return [];
          return ValueFn.set<Measurement, never>(prevValue, {
            ...prevValue,
            nodes: [
              ...prevValue.nodes.slice(0, prevValue.nodes.length - 1),
              worldPos
            ]
          });
        }
      });
    }
    return true;
  }, [measurementRef, getGrid, getScreenPos, getWorldPos, nodePosToWorldPos, isDragging, color]);

  const moveElement = useMoveElement(
    nodes,
    rootSelectionRef,
    activeNodeIdRef,
    isVisible,
    isAccessible
  );

  const isActingPlayer = useIsActingPlayer(activeNodeIdRef);
  const databaseRef = useDatabase();
  const onDragMouseUp = useCallback((ev: MouseEvent) => {
    if (ev.button !== 0) return true;
    if (!selectedNodeIdsRef.current?.isSuccess) return true;
    if (!nodesRef.current?.isSuccess) return true;
    const selectedNodeIDs = selectedNodeIdsRef.current.data;
    const measurement = measurementRef.value;
    measurementRef.apply((prevValue) => {
      if (prevValue?.type !== undefined)
        return ValueFn.set<Measurement, never>(prevValue, {type: "noop", id: prevValue.id, resourceRef: prevValue.resourceRef})
      return [];
    });

    if (!isDragging.current) {
      startWorldPosRef.current = undefined;
      return true;
    }
    if (!startWorldPosRef.current) return true;

    // drag and drop
    const nodeRef = selectedNodeIDs[0];
    const grid = getGrid(nodeRef);

    let worldPos = getWorldPos(getScreenPos([ev.clientX, ev.clientY]));
    const nodeId = selectedNodeIDs[0].elementID;
    const parentNodeId = getParentNode(nodesRef.current.data, nodeId)?.data.id;
    worldPos = !ev.shiftKey
      ? nodePosToWorldPos(parentNodeId, Grid.snap(grid, worldPosToNodePos(parentNodeId, worldPos)))
      : worldPos;
    const endPos = worldPos;
    const startPos = startWorldPosRef.current!;

    moveElement(
      selectedNodeIDs,
      viewRef.value,
      measurement?.type === "path" ?
        [
          startPos,
          ...measurement.nodes.slice(1, measurement.nodes.length - 1),
          endPos
        ] : [
          startPos, endPos
        ],
      isActingPlayer
    );

    startWorldPosRef.current = undefined;
    isDragging.current = false;
    return false;
  }, [nodes, getGrid, selectedNodeIds, getWorldPos, getScreenPos, selectedNodeIdsRef, nodesRef, storeRef, isDragging, databaseRef, nodePosToWorldPos, worldPosToNodePos, activeNodeIdRef, applyInteractionAction, rootSelectionRef, viewRef, isActingPlayer, moveElement]);

  const onDragKeyUp = useCallback((ev: KeyboardEvent) => {
    if (!isDragging.current) return true;
    if (ev.key === "q") {
      addPointToMeasurement();
    } else if (ev.key === "Escape") {
      startWorldPosRef.current = undefined;
      isDragging.current = false;
      measurementRef.apply(prev => {
        if (prev?.type !== "path") return [];
        return ValueFn.set<Measurement, never>(prev, {
          type: "noop",
          resourceRef: prev?.resourceRef,
          id: prev?.id
        });
      });
    }
    return false;
  }, [isDragging, addPointToMeasurement]);

  return {
    onSelectMouseDown: useMouseEventHandlers(onSelectMouseDown, onDragMouseDown),
    onSelectMouseUp: useMouseEventHandlers(onSelectMouseUp, onDragMouseUp),
    onSelectMouseMove: useMouseEventHandlers(onDragMouseMove),
    onSelectContextMenu,
    onDragKeyUp,
    onDragMouseUp,
    onDragContextMenu
  };
}
