import {FileReference, Point} from "common/types/index.ts";
import React from "react";
import {useVideoTexture} from "../../viewport/common/context/use-video-texture.ts";
import {ImageShader} from "../../viewport/common/shader/image-shader.tsx";

export function VideoView({file, normal, repeatX = 1, repeatY = 1, flipHorizontal, flipVertical, ...props}: {
  file: FileReference;
  normal?: FileReference;
  size: Point;
  origin: Point;
  pivot: Point;
  opacity: number;
  repeatX?: number | null;
  repeatY?: number | null;
  flipHorizontal?: boolean;
  flipVertical?: boolean;
}) {
  const [isLoaded, imageTexture] = useVideoTexture(file);
  const [isNormalLoaded, normalTexture] = useVideoTexture(normal);
  if (!isLoaded || (normal && !isNormalLoaded)) return <></>;
  return <ImageShader {...props} albedoTexture={imageTexture} normalTexture={normal ? normalTexture : undefined} repeatX={repeatX} repeatY={repeatY} flipHorizontal={flipHorizontal} flipVertical={flipVertical} />;
}

