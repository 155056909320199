import {Node} from "common/legends/node/index.ts";
import {LightElementLightPass} from "./light/light-element-light-pass.tsx";
import {useIsElementAccessible, useIsElementVisible} from "../viewport/common/context/visibility-context.ts";
import {Transform} from "common/types/generic/index.ts";
import {ModelProvider, useModel} from "../viewport/common/context/pvm-context.ts";
import {TokenElementLightPass} from "./token/token-element-light-pass.tsx";
import {useConditionsMet} from "../viewport/common/context/use-conditions-met.ts";
import React, {useMemo} from "react";

export const ElementLightPass = React.memo(function ElementLightPass({value}: {value: Node}) {
  const model = useModel();
  const valueModel = useMemo(() => Transform.divide(value.data.transform, model), [value.data.transform, model]);

  const isVisible = useIsElementVisible()(value);
  const isAccessible = useIsElementAccessible()(value);
  const conditionsMet = useConditionsMet(value.data.conditions);
  if ((!conditionsMet || !isVisible) && !isAccessible) return <></>;
  if (value.type === "light") return <LightElementLightPass value={value.data} />
  else if (value.type === "token") return <TokenElementLightPass value={value.data} />;

  return <ModelProvider value={valueModel}>
    {[...value.data.children].reverse().map(element => <ElementLightPass key={element.data.id} value={element} />)}
  </ModelProvider>
});
