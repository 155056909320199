import {NameField} from "../../field/name-field.tsx";
import {VisibleField} from "../../field/visible-field.tsx";
import {TransformField} from "../../field/transform-field.tsx";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {useMemo} from "react";
import {WallNode, WallNodeOperation, WallNodeSignals} from "common/legends/node/wall-node.ts";
import {ColorField} from "#lib/components/input/color-field.tsx";
import {OpacityField} from "../../field/opacity-field.tsx";
import {MutableRef} from "common/ref";
import {SelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {Panel, Section} from "#lib/components/panel-header.tsx";
import {useElementGrid} from "../../common/use-element-grid.ts";
import {GridProvider} from "../../viewport/common/context/grid-context.ts";

export function WallElementProperties({value, reference, pinned}: {
  value: MutableRef<WallNode, WallNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const {id, name, visibilityLayer, transform, opacity, color} = useMemo(() => WallNodeSignals(value), [value]);
  const elementGrid = useElementGrid(id);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <GridProvider value={elementGrid}>
      <Panel title="Wall Properties">
        <Section>
          <NameField value={name} reference={reference} pinned={pinned} />
          <VisibleField value={visibilityLayer} />
          <TransformField value={transform} />
          <ColorField label="Wall Color" value={color} />
          <OpacityField value={opacity}/>
        </Section>
      </Panel>
    </GridProvider>
  </div>
}