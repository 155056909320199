import {ImageNode} from "common/legends/index.ts";
import React, {useMemo} from "react";
import {Transform} from "common/types/index.ts";
import {ImageView} from "./image-view.tsx";
import {ElementRenderPass} from "../element-render-pass.tsx";
import {ModelProvider, useModel} from "../../viewport/common/context/pvm-context.ts";
import {OpacityProvider, useOpacity} from "../../viewport/common/context/opacity-context.ts";

export function ImageElementRenderPass({value}: {
  value: ImageNode;
}) {
  const model = useModel();
  const valueModel = useMemo(() => Transform.divide(value.transform, model), [value.transform, model]);
  const valueOpacity = value.opacity * useOpacity();

  return (<binder>
    <OpacityProvider value={valueOpacity}>
      <ModelProvider value={valueModel}>
        <ImageView
          file={value.file} normal={value.normal}
          size={value.size}
          flipHorizontal={value.flipHorizontal} flipVertical={value.flipVertical}
          origin={value.origin} pivot={value.pivot}
          opacity={valueOpacity} repeatX={value.repeatX} repeatY={value.repeatY} />
        {[...value.children].reverse().map((element) => <ElementRenderPass key={element.data.id} value={element} />)}
      </ModelProvider>
    </OpacityProvider>
  </binder>);
}