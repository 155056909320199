import {InputString} from "#lib/components/input/input-string.tsx";
import {MutableRef} from "common/ref";
import {StringOperation} from "common/types/generic/index.ts";
import {Field, FieldLabel, useUniqueID} from "#lib/components/panel-header.tsx";
import {InputGroup} from "#lib/components/index.ts";
import {twMerge} from "tailwind-merge";

export function StringField({valueRef, label, pt}: {
  label?: string,
  valueRef: MutableRef<string, StringOperation[]>,
  pt?: {
    root?: string,
    label?: string,
    group?: string,
    input?: string
  }
}) {
  const fieldID = useUniqueID("string");
  return <Field className={pt?.root}>
    {label && <FieldLabel className={pt?.label} htmlFor={fieldID}>{label}</FieldLabel>}
    <InputGroup className={twMerge("mx-2 rounded-md", pt?.group)}>
      <InputString className={pt?.input} id={fieldID} placeholder={label} value={valueRef} />
    </InputGroup>
  </Field>
}