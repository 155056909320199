import {AssetID, Node, NodeId, SceneID, TokenID} from "#common/legends/index.ts";
import {Cache} from "../cache.ts";
import {computed, Signal} from "#common/signal";
import {QLabDatabaseSignal, QLabResourceID} from "#common/qlab/index.ts";
import {Optional, TreePath, VisitResult, walkTree} from "#common/types/index.ts";
import {WeakCache} from "#common/legends/access/weak-cache.js";
import {ResourceCache} from "#common/legends/access/resource/resource-cache.js";

export type ElementPath =
  | {type: "scene-node", sceneID: SceneID, path: TreePath}
  | {type: "asset-node", assetID: AssetID, tokenID: TokenID, path: TreePath}
  ;
export const ElementPathFn = {
  equals(a: Optional<ElementPath>, b: Optional<ElementPath>) {
    if (a?.type === "scene-node" && b?.type === "scene-node") {
      return a.sceneID === b.sceneID && TreePath.equals(a.path, b.path);
    } else if (a?.type === "asset-node" && b?.type === "asset-node") {
      return a.assetID === b.assetID && a.tokenID === b.tokenID && TreePath.equals(a.path, b.path);
    } else {
      return false;
    }
  }
} as const;

export type ElementPathCache = Cache<Optional<NodeId>, Signal<Optional<ElementPath>>>;
export function ElementPathCache(databaseRef: QLabDatabaseSignal, resourceCache: ResourceCache): ElementPathCache {
  const cache: {[elementID: NodeId]: ElementPath} = {};
  const resourceElementPaths = new WeakCache((resourceID: Optional<QLabResourceID>) => computed(() => {
    if (!resourceID) return {};
    const resource = resourceCache(resourceID).value;
    if (resource?.type === "scene") {
      const elements: {[elementID: NodeId]: ElementPath} = {};
      walkTree(resource.data.children, {
        visit(value: Node, path: number[]): VisitResult | void {
          const prev: Optional<ElementPath> = cache[value.data.id];
          const next: Optional<ElementPath> = {type: "scene-node", sceneID: resourceID as SceneID, path};
          cache[value.data.id] = ElementPathFn.equals(prev, next) ? prev : next;
          elements[value.data.id] = cache[value.data.id];
        }
      });
      return elements;
    } else if (resource?.type === "asset") {
      const elements: {[elementID: NodeId]: ElementPath} = {};
      for (const token of Object.values(resource.data.tokens)) {
        walkTree(token.children, {
          visit(value: Node, path: number[]): VisitResult | void {
            const prev: Optional<ElementPath> = cache[value.data.id];
            const next: Optional<ElementPath> = {type: "asset-node", assetID: resourceID as AssetID, tokenID: token.tokenID, path};
            cache[value.data.id] = ElementPathFn.equals(prev, next) ? prev : next;
            elements[value.data.id] = cache[value.data.id];
          }
        });
      }
      return elements;
    } else {
      return {};
    }
  }));

  return (elementID: Optional<NodeId>) => computed(() => {
    if (elementID === undefined) return undefined;
    const database = databaseRef.value;
    for (const resourceID of (Object.keys(database.resources) as QLabResourceID[])) {
      const resourcePaths = resourceElementPaths.get(resourceID).value;
      if (resourcePaths[elementID] === undefined) continue;
      return resourcePaths[elementID];
    }
    return undefined;
  });
}
