import {NumberOperation, numberType, ObjectType, Type} from "../../../types/index.ts";
import {MutableRef} from "#common/ref";
import {z} from "zod";

export const AudioShapePointSource = z.object({
  radius: z.number(),
  falloff: z.number(),
  falloffStrength: z.number(),
});
export type AudioShapePointSource = z.infer<typeof AudioShapePointSource>;
export const AudioShapePointSourceOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-radius"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-falloff"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-falloff-strength"), operations: z.array(NumberOperation)})
]);
export type AudioShapePointSourceOperation = z.infer<typeof AudioShapePointSourceOperation>;

export type AudioShapePointSourceType = Type<AudioShapePointSource, AudioShapePointSourceOperation>;

export const audioShapePointSourceType: AudioShapePointSourceType = new ObjectType({
  radius: numberType,
  falloff: numberType,
  falloffStrength: numberType
});

export const AudioShapePointSourceFn = {
  expand(valueRef: MutableRef<AudioShapePointSource, AudioShapePointSourceOperation[]>) {
    return {
      radiusRef: valueRef.map<number, NumberOperation[]>(value => value.radius, (_, operations) => [{type: "update-radius", operations}]),
      falloffRef: valueRef.map<number, NumberOperation[]>(value => value.falloff, (_, operations) => [{type: "update-falloff", operations}]),
      falloffStrengthRef: valueRef.map<number, NumberOperation[]>(value => value.falloffStrength, (_, operations) => [{type: "update-falloff-strength", operations}])
    }
  }
} as const;

