import React, {Suspense, useEffect, useMemo} from "react";
import {QLabStoreID} from "common/qlab/index.ts";
import {EditorGameContainer} from "./editor-game-container/editor-game-container.tsx";
import {EditorController} from "./editor-controller.ts";
import {EditorProvider} from "./editor-context.ts";
import {GameID} from "common/legends/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {useGame} from "../../../../routes/game";
import {Beyond20Integration} from "../../../../routes/game/view/main/beyond-20-integration.tsx";
import {SceneViewport} from "../../../viewport/scene";
import {AssetViewport} from "../../../viewport/character";
import {Transform, ValueFn} from "common/types/generic/index.ts";
import {ToolFn} from "../../../common/tool/tool.ts";

export type EditorViewProps = {
  storeId: QLabStoreID;
};

export function EditorView({storeId}: EditorViewProps) {
  const editor = useMemo(() => new EditorController(storeId), [storeId]);
  const type = useRefValue(editor.state)?.type;
  const gameRef = useGame();
  useEffect(() => {
    if (type === undefined) {
      const defaultSceneID = gameRef.value.defaultSceneID;
      if (defaultSceneID) {
        editor.state.apply(state => ValueFn.set(state, {
          type: "scene",
          data: {
            sceneReference: {gameID: storeId as GameID, sceneID: defaultSceneID},
            toolMode: state?.data.toolMode || ToolFn.DEFAULT,
            selectedNodeIds: [],
            vision: state?.data.vision || {senseID: undefined, override: false},
            view: Transform.DEFAULT
          }
        }));
      }
    }
  }, [type, gameRef, editor])

  return (
    <EditorProvider value={editor}>
      <Beyond20Integration/>
      <EditorGameContainer>
        <Suspense fallback={<></>}>
          {type === "scene" && <SceneViewport />}
          {type === "asset" && <AssetViewport />}
        </Suspense>
      </EditorGameContainer>
    </EditorProvider>
  );
}
