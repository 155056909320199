import {NameField} from "../../field/name-field.tsx";
import {VisibleField} from "../../field/visible-field.tsx";
import {ConstantOperation, ListOperation, ValueFn, ValueOperation} from "common/types/index.ts";
import React, {useEffect, useMemo} from "react";
import {MutableRef} from "common/ref";
import {TransformField} from "../../field/transform-field.tsx";
import {SelectionField} from "../../field/selection-field.tsx";
import {OriginField} from "../../field/origin-field.tsx";
import {PivotField} from "../../field/pivot-field.tsx";
import {SelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {Field, FieldLabel, Panel, Section, SectionBody} from "#lib/components/panel-header.tsx";
import {faCircleDot, faGlobe, faVectorPolygon} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import {RangeField} from "../../field/generic/range-field.tsx";
import {Button, ButtonBar, ExpandableLabel, IconButton, useToggle} from "#lib/components/index.ts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTypedRef} from "../../common/use-typed-ref.ts";
import {useUnwrapValueRef} from "../../common/use-unwrap-value-ref.ts";
import {GridWidthNumberField} from "../../field/generic/grid-width-number-field.tsx";
import {NumberField} from "../../field/generic/number-field.tsx";
import {InputList, InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {twMerge} from "tailwind-merge";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {AudioShape, AudioShapeOperation, AudioShapes} from "common/legends/node/audio/audio-shape.ts";
import {AudioShapePointSource, AudioShapePointSourceFn, AudioShapePointSourceOperation} from "common/legends/node/audio/audio-shape-point-source.ts";
import {FaTimes} from "react-icons/fa";
import {Track, TrackFn, TrackOperation} from "common/legends/node/audio/track.ts";
import {AudioElement, AudioElementFn, AudioElementOperation} from "common/legends/node/audio/audio-element.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {AudioFileField} from "../../field/generic/audio-file.tsx";
import {StringField} from "#lib/components/input/string-field.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {BooleanField} from "../../field/generic/boolean-field.tsx";
import {useElementGrid} from "../../common/use-element-grid.ts";
import {GridProvider} from "../../viewport/common/context/grid-context.ts";
import {AudioShapeFreeform, AudioShapeFreeformFn, AudioShapeFreeformOperation} from "common/legends/node/audio/audio-shape-freeform.ts";

function AudioShapePointSourceField({valueRef}: {valueRef: MutableRef<AudioShapePointSource, AudioShapePointSourceOperation[]>}) {
  const {radiusRef, falloffRef, falloffStrengthRef} = useMemo(() => AudioShapePointSourceFn.expand(valueRef), [valueRef]);
  return <SectionBody className="pt-2">
    <GridWidthNumberField label="Radius" valueRef={radiusRef} />
    <GridWidthNumberField label="Falloff Radius" valueRef={falloffRef} />
    <NumberField label="Falloff Strength" valueRef={falloffStrengthRef} min={0} max={5} />
  </SectionBody>
}

function AudioShapeFreeformField({valueRef}: {valueRef: MutableRef<AudioShapeFreeform, AudioShapeFreeformOperation[]>}) {
  const {falloffRef, falloffStrengthRef} = useMemo(() => AudioShapeFreeformFn.expand(valueRef), [valueRef]);
  return <SectionBody className="pt-2">
    <GridWidthNumberField label="Falloff Radius" valueRef={falloffRef} />
    <NumberField label="Falloff Strength" valueRef={falloffStrengthRef} min={0} max={5} />
  </SectionBody>
}

function AudioShapeField({valueRef}: {valueRef: MutableRef<AudioShape, ValueOperation<AudioShape, AudioShapeOperation>[]>}) {
  const setGlobal = () => {
    valueRef.apply(prev => ValueFn.set(prev, {type: "global", data: {}}));
  }
  const setSpotlight = () => {
    valueRef.apply(prev => ValueFn.set(prev, {type: "point-source", data: {
        radius: 64,
        falloff: 32,
        falloffStrength: 2.71
      }}));
  }
  const setFreeform = () => {
    valueRef.apply(prev => ValueFn.set(prev, {type: "freeform", data: {
      areas: [],
      falloff: 32,
      falloffStrength: 2.71
    }}));
  }

  const [type, shapeRef] = useTypedRef<AudioShapes>(useUnwrapValueRef(valueRef));

  return (<Field>
    <FieldLabel>Shape</FieldLabel>
    <div className="mx-2 rounded-md overflow-hidden bg-zinc-800/50">
      <ButtonBar>
        <Button variant={type === "global" ? "primary" : undefined} className="flex-1" onClick={type !== "global" ? setGlobal : undefined}>
          <FontAwesomeIcon icon={faGlobe}/> Global
        </Button>
        <Button variant={type === "point-source" ? "primary" : undefined} className="flex-1" onClick={type !== "point-source" ? setSpotlight : undefined}>
          <FontAwesomeIcon icon={faCircleDot}/> Point
        </Button>
        <Button variant={type === "freeform" ? "primary" : undefined} className="flex-1" onClick={type !== "freeform" ? setFreeform : undefined}>
          <FontAwesomeIcon icon={faVectorPolygon}/> Freeform
        </Button>
      </ButtonBar>
      {type === "global" && <></>}
      {type === "point-source" && <AudioShapePointSourceField valueRef={shapeRef}/>}
      {type === "freeform" && <AudioShapeFreeformField valueRef={shapeRef}/>}
    </div>
  </Field>);
}

export function TrackListItem({item, remove}: InputListItemProps<Track, TrackOperation>) {
  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/audio", item, remove);
  const {nameRef, fileRef, enableRef} = useMemo(() => TrackFn.expand(item), [item]);
  const [expanded, toggleExpanded] = useToggle(false);
  const enabled = useRefValue(enableRef);
  const file = useRefValue(fileRef);

  return (<div role="list-item" ref={dragRefPreview} className={twMerge("flex flex-col bg-zinc-800/50")}>
    <div className="flex flex-row gap-0.5">
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <ExpandableLabel expanded={expanded || file === undefined} toggleExpand={file === undefined ? undefined : toggleExpanded}>
      <span className={twMerge(
        "flex-1 bg-zinc-800/50 text-white content-center px-4 h-12",
        !enabled && "text-white/50"
      )} onClick={file === undefined ? undefined : toggleExpanded}>
        {useRefValue(nameRef)}
      </span>
      </ExpandableLabel>
      <IconButton className="mx-0" variant="destructive" onClick={remove}><FaTimes /></IconButton>
    </div>
    {(expanded || file === undefined) && <Fieldset>
      <BooleanField label="Enabled" valueRef={enableRef} />
      <StringField label="Name" valueRef={nameRef} />
      <AudioFileField valueRef={fileRef} />
    </Fieldset>}
  </div>);
}

export function AudioElementProperties({valueRef, reference, pinned}: {
  valueRef: MutableRef<AudioElement, AudioElementOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const {idRef, name, originRef, pivotRef, visibilityLayerRef, selectionMaskRef, transformRef, shapeRef, playlistRef, volumeRef} = useMemo(() => AudioElementFn.expand(valueRef), [valueRef]);
  const elementGrid = useElementGrid(idRef);

  const addTrack = () => {
    playlistRef.apply(prev => ListOperation.insert(prev.length, {file: undefined, enable: true, name: ""}));
  };

  // Always have 1 audio file input available to upload a new file
  useEffect(() => {
    playlistRef.observe({
      next: (value) => {
        if (value.every(audio => audio.file !== undefined)) addTrack();
      },
      error(error) {console.error(error)},
      complete(){}
    });
  }, [playlistRef])

  return <div className="tab-content flex flex-col py-2 gap-1">
    <GridProvider value={elementGrid}>
      <Panel title="Audio Properties">
        <Section>
          <NameField value={name} reference={reference} pinned={pinned} />
          <VisibleField value={visibilityLayerRef} />
          <SelectionField value={selectionMaskRef} />
          <TransformField value={transformRef} />
          <OriginField valueRef={originRef} />
          <PivotField valueRef={pivotRef} />
          <RangeField label="Volume" valueRef={volumeRef} min={0} max={1} step={0.05} />
          <AudioShapeField valueRef={shapeRef} />
        </Section>
        <Section title="Playlist">
          <div className="mx-2 rounded-md overflow-hidden flex flex-col gap-0.5">
            <InputList
              accept="legends/audio"
              emptyMessage={<span className="text-white/50 text-xs text-center content-center flex-1 italic h-12">There are currently no audio files in the playlist.</span>}
              items={playlistRef}
              itemKey={(_, index) => index}
              copy={item => item}
              ListItem={TrackListItem} />
          </div>
        </Section>
      </Panel>
    </GridProvider>
  </div>
}
