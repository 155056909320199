import {PointSignals, Transform, TransformOperation, TransformSignals} from "common/types/index.ts";
import React, {useMemo} from "react";
import {MutableRef} from "common/ref";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";
import {GridWidthNumberField} from "./generic/grid-width-number-field.tsx";
import {GridHeightNumberField} from "./generic/grid-height-number-field.tsx";
import {NumberField} from "./generic/number-field.tsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngle} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";

export type TransformFieldProps = {
  value: MutableRef<Transform, TransformOperation[]>;
};

export function TransformField({value}: TransformFieldProps) {
  const {position, scale, rotation} = useMemo(() => TransformSignals(value), [value]);
  const {x, y} = useMemo(() => PointSignals(position), [position]);
  return (<>
    <Field>
      <FieldLabel>Position</FieldLabel>
      <div className="flex flex-row gap-0.5 mx-2 rounded-md overflow-hidden">
        <GridWidthNumberField icon="X" pt={{group: "mx-0"}} valueRef={x} />
        <GridHeightNumberField icon="Y" pt={{group: "mx-0"}} valueRef={y} />
      </div>
    </Field>
    <NumberField icon={<FontAwesomeIcon icon={faAngle} /> } label="Rotation" valueRef={rotation} min={0} max={360} />
    <NumberField label="Scale" valueRef={scale}  />
  </>);
}
