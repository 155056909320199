import {MouseEvent, PropsWithChildren, useRef} from "react";
import {Point} from "common/types/generic/index.ts";
import {getScreenPosition} from "./tool/use-get-screen-pos.ts";
import {useRenderingContext} from "#lib/gl-react/index.ts";
import {Vector2} from "common/math/vector/vector2.ts";

export type MouseInteractionProps = {
  onMouseDown?: (event: MouseEvent<HTMLCanvasElement>, screenPos: Point) => void;
  onMouseUp?: (event: MouseEvent<HTMLCanvasElement>, screenPos: Point) => void;
  onMouseMove?: (event: MouseEvent<HTMLCanvasElement>, screenPos: Point) => void;
  onClick?: (event: MouseEvent<HTMLCanvasElement>, screenPos: Point) => void;
  draggable?: boolean;
  onDragStart?: (event: MouseEvent<HTMLCanvasElement>, startScreenPos: Point) => void;
  onDragMove?: (event: MouseEvent<HTMLCanvasElement>, startScreenPos: Point, currentScreenPos: Point) => void;
  onDragEnd?: (event: MouseEvent<HTMLCanvasElement>, startScreenPos: Point, endScreenPos: Point) => void;
};

const DRAG_THRESHOLD = 8;

export function MouseInteraction({isInBounds, transform = (v) => v, children, ...props}: {
  isInBounds?: (screenPosition: Point) => boolean;
  transform?: (screenPosition: Point) => Point;
} & PropsWithChildren<MouseInteractionProps>) {
  const canvas = useRenderingContext().canvas! as HTMLCanvasElement;
  const startPos = useRef<Point | undefined>(undefined);
  const isDragging = useRef<boolean>(false);
  const mouseButtonRef = useRef<number>(-1);

  return <interaction
    onMouseDown={(event) => {
      const screenPos = getScreenPosition(canvas, [event.clientX, event.clientY]);
      const localPos = transform(screenPos);
      if (!isInBounds || isInBounds(localPos)) {
        isDragging.current = false;
        startPos.current = screenPos;
        mouseButtonRef.current = event.button;

        props.onMouseDown?.(event, localPos);
        if (event.isDefaultPrevented()) return true;
        if (event.isPropagationStopped()) return false;
      }
      return undefined;
    }}
    onMouseMove={(event) => {
      const screenPos = getScreenPosition(canvas, [event.clientX, event.clientY]);
      const localPos = transform(screenPos);
      if (!isInBounds || isInBounds(localPos)) {
        props.onMouseMove?.(event, localPos);
        if (event.isDefaultPrevented()) return true;
        if (event.isPropagationStopped()) return false;
      }

      if (startPos.current === undefined) return;

      if (!isDragging.current) {
        if (props.draggable && mouseButtonRef.current === 0) {
          if (Vector2.distance(startPos.current, screenPos) > DRAG_THRESHOLD) {
            props.onDragStart?.(event, screenPos);
            if (event.isDefaultPrevented()) return true;
            if (event.isPropagationStopped()) return false;
            isDragging.current = true;
          }
        }
      } else {
        props.onDragMove?.(event, transform(startPos.current), localPos);
        if (event.isDefaultPrevented()) return true;
        if (event.isPropagationStopped()) return false;
      }
      return undefined;
    }}
    onMouseUp={(event) => {
      try {
        if (!startPos.current) return;
        const screenPos = getScreenPosition(canvas, [event.clientX, event.clientY]);
        const localPos = transform(screenPos);
        const inBounds = !isInBounds || isInBounds(localPos);
        if (inBounds) {
          props.onMouseUp?.(event, localPos);
          if (event.isDefaultPrevented()) return true;
          if (event.isPropagationStopped()) return false;
        }

        if (isDragging.current) {
          props.onDragEnd?.(event, transform(startPos.current), localPos);
          if (event.isDefaultPrevented()) return true;
          if (event.isPropagationStopped()) return false;
        } else if (inBounds) {
          props.onClick?.(event, localPos);
          if (event.isDefaultPrevented()) return true;
          if (event.isPropagationStopped()) return false;
        }
        return undefined;
      } finally {
        isDragging.current = false;
        startPos.current = undefined;
        mouseButtonRef.current = -1;
      }
    }}
  >
    {children}
  </interaction>
}
