import {MutableSignal} from "common/signal";
import {BooleanFn, BooleanOperation, FileReference, Optional, Point, Size, Transform} from "common/types/generic/index.ts";
import {useSignalValue} from "#lib/signal/index.ts";
import {ModelProvider, usePVM} from "../../viewport/common/context/pvm-context.ts";
import React from "react";
import {useImageTexture} from "../../viewport/common/context/use-image-texture.ts";
import {ImageShader} from "../../viewport/common/shader/image-shader.tsx";
import {Vector2} from "common/math/vector/vector2.ts";
import reflectHorizontal from "./reflect-horizontal.svg";
import reflectVertical from "./reflect-vertical.svg";

function FlipHorizontalButton({origin, onClick}: {origin: Point, onClick: () => void}) {
  const [isLoaded, texture] = useImageTexture(reflectHorizontal as FileReference);
  return <>
    {isLoaded && <ImageShader
        albedoTexture={texture}
        size={[20, 20]}
        origin={Vector2.add(origin, [10, 10])}
        opacity={0.9}
        onMouseDown={(event) => {
          onClick();
          event.preventDefault();
          event.stopPropagation();
        }}
    />}
  </>
}

function FlipVerticalButton({origin, onClick}: {origin: Point, onClick: () => void}) {
  const [isLoaded, texture] = useImageTexture(reflectVertical as FileReference);
  return <>
    {isLoaded && <ImageShader
        albedoTexture={texture}
        size={[20, 20]}
        origin={Vector2.add(origin, [10, 10])}
        opacity={0.9}
        onMouseDown={(event) => {
          onClick();
          event.preventDefault();
          event.stopPropagation();
        }}
    />}
  </>
}

type FlipEditorValue = {
  origin: Point;
  pivot: Point;
  transform: Transform;
  size: Size;
  flipHorizontal: boolean;
  flipVertical: boolean;
}
type FlipEditorOperation =
  | {type: "update-flip-horizontal", operations: BooleanOperation[]}
  | {type: "update-flip-vertical", operations: BooleanOperation[]}
  ;

export function FlipEditor({valueRef, speculativeValueRef}: {
  valueRef: MutableSignal<Optional<FlipEditorValue>, FlipEditorOperation[]>,
  speculativeValueRef: MutableSignal<Optional<FlipEditorValue>, FlipEditorOperation[]>
}) {
  const {model} = usePVM();
  const speculativeValue = useSignalValue(speculativeValueRef);

  if (speculativeValue === undefined) return <></>;
  const speculativeValueModel = Transform.divide(speculativeValue.transform, model);

  return <ModelProvider value={speculativeValueModel}>
    <FlipHorizontalButton
      origin={[-speculativeValue.pivot[0] + speculativeValue.origin[0] + 48, -speculativeValue.size[1] + speculativeValue.origin[1] - 32]}
      onClick={() => {
        valueRef.apply(prev => [{type: "update-flip-horizontal", operations: BooleanFn.set(prev!.flipHorizontal, !prev!.flipHorizontal)}])
      }}
    />
    <FlipVerticalButton
      origin={[-speculativeValue.pivot[0] + speculativeValue.origin[0] - 48, -speculativeValue.size[1] + speculativeValue.origin[1] - 32]}
      onClick={() => {
        valueRef.apply(prev => [{type: "update-flip-vertical", operations: BooleanFn.set(prev!.flipVertical, !prev!.flipVertical)}])
      }}
    />
  </ModelProvider>
}