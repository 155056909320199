import React, {useMemo} from "react";
import {Color, ListOperation, Point, Transform, ValueFn} from "common/types/index.ts";
import {ElementHUDPass} from "../element-h-u-d-pass.tsx";
import {SelectionIndicator} from "../../viewport/common/selection-indicator/selection-indicator.tsx";
import {ModelProvider, useModel} from "../../viewport/common/context/pvm-context.ts";
import {useIsActiveSelection} from "../../viewport/common/context/use-is-active-selection.ts";
import {AudioPointSourceShader} from "../../viewport/common/shader/audio/audio-point-source-shader.tsx";
import {AudioElement} from "common/legends/node/audio/audio-element.ts";
import {AudioShapeFn} from "common/legends/node/audio/audio-shape.ts";
import {useAccess} from "../../../routes/game/model/store-context.tsx";
import {computed} from "common/signal";
import {NodeId} from "common/legends/node/index.ts";
import {Spline, SplineOperation} from "common/types/generic/spline/index.ts";
import {AudioShapeFreeform} from "common/legends/node/audio/audio-shape-freeform.ts";
import {AreasEditor} from "../area/areas-editor.tsx";
import {AudioFreeformAreaShader} from "../../viewport/common/shader/audio/audio-freeform-shader.tsx";

function AudioElementFreeformHUDPass({elementID, value, origin}: {
  elementID: NodeId,
  origin: Point,
  value: AudioShapeFreeform
}) {
  const access = useAccess();
  const elementRef = useMemo(() => access.element(elementID), [access, elementID])
  const areasRef = useMemo(() => computed(
    () => {
      const element = elementRef.value;
      if (element?.type !== "audio" || element?.data.shape?.type !== "freeform") return [];
      return element.data.shape.data.areas;
    },
    (operations: ListOperation<Spline, SplineOperation>[]) => {
      elementRef.apply(prev => {
        if (prev?.type !== "audio" || prev?.data.shape?.type !== "freeform") return [];
        return [{type: "audio", operations: [{type: "update-shape", operations: ValueFn.apply([{type: "freeform", operations: [{
          type: "update-areas", operations
        }]}])}]}];
      });
    }
  ), [elementRef])

  return (<>
    <AreasEditor origin={origin} color={Color.WHITE75} valueRef={areasRef} />
    <AudioFreeformAreaShader areas={value.areas} color={Color.WHITE75} origin={origin} falloff={value.falloff} falloffStrength={value.falloffStrength} />
  </>);
}



export function AudioElementHUDPass({value: {id, children, transform, shape, origin}}: {
  value: AudioElement;
}) {
  const model = useModel();
  const valueModel = Transform.divide(transform, model);
  const isSelected = useIsActiveSelection(id);

  const shapeOrigin = AudioShapeFn.getAudioOrigin(shape, origin);
  const shapeSize = AudioShapeFn.getAudioSize(shape);

  return (<binder>
    <ModelProvider value={valueModel}>
      {[...children].reverse().map((element) => <ElementHUDPass key={element.data.id} value={element} />)}
      {isSelected && <SelectionIndicator origin={shapeOrigin} size={shapeSize} color={Color.WHITE} />}
      {isSelected && shape?.type === "point-source" && <AudioPointSourceShader value={shape.data} origin={origin} />}
      {isSelected && shape?.type === "freeform" && <AudioElementFreeformHUDPass elementID={id} origin={origin} value={shape.data} />}
    </ModelProvider>
  </binder>);
}