import {applyAll, Optional, Type, ValueOperation} from "#common/types/index.ts";
import {StoreOperation, storeType, StoreValue} from "#common/qlab/store/index.ts";
import {QLabResourceID, ResourceOperation, resourceType, ResourceValue} from "#common/qlab/resource/index.ts";
import {MessageOperation, messageType, MessageValue, QLabMessageID} from "#common/qlab/message/index.ts";
import {ConnectionID} from "#common/qlab/api/connection-id.ts";
import {UserID} from "#common/legends/index.ts";
import {ValidationError} from "#common/types/type/validation/validation.ts";
import {MutableSignal} from "#common/signal";

export type QLabDatabase = {
  connected: boolean;
  store: Optional<StoreValue>;
  resources: {[resourceId: QLabResourceID]: Optional<ResourceValue>};
  messages: {[messageId: QLabMessageID]: Optional<MessageValue>};
  connections: {[connectionId: ConnectionID]: UserID};
  timeOffset: number;
};

export type QLabDatabaseOperation =
  | {type: "store", operations: ValueOperation<Optional<StoreValue>, StoreOperation>[]}
  | {type: "resource", resourceID: QLabResourceID, operations: ValueOperation<Optional<ResourceValue>, ResourceOperation>[]}
  | {type: "message", messageID: QLabMessageID, operations: ValueOperation<Optional<MessageValue>, MessageOperation>[]}
  ;

export const qlabDatabaseType: Type<QLabDatabase, QLabDatabaseOperation> = {
  apply: (value: QLabDatabase, operation: QLabDatabaseOperation): QLabDatabase => {
    switch (operation.type) {
      case "store": return ({
        ...value,
        store: applyAll(storeType, value.store, operation.operations)
      });
      case "resource": return ({
        ...value,
        resources: {
          ...value.resources,
          [operation.resourceID]: applyAll(resourceType, value.resources[operation.resourceID], operation.operations)
        }
      });
      case "message": return ({
        ...value,
        messages: {
          ...value.messages,
          [operation.messageID]: applyAll(messageType, value.messages[operation.messageID], operation.operations)
        }
      });
    }
  },
  migrateValue(_: any): QLabDatabase {
    throw new Error("Unsupported Operation")
  },
  migrateOperation(_: any): QLabDatabaseOperation[] {
    throw new Error("Unsupported Operation")
  },
  validate(_: any): ValidationError[] {
    throw new Error("Unsupported Operation")
  },
  invert(_: QLabDatabaseOperation): QLabDatabaseOperation[] {
    throw new Error("Unsupported Operation")
  },
  transform(_left: QLabDatabaseOperation, _top: QLabDatabaseOperation, _tie: boolean): QLabDatabaseOperation[] {
    throw new Error("Unsupported Operation")
  }
};
export type QLabDatabaseSignal = MutableSignal<QLabDatabase, QLabDatabaseOperation[]>;
