import {Color, NumberFn, Optional, Point, PointFn, Size, Transform, TransformFn, TransformOperation} from "common/types/generic/index.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {MutableSignal} from "common/signal";
import {ModelProvider, usePVM} from "../../viewport/common/context/pvm-context.ts";
import {useSignalValue} from "#lib/signal/index.ts";
import React, {useMemo} from "react";
import {useGrid} from "../../viewport/common/context/grid-context.ts";
import {MouseInteraction} from "../../viewport/mouse-interaction.tsx";
import {getWorldPositionFromScreenPosition} from "../../viewport/tool/use-get-world-pos.ts";
import {CircleShader} from "../../viewport/common/shader/circle-shader.tsx";
import {Grid} from "common/legends/scene/index.ts";

export type RotationEditorValue = {
  origin: Point;
  pivot: Point;
  size: Size;
  transform: Transform;
};
export type RotationEditorOperation =
  | {type: "update-transform", operations: TransformOperation[]};

const ORIGIN_SIZES: Point[] = [
  [32,32],
  [24,24],
  [20,20]
];
const PIVOT_SIZES: Point[] = [
  [4,4],
  [2,2]
];

function getRotateOperations(prev: RotationEditorValue, rotationAmount: number, grid: Grid, snapToGrid: boolean): RotationEditorOperation[] {
  const rotationSnap = (grid.shape === "hexagon-vertical" || grid.shape === "hexagon-horizontal") ? 180 / 12 : 180 / 8 ;
  const newRotation = snapToGrid ? Math.round((prev.transform.rotation + rotationAmount) / rotationSnap) * rotationSnap : prev.transform.rotation + rotationAmount;

  const prevPivot = Vector2.rotate(
    Vector2.multiply(Vector2.subtract(prev.pivot, prev.origin), prev.transform.scale),
    prev.transform.rotation * Math.PI / 180
  );
  const nextPivot = Vector2.rotate(
    Vector2.multiply(Vector2.subtract(prev.pivot, prev.origin), prev.transform.scale),
    newRotation * Math.PI / 180
  );
  return [{type: "update-transform", operations: [
    ...TransformFn.updatePosition(PointFn.set(prev.transform.position, Vector2.add(
      prev.transform.position,
      Vector2.subtract(prevPivot, nextPivot)
    ))),
    ...TransformFn.updateRotation(NumberFn.set(prev.transform.rotation, (newRotation % 360 + 360) % 360))
  ]}];
}

export function RotationEditor({valueRef, speculativeValueRef}: {
  valueRef: MutableSignal<Optional<RotationEditorValue>, RotationEditorOperation[]>,
  speculativeValueRef: MutableSignal<Optional<RotationEditorValue>, RotationEditorOperation[]>,
}) {
  const {view, model} = usePVM();
  const value = useSignalValue(valueRef);
  const speculativeValue = useSignalValue(speculativeValueRef);
  const grid = useGrid();

  const originOrigins = useMemo((): Point[] => {
    if (speculativeValue === undefined) return [[0, 0], [0, 0], [0, 0]];
    return [
      [16 - speculativeValue.pivot[0] + speculativeValue.origin[0], 16 - speculativeValue.size[1] + speculativeValue.origin[1] - 32],
      [12 - speculativeValue.pivot[0] + speculativeValue.origin[0], 12 - speculativeValue.size[1] + speculativeValue.origin[1] - 32],
      [10 - speculativeValue.pivot[0] + speculativeValue.origin[0], 16 - speculativeValue.size[1] + speculativeValue.origin[1] - 32]
    ];
  }, [speculativeValue?.pivot, speculativeValue?.origin, speculativeValue?.size]);

  const pivotOrigins = useMemo((): Point[] => {
    if (speculativeValue === undefined) return [[0, 0], [0, 0]];
    return [
      [speculativeValue.origin[0] - speculativeValue.pivot[0]+2, speculativeValue.origin[1] - speculativeValue.pivot[1]+2],
      [speculativeValue.origin[0] - speculativeValue.pivot[0]+1, speculativeValue.origin[1] - speculativeValue.pivot[1]+1]
    ];
  }, [speculativeValue?.origin, speculativeValue?.pivot]);


  if (speculativeValue === undefined || value === undefined) return <></>;

  const speculativeValueModel = Transform.divide(speculativeValue.transform, model);
  return <ModelProvider value={speculativeValueModel}>
    <MouseInteraction
      transform={screenPos => Vector2.divideTransform(getWorldPositionFromScreenPosition(view, screenPos), speculativeValueModel)}
      isInBounds={localPos => value ?
        Vector2.distance(
          localPos,
          Vector2.add(
            [0, value.size[1] + 32],
            Vector2.subtract([value.pivot[0], 0], value.origin)
          )
        ) <= 16 : false
      }
      draggable
      onDragMove={(event, startPos, endPos) => {
        if (!value) return;
        const relative = Vector2.subtract(value.pivot, value.origin);
        const startPivot = Vector2.subtract(startPos, relative);
        const startAngle = Math.atan2(startPivot[1], startPivot[0]);
        const endPivot = Vector2.subtract(endPos, relative);
        const endAngle = Math.atan2(endPivot[1], endPivot[0]);
        const rotationAmount = (endAngle - startAngle) / Math.PI * 180;
        speculativeValueRef.apply(_ => getRotateOperations(value!, rotationAmount, grid, !event.shiftKey));
      }}
      onDragEnd={(event, startPos, endPos) => {
        if (!value) return;
        const relative = Vector2.subtract(value.pivot, value.origin);
        const startPivot = Vector2.subtract(startPos, relative);
        const startAngle = Math.atan2(startPivot[1], startPivot[0]);
        const endPivot = Vector2.subtract(endPos, relative);
        const endAngle = Math.atan2(endPivot[1], endPivot[0]);
        const rotationAmount = (endAngle - startAngle) / Math.PI * 180;
        valueRef.apply(prev => getRotateOperations(prev!, rotationAmount, grid, !event.shiftKey));
        speculativeValueRef.apply(_ => []);
      }}
      onMouseDown={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }} />

    <CircleShader origin={originOrigins[0]} size={ORIGIN_SIZES[0]} color={Color.BLACK} />
    <CircleShader origin={originOrigins[1]} size={ORIGIN_SIZES[1]} color={Color.WHITE} />
    <CircleShader origin={originOrigins[2]} size={ORIGIN_SIZES[2]} color={Color.BLACK} />

    <CircleShader origin={pivotOrigins[0]} size={PIVOT_SIZES[0]} color={Color.BLACK75} />
    <CircleShader origin={pivotOrigins[1]} size={PIVOT_SIZES[1]} color={Color.GREEN} />
  </ModelProvider>
}
