import {MutableRef} from "common/ref";
import {LightShape, LightShapeOperation, LightShapeTypes} from "common/legends/node/light/light-shape.ts";
import {Optional, ValueOperation} from "common/types/generic/index.ts";
import {useSuspendPresent} from "../../../../common/use-optional-signal.ts";
import {useTypedRef} from "../../../../common/use-typed-ref.ts";
import {useUnwrapValueRef} from "../../../../common/use-unwrap-value-ref.ts";
import {LightShapeGlobalFields} from "./light-shape-global-fields.tsx";
import {LightShapeSpotlightFields} from "./light-shape-spotlight-fields.tsx";
import {LightShapeFreeformFields} from "./light-shape-freeform-fields.tsx";
import {LightShapeSpriteFields} from "./light-shape-sprite-fields.tsx";

export function InputLightShape({valueRef}: {
  valueRef: MutableRef<Optional<LightShape>, ValueOperation<Optional<LightShape>, LightShapeOperation>[]>
}) {
  const [type, typedRef] = useTypedRef<LightShapeTypes>(useUnwrapValueRef(useSuspendPresent(valueRef)));
  if (type === "global") return <LightShapeGlobalFields valueRef={typedRef} />;
  if (type === "spotlight") return <LightShapeSpotlightFields valueRef={typedRef} />;
  if (type === "freeform") return <LightShapeFreeformFields valueRef={typedRef} />;
  if (type === "sprite") return <LightShapeSpriteFields valueRef={typedRef} />;
  else return <></>
}