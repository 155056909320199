import {FileReference, Point} from "common/types/index.ts";
import React from "react";
import {useImageTexture} from "../../viewport/common/context/use-image-texture.ts";
import {ImageShader} from "../../viewport/common/shader/image-shader.tsx";
import {Nullable} from "common/types/generic/nullable/index.ts";

export function ImageView({file, normal, ...props}: {
  file: FileReference;
  normal?: FileReference;
  size: Point;
  flipHorizontal?: boolean;
  flipVertical?: boolean;
  origin: Point;
  pivot: Point;
  opacity: number;
  repeatX: Nullable<number>;
  repeatY: Nullable<number>;
}) {
  const [isLoaded, imageTexture] = useImageTexture(file);
  const [isNormalLoaded, normalTexture] = useImageTexture(normal);
  if (!isLoaded || (normal && !isNormalLoaded)) return <></>;
  return <ImageShader {...props} albedoTexture={imageTexture} normalTexture={normal ? normalTexture : undefined} />;
}

