import {MutableRef} from "common/ref";
import {LightShapeSpotlight, LightShapeSpotlightOperation, LightShapeSpotlightRef} from "common/legends/node/light/light-shape-spotlight.ts";
import {useMemo} from "react";
import {ColorField} from "#lib/components/input/color-field.tsx";
import {SectionBody} from "#lib/components/panel-header.tsx";
import {NumberField} from "../../../../field/generic/number-field.tsx";
import {GridWidthNumberField} from "../../../../field/generic/grid-width-number-field.tsx";

export function LightShapeSpotlightFields({valueRef}: {
  valueRef: MutableRef<LightShapeSpotlight, LightShapeSpotlightOperation[]>
}) {
  const {colorRef, intensityRef, angleRef, falloffAngleRef, falloffRef, falloffStrengthRef, radiusRef} = useMemo(() => LightShapeSpotlightRef(valueRef), [valueRef]);
  return <SectionBody className="pt-2">
    <ColorField label="Color" value={colorRef}/>
    <NumberField label="Intensity" valueRef={intensityRef} min={0} max={1} />
    <NumberField label="Falloff Strength" valueRef={falloffStrengthRef} min={0} max={5} />
    <GridWidthNumberField label="Radius" valueRef={radiusRef} />
    <GridWidthNumberField label="Falloff Radius" valueRef={falloffRef} />
    <NumberField label="Angle" valueRef={angleRef} min={-360} max={360} />
    <NumberField label="Falloff Angle" valueRef={falloffAngleRef} min={-360} max={360} />
  </SectionBody>
}