import {UserID} from "common/legends/index.ts";
import {FileReference} from "common/types/index.ts";
import {useDatabase} from "./model/store-context.tsx";
import {useComputedValue} from "#lib/signal/index.ts";
import {useControllerElementsSceneIDs} from "../../legends/viewport/token/use-controller-node-ids.ts";
import {useGetNodeIcon} from "../../legends/common/use-get-node-icon.ts";

export function usePlayerIcon(userID: UserID): FileReference | undefined {
  const database = useDatabase();
  const characterNodeIDs = useComputedValue(useControllerElementsSceneIDs(userID), nodeScenes => Object.keys(nodeScenes));
  const getNodeIcon = useGetNodeIcon();

  return useComputedValue(database, database => {
    const game = database.store;
    if (game?.type !== "game") return undefined;
    if (game.data.players[userID] && game.data.players[userID].icon) return game.data.players[userID].icon;
    if (game.data.owner.id === userID && game.data.owner.icon) return game.data.owner.icon;

    for (const nodeID of characterNodeIDs) {
      const icon = getNodeIcon(nodeID);
      if (icon) return icon;
    }
    return undefined;
  }, [characterNodeIDs, getNodeIcon]);
}