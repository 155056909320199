import {useCallback} from "react";
import {NodeSelectionRef} from "../nav/editor/state/selection-ref.ts";
import {Grid} from "common/legends/scene/index.ts";
import {Tree, TreePath} from "common/types/generic/index.ts";
import {useAccess} from "../../../routes/game/model/store-context.tsx";
import {Node} from "common/legends/node/index.ts";

export function useGetGrid() {
  const access = useAccess();
  return useCallback((nodeRef: NodeSelectionRef): Grid => {
    const getGridByPath = (children: Node[], path: TreePath): Grid | undefined => {
      for (let i = path.length - 1; i >= 1; i --) {
        const nodePath = path.slice(0, i);
        const node = Tree.getItemByPath(children, nodePath);
        if (node.type === "token") {
          const assetToken = access.assetToken(
            node.data.tokenReference.assetID,
            node.data.tokenReference.tokenID
          ).value;
          if (assetToken === undefined) continue;
          return assetToken.grid;
        }
      }
      return undefined;
    }

    if (nodeRef.type === "element") {
      const elementPath = access.elementPath(nodeRef.elementID).value;
      if (elementPath?.type === "scene-node") {
        const scene = access.scene(elementPath.sceneID).value;
        if (scene === undefined) return Grid.DEFAULT;
        return getGridByPath(scene.children, elementPath.path) ?? scene.grid;
      } else if (elementPath?.type === "asset-node") {
        const token = access.assetToken(elementPath.assetID, elementPath.tokenID).value;
        if (token === undefined) return Grid.DEFAULT;
        return getGridByPath(token.children, elementPath.path) ?? token.grid;
      }
      return Grid.DEFAULT;
    } else {
      return Grid.DEFAULT;
    }
  }, [access]);
}