import {TextNode, TextNodeOperation, textNodeType} from "common/legends/index.ts";
import React, {useMemo} from "react";
import {Color, Optional, Transform} from "common/types/index.ts";
import {ElementHUDPass} from "../element-h-u-d-pass.tsx";
import {SelectionIndicator} from "../../viewport/common/selection-indicator/selection-indicator.tsx";
import {getTextHeight, getTextOrigin, getTextWidth} from "#lib/gl-react/component/font-helper.ts";
import {ModelProvider, useModel} from "../../viewport/common/context/pvm-context.ts";
import {useIsActiveSelection} from "../../viewport/common/context/use-is-active-selection.ts";
import {useIsTokenController} from "../../viewport/common/context/use-is-token-controller.ts";
import {RotationEditor, RotationEditorOperation, RotationEditorValue} from "../common/rotation-editor.tsx";
import {useTypedElementById} from "../common/use-typed-element-by-id.ts";
import {useSpeculativeValue} from "../common/use-speculative-value.ts";
import {computed, MutableSignal} from "common/signal";
import {useSignalValue} from "#lib/signal/index.ts";
import {Vector2} from "common/math/vector/vector2.ts";

function useTextNodeRotationEditorRef(valueRef: MutableSignal<Optional<TextNode>, TextNodeOperation[]>): MutableSignal<Optional<RotationEditorValue>, RotationEditorOperation[]> {
  return useMemo(() => computed(
      () => {
        const value = valueRef.value;
        if (!value) return undefined;
        return ({
          origin: value.origin,
          pivot: value.pivot,
          size: [
            getTextWidth(value.text, value.size),
            getTextHeight(value.text, value.size)
          ],
          transform: value.transform,
        })
      },
      (operations: RotationEditorOperation[]) => valueRef.apply(_ => operations)
  ), [valueRef])
}

export function TextElementHUDPass({value: {id, children, transform, text, size, hTextAlign, vTextAlign}}: {
  value: TextNode;
}) {
  const model = useModel();
  const valueModel = Transform.divide(transform, model);
  const isSelected = useIsActiveSelection(id);
  const isEditing = !useIsTokenController();

  const valueRef = useTypedElementById("text", id);
  const speculativeValueRef = useSpeculativeValue(textNodeType, valueRef);

  const rotationEditorRef = useTextNodeRotationEditorRef(valueRef);
  const speculativeRotationEditorRef = useTextNodeRotationEditorRef(speculativeValueRef);
  const speculativeRotationEditor = useSignalValue(speculativeRotationEditorRef);

  return (<binder>
    <ModelProvider value={valueModel}>
      {[...children].reverse().map((child) => <ElementHUDPass key={child.data.id} value={child} />)}
    </ModelProvider>
    {isSelected && isEditing && <>
      {speculativeRotationEditor && <ModelProvider value={Transform.divide(speculativeRotationEditor.transform, model)}>
        <SelectionIndicator origin={getTextOrigin(text, size, hTextAlign, vTextAlign)} size={speculativeRotationEditor.size} color={Color.WHITE} />
        <SelectionIndicator origin={Vector2.add(Vector2.subtract(speculativeRotationEditor.origin, speculativeRotationEditor.pivot), [2, 2])} size={[4, 4]} color={Color.GREEN} />
      </ModelProvider>}
      <RotationEditor valueRef={rotationEditorRef} speculativeValueRef={speculativeRotationEditorRef} />
    </>}
  </binder>);
}