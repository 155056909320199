import {Button, ButtonBar, Spacer} from "#lib/components/index.ts";
import {ConstantOperation, MapRef, SetOperation, Transform, Tree, TreeId, TreeOperation, TreePath} from "common/types/index.ts";
import {GroupNode, Node, NodeId, NodeOperation} from "common/legends/index.ts";
import {useCallback, useMemo} from "react";
import {FaObjectGroup, FaTrash} from "react-icons/fa";
import {TreeView} from "#lib/components/tree-view/index.ts";
import {ExpandOptions} from "#lib/components/expand-options.tsx";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {AddElementButton} from "./add-element-button.tsx";
import {MutableRef} from "common/ref";
import {NodeSelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";

export function OutlinerView({valueRef, expanded, selectedNodeIdsRef}: {
  valueRef: MutableRef<Node[], TreeOperation<Node, NodeOperation>[]>;
  expanded: MapRef<NodeId, boolean, ConstantOperation>;
  selectedNodeIdsRef: MutableRef<NodeSelectionRef[], SetOperation<NodeSelectionRef>[]>;
}) {
  const isCreateGroupDisabled = useMemo(() => {
    const selectedNodeIds = selectedNodeIdsRef.value;
    if (selectedNodeIds.length === 0) return true;
    const nodePaths = selectedNodeIds.map(nodeRef => Tree.getPath(valueRef.value, child => child.data.id === nodeRef.elementID));
    const ancestorPaths = nodePaths.map(path => path ? TreePath.splitParent(path)[0] : []);
    return ancestorPaths.some(path => !TreePath.equals(path, ancestorPaths[0]));
  }, [valueRef, selectedNodeIdsRef]);

  const deleteNode = useCallback((item: NodeId) => {
    valueRef.apply(prevNodes => {
      const nodePath = Tree.getPath(prevNodes, value => value.data.id === item);
      if (!nodePath) return [];
      const prevValue = Tree.getNode(prevNodes, nodePath);
      return [{type: "delete", path: nodePath, prevItem: prevValue}];
    });
    selectedNodeIdsRef.apply(prev => [{
      type: "set",
      prevItems: prev,
      nextItems: []
    }]);
  }, [valueRef, selectedNodeIdsRef]);

  const createGroup = useCallback(() => {
    valueRef.apply((prevNodes: Node[]): TreeOperation<Node, NodeOperation>[] => {
      if (!prevNodes) return [];
      const nodePaths = selectedNodeIdsRef.value.map(nodeRef => Tree.getPath(prevNodes, child => child.data.id === nodeRef.elementID)).filter(path => path !== undefined).map(path => path as TreePath).sort(TreePath.sort);
      if (nodePaths.length === 0) return [];
      const groupPath = nodePaths[0];
      let [ancestorPath, groupIndex] = TreePath.splitParent(groupPath);
      return [{
          type: "insert",
          path: groupPath,
          item: {
            type: "group",
            data: {
              ...GroupNode.DEFAULT,
              id: NodeId.generate()
            }
          }
        }, ...nodePaths
          .reverse()
          .flatMap((nodePath): TreeOperation<Node,  NodeOperation>[] => {
            let parentIndex = TreePath.splitParent(nodePath)[1];
            return [{
              type: "move",
              fromPath: [...ancestorPath, parentIndex >= groupIndex ? parentIndex + 1 : parentIndex],
              toPath: [...groupPath, 0]
            }];
          })
      ];
    });
  }, [valueRef, selectedNodeIdsRef]);


  const resolvedExpanded = useRefValue(expanded);
  const onToggle = (id: TreeId) => {
    expanded.apply((prev) => {
      if (prev[id] === undefined) return [{type: "put", key: id, item: true}];
      return [{type: "delete", key: id, item: prev[id]!}];
    })
  };
  const selected = useComputedValue(selectedNodeIdsRef, selectedNodeIds => selectedNodeIds.reduce((a: {[id: string]: boolean}, b) => {
    a[b.elementID] = true;
    return a;
  }, {}));
  const onSelect = (id: TreeId) => selectedNodeIdsRef.apply(prev => [{type: "set", prevItems: prev, nextItems: [
    {type: "element", elementID: id}
  ]}]);
  const onDeselect = () => selectedNodeIdsRef.apply(prev => [{type: "set", prevItems: prev, nextItems: []}]);

  const moveNode = (fromPath: TreePath, toPath: TreePath) => {
    const node = Tree.getNode(value, fromPath);
    const oldTransform = node.data.transform;

    let rootTransform = fromPath.slice(0, fromPath.length-1).map((_, i) => fromPath.slice(0, i+1))
      .map(path => Tree.getNode(value, path))
      .reverse()
      .reduce((a, b) => b.type !== "parallax" ? Transform.divide(a, b.data.transform) : a, oldTransform);
    let newTransform = toPath.slice(0, toPath.length - 1).map((_, i) => toPath.slice(0, i+1))
      .map(path => Tree.getNode(value, path))
      .reduce((a, b) => b.type !== "parallax" ? Transform.multiply(a, b.data.transform) : a, rootTransform);

    valueRef.apply(_ => [{
      type: "apply", path: fromPath, operations: [{type: node.type, operations: [{
        type: "update-transform",
        operations: [{type: "set", prevValue: oldTransform, nextValue: newTransform}]
      }]}]
    }, {type: "move", fromPath, toPath}]);
  };

  const value = useRefValue(valueRef);
  return <div className="flex flex-col gap-1">
    <ButtonBar>
      <Spacer />

      {!isCreateGroupDisabled && <Button className="justify-start" title="Group" onClick={createGroup}>
        <FaObjectGroup />
        <span>Group</span>
      </Button>}

      <AddElementButton valueRef={valueRef} selectedNodeIdsRef={selectedNodeIdsRef} />
    </ButtonBar>

    <TreeView type={"nodes"} items={value} isVisible={() => true} move={moveNode} selected={selected} onSelect={onSelect} onDeselect={onDeselect} expanded={resolvedExpanded} onExpand={onToggle} onCollapse={onToggle} ItemActions={(item) => <>
      <ExpandOptions options={{placement: "right-start"}}>
        <Button variant="destructive" onClick={() => deleteNode(item.data.id)} title="Delete Element"><FaTrash /> Delete Element</Button>
      </ExpandOptions>
    </>} />
  </div>;
}
