import {LightShapeSpotlight} from "common/legends/node/light/light-shape-spotlight.ts";
import React, {useState} from "react";
import {Point, PointFn} from "common/types/generic/index.ts";
import {LightTextureShader} from "./light-texture-shader.tsx";
import {LightSpotlightShader} from "../../viewport/common/shader/light/light-spotlight-shader.tsx";
import {WallTintShader} from "../../viewport/common/shader/wall-tint-shader.tsx";
import {BlendEquationSeparate} from "#lib/gl-react/component/opengl/blend-equation-separate.tsx";
import {BlendFuncSeperate} from "#lib/gl-react/component/opengl/blend-func-seperate.tsx";
import {Framebuffer} from "../../viewport/common/shader/framebuffer.tsx";
import {WallMaskFramebuffer} from "../../viewport/common/shader/wall-mask-framebuffer.tsx";
import {Line} from "../../viewport/scene/scene-view.tsx";
import {Vector2} from "common/math/vector/vector2.ts";
import {TextureMaskShader} from "../../viewport/common/shader/texture-mask-shader.tsx";

export function LightShapeSpotlightLightPass({value, origin, bounds, walls}: {
  value: LightShapeSpotlight
  origin: Point,
  bounds: Vector2[],
  walls: Line[]
}) {
  const [lightTexture, setLightTexture] = useState<WebGLTexture | null>(null);
  const [wallTexture, setWallTexture] = useState<WebGLTexture | null>(null);

  return (<binder>
    <Framebuffer ref={setLightTexture}>
      <BlendEquationSeparate rgb={WebGL2RenderingContext.FUNC_ADD} alpha={WebGL2RenderingContext.MAX}>
        <BlendFuncSeperate
          srcRGB={WebGL2RenderingContext.SRC_COLOR} dstRGB={WebGL2RenderingContext.ONE_MINUS_SRC_COLOR}
          srcAlpha={WebGL2RenderingContext.SRC_ALPHA} dstAlpha={WebGL2RenderingContext.DST_ALPHA}>
          <LightSpotlightShader value={value} origin={origin}/>
          <WallTintShader origin={PointFn.ZERO} walls={walls} bounds={bounds} />
        </BlendFuncSeperate>
      </BlendEquationSeparate>
    </Framebuffer>

    <WallMaskFramebuffer ref={setWallTexture} origin={PointFn.ZERO} walls={walls} bounds={bounds} />

    {lightTexture && wallTexture && <LightTextureShader>
      <TextureMaskShader texture={lightTexture} mask={wallTexture}/>
    </LightTextureShader>}
  </binder>);
}