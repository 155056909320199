import {Field, FieldLabel} from "#lib/components/panel-header.tsx";
import {InputGroup, InputGroupRange} from "#lib/components/index.ts";
import {MutableRef} from "common/ref";
import {NumberOperation} from "common/types/generic/index.ts";

export function RangeField({label, valueRef, min, max, step}: {
  label: string,
  valueRef: MutableRef<number, NumberOperation[]>,
  min: number,
  max: number,
  step: number
}) {
  return <Field>
    <FieldLabel>{label}</FieldLabel>
    <InputGroup className="mx-2 rounded-md overflow-hidden">
      <InputGroupRange value={valueRef} min={min} max={max} step={step} />
    </InputGroup>
  </Field>
}