import React, {useEffect, useMemo} from "react";
import {Color, Transform} from "common/types/index.ts";
import {ElementHUDPass} from "../element-h-u-d-pass.tsx";
import {SelectionIndicator} from "../../viewport/common/selection-indicator/selection-indicator.tsx";
import {ModelProvider, useModel} from "../../viewport/common/context/pvm-context.ts";
import {OpacityProvider, useOpacity} from "../../viewport/common/context/opacity-context.ts";
import {useIsActiveSelection} from "../../viewport/common/context/use-is-active-selection.ts";
import {VideoElement, videoElementType} from "common/legends/node/video-element.ts";
import {SizeEditor} from "../common/size-editor.tsx";
import {useIsTokenController} from "../../viewport/common/context/use-is-token-controller.ts";
import {FlipEditor} from "../common/flip-editor.tsx";
import {RotationEditor} from "../common/rotation-editor.tsx";
import {useTypedElementById} from "../common/use-typed-element-by-id.ts";
import {useSpeculativeValue} from "../common/use-speculative-value.ts";

export function VideoElementHUDPass({value}: {
  value: VideoElement;
}) {
  const model = useModel();
  const valueModel = useMemo(() => Transform.divide(value.transform, model), [value.transform, model]);
  const opacity = useOpacity();
  const valueOpacity = useMemo(() => value.opacity * opacity, [value.opacity, opacity]);
  const isSelected = useIsActiveSelection(value.id);
  const isEditing = !useIsTokenController();

  const valueRef = useTypedElementById("video", value.id);
  const speculativeValueRef = useSpeculativeValue(videoElementType, valueRef);
  useEffect(() => speculativeValueRef.apply(_ => []), [isSelected, speculativeValueRef]);

  return (<binder>
    <OpacityProvider value={valueOpacity}>
      <ModelProvider value={valueModel}>
        {[...value.children].reverse().map((child) => <ElementHUDPass key={child.data.id} value={child} />)}
        {isSelected && <SelectionIndicator origin={value.origin} size={value.size} color={Color.WHITE} repeatX={value.repeatX ?? 1} repeatY={value.repeatY ?? 1} />}
      </ModelProvider>
      {isSelected && <>
        <RotationEditor valueRef={valueRef} speculativeValueRef={speculativeValueRef} />
        {isEditing && <>
          <SizeEditor valueRef={valueRef} speculativeValueRef={speculativeValueRef} />
          <FlipEditor valueRef={valueRef} speculativeValueRef={speculativeValueRef} />
        </>}
      </>}
    </OpacityProvider>
  </binder>);
}