import {
  useArrayBuffer,
  useAttribLocation,
  useBindVertexArribArray,
  useElementArrayBuffer,
  useProgram,
  useShader,
  useUniformLocation,
  useVertexBuffer
} from "#lib/gl-react/index.ts";
import React, {useMemo} from "react";
import {useModel, useProjection, useView} from "../../context/pvm-context.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {Color, Point} from "common/types/generic/index.ts";
import {LightShapeSpotlight} from "common/legends/node/light/light-shape-spotlight.ts";
import {useRenderPassTexture} from "../../context/render-pass-texture-context.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {LightShapeFn} from "common/legends/node/light/light-shape.ts";


const vertexShader = `#version 300 es
precision highp float;

uniform mat4 u_projection;
uniform mat4 u_view;
uniform mat4 u_model;

in vec2 a_position;
in vec2 a_tex_coord;

out vec2 v_world_pos;
out vec2 v_normal_coord;
out vec2 v_position;
out vec2 v_tex_coord;

void main()
{
  gl_Position = u_projection * u_view * u_model * vec4(a_position, 0, 1);
  v_position = a_position;
  v_world_pos = (u_model * vec4(a_position, 0.0, 1.0)).xy;
  v_normal_coord = (gl_Position.xy + 1.0) / 2.0;
  v_tex_coord = a_tex_coord;
}
`;

const fragmentShader = `#version 300 es
#define PI 3.1415926538
precision highp float;
precision highp sampler2DArray;

in vec2 v_position;
in vec2 v_world_pos;
in vec2 v_normal_coord;
in vec2 v_tex_coord;

uniform float angle;
uniform float falloffAngle;
uniform float radius;
uniform float falloff;
uniform float falloffStrength;
uniform float intensity;
uniform vec2 origin;
uniform vec3 color;

uniform sampler2D u_normal;

out vec4 outColor;

void main() {
  float dist = length(v_tex_coord);

  vec2 v_dist = v_tex_coord;
  float a = mod(atan(v_dist.y, v_dist.x) + 2. * PI, 2.* PI);

  float s1 = mod((2. * PI) - (angle / 2.) + (2. * PI), 2. * PI);
  float s2 = mod((2. * PI) - ((angle + falloffAngle) / 2.) + (2. * PI), 2. * PI);
  float e1 = mod(0. + (angle / 2.) + (2. * PI), 2. * PI);
  float e2 = mod(0. + ((angle + falloffAngle) / 2.) + (2. * PI), 2. * PI);

  float radiusFalloff = pow(1. - min(1., max(0., (dist - radius) / falloff)), falloffStrength);
  float angularFalloff = 0.;
  if (s2 < e2 && a >= s2 && a <= e2) {
    if (a-falloffAngle >= s2) {
      angularFalloff = 0.5;
    } else {
      angularFalloff = 0.5;
    }
  } else if (s2 > e2) {
    if (a >= s1) angularFalloff = 1.;
    else if (a >= s2) angularFalloff = pow(1. - (a - s1) / (s2 - s1), falloffStrength);
    else if (a <= e1) angularFalloff = 1.;
    else if (a <= e2) angularFalloff = pow(1. - (a - e1) / (e2 - e1), falloffStrength);
  } else if (s1 == e1) {
    angularFalloff = 1.;
  } else if (s2 == e2) {
    if (e1 > a || s1 < a) angularFalloff = 1.;
    else if (e2 > a) angularFalloff = pow(1. - (a - e1) / (e2 - e1), falloffStrength);
    else angularFalloff = pow(1. - (a - s1) / (s2 - s1), falloffStrength);
  }
  
  vec2 coord = normalize(origin - v_world_pos);
  vec3 normal = texture(u_normal, v_normal_coord).rgb * 2.0 - 1.0;
  float n = mix(1.0, max(dot(normalize(normal.xy), coord.xy), 0.), max(0.0, 1.0 - normal.z));
  outColor = vec4(color, (angularFalloff * radiusFalloff) * intensity * n);
}
`;

export const LightSpotlightShader = function LightSpotlightShader({value, origin}: {value: LightShapeSpotlight, origin: Point}) {
  const projection = useProjection();
  const view = useView();
  const model = useModel();

  const program = useProgram(
    useShader(WebGL2RenderingContext.VERTEX_SHADER, vertexShader),
    useShader(WebGL2RenderingContext.FRAGMENT_SHADER, fragmentShader)
  );
  const vbo = useArrayBuffer(useMemo(() => {
    const shapeOrigin = LightShapeFn.getLightOrigin({type: "spotlight", data: value}, origin);
    const shapeSize = LightShapeFn.getLightSize({type: "spotlight", data: value});
    const [x, y] = shapeOrigin;
    const [w, h] = shapeSize;
    const tr = value.radius + value.falloff;
    return new Float32Array([
      -x+0, -y+0,  tr, -tr,
      -x+w, -y+0, -tr, -tr,
      -x+w, -y+h, -tr,  tr,
      -x+0, -y+h,  tr,  tr
    ]);
  }, [origin, value]));
  const vao = useVertexBuffer();
  useBindVertexArribArray(vao, useAttribLocation(program, "a_position"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 0);
  useBindVertexArribArray(vao, useAttribLocation(program, "a_tex_coord"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 0);
  const ebo = useElementArrayBuffer(useMemo(() => new Uint16Array([0, 1, 2, 2, 3, 0]), []));

  const originUniform = useMemo(() => new Float32Array(Vector2.multiplyTransform([0,0], model)), [model]);
  const colorUniform = useMemo(() => new Float32Array(Color.toRGB(value.color)), [value.color]);

  const projectionMatrix4f = useMemo(() => new Float32Array(projection), [projection]);
  const viewMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(view)), [view]);
  const modelMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(model)), [model]);

  const [_, normalTexture] = useRenderPassTexture();

  return <program value={program}>
    <uniformMat4fv location={useUniformLocation(program, "u_projection")} transpose={true} data={projectionMatrix4f}/>
    <uniformMat4fv location={useUniformLocation(program, "u_view")} transpose={true} data={viewMatrix4f}/>
    <uniformMat4fv location={useUniformLocation(program, "u_model")} transpose={true} data={modelMatrix4f}/>

    <uniform1f location={useUniformLocation(program, "angle")} data={value.angle / 180 * Math.PI}/>
    <uniform1f location={useUniformLocation(program, "falloffAngle")} data={value.falloffAngle / 180 * Math.PI}/>
    <uniform1f location={useUniformLocation(program, "radius")} data={value.radius}/>
    <uniform1f location={useUniformLocation(program, "falloff")} data={value.falloff}/>
    <uniform1f location={useUniformLocation(program, "falloffStrength")} data={value.falloffStrength}/>
    <uniform1f location={useUniformLocation(program, "intensity")} data={value.intensity}/>
    <uniform2fv location={useUniformLocation(program, "origin")} data={originUniform}/>
    <uniform3fv location={useUniformLocation(program, "color")} data={colorUniform}/>

    <texture2d value={normalTexture}>
      <vertexArray value={vao}>
        <elementArrayBuffer value={ebo}>
          <drawElements mode={WebGL2RenderingContext.TRIANGLES} type={WebGL2RenderingContext.UNSIGNED_SHORT} offset={0} count={6}/>
        </elementArrayBuffer>
      </vertexArray>
    </texture2d>
  </program>;
}
