import {QLabDatabase} from "common/qlab/q-lab-database.ts";
import {walkTree} from "common/types/generic/index.ts";
import {Node} from "common/legends/node/index.ts";
import {getGameMasterIDs} from "./get-game-master-i-ds.ts";
import {StageID} from "common/legends/stage/stage-i-d.ts";

export function getPlayerCharacterTokens(database: QLabDatabase, stageID: StageID) {
  const gameMasterIDs = getGameMasterIDs(database);
  const elements: Node[] = [];
  for (const resource of Object.values(database.resources)) {
    if (resource?.type !== "scene") continue;
    walkTree(resource.data.children, {
      visit(value) {
        if (value.type !== "token") return;

        if (!value.data.stageIDs.includes("ALL") && !value.data.stageIDs.includes(stageID)) {
          return;
        }
        if (value.data.ownerIDs.length === 0 || value.data.ownerIDs.every((ownerID) => gameMasterIDs.includes(ownerID))) {
          return;
        }

        elements.push(value);
      }
    })
  }
  return elements;
}
