import React, {PropsWithChildren, useContext, useMemo} from "react";
import {useRenderingContext} from "#lib/gl-react/index.ts";

export class ProgramManager {
  private shaders: Map<WebGLShader, number> = new Map();
  private programs: {[vertexIndex: number]: {[fragmentIndex: number]: WebGLProgram}} = {};
  constructor(private readonly context: WebGL2RenderingContext) {}
  getProgram(vertexShader: WebGLShader, fragmentShader: WebGLShader): WebGLProgram {
    const vertexIndex = this.getShaderIndex(vertexShader);
    const fragmentIndex = this.getShaderIndex(fragmentShader);
    if (this.programs[vertexIndex] === undefined || this.programs[vertexIndex][fragmentIndex] === undefined) {
      const program = this.context.createProgram();
      if (program === null) throw new Error("Cannot create program");
      this.context.attachShader(program, vertexShader);
      this.context.attachShader(program, fragmentShader);
      this.context.linkProgram(program);
      const programError = this.context.getProgramInfoLog(program);
      if (programError) throw new Error(programError);
      if (!this.programs[vertexIndex]) this.programs[vertexIndex] = {};
      this.programs[vertexIndex][fragmentIndex] = program;
    }
    return this.programs[vertexIndex][fragmentIndex];
  }

  private getShaderIndex(shader: WebGLShader): number {
    if (this.shaders.has(shader)) {
      return this.shaders.get(shader)!;
    } else {
      const index = this.shaders.size;
      this.shaders.set(shader, index);
      return index;
    }
  }

  private programUniformLocations: Map<WebGLProgram, Map<string, WebGLUniformLocation>> = new Map();
  getUniformLocation(program: WebGLProgram, name: string) {
    if (!this.programUniformLocations.has(program)) {
      this.programUniformLocations.set(program, new Map());
    }
    const uniforms = this.programUniformLocations.get(program)!;
    if (!uniforms.has(name)) {
      const uniformLocation = this.context.getUniformLocation(program, name);
      if (uniformLocation === null) console.warn("Invalid Uniform Location: " + name);
      uniforms.set(name, uniformLocation!);
    }
    return uniforms.get(name)!;
  }

  private programAttributeLocations: Map<WebGLProgram, Map<string, GLint>> = new Map();
  getAttributeLocation(program: WebGLProgram, name: string) {
    if (!this.programAttributeLocations.has(program)) {
      this.programAttributeLocations.set(program, new Map());
    }
    const attributes = this.programAttributeLocations.get(program)!;
    if (!attributes.has(name)) {
      const attributeLocation = this.context.getAttribLocation(program, name)
      attributes.set(name, attributeLocation!);
    }
    return attributes.get(name)!;
  }
}

const ProgramManagerContext = React.createContext<ProgramManager | undefined>(undefined);
export function ProgramManagerProvider({children}: PropsWithChildren<object>) {
  const context = useRenderingContext();
  const textureManager = useMemo(() => new ProgramManager(context), [context]);
  return <ProgramManagerContext.Provider value={textureManager}>
    {children}
  </ProgramManagerContext.Provider>
}

export function useProgramManager() {
  return useContext(ProgramManagerContext)!;
}
