import {useMemo} from "react";
import {distinct, map, Observer} from "common/observable";
import {ApplyAction, ConstantOperation, constantType, Optional, ValueFn, ValueOperation, ValueType} from "common/types/index.ts";
import {pipe} from "common/pipe";
import {usePlayerController} from "../../panel/nav/player/player-controller-provider.ts";
import {ActiveControllerOperation, ActiveControllerValue, TokenViewportPropertiesFn} from "./token-viewport-properties.ts";
import {MutableRef} from "common/ref";
import {NodeId} from "common/legends/node/index.ts";
import {useEditor} from "../../panel/nav/editor/editor-context.ts";
import {EditorState} from "../../panel/nav/editor/state";

export function useActiveController(): MutableRef<Optional<ActiveControllerValue>, ValueOperation<Optional<ActiveControllerValue>, ActiveControllerOperation>[]> {
  const editor = useEditor();
  const playerController = usePlayerController();
  return useMemo(() => {
    if (editor?.state !== undefined) {
      const valueFn = (value: EditorState): Optional<ActiveControllerValue> => {
        if (value?.type === "scene" || value?.type === "asset") {
          if (value.data.selectedNodeIds.length > 0) {
            const nodeIds = value.data.selectedNodeIds;
            return ({
              controllerNodeID: nodeIds[0].elementID,
              view: value.data.view
            })
          }
        }
        return undefined;
      }
      return new MutableRef({
        value(): Optional<ActiveControllerValue> {
          return valueFn(editor.state.value);
        },
        observe: pipe(editor.state.observe, map(valueFn), distinct()),
        apply: _ => {
          throw new Error("Unsupported.");
        }
      });
    } else if (playerController?.state !== undefined) {
      return TokenViewportPropertiesFn.expand(playerController.state).activeController;
    } else {
      return new MutableRef({
        value(): Optional<ActiveControllerValue> {
          return undefined;
        },
        observe: (observer: Observer<Optional<ActiveControllerValue>>) => {
          observer.next(undefined);
          return () => {}
        },
        apply: async (_: ApplyAction<Optional<ActiveControllerValue>, ValueOperation<Optional<ActiveControllerValue>, ActiveControllerOperation>[]>) => {
          throw new Error("Unsupported.");
        }
      });
    }
  }, [editor?.state, playerController?.state]);
}

export function useActiveControllerReferenceNode(): MutableRef<Optional<NodeId>, ValueOperation<Optional<NodeId>, ConstantOperation>[]> {
  const activeControllerRef = useActiveController();
  return useMemo(() => {
    return activeControllerRef.map<Optional<NodeId>, ValueOperation<Optional<NodeId>, ConstantOperation>[]>(
      value => value?.controllerNodeID,
      (prev, operations) => {
        if (prev === undefined) return [];
        const nodeReferenceType = new ValueType(constantType);
        const nextValue = operations.reduce(nodeReferenceType.apply, prev);
        if (prev === nextValue) return [];
        return ValueFn.set(prev, {
          controllerNodeID: nextValue,
          view: prev?.view
        });
      }
    ).distinct()
  }, [activeControllerRef]);
}
