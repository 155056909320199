import {useSignalValue} from "#lib/signal/index.ts";
import {effect, valueSignal} from "common/signal";

const gridMode = valueSignal(localStorage.getItem("gridMode") === null);

effect(() => {
  if (gridMode.value) {
    localStorage.removeItem("gridMode");
  } else {
    localStorage.setItem("gridMode", "false");
  }
});

export function useIsGridMode(): boolean {
  return useSignalValue(gridMode);
}

export function toggleGridMode() {
  gridMode.apply(prev => !prev);
}
