import {ShapeNode} from "common/legends/index.ts";
import React from "react";
import {Color, ShapeFn, Transform} from "common/types/index.ts";
import {ElementHUDPass} from "../element-h-u-d-pass.tsx";
import {SelectionIndicator} from "../../viewport/common/selection-indicator/selection-indicator.tsx";
import {ModelProvider, useModel} from "../../viewport/common/context/pvm-context.ts";
import {useIsActiveSelection} from "../../viewport/common/context/use-is-active-selection.ts";

export function ShapeElementHUDPass({value: {id, children, transform, shape, origin, pivot}}: {
  value: ShapeNode;
}) {
  const model = useModel();
  const valueModel = Transform.divide(transform, model);
  const isSelected = useIsActiveSelection(id);

  const shapeOrigin = ShapeFn.getShapeOrigin(shape, origin);
  const shapeSize = ShapeFn.getShapeSize(shape);

  return (<binder>
    <ModelProvider value={valueModel}>
      {[...children.reverse()].map((child) => <ElementHUDPass key={child.data.id} value={child} />)}
      {isSelected && <SelectionIndicator origin={shapeOrigin} size={shapeSize} color={Color.WHITE} />}
      {isSelected && <SelectionIndicator origin={[origin[0] - pivot[0] + 2, origin[1] - pivot[1] + 2]} size={[4, 4]} color={Color.GREEN} />}
    </ModelProvider>
  </binder>);
}
