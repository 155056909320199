import {Rectangle, Transform} from "common/types/generic/index.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {TextShader} from "#lib/gl-react/component/text-shader.tsx";
import {useMemo} from "react";
import {RectangleMeasurement} from "common/legends/measurement/index.ts";
import {Grid} from "common/legends/scene/index.ts";
import {useGrid} from "../context/grid-context.ts";
import {RectangleShader} from "../shader/shape/rectangle-shader.tsx";
import {ModelProvider, useView} from "../context/pvm-context.ts";

export function RectangleMeasurementView({measurement: {startPoint, endPoint, color}}: {
  measurement: RectangleMeasurement
}) {
  const view = useView();
  const grid = useGrid();
  const size = useMemo(() => Vector2.subtract(endPoint, startPoint), [endPoint, startPoint]);

  const model: Transform = useMemo(() => ({
    scale: 1,
    position: startPoint,
    rotation: 0
  }), [startPoint, size]);

  const widthTextModel: Transform = useMemo(() => ({
    scale: 1 / view.scale,
    position: [startPoint[0] + size[0] / 2, endPoint[1]],
    rotation: 0
  }), [size, startPoint, endPoint, view.scale]);


  const heightTextModel: Transform = useMemo(() => ({
    scale: 1 / view.scale,
    position: [endPoint[0], startPoint[1] + size[1] / 2],
    rotation: -90
  }), [size, startPoint, endPoint, view.scale]);

  const rectangle = useMemo((): Rectangle => ({
    width: size[0],
    height: size[1]
  }), [size]);

  const width = Grid.distance(grid, startPoint, [endPoint[0], startPoint[1]]);
  const height = Grid.distance(grid, startPoint, [startPoint[0], endPoint[1]]);

  return <ModelProvider value={model}>
    <RectangleShader origin={[0,0]} color={color} rectangle={rectangle} />
    <ModelProvider value={widthTextModel}>
      <TextShader value={`${width} ${grid.unit.label}`} size={32} vTextAlign={startPoint[1] > endPoint[1] ? "top" : "bottom"} />
    </ModelProvider>
    <ModelProvider value={heightTextModel}>
      <TextShader value={`${height} ${grid.unit.label}`} size={32} vTextAlign={startPoint[0] > endPoint[0] ? "top" : "bottom"} />
    </ModelProvider>
  </ModelProvider>;
}