import {applyAll, BooleanFn, FileReference, HSLA, Optional, Point, PointFn, SetFn, Transform, ValueFn} from "common/types/index.ts";
import React, {Dispatch, Fragment, useCallback, useMemo, useState} from "react";
import {WallGraph, WallGraphFn, WallGraphOperation, wallGraphType, WallNode} from "common/legends/node/wall-node.ts";
import {ModelProvider, useModel, useView} from "../../viewport/common/context/pvm-context.ts";
import {OpacityProvider, useOpacity} from "../../viewport/common/context/opacity-context.ts";
import {WallEdgeShader} from "../../viewport/common/shader/wall-edge-shader.tsx";
import {ElementHUDPass} from "../element-h-u-d-pass.tsx";
import {useActiveEdgeSelection, useActiveVertexSelection, useIsActiveSelection} from "../../viewport/common/context/use-is-active-selection.ts";
import {useIsTokenController} from "../../viewport/common/context/use-is-token-controller.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {VertexShader} from "../../viewport/common/selection-indicator/vertex-shader.tsx";
import {StraightLineShader} from "../../viewport/common/shader/straight-line-shader.tsx";
import {useAccess} from "../../../routes/game/model/store-context.tsx";
import {NodeId, NodeOperation} from "common/legends/node/index.ts";
import {useEditor} from "../../panel/nav/editor/editor-context.ts";
import {getScreenPosition} from "../../viewport/tool/use-get-screen-pos.ts";
import {getWorldPositionFromScreenPosition} from "../../viewport/tool/use-get-world-pos.ts";
import {useRenderingContext} from "#lib/gl-react/index.ts";
import {useGrid} from "../../viewport/common/context/grid-context.ts";
import {Grid} from "common/legends/scene/index.ts";
import {lerpBezier, perpendicularBezier} from "common/math/bezier/bezier.ts";
import {useImageTexture} from "../../viewport/common/context/use-image-texture.ts";
import faEyeSlash from "./eye-slash.png";
import faEyeSolid from "./eye-solid.png";
import faCircleStop from "./circle-stop-regular.svg";
import faCircleStopSlash from "./regular-circle-stop-slash.svg";
import {ImageShader} from "../../viewport/common/shader/image-shader.tsx";
import {EditorController} from "../../panel/nav/editor/editor-controller.ts";
import {computed, MutableSignal} from "common/signal";
import {NodeSelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {EditorStateOperation} from "../../panel/nav/editor/state";


function WallElementEdgeControlPointsHUDPass({elementID, edgeIndex, graph, origin, color, speculative, setSpeculative}: {
  elementID: NodeId,
  edgeIndex: number;
  graph: WallGraph;
  origin: Point;
  color: HSLA;
  speculative: WallGraph;
  setSpeculative: Dispatch<WallGraphOperation[]>
}) {
  const access = useAccess();

  const edge = speculative.edges[edgeIndex];
  const startVertex = graph.vertices[edge.start];
  const endVertex = graph.vertices[edge.end];
  const grid = useGrid();

  return <>
    <StraightLineShader
      origin={origin}
      start={startVertex.coordinate}
      end={Vector2.add(startVertex.coordinate, edge.controlPoint1)}
      color={color}
      scale={0.5}
      onClick={ev => {
        ev.preventDefault();
        ev.stopPropagation();
      }}
    />
    <StraightLineShader
      origin={origin}
      start={endVertex.coordinate}
      end={Vector2.add(endVertex.coordinate, edge.controlPoint2)}
      color={color}
      scale={0.5}
      onClick={ev => {
        ev.preventDefault();
        ev.stopPropagation();
      }}
    />
    <VertexShader
      radius={8}
      origin={Vector2.add(Vector2.add(origin, startVertex.coordinate), edge.controlPoint1)}
      opacity={1}
      draggable
      onDragMove={(event, _startPos, newPosition) => {
        const newLocalPosition = Vector2.subtract(newPosition, Vector2.add(origin, graph.vertices[edge.start].coordinate));
        const snapPos = !event.shiftKey ? Grid.snap(grid, newLocalPosition) : newLocalPosition;
        setSpeculative(WallGraphFn.moveControlPoint1(graph, edgeIndex, snapPos))
      }}
      onDragEnd={(event, _startPos, newPosition) => {
        access.element(elementID).apply((prev): NodeOperation[] => {
          if (prev?.type !== "wall") return [];
          if (edgeIndex >= prev.data.graph.edges.length) return [];
          const newLocalPosition = Vector2.subtract(newPosition, Vector2.add(prev.data.origin, prev.data.graph.vertices[edge.start].coordinate));
          const snapPos = !event.shiftKey ? Grid.snap(grid, newLocalPosition) : newLocalPosition;
          return [{type: "wall", operations: [{type: "update-graph",
            operations: WallGraphFn.moveControlPoint1(prev.data.graph, edgeIndex, snapPos)
          }]}]
        });
        setSpeculative([]);
      }}
    />
    <VertexShader
      radius={8}
      origin={Vector2.add(Vector2.add(origin, endVertex.coordinate), edge.controlPoint2)}
      opacity={1}
      draggable
      onDragMove={(event, _startPos, newPosition) => {
        const newLocalPosition = Vector2.subtract(newPosition, Vector2.add(origin, graph.vertices[edge.end].coordinate));
        const snapPos = !event.shiftKey ? Grid.snap(grid, newLocalPosition) : newLocalPosition;
        setSpeculative(WallGraphFn.moveControlPoint2(graph, edgeIndex, snapPos))
      }}
      onDragEnd={(event, _startPos, endPos) => {
        access.element(elementID).apply((prev): NodeOperation[] => {
          if (prev?.type !== "wall") return [];
          if (edgeIndex >= prev.data.graph.edges.length) return [];
          const newLocalPosition = Vector2.subtract(endPos, Vector2.add(prev.data.origin, prev.data.graph.vertices[edge.end].coordinate));
          const snapPos = !event.shiftKey ? Grid.snap(grid, newLocalPosition) : newLocalPosition;
          return [{type: "wall", operations: [{type: "update-graph",
            operations: WallGraphFn.moveControlPoint2(prev.data.graph, edgeIndex, snapPos)
          }]}]
        });
        setSpeculative([]);
      }}
    />
  </>
}

export function WallElementEdgeControls({elementID, edgeIndex, graph}: {
  elementID: NodeId,
  graph: WallGraph,
  edgeIndex: number
}) {
  const edge = graph.edges[edgeIndex];

  const [isInvisibleLoaded, invisibleImage] = useImageTexture(faEyeSlash as FileReference);
  const [isVisibleLoaded, visibleImage] = useImageTexture(faEyeSolid as FileReference);

  const [isBlockMovementLoaded, blockMovementImage] = useImageTexture(faCircleStop as FileReference);
  const [isAllowMovementLoaded, allowMovementImage] = useImageTexture(faCircleStopSlash as FileReference);

  const visibilityIndicators = useMemo((): [Point, Point] => {
    const a = graph.vertices[edge.start].coordinate;
    const b = graph.vertices[edge.end].coordinate;
    const mid = lerpBezier(a, b, edge.controlPoint1, edge.controlPoint2, edge.resolution, 0.5);
    const p = perpendicularBezier(a,b, edge.controlPoint1, edge.controlPoint2, edge.resolution, 0.5);
    const u = Vector2.normalize([p[1], -p[0]]);
    return [
      Vector2.add(Vector2.add(mid, Vector2.multiply(u, 18)), Vector2.multiply(p, -48)),
      Vector2.add(Vector2.add(mid, Vector2.multiply(u, 18)), Vector2.multiply(p, 48))
    ];
  }, [graph, edge]);

  const blockMovementIndicators = useMemo((): [Point, Point] => {
    const a = graph.vertices[edge.start].coordinate;
    const b = graph.vertices[edge.end].coordinate;
    const mid = lerpBezier(a, b, edge.controlPoint1, edge.controlPoint2, edge.resolution, 0.5);
    const p = perpendicularBezier(a,b, edge.controlPoint1, edge.controlPoint2, edge.resolution, 0.5);
    const u = Vector2.normalize([p[1], -p[0]]);
    return [
      Vector2.add(Vector2.add(mid, Vector2.multiply(u, -18)), Vector2.multiply(p, -48)),
      Vector2.add(Vector2.add(mid, Vector2.multiply(u, -18)), Vector2.multiply(p, 48))
    ];
  }, [graph, edge]);

  const access = useAccess();
  const toggleLeftVisibility = useCallback(() => {
    access.element(elementID).apply(prev => {
      if (prev?.type !== "wall") return [];
      const prevValue = prev.data.graph.edges[edgeIndex].data.blockVisibilityLeft;
      return [{type: "wall", operations: [{type: "update-graph", operations: [{
            type: "update-edge",
            index:edgeIndex,
            operations: [{
              type: "update-data",
              operations: [{
                type: "update-block-visibility-left",
                operations: BooleanFn.set(prevValue, !prevValue)
              }]
            }]
          }]}]}];
    })
  }, [access, elementID, edgeIndex]);
  const toggleRightVisibility = useCallback(() => {
    access.element(elementID).apply(prev => {
      if (prev?.type !== "wall") return [];
      const prevValue = prev.data.graph.edges[edgeIndex].data.blockVisibilityRight;
      return [{type: "wall", operations: [{type: "update-graph", operations: [{
            type: "update-edge",
            index:edgeIndex,
            operations: [{
              type: "update-data",
              operations: [{
                type: "update-block-visibility-right",
                operations: BooleanFn.set(prevValue, !prevValue)
              }]
            }]
          }]}]}];
    })
  }, [access, elementID, edgeIndex]);
  const toggleLeftMovement = useCallback(() => {
    access.element(elementID).apply(prev => {
      if (prev?.type !== "wall") return [];
      const prevValue = prev.data.graph.edges[edgeIndex].data.blockMovementLeft;
      return [{type: "wall", operations: [{type: "update-graph", operations: [{
            type: "update-edge",
            index:edgeIndex,
            operations: [{
              type: "update-data",
              operations: [{
                type: "update-block-movement-left",
                operations: BooleanFn.set(prevValue, !prevValue)
              }]
            }]
          }]}]}];
    })
  }, [access, elementID, edgeIndex]);
  const toggleRightMovement = useCallback(() => {
    access.element(elementID).apply(prev => {
      if (prev?.type !== "wall") return [];
      const prevValue = prev.data.graph.edges[edgeIndex].data.blockMovementRight;
      return [{type: "wall", operations: [{type: "update-graph", operations: [{
            type: "update-edge",
            index:edgeIndex,
            operations: [{
              type: "update-data",
              operations: [{
                type: "update-block-movement-right",
                operations: BooleanFn.set(prevValue, !prevValue)
              }]
            }]
          }]}]}];
    })
  }, [access, elementID, edgeIndex]);

  return <>
    {isVisibleLoaded && isInvisibleLoaded && <ImageShader
        albedoTexture={edge.data.blockVisibilityLeft ? invisibleImage : visibleImage}
        size={[32, 32]}
        origin={Vector2.add(Vector2.subtract([0, 0], visibilityIndicators[0]), [16, 16])}
        opacity={0.9} repeatX={1} repeatY={1}
        onMouseDown={(event) => {
          toggleLeftVisibility();
          event.preventDefault();
          event.stopPropagation();
          return true;
        }}
    />}

    {isVisibleLoaded && isInvisibleLoaded && <ImageShader
        albedoTexture={edge.data.blockVisibilityRight ? invisibleImage : visibleImage}
        size={[32, 32]}
        origin={Vector2.add(Vector2.subtract([0, 0], visibilityIndicators[1]), [16, 16])}
        opacity={0.9} repeatX={1} repeatY={1}
        onMouseDown={(event) => {
          toggleRightVisibility();
          event.preventDefault();
          event.stopPropagation();
          return true;
        }}
    />}

    {isAllowMovementLoaded && isBlockMovementLoaded && <ImageShader
        albedoTexture={edge.data.blockMovementLeft ? blockMovementImage : allowMovementImage}
        size={[32, 32]}
        origin={Vector2.add(Vector2.subtract([0, 0], blockMovementIndicators[0]), [16, 16])}
        opacity={0.9} repeatX={1} repeatY={1}
        onMouseDown={(event) => {
          toggleLeftMovement();
          event.preventDefault();
          event.stopPropagation();
          return true;
        }}
    />}
    {isAllowMovementLoaded && isBlockMovementLoaded && <ImageShader
        albedoTexture={edge.data.blockMovementRight ? blockMovementImage : allowMovementImage}
        size={[32, 32]}
        origin={Vector2.add(Vector2.subtract([0, 0], blockMovementIndicators[1]), [16, 16])}
        opacity={0.9} repeatX={1} repeatY={1}
        onMouseDown={(event) => {
          toggleRightMovement();
          event.preventDefault();
          event.stopPropagation();
          return true;
        }}
    />}
  </>
}

function getActiveSelection(editor: EditorController): MutableSignal<Optional<NodeSelectionRef>, Optional<NodeSelectionRef>> {
  return computed(() => {
    if (!editor) return undefined;
    const selectedElementRefs = editor.state.value?.data.selectedNodeIds ?? [];
    if (selectedElementRefs.length > 0) {
      return selectedElementRefs[0];
    }
  }, (newValue: NodeSelectionRef) => {
    if (!editor) return;
    editor.state.apply((prev): EditorStateOperation[] => {
      if (prev?.type === "asset") {
        return ValueFn.apply([{type: "asset", operations: [{
            type: "update-selected-node-ids",
            operations: SetFn.set<NodeSelectionRef>(prev.data.selectedNodeIds, [newValue])
          }]}]);
      } else if (prev?.type === "scene") {
        return ValueFn.apply([{type: "scene", operations: [{
            type: "update-selected-node-ids",
            operations: SetFn.set(prev.data.selectedNodeIds, [newValue])
          }]}]);
      } else {
        return [];
      }
    })
  });
}

export function WallElementHUDPass({value}: {
  value: WallNode;
}) {
  const isEditing = !useIsTokenController();
  const isActiveSelection = useIsActiveSelection(value.id);
  const activeEdge = useActiveEdgeSelection(value.id);
  const activeVertex = useActiveVertexSelection(value.id);
  const access = useAccess();
  const editor = useEditor();
  const view = useView();
  const canvas = useRenderingContext().canvas as HTMLCanvasElement;
  const activeSelectionRef = useMemo(() => getActiveSelection(editor), [editor]);

  const [startPos, setStartPos] = useState<Point | undefined>(undefined);
  const [speculativeOperations, setSpeculativeOperations] = useState<WallGraphOperation[]>([]);
  const speculativeGraph = useMemo(() => {
    try {
      return applyAll(wallGraphType, value.graph, speculativeOperations);
    } catch (ex) {
      return value.graph;
    }
  }, [value.graph, speculativeOperations]);

  const grid = useGrid();

  const opacity = useOpacity();
  const valueOpacity = useMemo(() => value.opacity * opacity, [value.opacity, opacity]);
  const model = useModel();
  let valueModel = useMemo(() => Transform.divide(value.transform, model), [value.transform, model]);
  return (<binder>
    <OpacityProvider value={valueOpacity}>
      <ModelProvider value={valueModel}>
        {isEditing && isActiveSelection && <>
            <interaction
                onKeyUp={(event) => {
                  if (event.key === "Escape") {
                    if (activeSelectionRef.value?.type !== "element") {
                      activeSelectionRef.apply(_ => ({type: "element", elementID: value.id}));
                      event.preventDefault();
                      event.stopPropagation();
                      return true;
                    }
                  }
                }}
                onMouseDown={(event) => {
                  if (event.button === 0) {
                    if (activeSelectionRef.value?.type !== "element") {
                      activeSelectionRef.apply(_ => ({type: "element", elementID: value.id}));
                      event.preventDefault();
                      event.stopPropagation();
                      return true;
                    }
                  }
                }} />
            <interaction onKeyUp={event => {
              if (event.key === "Delete" || event.key === "Backspace") {
                access.element(value.id).apply((prev): NodeOperation[] => {
                  if (prev?.type !== "wall") return [];
                  if (activeVertex !== undefined && activeVertex < prev.data.graph.vertices.length) {
                    return [{
                      type: "wall", operations: [{
                        type: "update-graph",
                        operations: WallGraphFn.deleteVertex(prev.data.graph, activeVertex)
                      }]
                    }]
                  } else if (activeEdge !== undefined && activeEdge < prev.data.graph.edges.length) {
                    return [{
                      type: "wall", operations: [{
                        type: "update-graph",
                        operations: WallGraphFn.deleteEdge(prev.data.graph, activeEdge)
                      }]
                    }]
                  } else {
                    return [];
                  }
                });
                activeSelectionRef.apply(_ => ({type: "element", elementID: value.id}));
                event.preventDefault();
                event.stopPropagation();
                return true;
              }
            }}/>

          {speculativeGraph.edges.map((edge, edgeIndex) => <Fragment key={edgeIndex}>
            <WallEdgeShader
              key={edgeIndex}
              origin={value.origin}
              start={speculativeGraph.vertices[edge.start].coordinate}
              end={speculativeGraph.vertices[edge.end].coordinate}
              resolution={edge.resolution}
              controlPoint1={edge.controlPoint1}
              controlPoint2={edge.controlPoint2}
              opacity={activeEdge === edgeIndex ? valueOpacity : valueOpacity * 0.5}
              data={edge.data}
              color={value.color}
              onMouseDown={(ev) => {
                if (ev.button === 0) {
                  ev.preventDefault();
                  ev.stopPropagation();
                }
              }}
              onClick={(ev) => {
                if (ev.button !== 0) return;
                ev.preventDefault();
                ev.stopPropagation();
                if (ev.detail === 1) {
                  activeSelectionRef.apply(_ => ({type: "element-edge", elementID: value.id, edgeIndex}));
                } else if (ev.detail === 2) {
                  if (activeSelectionRef.value?.type === "element-edge" && activeSelectionRef.value.edgeIndex === edgeIndex) {
                    const worldPos = getWorldPositionFromScreenPosition(view, getScreenPosition(canvas, [ev.clientX, ev.clientY]));
                    const snapPos = !ev.shiftKey ? Grid.snap(grid, worldPos) : worldPos;
                    const localPos = Vector2.divideTransform(snapPos, valueModel);
                    const closestPoint = Vector2.closestPointToLine(
                      localPos,
                      speculativeGraph.vertices[edge.start].coordinate,
                      speculativeGraph.vertices[edge.end].coordinate
                    );

                    access.element(value.id).apply((prev): NodeOperation[] => {
                      if (prev?.type !== "wall") return [];
                      const operations = WallGraphFn.insertVertex(prev.data.graph, {coordinate: closestPoint, data: undefined});
                      const newGraph = applyAll(wallGraphType, prev.data.graph, operations);
                      const newVertexIndex = newGraph.vertices.findIndex(vertex => PointFn.equals(vertex.coordinate, closestPoint));
                      setStartPos(undefined);
                      activeSelectionRef.apply(_ => ({type: "element-vertex", elementID: value.id, vertexIndex: newVertexIndex}));
                      return [{type: "wall", operations: [{
                          type: "update-graph", operations
                      }]}];
                    });
                    ev.preventDefault();
                    ev.stopPropagation();
                    return;
                  }
                }
              }}/>
            </Fragment>
          )}
          {value.graph.vertices.map((vertex, vertexIndex) =>
            <VertexShader
              key={vertexIndex}
              origin={Vector2.add(value.origin, vertex.coordinate)}
              opacity={activeVertex === vertexIndex ? 1 : 0.75}
              draggable={activeVertex === vertexIndex}
              onClick={(event) => {
                if (event.button !== 0) return;
                event.preventDefault();
                event.stopPropagation();
                activeSelectionRef.apply(prev => (prev?.type === "element-vertex" && prev.elementID === value.id && prev.vertexIndex === vertexIndex)
                  ? ({type: "element", elementID: value.id})
                  : ({type: "element-vertex", elementID: value.id, vertexIndex: vertexIndex})
                );
              }}
              onDragMove={(event, _startPos, newPosition) => {
                const newLocalPosition = Vector2.subtract(newPosition, value.origin);
                const snapPos = !event.shiftKey ? Grid.snap(grid, newLocalPosition) : newLocalPosition;
                setSpeculativeOperations(WallGraphFn.moveVertex(value.graph, vertexIndex, snapPos))
              }}
              onDragEnd={(event, _startPos, newPosition) => {
                access.element(value.id).apply((prev): NodeOperation[] => {
                  if (prev?.type !== "wall") return [];
                  if (vertexIndex >= prev.data.graph.vertices.length) return [];
                  const newLocalPosition = Vector2.subtract(newPosition, prev.data.origin);
                  const snapPos = !event.shiftKey ? Grid.snap(grid, newLocalPosition) : newLocalPosition;
                  return [{type: "wall", operations: [{
                    type: "update-graph", operations: WallGraphFn.moveVertex(prev.data.graph, vertexIndex, snapPos)
                  }]}]
                });
                setSpeculativeOperations([]);
              }}
            />
          )}

          <interaction
              onMouseDown={ev => {
                if (ev.button !== 0) return;
                if (!ev.ctrlKey) {
                  setStartPos(undefined);
                  return;
                }
                const initialPos = activeVertex !== undefined ? value.graph.vertices[activeVertex].coordinate : startPos;
                const newPos = getWorldPositionFromScreenPosition(view, getScreenPosition(canvas, [ev.clientX, ev.clientY]));
                const snapPos = !ev.shiftKey ? Grid.snap(grid, newPos) : newPos;
                const localPos = Vector2.divideTransform(snapPos, valueModel);
                if (initialPos === undefined) {
                  setStartPos(localPos);
                } else {
                  access.element(value.id).apply((prev): NodeOperation[] => {
                    if (prev?.type !== "wall") return [];
                    const operations = WallGraphFn.insertLine(prev.data.graph, initialPos, localPos);
                    const newGraph = applyAll(wallGraphType, prev.data.graph, operations);
                    const newVertexIndex = newGraph.vertices.findIndex(vertex => PointFn.equals(vertex.coordinate, localPos));
                    setStartPos(undefined);
                    activeSelectionRef.apply(_ => ({type: "element-vertex", elementID: value.id, vertexIndex: newVertexIndex}));
                    return [{type: "wall", operations: [{
                      type: "update-graph", operations
                    }]}]
                  });
                  setStartPos(localPos);
                }
                ev.preventDefault();
                ev.stopPropagation();
              }}/>
          {startPos && activeVertex === undefined && <VertexShader origin={startPos} opacity={1.0}/>}
          {activeEdge !== undefined && activeEdge < value.graph.edges.length && <WallElementEdgeControlPointsHUDPass
              elementID={value.id}
              origin={value.origin}
              color={value.color}
              graph={value.graph}
              edgeIndex={activeEdge}
              speculative={speculativeGraph}
              setSpeculative={setSpeculativeOperations}
          />}
          {activeEdge !== undefined && activeEdge < value.graph.edges.length && <WallElementEdgeControls elementID={value.id} graph={speculativeGraph} edgeIndex={activeEdge} />}
        </>}
        {[...value.children].reverse().map((element) => <ElementHUDPass key={element.data.id} value={element}/>)}
      </ModelProvider>
    </OpacityProvider>
  </binder>);
}