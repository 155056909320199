import {NumberFn, NumberOperation} from "../number/index.ts";
import {Size} from "./size.ts";

export type UpdateWidthSizeOperation = {
  type: "update-width";
  operations: NumberOperation[];
};
export type UpdateHeightSizeOperation = {
  type: "update-height";
  operations: NumberOperation[];
};

export type SizeOperation =
  | UpdateWidthSizeOperation
  | UpdateHeightSizeOperation
  ;
export const SizeFn = {
  set: (prev: Size, next: Size): SizeOperation[] => [
    {type: "update-width", operations: NumberFn.set(prev[0], next[0])},
    {type: "update-height", operations: NumberFn.set(prev[1], next[1])}
  ]
};