import {BooleanOperation, booleanType, FileReference, FileReferenceOperation, fileReferenceType, ObjectType, StringOperation, stringType} from "#common/types/index.ts";
import {MutableRef} from "#common/ref";

export type Track = {
  name: string;
  enable: boolean;
  file: FileReference;
};

export type TrackOperation =
  | {type: "update-name", operations: StringOperation[]}
  | {type: "update-enable", operations: BooleanOperation[]}
  | {type: "update-file", operations: FileReferenceOperation[]}
  ;

export const trackType = new ObjectType({
  name: stringType,
  enable: booleanType,
  file: fileReferenceType
});

export const TrackFn = {
  expand(valueRef: MutableRef<Track, TrackOperation[]>) {
    return ({
      nameRef: valueRef.map<string, StringOperation[]>(value => value.name, (_, operations) => [{type: "update-name", operations}]),
      enableRef: valueRef.map<boolean, BooleanOperation[]>(value => value.enable, (_, operations) => [{type: "update-enable", operations}]),
      fileRef: valueRef.map<FileReference, FileReferenceOperation[]>(value => value.file, (_, operations) => [{type: "update-file", operations}]),
    })
  }
} as const;

