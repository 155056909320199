import {Vector2} from "common/math/vector/vector2.ts";
import {Spline, SplineFn} from "common/types/generic/spline/index.ts";
import {WallLineFn} from "../../scene/scene-view.tsx";
import {Color} from "common/types/generic/index.ts";

export function distanceToArea(point: Vector2, area: Spline): number {
  if (isPointInArea(point, area)) return 0;
  const areaLines = WallLineFn.toLines(SplineFn.getLines(area), false, false, false, false, Color.WHITE);
  return Math.min(...areaLines.map(line => Vector2.distanceToLine(point, line.start, line.end)));
}

export function isPointInArea(point: Vector2, area: Spline): boolean {
  const areaLines = WallLineFn.toLines(SplineFn.getLines(area), false, false, false, false, Color.WHITE);
  let intersections = 0;
  for (let edgeIndex = 0; edgeIndex < areaLines.length; edgeIndex ++) {
    const line = areaLines[edgeIndex];
    const a = Vector2.lineIntersect(point, Vector2.add(point, [32, 0]), line.start, line.end);
    const b = Vector2.lineIntersect(line.start, line.end, point, Vector2.add(point, [32, 0]));
    if (a <= 0 && b >= 0 && b <= 1) {
      intersections++;
    }
  }
  return (intersections % 2 === 1);
}
