import {MultiType, Optional, Point, ShapeFn, Size, Type} from "../../../types/index.ts";
import {AudioShapePointSource, AudioShapePointSourceOperation, AudioShapePointSourceType, audioShapePointSourceType} from "./audio-shape-point-source.ts";
import {z} from "zod";
import {AudioShapeGlobal, AudioShapeGlobalOperation, audioShapeGlobalType, AudioShapeGlobalType} from "./audio-shape-global.ts";
import {AudioShapeFreeform, AudioShapeFreeformOperation, AudioShapeFreeformType, audioShapeFreeformType} from "./audio-shape-freeform.ts";

export const AudioShape = z.discriminatedUnion("type", [
  z.object({type: z.literal("global"), data: AudioShapeGlobal}),
  z.object({type: z.literal("point-source"), data: AudioShapePointSource}),
  z.object({type: z.literal("freeform"), data: AudioShapeFreeform})
]);
export type AudioShape = z.infer<typeof AudioShape>;

export const AudioShapeOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("global"), operations: z.array(AudioShapeGlobalOperation)}),
  z.object({type: z.literal("point-source"), operations: z.array(AudioShapePointSourceOperation)}),
  z.object({type: z.literal("freeform"), operations: z.array(AudioShapeFreeformOperation)})
]);
export type AudioShapeOperation = z.infer<typeof AudioShapeOperation>;

export type AudioShapeTypes = {
  global: AudioShapeGlobalType;
  pointSource: AudioShapePointSourceType;
  freeform: AudioShapeFreeformType;
};
export type AudioShapeType = Type<AudioShape, AudioShapeOperation>;
export const audioShapeType: AudioShapeType = new MultiType<AudioShapeTypes>({
  global: audioShapeGlobalType,
  pointSource: audioShapePointSourceType,
  freeform: audioShapeFreeformType
});

export type AudioShapes = {
  "global": AudioShapeGlobalType;
  "pointSource": AudioShapePointSourceType;
  "freeform": AudioShapeFreeformType;
};

export const AudioShapeFn = {
  getAudioOrigin: (shape: Optional<AudioShape>, origin: Point): Point => {
    if (shape?.type === "global") {
      return [0, 0];
    } else if (shape?.type === "point-source") {
      return ShapeFn.getShapeOrigin({
        type: "arc",
        data: {
          radius: shape.data.radius + shape.data.falloff,
          startAngle: -180,
          endAngle: 180
        }
      }, origin);
    }
    return origin;
  },
  getAudioSize: (shape: Optional<AudioShape>): Size => {
    if (shape?.type === "global") return [0, 0];
    else if (shape?.type === "point-source") return ShapeFn.getShapeSize({
      type: "arc",
      data: {
        radius: shape.data.radius + shape.data.falloff,
        startAngle: -180,
        endAngle: 180,
      }
    });
    return [16, 16];
  }
};