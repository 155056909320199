import {useObservable} from "#lib/qlab/index.ts";
import {pipe} from "common/pipe";
import {combine, distinct, from, map, Observable, switchAll} from "common/observable";
import {SelectionRef} from "../nav/editor/state/selection-ref.ts";
import {useEditor} from "../nav/editor/editor-context.ts";
import {EditorState} from "../nav/editor/state";
import {selectionRefObservable} from "../nav/editor/state/use-selection-ref-observable.ts";
import {useAccess} from "../../../routes/game/model/store-context.tsx";
import {fromSignal} from "common/ref";
import {LegendsAccess} from "common/access";
import {computed} from "common/signal";

export function useSelectionRef(pinnedSelectionRef: SelectionRef) {
  const editor = useEditor();
  return useObservable(pipe(
    pinnedSelectionRefObservable(editor.state.observe, pinnedSelectionRef)
  ), pinnedSelectionRef, [editor.state, pinnedSelectionRef]);
}

export function pinnedSelectionRefObservable(editorState: Observable<EditorState>, pinnedSelectionRef: SelectionRef): Observable<SelectionRef> {
  return pipe(
    combine(
      from(pinnedSelectionRef),
      selectionRefObservable(editorState)
    ),
    map(([a, b]) => a || b),
    distinct(SelectionRef.equals)
  );
}

export function useSelectionRefName(reference: SelectionRef): string | undefined {
  const access = useAccess();
  return useObservable(pipe(
    from(reference),
    selectionRefNameObservable(access)
  ), undefined, [access, reference]);
}

export function selectionRefNameObservable(access: LegendsAccess) {
  return (reference: Observable<SelectionRef>) => pipe(
    reference,
    map((reference): Observable<string | undefined> => {
      if (reference?.type === "asset-token") {
        return fromSignal(computed(() => {
          return access.assetToken(reference.assetID, reference.tokenID).value?.name;
        })).observe;
      } else if (reference?.type === "element" || reference?.type === "element-edge" || reference?.type === "element-vertex") {
        return fromSignal(computed(() => {
          return access.element(reference.elementID).value?.data.name;
        })).observe;
      } else if (reference?.type === "scene") {
        return fromSignal(computed(() => {
          return access.scene(reference.sceneID).value?.name;
        })).observe;
      } else {
        return from(undefined)
      }
    }),
    switchAll(),
    distinct()
  );
}
