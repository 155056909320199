import {InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {TokenVision, TokenVisionFn, TokenVisionOperation} from "common/legends/asset/token/token-vision.ts";
import React, {useMemo} from "react";
import {ButtonBar, ExpandableLabel, IconButton, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {InputString} from "#lib/components/input/input-string.tsx";
import {VisionField} from "../../../field/vision-field.tsx";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {Field, FieldLabel, SectionHeader} from "#lib/components/panel-header.tsx";
import {FaCopy} from "react-icons/fa";
import {BooleanOperation, booleanType, ValueFn} from "common/types/generic/index.ts";
import {usePresent} from "../../../common/use-optional-signal.ts";
import {VisionLimitFields} from "./vision-limit-fields.tsx";
import {useUnwrapValueRef} from "../../../common/use-unwrap-value-ref.ts";
import {OffsetField} from "../node/offset-field.tsx";
import {InputSelectSense} from "#lib/components/input-mask/input-select-sense.tsx";
import {BooleanField} from "../../../field/generic/boolean-field.tsx";
import {StringField} from "#lib/components/input/string-field.tsx";

export function TokenVisionListItem({item, remove, duplicate}: InputListItemProps<TokenVision, TokenVisionOperation>) {
  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/token-vision", item, remove);

  const [expanded, toggleExpanded] = useToggle(false);
  const {nameRef, senseIDRef, grayscaleRef, limitRef, offsetRef} = useMemo(() => TokenVisionFn.expand(item), [item]);

  const limitedRef = useMemo(() => limitRef.map<boolean, BooleanOperation[]>(
    value => value !== undefined,
    (prev, operations) => {
      const next = operations.reduce(booleanType.apply, prev !== undefined);
      if ((prev !== undefined) === next) return [];
      return ValueFn.set(prev, next
        ? {
          distance: 64 * 6,
          falloff: 64,
          falloffStrength: 0.36
        }
        : undefined);
    }
  ), [limitRef]);

  const presentLimitRef = usePresent(useUnwrapValueRef(limitRef));


  const name = useRefValue(nameRef);
  return <div className="flex flex-col gap-0 bg-zinc-800/50">
    <ButtonBar ref={dragRefPreview}>
      <IconButton ref={dragHandlerRef} title="Move" className="bg-zinc-900/80"><DragIndicator /></IconButton>
      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <SectionHeader className="flex-1 cursor-pointer bg-zinc-800/50" onClick={toggleExpanded}>{name}</SectionHeader>
      </ExpandableLabel>
      {duplicate && <IconButton title="Duplicate" onClick={duplicate}><FaCopy /></IconButton>}
      <IconButton variant="destructive" title="Remove Vision" onClick={remove}>
        <FontAwesomeIcon icon={faTrash} />
      </IconButton>
    </ButtonBar>
    {expanded && <Fieldset>
      <StringField label="Name" valueRef={nameRef} />
      <OffsetField valueRef={offsetRef} />
      <Field>
        <FieldLabel onClick={(ev) => {ev.preventDefault(); return false;}}>Sense</FieldLabel>
        <InputSelectSense className="mx-2 rounded-md overflow-hidden" valueRef={senseIDRef} />
      </Field>
      <BooleanField label="Grayscale" valueRef={grayscaleRef} />
      <div className="flex flex-col gap-0 mx-2 rounded-md overflow-hidden bg-zinc-800/50">
          <InputGroup className="pl-0">
              <InputCheckbox value={limitedRef} />
              <InputGroupLabel>Limit Range</InputGroupLabel>
          </InputGroup>
        {presentLimitRef && <Fieldset>
          <VisionLimitFields valueRef={presentLimitRef} />
        </Fieldset>}
      </div>
    </Fieldset>}
  </div>
}