import {
  useArrayBuffer,
  useAttribLocation,
  useBindVertexArribArray,
  useElementArrayBuffer,
  useProgram, useRenderingContext,
  useShader,
  useUniformLocation,
  useVertexBuffer
} from "#lib/gl-react/index.ts";
import React, {MouseEventHandler, MouseEvent, useCallback, useMemo} from "react";
import {Color, HSLA} from "common/types/index.ts";
import {Matrix4f, Point} from "#lib/math/index.ts";
import {Line, WallLineFn} from "../../scene/scene-view.tsx";
import {usePVM} from "../context/pvm-context.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {cubicBezier} from "common/math/bezier/bezier.ts";
import {WallGraphEdge, WallGraphEdgeData} from "common/legends/node/index.ts";
import {getWorldPositionFromScreenPosition} from "../../tool/use-get-world-pos.ts";
import {getScreenPosition} from "../../tool/use-get-screen-pos.ts";
import {MouseInteraction, MouseInteractionProps} from "../../mouse-interaction.tsx";


const vertexShader = `#version 300 es
precision highp float;

in vec2 a_position;
in vec2 a_tex_coord;
in vec2 a_start;
in vec2 a_end;
in float a_left;
in float a_right;

uniform mat4 u_projection;
uniform mat4 u_view;
uniform mat4 u_model;

out vec2 fragCoord;

out vec2 v_tex_coord;
out vec2 v_start;
out vec2 v_end;
out float v_left;
out float v_right;

void main()
{
  gl_Position = u_projection * u_view * u_model * vec4(a_position, 0, 1);
  v_tex_coord = a_tex_coord;
  v_start = a_start;
  v_end = a_end;
  v_left = a_left;
  v_right = a_right;
}
`;

const fragmentShader = `#version 300 es
precision highp float;
precision highp sampler2DArray;

in vec2 v_tex_coord;
in vec2 v_start;
in vec2 v_end;
in float v_left;
in float v_right;

uniform vec4 u_color;

out vec4 outColor;

void main() {
  vec2 lineDir = v_end - v_start;
  vec2 perpDir = vec2(lineDir.y, -lineDir.x);
  vec2 dirToPt1 = v_start - v_tex_coord;
  float d = dot(normalize(perpDir), dirToPt1);

  float d1 = distance(v_start, v_tex_coord);
  float d2 = distance(v_end, v_tex_coord);

  vec2 intersection = v_tex_coord + d * normalize(perpDir);

  float r1 = distance(intersection, v_start);
  float r2 = distance(intersection, v_end);
  float rf = distance(v_start, v_end);
  float p = distance(intersection, v_tex_coord);
  
  bool isLeft = lineDir.x*(v_tex_coord.y - v_start.y) - lineDir.y*(v_tex_coord.x - v_start.x) > 0.;
  if (d1 <= 16. || d2 <= 16.) {
    outColor = vec4(u_color);
  } else if (r1 > rf || r2 > rf) {
    outColor = vec4(0.);
  } else if (p < 2.) {
    outColor = vec4(0., 0., 0., u_color.a);
  } else if (p < 12.) {
    outColor = u_color;
  } else if (p < 16.) {
    if (isLeft && v_left != 1.0) {
      outColor = vec4(0., 0., 0., u_color.a);
    } else if (!isLeft && v_right != 1.0) {
      outColor = vec4(0., 0., 0., u_color.a);
    } else {
      outColor = u_color;
    }
  }
}
`;

export type WallLineShaderProps = {
  opacity: number;

  start: Point;
  end: Point;
  resolution: number;
  controlPoint1: Point;
  controlPoint2: Point;
  data: WallGraphEdgeData;
  origin: Point;
  color: HSLA;
} & MouseInteractionProps;

export function WallEdgeShader({
  start, end, resolution, controlPoint1, controlPoint2, data,
  color, opacity, origin,
  ...props
}: WallLineShaderProps) {
  const {projection, view, model} = usePVM();
  const program = useProgram(
    useShader(WebGL2RenderingContext.VERTEX_SHADER, vertexShader),
    useShader(WebGL2RenderingContext.FRAGMENT_SHADER, fragmentShader)
  );
  const projectionLocation = useUniformLocation(program, "u_projection");
  const viewLocation = useUniformLocation(program, "u_view");
  const modelLocation = useUniformLocation(program, "u_model");
  const colorLocation = useUniformLocation(program, "u_color");

  const lines = WallLineFn.toLines(cubicBezier(start, end, controlPoint1, controlPoint2, resolution), !data.blockVisibilityLeft, !data.blockVisibilityRight, data.blockMovementLeft, data.blockMovementRight, data.tint);

  const vboArray = useMemo(() => {
    const vertices: number[] = [];
    for (const line of lines) {
      const [sx, sy] = Vector2.subtract(line.start, origin);
      const [ex, ey] = Vector2.subtract(line.end, origin);
      const x1 = Math.min(sx, ex);
      const x2 = Math.max(sx, ex);
      const y1 = Math.min(sy, ey);
      const y2 = Math.max(sy, ey);
      const [w, h] = [32, 32];
      vertices.push(...[
        -w/2 + x1, -h/2 + y1,  -w/2 + x1, -h/2 + y1, sx, sy, ex, ey, line.viewLeft ? 1 : 0, line.viewRight ? 1 : 0,
         w/2 + x2, -h/2 + y1,   w/2 + x2, -h/2 + y1, sx, sy, ex, ey, line.viewLeft ? 1 : 0, line.viewRight ? 1 : 0,
         w/2 + x2,  h/2 + y2,   w/2 + x2,  h/2 + y2, sx, sy, ex, ey, line.viewLeft ? 1 : 0, line.viewRight ? 1 : 0,
        -w/2 + x1,  h/2 + y2,  -w/2 + x1,  h/2 + y2, sx, sy, ex, ey, line.viewLeft ? 1 : 0, line.viewRight ? 1 : 0
      ]);
    }
    return new Float32Array(vertices);
  }, [lines, origin]);

  const vbo = useArrayBuffer(vboArray);
  const vao = useVertexBuffer();
  useBindVertexArribArray(vao, useAttribLocation(program, "a_position"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 10 * 4, 0);
  useBindVertexArribArray(vao, useAttribLocation(program, "a_tex_coord"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 10 * 4, 2 * 4);

  useBindVertexArribArray(vao, useAttribLocation(program, "a_start"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 10 * 4, 4 * 4);
  useBindVertexArribArray(vao, useAttribLocation(program, "a_end"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 10 * 4, 6 * 4);
  useBindVertexArribArray(vao, useAttribLocation(program, "a_left"), vbo, 1, WebGL2RenderingContext.FLOAT, false, 10 * 4, 8 * 4);
  useBindVertexArribArray(vao, useAttribLocation(program, "a_right"), vbo, 1, WebGL2RenderingContext.FLOAT, false, 10 * 4, 9 * 4);

  const eboArray = useMemo(() => {
    const eboArray = [];
    for (let i = 0; i < vboArray.length / 40; i ++) {
      const o = i*4;
      eboArray.push(...[
        o+0, o+1, o+2,
        o+2, o+3, o+0
      ]);
    }
    return new Uint16Array(eboArray)
  }, [vboArray]);
  const ebo = useElementArrayBuffer(eboArray);

  const projectionMatrix4f = useMemo(() => new Float32Array(projection), [projection]);
  const viewMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(view)), [view]);
  const modelMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(model)), [model]);
  const color4f = useMemo(() => new Float32Array(Color.toRGBA([color[0], color[1], color[2], opacity] as HSLA)), [color, opacity]);

  return (
    <MouseInteraction isInBounds={(screenPos) => {
      const worldPos = getWorldPositionFromScreenPosition(view, screenPos);
      const localPos = Vector2.divideTransform(worldPos, model);

      return lines.some(line => {
        const t = Vector2.pointIntersect(line.start, line.end, localPos);
        const intersection = Vector2.lerp(line.start, line.end, t);
        if (t < 0.001 || t > 0.999) return false;
        return Vector2.distance(intersection, localPos) <= 16;
      })
    }} {...props}>
      <program value={program}>
        <uniformMat4fv location={projectionLocation} transpose={true} data={projectionMatrix4f}/>
        <uniformMat4fv location={viewLocation} transpose={true} data={viewMatrix4f}/>
        <uniformMat4fv location={modelLocation} transpose={true} data={modelMatrix4f}/>
        <uniform4fv location={colorLocation} data={color4f}/>

        <vertexArray value={vao}>
          <elementArrayBuffer value={ebo}>
            <drawElements mode={WebGL2RenderingContext.TRIANGLES} type={WebGL2RenderingContext.UNSIGNED_SHORT} offset={0} count={eboArray.length}/>
          </elementArrayBuffer>
        </vertexArray>
      </program>
    </MouseInteraction>
  );
}