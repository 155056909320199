import {useGame} from "../../../../../routes/game";
import {useCallback, useMemo} from "react";
import {GameSignals} from "common/legends/game/index.ts";
import {InputDropdown} from "#lib/components/dropdown/input-dropdown.tsx";
import {StageID} from "common/legends/stage/stage-i-d.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {ValueFn} from "common/types/generic/index.ts";

export function CurrentStageControl() {
  const gameRef = useGame();
  const {globalStageIDRef, stagesRef} = useMemo(() => GameSignals(gameRef), [gameRef]);

  const value = useRefValue(globalStageIDRef);
  const stages = useRefValue(stagesRef);

  const setValue = useCallback((stageID: StageID) => {
    globalStageIDRef.apply(prev => ValueFn.set(prev, stageID));
  }, [globalStageIDRef]);

  if (stages.length === 0) return <></>

  return <InputDropdown
    size="small"
    className="mx-2 rounded-md overflow-hidden"
    placeholder="Current Stage"
    title="Current Stage"
    value={value}
    options={[
      {
        value: "ALL",
        label: "None"
      },
      ...stages.map(stage => ({
        value: stage.stageID,
        label: stage.name
      }))]}
    onChange={e => setValue(e.target.value as StageID)}
  />
}