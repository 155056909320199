import React, {useCallback} from "react";
import {Button, Spacer} from "#lib/components/index.ts";
import {GameCard} from "./game-card.tsx";
import {Background} from "../background.tsx";
import {Dialog} from "@headlessui/react";
import {useCreateGame} from "../game/index.ts";
import {useAddGameId, useRemoveGameId} from "./use-user-session.ts";
import {useStore} from "#lib/qlab/react/hooks/store/use-store.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {useApplyToStore} from "#lib/qlab/index.ts";
import {FaPlus} from "react-icons/fa";

export function UserHomePage() {
  const userID = useUserID()!;
  const user = useStore("user", userID);
  const applyToUser = useApplyToStore("user", userID);

  const createGameCallback = useCreateGame();

  const addGameId = useAddGameId(applyToUser);
  const removeGameId = useRemoveGameId(applyToUser);

  const createNewGame = useCallback(() => {
    createGameCallback("New Game").then((newGameID) => {
      addGameId(newGameID);
    });
  }, [addGameId, createGameCallback])

  return (
    <Background>
      <Dialog open onClose={() => {}} className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-start justify-center p-4 pt-[10vh]">
          <Dialog.Panel className="w-full max-w-screen-lg rounded-lg shadow-md backdrop-blur bg-slate-800/50 text-white flex flex-col gap-2 pb-4">
            <Dialog.Title className={"flex gap-2 p-4"}>
              <Button type="button" variant="tertiary" onClick={() => {
                location.href = `${import.meta.env.VITE_COGNITO_MANAGED_UI}/logout?client_id=${import.meta.env.VITE_COGNITO_CLIENT_ID}&logout_uri=${window.location.protocol}//${window.location.hostname}/sign-out`
              }}>Sign Off</Button>
              <Spacer />
              <Button type="button" onClick={createNewGame}><FaPlus /> New Game</Button>
            </Dialog.Title>

            {user.isSuccess && <div className={"px-4"}>
              {user.data.gameIds.length === 0
                ? (<div className="flex flex-col h-[140px] items-center justify-around">
                  <div><span>You don't have any games.</span></div>
                  <div><Button onClick={createNewGame}><FaPlus /> New Game</Button></div>
                </div>)
                : (<div className="w-full justify-around items-center grid gap-y-5 gap-x-5 auto-rows-[140px]" style={{gridTemplateColumns: "repeat(auto-fill, 240px)"}}>
                  {user.data.gameIds.map(gameId => <GameCard key={gameId} gameId={gameId} onClick={() => {
                    removeGameId(gameId)
                  }} />)}
                </div>)}
            </div>}
        </Dialog.Panel>
      </div>
      </Dialog>
    </Background>
  );
}