import {useMemo} from "react";
import {computed} from "common/signal";
import {Optional} from "common/types/generic/index.ts";
import {ElementTypes, NodeId, NodeOperation} from "common/legends/node/index.ts";
import {useAccess} from "../../../routes/game/model/store-context.tsx";
import {ExtractOperation, ExtractValue} from "common/types/type/index.ts";

export function useTypedElementById<ElementType extends keyof ElementTypes>(type: ElementType, id: NodeId) {
  const access = useAccess();
  return useMemo(() => {
    const elementRef = access.element(id);
    return computed(
      (): Optional<ExtractValue<ElementTypes[ElementType]>> => {
        const element = elementRef.value;
        if (element?.type === type) return element.data as ExtractValue<ElementTypes[ElementType]>;
        return undefined;
      },
      (operations: ExtractOperation<ElementTypes[ElementType]>[]) => {
        elementRef.apply(prev => {
          if (prev?.type !== type) return [];
          return [{type: type, operations: operations}] as NodeOperation[]
        })
      }
    );
  }, [access, id]);
}

