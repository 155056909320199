import React from "react";
import {Color, Transform} from "common/types/index.ts";
import {CameraElement, cameraElementType} from "common/legends/node/camera-element.ts";
import {ModelProvider, useModel} from "../../viewport/common/context/pvm-context.ts";
import {useIsActiveSelection} from "../../viewport/common/context/use-is-active-selection.ts";
import {ElementHUDPass} from "../element-h-u-d-pass.tsx";
import {CircleShader} from "../../viewport/common/shader/circle-shader.tsx";
import {useIsTokenController} from "../../viewport/common/context/use-is-token-controller.ts";
import {SizeEditor} from "../common/size-editor.tsx";
import {useTypedElementById} from "../common/use-typed-element-by-id.ts";
import {useSpeculativeValue} from "../common/use-speculative-value.ts";
import {useSignalValue} from "#lib/signal/index.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {RotationEditor} from "../common/rotation-editor.tsx";

export function CameraElementHUDPass({value}: {
  value: CameraElement;
}) {
  const model = useModel();
  const valueModel = Transform.divide(value.transform, model);
  const isSelected = useIsActiveSelection(value.id);
  const isEditing = !useIsTokenController();

  const valueRef = useTypedElementById("camera", value.id);
  const speculativeValueRef = useSpeculativeValue(cameraElementType, valueRef);
  const speculativeValue = useSignalValue(speculativeValueRef);

  return (<binder>
    <ModelProvider value={valueModel}>
      {[...value.children].reverse().map((element) => <ElementHUDPass key={element.data.id} value={element} />)}
    </ModelProvider>
    {isSelected && isEditing && speculativeValue && <>
      <SizeEditor valueRef={valueRef} speculativeValueRef={speculativeValueRef} />
      <RotationEditor valueRef={valueRef} speculativeValueRef={speculativeValueRef} />
      <ModelProvider value={Transform.divide(speculativeValue.transform, model)}>
        <CircleShader origin={Vector2.add(Vector2.subtract(speculativeValue.origin, speculativeValue.pivot), [2,2])} size={[4,4]} color={Color.BLACK75} />
        <CircleShader origin={Vector2.add(Vector2.subtract(speculativeValue.origin, speculativeValue.pivot), [1,1])} size={[2,2]} color={Color.GREEN} />
      </ModelProvider>
    </>}
  </binder>);
}