import {computed} from "common/signal";
import {usePlayerController} from "../../panel/nav/player/player-controller-provider.ts";
import {Optional, Transform, TransformOperation, transformType, Tree, TreePath, ValueFn} from "common/types/generic/index.ts";
import {useMemo} from "react";
import {useAccess} from "../../../routes/game/model/store-context.tsx";
import {fromSignal} from "common/ref";
import {Node, NodeId} from "common/legends/node/index.ts";
import {AssetID, TokenID} from "common/legends/asset/index.ts";
import {LegendsAccess} from "common/access";
import {applyAll} from "common/types/type/index.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {windowSize} from "../window-size.ts";
import {CameraElement} from "common/legends/node/camera-element.ts";

function getCameraTransform(camera: CameraElement): Transform {
  const [ww, wh] = windowSize.value;
  const [cw, ch] = camera.size;

  const adjustScale = Math.min(
    cw / (ww),
    ch / (wh)
  );
  const [ox, oy] = camera.origin;
  return {
    position: [cw/2 - ox, ch/2 - oy],
    rotation: 0,
    scale: adjustScale
  };
}

function getWorldTransform(elements: Node[], path: TreePath) {
  let transform = Transform.DEFAULT;
  for (let i = 0; i < path.length; i ++) {
    transform = Transform.divide(elements[path[i]].data.transform, transform)
    elements = elements[path[i]].data.children;
  }
  return transform;
}

function TokenCamera(access: LegendsAccess) {
  return (assetID: AssetID, tokenID: TokenID) => computed((): Optional<CameraElement> => {
    const token = access.assetToken(assetID, tokenID).value;
    if (!token) return undefined;
    const cameraPath = Tree.getPath(token.children, node => node.type === "camera");
    if (cameraPath === undefined) return undefined;
    const camera = Tree.getItemByPath(token.children, cameraPath);
    if (camera?.type === "camera") return camera.data;
    return undefined;
  });
}

function TokenCameraTransform(access: LegendsAccess) {
  return (assetID: AssetID, tokenID: TokenID) => computed(() => {
    const token = access.assetToken(assetID, tokenID).value;
    if (!token) return undefined;
    const cameraPath = Tree.getPath(token.children, node => node.type === "camera");
    if (cameraPath === undefined) return undefined;

    const camera = Tree.getItemByPath(token.children, cameraPath);
    if (camera?.type !== "camera") return undefined;
    return Transform.divide(getCameraTransform(camera.data), getWorldTransform(token.children, cameraPath));
  });
}

function NodeTransform(access: LegendsAccess) {
  return (nodeID: NodeId) => computed(() => {
    const path = access.elementPath(nodeID).value;
    if (path?.type === "scene-node") {
      const scene = access.scene(path.sceneID).value;
      if (scene) return getWorldTransform(scene.children, path.path);
    } else if (path?.type === "asset-node") {
      const token = access.assetToken(path.assetID, path.tokenID).value;
      if (token) return getWorldTransform(token.children, path.path);
    }
    return undefined;
  });
}

function NodeCameraTransform(access: LegendsAccess) {
  const getNodeTransform = NodeTransform(access);
  const getTokenCameraTransform = TokenCameraTransform(access);
  return (elementID: NodeId) => computed(() => {
    const nodeTransform = getNodeTransform(elementID).value;
    if (nodeTransform === undefined) return undefined;

    const node = access.element(elementID).value;
    if (node?.type === "token") {
      const cameraTransform = getTokenCameraTransform(node.data.tokenReference.assetID, node.data.tokenReference.tokenID).value;
      if (cameraTransform === undefined) return undefined;
      return Transform.divide(cameraTransform, nodeTransform);
    } else if (node?.type === "camera") {
      const frame = getCameraTransform(node.data);
      return Transform.divide(frame, nodeTransform);
    } else {
      return undefined;
    }
  });
}

function NodeCamera(access: LegendsAccess) {
  const getTokenCamera = TokenCamera(access);
  return (elementID: NodeId) => computed((): Optional<CameraElement> => {
    const node = access.element(elementID).value;
    if (node?.type === "token") {
      return getTokenCamera(node.data.tokenReference.assetID, node.data.tokenReference.tokenID).value;
    } else if (node?.type === "camera") {
      return node.data;
    } else {
      return undefined;
    }
  });
}

export function useActiveControllerView() {
  const playerControllerRef = usePlayerController();
  const access = useAccess();
  return useMemo(() => {
    const getNodeCameraTransform = NodeCameraTransform(access);
    const getNodeCamera = NodeCamera(access);
    const valueFn = () => {
      if (playerControllerRef) {
        const playerController = playerControllerRef.state.value;
        if (!playerController.activeController) return Transform.DEFAULT;
        const elementID = playerController.activeController.controllerNodeID;
        const camera = getNodeCamera(elementID).value;
        let cameraTransform = getNodeCameraTransform(elementID).value;
        if (camera && cameraTransform) {
          cameraTransform = {
            ...cameraTransform,
            position: Vector2.rotate(cameraTransform.position, -cameraTransform.rotation * Math.PI / 180),
          };
          return Transform.invert(cameraTransform);
        } else {
          return playerController.activeController.view;
        }
      }
      return Transform.DEFAULT;
    };

    return fromSignal(computed(
      valueFn,
      (operations: TransformOperation[]) => {
        if (playerControllerRef) {
          playerControllerRef.state.apply(playerController => {
            const prev = valueFn();
            let result = applyAll(transformType, prev, operations);
            if (playerController.activeController) {
              const cameraTransform = getNodeCameraTransform(playerController.activeController.controllerNodeID).value;
              if (cameraTransform) return [];
            }

            return [{
              type: "update-active-controller",
              operations: ValueFn.apply([{
                type: "update-view",
                operations: ValueFn.set(prev, result)
              }])
            }];
          });
        }
      }
    ))
  }, [playerControllerRef, access]);
}
