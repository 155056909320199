import {twMerge} from "tailwind-merge";
import {FaUser} from "react-icons/fa";
import {NodeId, UserID} from "common/legends/index.ts";
import {useFromObservable} from "#lib/qlab/react/use-from-observable.ts";
import {useObservable} from "#lib/qlab/index.ts";
import React, {useMemo} from "react";
import {FileReference} from "common/types/index.ts";
import {usePlayerName} from "../../routes/game/use-player-name.ts";
import {usePlayerIcon} from "../../routes/game/use-player-icon.ts";
import {useNodeIcon} from "./use-get-node-icon.ts";
import {useIsGameMaster} from "./game/use-is-game-master.ts";
import {useDrag} from "react-dnd";
import {useAccess} from "../../routes/game/model/store-context.tsx";
import {fromSignal} from "common/ref";
import {useComputed} from "#lib/signal/react/use-computed.ts";

export type NodeIconProps = {
  userID: UserID;
  nodeID?: NodeId;
  icon?: FileReference;
  className?: string;
  size?: number;
  onClick?: () => void;
};

export function NodeIcon({userID, nodeID, className, icon, size = 20, onClick}: NodeIconProps) {
  const playerName = usePlayerName(userID);
  const access = useAccess();
  const nodeRef = useMemo(() => fromSignal(access.element(nodeID)), [nodeID]);

  const name = useComputed(() => nodeRef.value?.data.name, [nodeRef]);
  const displayIcon = useComputed(() => {
    if (nodeRef.value?.type !== "token") return false;
    return nodeRef.value.data.displayIcon;
  }, [nodeRef]);
  const displayName = useComputed(() => {
    if (nodeRef.value?.type !== "token") return false;
    return nodeRef.value.data.displayName;
  }, [nodeRef]);

  const nodeIcon = useObservable(useNodeIcon(useFromObservable(nodeID)), undefined, [nodeID]);
  const playerIcon = usePlayerIcon(userID);

  const isGameMaster = useIsGameMaster();

  const isRevealName = displayName || isGameMaster;
  const isRevealIcon = displayIcon || isGameMaster ? icon || nodeIcon || playerIcon : playerIcon;

  const [, dragRef] = useDrag(() => ({
    type: "legends/token",
    item: ({
      nodeID
    })
  }), [nodeID]);

  return (
    <div ref={dragRef} className={twMerge(
      "rounded-full bg-zinc-900 w-10 h-10 inline-flex items-center justify-center pointer-events-auto ring-1 ring-inset ring-zinc-900 overflow-hidden",
      nodeID && "cursor-pointer",
      className
    )} title={isRevealName && name ? `${name} (${playerName})` : playerName} onClick={onClick}>
      {isRevealIcon && <img draggable={false} alt={isRevealName ? `${name} (${playerName})` : playerName} src={isRevealIcon} crossOrigin="anonymous" className="w-full h-full" />}
      {!isRevealIcon && <FaUser size={size} />}
    </div>
  );
}
