import {NameField} from "../../field/name-field.tsx";
import {VisibleField} from "../../field/visible-field.tsx";
import {TransformField} from "../../field/transform-field.tsx";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {useMemo} from "react";
import {TextNode, TextNodeOperation, TextNodeSignals} from "common/legends/node/text-node.ts";
import {ColorField} from "#lib/components/input/color-field.tsx";
import {MutableRef} from "common/ref";
import {InputString} from "#lib/components/input/input-string.tsx";
import {InputGroup, InputNumber} from "#lib/components/index.ts";
import {InputHorizontalAlignment} from "../../panel/properties/node/input-horizontal-alignment.tsx";
import {InputVerticalAlignment} from "../../panel/properties/node/input-vertical-alignment.tsx";
import {SelectionField} from "../../field/selection-field.tsx";
import {PivotField} from "../../field/pivot-field.tsx";
import {ConditionsSection} from "../../panel/properties/node/conditions/conditions-section.tsx";
import {SelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {Field, FieldLabel, Panel, Section} from "#lib/components/panel-header.tsx";
import {useElementGrid} from "../../common/use-element-grid.ts";
import {GridProvider} from "../../viewport/common/context/grid-context.ts";

export function TextElementProperties({value, reference, pinned}: {
  value: MutableRef<TextNode, TextNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const {idRef, name, pivotRef, textRef, sizeRef, visibilityLayerRef, transformRef, selectionMaskRef, fillColorRef, outlineColorRef, hTextAlignRef, vTextAlignRef, conditionsRef} = useMemo(() => TextNodeSignals(value), [value]);
  const elementGrid = useElementGrid(idRef);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <GridProvider value={elementGrid}>
      <Panel title="Text Properties">
        <Section>
          <NameField value={name} reference={reference} pinned={pinned} />
          <VisibleField value={visibilityLayerRef} />
          <SelectionField value={selectionMaskRef} />
          <TransformField value={transformRef} />
          <PivotField valueRef={pivotRef} />
          <Field>
            <FieldLabel>Text</FieldLabel>
            <InputGroup className="mx-2 rounded-md overflow-hidden">
              <InputString value={textRef} />
            </InputGroup>
          </Field>
          <Field>
            <FieldLabel>Size</FieldLabel>
            <InputGroup className="mx-2 rounded-md overflow-hidden">
              <InputNumber value={sizeRef} />
            </InputGroup>
          </Field>
          <InputHorizontalAlignment value={hTextAlignRef} />
          <InputVerticalAlignment value={vTextAlignRef} />
          <ColorField label="Fill Color" value={fillColorRef} />
          <ColorField label="Outline Color" value={outlineColorRef} />
        </Section>
        <ConditionsSection value={conditionsRef} />
      </Panel>
    </GridProvider>
  </div>
}