import {
  ListPropertyRef,
  NumberOperation,
  numberType,
  ObjectType,
  Point,
  PointOperation,
  PropertyRef,
  Size,
  SizeOperation,
  sizeType,
  StringOperation,
  Transform,
  TransformOperation,
  Type
} from "../../types/index.ts";
import {VisibilityLayer, VisibilityLayerOperation} from "#common/legends/visibility/index.ts";
import {NodeCondition, NodeConditionOperation} from "#common/legends/node/condition/node-condition.ts";
import {Mask, MaskOperation} from "#common/types/generic/mask/mask.ts";
import {MutableRef} from "#common/ref";
import {LocalNode, LocalNodeOperation, localNodeTypePropTypes, localNodeUpdater} from "./local-node.ts";
import {NodeId} from "#common/legends/index.ts";

export type GroupNode = LocalNode & {
  opacity: number;
  size: Size;
};
export type GroupNodeOperation =
  | LocalNodeOperation
  | {type: "update-opacity", operations: NumberOperation[]}
  | {type: "update-size", operations: SizeOperation[]}
  ;

export const groupNodeType: Type<GroupNode, GroupNodeOperation> = new ObjectType(() => ({
  ...localNodeTypePropTypes(),
  opacity: numberType,
  size: sizeType
}), value => {
  value = localNodeUpdater(value);
  if (!value.conditions) value.conditions = [];
  if (value.selectable) {
    value.selectionMask = value.selectable ? 1 : 0;
    delete value["selectable"];
  }
  if (!value.selectionMask) value.selectionMask = 0;
  return value;
});

export const GroupNode = {
  DEFAULT: {
    name: "Group",
    tags: [],
    transform: Transform.DEFAULT,
    visibilityLayer: ["ALL"],
    opacity: 1,
    origin: [32, 32],
    pivot: [32, 32],
    size: [64, 64],
    selectionMask: ["GM"],
    children: [],
    conditions: []
  } as Omit<GroupNode, "id">,

  getName: (node: GroupNode) => node.name,
  updateName: (operations: StringOperation[]): GroupNodeOperation[] => [{type: "update-name", operations}],
  getVisibilityLayer: (node: GroupNode) => node.visibilityLayer,
  updateVisibilityLayer: (operations: VisibilityLayerOperation[]): GroupNodeOperation[] => [{type: "update-visibility-layer", operations}],
  getOpacity: (node: GroupNode) => node.opacity,
  updateOpacity: (operations: NumberOperation[]): GroupNodeOperation[] => [{type: "update-opacity", operations}],
  getOrigin: (node: GroupNode) => node.origin,
  updateOrigin: (operations: PointOperation[]): GroupNodeOperation[] => [{type: "update-origin", operations}],
  getSize: (node: GroupNode) => node.size,
  updateSize: (operations: SizeOperation[]): GroupNodeOperation[] => [{type: "update-size", operations}],
  getSelectionMask: (node: GroupNode) => node.selectionMask,
  updateSelectionMask: (operations: MaskOperation[]): GroupNodeOperation[] => [{type: "update-selection-mask", operations}],
  getTransform: (node: GroupNode) => node.transform,
  updateTransform: (operations: TransformOperation[]): GroupNodeOperation[] => [{type: "update-transform", operations}],
}

export const GroupNodeSignals = (value: MutableRef<GroupNode, GroupNodeOperation[]>) => ({
  idRef: value.map<NodeId>(value => value.id),
  name: PropertyRef<GroupNode, GroupNodeOperation, string, StringOperation>(
    value => value.name,
    operations => [{type: "update-name", operations}]
  )(value),
  size: PropertyRef<GroupNode, GroupNodeOperation, Size, SizeOperation>(
    value => value.size,
    operations => [{type: "update-size", operations}]
  )(value),
  visibilityLayer: PropertyRef<GroupNode, GroupNodeOperation, VisibilityLayer, VisibilityLayerOperation>(GroupNode.getVisibilityLayer, GroupNode.updateVisibilityLayer)(value),
  selectionMask: PropertyRef<GroupNode, GroupNodeOperation, Mask, MaskOperation>(
    value => value.selectionMask,
    operations => [{type: "update-selection-mask", operations}]
  )(value),
  opacity: PropertyRef<GroupNode, GroupNodeOperation, number, NumberOperation>(
    value => value.opacity,
    operations => [{type: "update-opacity", operations}]
  )(value),
  origin: PropertyRef<GroupNode, GroupNodeOperation, Point, PointOperation>(
    value => value.origin,
    operations => [{type: "update-origin", operations}]
  )(value),
  transform: PropertyRef<GroupNode, GroupNodeOperation, Transform, TransformOperation>(
    value => value.transform,
    operations => [{type: "update-transform", operations}]
  )(value),
  conditions: ListPropertyRef<GroupNode, GroupNodeOperation, NodeCondition, NodeConditionOperation>(
    value => value.conditions,
    operations => [{type: "update-conditions", operations}]
  )(value)
});
