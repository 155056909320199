import {NameField} from "../../field/name-field.tsx";
import {VisibleField} from "../../field/visible-field.tsx";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {Suspense, useMemo} from "react";
import {LightNode, LightNodeOperation, LightNodeRef} from "common/legends/node/light/light-node.ts";
import {MutableRef} from "common/ref";
import {TransformField} from "../../field/transform-field.tsx";
import {VisionField} from "../../field/vision-field.tsx";
import {SelectionField} from "../../field/selection-field.tsx";
import {OriginField} from "../../field/origin-field.tsx";
import {PivotField} from "../../field/pivot-field.tsx";
import {LightShapeButtonBar} from "../../panel/properties/node/image/light-shape-button-bar.tsx";
import {InputLightShape} from "../../panel/properties/node/image/input-light-shape.tsx";
import {useComputedValue} from "#lib/signal/index.ts";
import {ConditionsSection} from "../../panel/properties/node/conditions/conditions-section.tsx";
import {SelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {Field, FieldLabel, Panel, Section} from "#lib/components/panel-header.tsx";
import {NumberField} from "../../field/generic/number-field.tsx";
import {useElementGrid} from "../../common/use-element-grid.ts";
import {GridProvider} from "../../viewport/common/context/grid-context.ts";

export function LightElementProperties({valueRef, reference, pinned}: {
  valueRef: MutableRef<LightNode, LightNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const {idRef, name, originRef, pivotRef, visibilityLayerRef, selectionMaskRef, accessMaskRef, transformRef, shapeRef, blinkIntervalRef, blinkLengthRef, blinkOffsetRef, conditionsRef} = useMemo(() => LightNodeRef(valueRef), [valueRef]);
  const isShapeSelected = useComputedValue(shapeRef, shape => shape !== undefined);
  const elementGrid = useElementGrid(idRef);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <GridProvider value={elementGrid}>
      <Panel title="Light Properties">
        <Section>
          <NameField value={name} reference={reference} pinned={pinned} />
          <VisionField value={accessMaskRef} />
          <VisibleField value={visibilityLayerRef} />
          <SelectionField value={selectionMaskRef} />
          <TransformField value={transformRef} />
          <OriginField valueRef={originRef} />
          <PivotField valueRef={pivotRef} />
          <Field>
            <FieldLabel>Light</FieldLabel>
            <div className="mx-2 rounded-md overflow-hidden bg-zinc-800/50">
              <LightShapeButtonBar valueRef={shapeRef}/>
              {isShapeSelected && <Suspense fallback={<></>}>
                  <InputLightShape valueRef={shapeRef}/>
              </Suspense>}
            </div>
          </Field>
          <NumberField label="Blink Length" valueRef={blinkLengthRef} min={0} max={Number.POSITIVE_INFINITY} />
          <NumberField label="Blink Interval" valueRef={blinkIntervalRef} min={0} max={1} />
          <NumberField label="Blink Offset" valueRef={blinkOffsetRef} min={0} max={1} />
        </Section>
        <ConditionsSection value={conditionsRef}/>
      </Panel>
    </GridProvider>
  </div>
}