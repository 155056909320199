import React, {useEffect, useMemo} from "react";
import {Point, Transform} from "common/types/index.ts";
import {ModelProvider, useModel} from "../../viewport/common/context/pvm-context.ts";
import {AudioElement} from "common/legends/node/audio/audio-element.ts";
import {playManager} from "#lib/audio/play-manager.ts";
import {ElementAudioPass} from "../element-audio-pass.tsx";
import {Vector2} from "common/math/vector/vector2.ts";
import {useMasterVolume} from "../../common/master-volume/hooks/use-master-volume.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {distanceToArea} from "../../viewport/common/context/is-point-in-area.ts";

export function AudioElementAudioPass({value: {children, transform, origin: valueOrigin, volume, playlist, shape}, origin}: {
  value: AudioElement;
  origin: Point | undefined;
}) {
  const masterVolume = useRefValue(useMasterVolume());

  const model = useModel();
  const valueModel = Transform.divide(transform, model);

  const distance = useMemo(() => {
    if (shape.type === "global") {
      return 0;
    } else if (shape.type === "point-source") {
      if (origin === undefined) return Number.POSITIVE_INFINITY;
      const elementOrigin = Vector2.multiplyTransform([0, 0], valueModel);
      return Math.max(0, Vector2.distance(elementOrigin, origin) - shape.data.radius);
    } else if (shape.type === "freeform") {
      if (origin === undefined) return Number.POSITIVE_INFINITY;
      return Math.min(
        Number.POSITIVE_INFINITY,
        ...shape.data.areas.map(area => distanceToArea(origin, area))
      );
    } else {
      return Number.POSITIVE_INFINITY;
    }
  }, [valueModel, origin, valueOrigin, shape])

  const files = useMemo(() => {
    return playlist.filter(audioFile => audioFile.file !== undefined && audioFile.enable).map((audioFile) => {
      const audio = new Audio(audioFile.file);
      audio.muted = true;
      audio.loop = true;
      return audio;
    });
  }, [playlist]);

  const targetVolume = useMemo(() => {
    if (masterVolume.muted) return 0;
    if (volume * masterVolume.volume === 0) return 0;
    if (shape.type === "global") {
      return volume * masterVolume.volume;
    } else if (shape.type === "point-source" || shape.type === "freeform") {
      if (!Number.isFinite(distance)) return 0;
      if (distance > shape.data.falloff) {
        return 0;
      } else if (distance <= 0) {
        return volume * masterVolume.volume;
      } else {
        const attenuated = Math.pow(1 - distance / shape.data.falloff, shape.data.falloffStrength);
        return volume * attenuated * masterVolume.volume;
      }
    }  else {
      return 0;
    }
  }, [volume, masterVolume, distance, shape]);

  useEffect(() => {
    files.forEach(file => {
      file.muted = targetVolume === 0;
      file.volume = targetVolume;
    });
  }, [files, targetVolume]);

  useEffect(() => {
    const loadAudioFile = () => {
      if (!files.every(file => file.readyState >= 1)) return; // still loading
      const totalDuration = files.reduce((sum, file) => sum + file.duration, 0);
      let timeInPlaylist = (Date.now() / 1000) % totalDuration;

      // find current song
      for (let i = 0; i < files.length; i ++) {
        if (timeInPlaylist < files[i].duration) {
          files[i].currentTime = timeInPlaylist;
          files.forEach(file => {
            if (file !== files[i]) file.pause();
          });
          playManager.subscribe(() => files[i].play());
          break;
        }
        timeInPlaylist -= files[i].duration;
      }
    };

    files.forEach(file => {
      file.addEventListener("loadedmetadata", loadAudioFile);
      file.addEventListener("ended", loadAudioFile);
    });
    if (files.every(file => file.readyState >= 1)) {
      loadAudioFile();
    }

    return () => {
      files.forEach(file => {
        file.pause();
        file.removeEventListener("loadedmetadata", loadAudioFile);
        file.removeEventListener("ended", loadAudioFile);
      });
    };
  }, [files]);

  return (<binder>
    <ModelProvider value={valueModel}>
      {[...children].reverse().map((element) => <ElementAudioPass key={element.data.id} value={element} origin={origin} />)}
    </ModelProvider>
  </binder>);
}
