import {ImageNode, imageNodeType} from "common/legends/index.ts";
import React from "react";
import {Color, Transform} from "common/types/index.ts";
import {ElementHUDPass} from "../element-h-u-d-pass.tsx";
import {SelectionIndicator} from "../../viewport/common/selection-indicator/selection-indicator.tsx";
import {ModelProvider, useModel} from "../../viewport/common/context/pvm-context.ts";
import {OpacityProvider, useOpacity} from "../../viewport/common/context/opacity-context.ts";
import {useIsActiveSelection} from "../../viewport/common/context/use-is-active-selection.ts";
import {useIsTokenController} from "../../viewport/common/context/use-is-token-controller.ts";
import {SizeEditor} from "../common/size-editor.tsx";
import {FlipEditor} from "../common/flip-editor.tsx";
import {RotationEditor} from "../common/rotation-editor.tsx";
import {useTypedElementById} from "../common/use-typed-element-by-id.ts";
import {useSpeculativeValue} from "../common/use-speculative-value.ts";

export function ImageElementHUDPass({value}: {
  value: ImageNode;
}) {
  const model = useModel();
  const valueModel = Transform.divide(value.transform, model);
  const valueOpacity = value.opacity * useOpacity();
  const isSelected = useIsActiveSelection(value.id);
  const isEditing = !useIsTokenController();

  const valueRef = useTypedElementById("image", value.id);
  const speculativeValueRef = useSpeculativeValue(imageNodeType, valueRef);

  return (<binder>
    <OpacityProvider value={valueOpacity}>
      <ModelProvider value={valueModel}>
        {[...value.children].reverse().map((child) => <ElementHUDPass key={child.data.id} value={child} />)}
        {isSelected && !isEditing && <SelectionIndicator origin={value.origin} size={value.size} color={Color.WHITE} repeatX={value.repeatX} repeatY={value.repeatY} />}
      </ModelProvider>
      {isSelected && isEditing && <>
        <SizeEditor valueRef={valueRef} speculativeValueRef={speculativeValueRef} />
        <RotationEditor valueRef={valueRef} speculativeValueRef={speculativeValueRef} />
        <FlipEditor valueRef={valueRef} speculativeValueRef={speculativeValueRef} />
      </>}
    </OpacityProvider>
  </binder>);
}