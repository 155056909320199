import {NodeId} from "common/legends/index.ts";
import {useSelectedNodeIDs} from "./selected-node-i-ds.ts";
import {useMemo} from "react";
import {computed} from "common/signal";
import {useSignalValue} from "#lib/signal/index.ts";

export function useNothingSelected() {
  const selectedElementIDsRef = useSelectedNodeIDs();
  return useSignalValue(useMemo(() => computed(() => {
    const selectedElementIDs = selectedElementIDsRef.value;
    return (selectedElementIDs.length === 0);
  }), [selectedElementIDsRef]));
}

export function useIsActiveSelection(elementID: NodeId) {
  const selectedElementIDsRef = useSelectedNodeIDs();
  return useSignalValue(useMemo(() => computed(() => {
    const selectedElementIDs = selectedElementIDsRef.value;
    if (selectedElementIDs.length > 0) {
      const activeElementID = selectedElementIDs[0];
      if (activeElementID.type === "element" || activeElementID.type === "element-edge" || activeElementID.type === "element-vertex") {
        return activeElementID.elementID === elementID;
      }
    }
    return false;
  }), [selectedElementIDsRef, elementID]));
}

export function useActiveEdgeSelection(elementID: NodeId) {
  const selectedElementIDsRef = useSelectedNodeIDs();
  return useSignalValue(useMemo(() => computed(() => {
    const selectedElementIDs = selectedElementIDsRef.value;
    if (selectedElementIDs.length > 0) {
      const activeElementID = selectedElementIDs[0];
      if (activeElementID.type === "element-edge" && activeElementID.elementID === elementID) {
        return activeElementID.edgeIndex;
      }
    }
    return undefined;
  }), [selectedElementIDsRef, elementID]));
}

export function useActiveVertexSelection(elementID: NodeId) {
  const selectedElementIDsRef = useSelectedNodeIDs();
  return useSignalValue(useMemo(() => computed(() => {
    const selectedElementIDs = selectedElementIDsRef.value;
    if (selectedElementIDs.length > 0) {
      const activeElementID = selectedElementIDs[0];
      if (activeElementID.type === "element-vertex" && activeElementID.elementID === elementID) {
        return activeElementID.vertexIndex;
      }
    }
    return undefined;
  }), [selectedElementIDsRef, elementID]));
}
