import {Measurement} from "common/legends/measurement/index.ts";
import {PathMeasurementView} from "./path-measurement-view.tsx";
import {ConeMeasurementView} from "./cone-measurement-view.tsx";
import {Ref} from "common/ref";
import {useRefValue} from "#lib/signal/index.ts";
import {RectangleMeasurementView} from "./rectangle-measurement-view.tsx";
import {CircleMeasurementView} from "./circle-measurement-view.tsx";
import {BeamMeasurementView} from "./beam-measurement-view.tsx";
import {AssetTokenSelectionRef, SceneSelectionRef} from "../../../panel/nav/editor/state/selection-ref.ts";

export type MeasurementViewProps = {
  measurementRef: Ref<Measurement>;
  rootRef: SceneSelectionRef | AssetTokenSelectionRef;
};
export function MeasurementView({measurementRef, rootRef}: MeasurementViewProps) {
  const value = useRefValue(measurementRef);
  if (value?.type ==="path") return <PathMeasurementView measurement={value} rootRef={rootRef} />;
  if (value?.type ==="cone") return <ConeMeasurementView measurement={value} />;
  if (value?.type ==="rectangle") return <RectangleMeasurementView measurement={value} />;
  if (value?.type ==="circle") return <CircleMeasurementView measurement={value} />;
  if (value?.type ==="beam") return <BeamMeasurementView measurement={value} />;
  return <></>
}