import {Panel, Section} from "#lib/components/panel-header.tsx";
import {NameField} from "../../field/name-field.tsx";
import {TransformField} from "../../field/transform-field.tsx";
import {MutableRef} from "common/ref";
import {CameraElement, CameraElementFn, CameraElementOperation} from "common/legends/node/camera-element.ts";
import {SelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {ConstantOperation, ValueOperation} from "common/types/generic/index.ts";
import {useMemo} from "react";
import {SizeField} from "../../field/size-field.tsx";
import {OriginField} from "../../field/origin-field.tsx";
import {PivotField} from "../../field/pivot-field.tsx";
import {SelectionField} from "../../field/selection-field.tsx";
import {useElementGrid} from "../../common/use-element-grid.ts";
import {GridProvider} from "../../viewport/common/context/grid-context.ts";
import {OwnersField} from "../../field/owners-field.tsx";
import {StagesField} from "../../field/stages-field.tsx";
import {VisionField} from "../../panel/properties/asset/vision-field.tsx";
import {useIsAssetElement} from "../../common/use=is-asset-element.ts";
import {useRefValue} from "#lib/signal/index.ts";

export function CameraElementProperties({
  valueRef,
  pinned, reference
}: {
  valueRef: MutableRef<CameraElement, CameraElementOperation[]>,
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const {idRef, nameRef, sizeRef, originRef, selectionMaskRef, pivotRef, transformRef, ownerIDsRef, stageIDsRef, visionRef} = useMemo(() => CameraElementFn.expand(valueRef), [valueRef]);
  const elementGrid = useElementGrid(idRef);
  const isAssetElement = useIsAssetElement(useRefValue(idRef));


  return <div className="tab-content flex flex-col py-2 gap-1">
    <GridProvider value={elementGrid}>
      <Panel title="Camera Properties">
        <Section>
          <NameField value={nameRef} reference={reference} pinned={pinned} />
          <SelectionField value={selectionMaskRef} />
          <TransformField value={transformRef} />
          <OriginField valueRef={originRef} />
          <PivotField valueRef={pivotRef} />
          <SizeField valueRef={sizeRef} />
          {!isAssetElement && <VisionField valueRef={visionRef} />}
        </Section>
        {!isAssetElement && <Section title="Owners">
          <OwnersField value={ownerIDsRef} />
        </Section>}
        {!isAssetElement && <Section title="Stages">
          <StagesField valueRef={stageIDsRef} />
        </Section>}
      </Panel>
    </GridProvider>
  </div>
}