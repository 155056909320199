import {NameField} from "../../field/name-field.tsx";
import {VisibleField} from "../../field/visible-field.tsx";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {useMemo} from "react";
import {ParallaxNode, ParallaxNodeOperation, ParallaxNodeRef} from "common/legends/node/parallax-node.ts";
import {MutableRef} from "common/ref";
import {TransformField} from "../../field/transform-field.tsx";
import {SelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {Panel, Section} from "#lib/components/panel-header.tsx";
import {useElementGrid} from "../../common/use-element-grid.ts";
import {GridProvider} from "../../viewport/common/context/grid-context.ts";

export function ParallaxElementProperties({value, reference, pinned}: {
  value: MutableRef<ParallaxNode, ParallaxNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const {idRef, name, visibilityLayerRef, transformRef} = useMemo(() => ParallaxNodeRef(value), [value]);
  const elementGrid = useElementGrid(idRef);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <GridProvider value={elementGrid}>
      <Panel title="Parallax Properties">
        <Section>
          <NameField value={name} reference={reference} pinned={pinned} />
          <TransformField value={transformRef} />
          <VisibleField value={visibilityLayerRef} />
        </Section>
      </Panel>
    </GridProvider>
  </div>
}