import {MultiType, Optional, Point, ShapeFn, Size, Type} from "../../../types/index.ts";
import {LightShapeSpotlight, LightShapeSpotlightOperation, LightShapeSpotlightType, lightShapeSpotlightType} from "./light-shape-spotlight.ts";
import {LightShapeFreeform, LightShapeFreeformOperation, lightShapeFreeformType, LightShapeFreeformType} from "./light-shape-freeform.ts";
import {LightShapeSprite, LightShapeSpriteOperation, lightShapeSpriteType, LightShapeSpriteType} from "./light-shape-sprite.ts";
import {z} from "zod";
import {LightShapeGlobal, LightShapeGlobalOperation, lightShapeGlobalType, LightShapeGlobalType} from "./light-shape-global.ts";
import {Vector2} from "../../../math/vector/vector2.ts";
import {SplineFn} from "../../../types/generic/spline/index.ts";

export const LightShape = z.discriminatedUnion("type", [
  z.object({type: z.literal("freeform"), data: LightShapeFreeform}),
  z.object({type: z.literal("global"), data: LightShapeGlobal}),
  z.object({type: z.literal("spotlight"), data: LightShapeSpotlight}),
  z.object({type: z.literal("sprite"), data: LightShapeSprite}),
]);
export type LightShape = z.infer<typeof LightShape>;

export const LightShapeOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("freeform"), operations: z.array(LightShapeFreeformOperation)}),
  z.object({type: z.literal("global"), operations: z.array(LightShapeGlobalOperation)}),
  z.object({type: z.literal("spotlight"), operations: z.array(LightShapeSpotlightOperation)}),
  z.object({type: z.literal("sprite"), operations: z.array(LightShapeSpriteOperation)}),
]);
export type LightShapeOperation = z.infer<typeof LightShapeOperation>;

export type LightShapeTypes = {
  freeform: LightShapeFreeformType;
  global: LightShapeGlobalType;
  spotlight: LightShapeSpotlightType;
  sprite: LightShapeSpriteType;
};
export type LightShapeType = Type<LightShape, LightShapeOperation>;
export const lightShapeType: LightShapeType = new MultiType<LightShapeTypes>({
  freeform: lightShapeFreeformType,
  global: lightShapeGlobalType,
  spotlight: lightShapeSpotlightType,
  sprite: lightShapeSpriteType
});

export const LightShapeFn = {
  getLightOrigin: (shape: Optional<LightShape>, origin: Point): Point => {
    if (shape?.type === "global") {
      return [0, 0];
    } else if (shape?.type === "sprite") {
      return origin;
    } else if (shape?.type === "spotlight") {
      return ShapeFn.getShapeOrigin({
        type: "arc",
        data: {
          radius: shape.data.radius + shape.data.falloff,
          startAngle: -(shape.data.angle + shape.data.falloffAngle) / 2,
          endAngle: (shape.data.angle + shape.data.falloffAngle) / 2,
        }
      }, origin);
    } else if (shape?.type === "freeform") {
      return LightShapeFn.getFreeformOrigin(shape.data, origin);
    }
    return origin;
  },
  getLightSize(shape: Optional<LightShape>): Size {
    if (shape?.type === "global") return [0, 0];
    else if (shape?.type === "sprite") return shape.data.size;
    else if (shape?.type === "freeform") return LightShapeFn.getFreeformSize(shape.data);
    else if (shape?.type === "spotlight") return ShapeFn.getShapeSize({
      type: "arc",
      data: {
        radius: shape.data.radius + shape.data.falloff,
        startAngle: -(shape.data.angle + shape.data.falloffAngle) / 2,
        endAngle: (shape.data.angle + shape.data.falloffAngle) / 2,
      }
    });
    return [16, 16];
  },
  getFreeformOrigin(shape: LightShapeFreeform, origin: Vector2) {
    const points = shape.areas.flatMap(area => SplineFn.getLines(area, 8));
    return Vector2.add(Vector2.add([
      -Math.min(...points.map(p => p[0])),
      -Math.min(...points.map(p => p[1]))
    ], [shape.falloff, shape.falloff]), origin);
  },
  getFreeformSize(shape: LightShapeFreeform): Size {
    const points = shape.areas.flatMap(area => SplineFn.getLines(area, 8));
    const minX = Math.min(...points.map(p => p[0]));
    const maxX = Math.max(...points.map(p => p[0]));
    const minY = Math.min(...points.map(p => p[1]));
    const maxY = Math.max(...points.map(p => p[1]));
    return [
      maxX - minX + 2 * shape.falloff,
      maxY - minY + 2 * shape.falloff
    ];
  }
};