import {Point, PointOperation, PointSignals} from "common/types/index.ts";
import {useMemo} from "react";
import {MutableRef} from "common/ref";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";
import {GridWidthNumberField} from "./generic/grid-width-number-field.tsx";
import {GridHeightNumberField} from "./generic/grid-height-number-field.tsx";

export function PivotField({valueRef}: {
  valueRef: MutableRef<Point, PointOperation[]>;
}) {
  const {x, y} = useMemo(() => PointSignals(valueRef), [valueRef]);

  return (
    <Field>
      <FieldLabel>Pivot</FieldLabel>
      <div className="flex flex-row gap-0.5 mx-2 rounded-md overflow-hidden">
        <GridWidthNumberField icon="X" pt={{group: "mx-0", label: "px-2"}} valueRef={x} />
        <GridHeightNumberField icon="Y" pt={{group: "mx-0", label: "px-2"}} valueRef={y} />
      </div>
    </Field>
  );
}