import {NodeId, OptionalElementSignal} from "#common/legends/index.ts";
import {Cache} from "../cache.ts";
import {computed} from "#common/signal";
import {QLabDatabaseOperation, QLabDatabaseSignal, QLabResourceID} from "#common/qlab/index.ts";
import {ListOperation, Optional, Tree, TreeOperation, ValueFn} from "#common/types/index.ts";
import {ElementPathCache} from "#common/legends/access/resource/element-path-cache.js";

export type ElementCache = Cache<Optional<NodeId>, OptionalElementSignal>;
export function ElementCache(databaseRef: QLabDatabaseSignal, elementPathCache: ElementPathCache): ElementCache {
  return (elementID: Optional<NodeId>): OptionalElementSignal => {
    return computed(
      () => {
        if (elementID === undefined) return undefined;
        const elementPath = elementPathCache(elementID).value;
        const database = databaseRef.value;
        if (elementPath?.type === "scene-node") {
          const scene = database.resources[elementPath.sceneID];
          if (scene?.type !== "scene") return undefined;
          const element = Tree.getItemByPath(scene.data.children, elementPath.path);
          if (element?.data.id === elementID) return element;
        } else if (elementPath?.type === "asset-node") {
          const asset = database.resources[elementPath.assetID];
          if (asset?.type !== "asset") return undefined;
          const token = asset.data.tokens.find(token => token.tokenID === elementPath.tokenID);
          if (token === undefined) return undefined;
          const element = Tree.getItemByPath(token.children, elementPath.path);
          if (element?.data.id !== elementID) return undefined;
          return element;
        } else {
          return undefined;
        }
      },
      operations => {
        if (elementID === undefined) return;
        databaseRef.apply(database => {
          for (const [resourceID, resource] of Object.entries(database.resources)) {
            if (resource?.type === "scene") {
              const nodePath = Tree.getPath(resource.data.children, node => node.data.id === elementID);
              if (nodePath === undefined) continue;
              return [{
                type: "resource", resourceID: resourceID as QLabResourceID, operations: ValueFn.apply([{
                  type: "scene", operations: [{
                    type: "update-children", operations: TreeOperation.apply(nodePath, operations)
                  }]
                }])
              }] satisfies QLabDatabaseOperation[];
            } else if (resource?.type === "asset") {
              for (let tokenIndex = 0; tokenIndex < resource.data.tokens.length; tokenIndex ++) {
                const token = resource.data.tokens[tokenIndex];
                const path = Tree.getPath(token.children, node => node.data.id === elementID);
                if (path === undefined) continue;
                return [{
                  type: "resource", resourceID: resourceID as QLabResourceID, operations: ValueFn.apply([{
                    type: "asset", operations: [{
                      type: "update-tokens", operations: ListOperation.apply(tokenIndex, [{
                        type: "update-children", operations: TreeOperation.apply(path, operations)
                      }])
                    }]
                  }])
                }];
              }
            }
          }
          return [];
        });
      }
    );
  };
}
