import {
  useArrayBuffer,
  useAttribLocation,
  useBindVertexArribArray,
  useElementArrayBuffer,
  useProgram, useRenderingContext,
  useShader,
  useUniformLocation,
  useVertexBuffer
} from "#lib/gl-react/index.ts";
import React, {MouseEvent, MouseEventHandler, useCallback, useMemo} from "react";
import {Color, HSLA, Point} from "common/types/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {usePVM} from "../context/pvm-context.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {getScreenPosition} from "../../tool/use-get-screen-pos.ts";
import {getWorldPositionFromScreenPosition} from "../../tool/use-get-world-pos.ts";


const vertexShader = `#version 300 es
precision highp float;

in vec2 a_position;
in vec2 a_tex_coord;

uniform mat4 u_projection;
uniform mat4 u_view;
uniform mat4 u_model;

out vec2 fragCoord;
out vec2 v_tex_coord;

void main()
{
  gl_Position = u_projection * u_view * u_model * vec4(a_position, 0, 1);
  v_tex_coord = a_tex_coord;
}
`;

const fragmentShader = `#version 300 es
precision highp float;
precision highp sampler2DArray;

in vec2 v_tex_coord;
uniform vec2 u_start;
uniform vec2 u_end;
uniform float u_scale;
uniform vec4 u_color;

out vec4 outColor;

void main() {
  vec2 lineDir = u_end - u_start;
  vec2 pointDir = v_tex_coord - u_start;
  float t = dot(normalize(lineDir), pointDir);
  vec2 intersection = u_start + t * normalize(lineDir);
  
  float d = distance(u_start, intersection) / u_scale;
  float p = distance(intersection, v_tex_coord) / u_scale;
  float d1 = distance(u_start, v_tex_coord) / u_scale;
  float d2 = distance(u_end, v_tex_coord) / u_scale;

  bool left = lineDir.x*pointDir.y - lineDir.y*pointDir.x > 0.;

  float r1 = distance(intersection, u_start);
  float r2 = distance(intersection, u_end);
  float rf = distance(u_start, u_end);

  if (d1 <= 10. || d2 <= 10.) {
    outColor = u_color;
  } else if (r1 > rf || r2 > rf) {
    outColor = vec4(0.);
  } else {
    if (p < 4.) {
      outColor = u_color;
    } else if (p < 10.) {
      outColor = vec4(0., 0., 0., 0.75);
    } else {
      outColor = vec4(0.);
    }
  }
}
`;

export function StraightLineShader({origin, start, end, scale, color, onClick}: {
  origin: Point;
  start: Point;
  end: Point;
  scale: number;
  color: HSLA;
  onClick?: MouseEventHandler
}) {
  const {projection, model, view} = usePVM();
  const program = useProgram(
    useShader(WebGL2RenderingContext.VERTEX_SHADER, vertexShader),
    useShader(WebGL2RenderingContext.FRAGMENT_SHADER, fragmentShader)
  );
  const projectionLocation = useUniformLocation(program, "u_projection");
  const viewLocation = useUniformLocation(program, "u_view");
  const modelLocation = useUniformLocation(program, "u_model");
  const startLocation = useUniformLocation(program, "u_start");
  const endLocation = useUniformLocation(program, "u_end");
  const scaleLocation = useUniformLocation(program, "u_scale");
  const colorLocation = useUniformLocation(program, "u_color");

  const vbo = useArrayBuffer(useMemo(() => {
    const [sx, sy] = Vector2.subtract(start, origin);
    const [ex, ey] = Vector2.subtract(end, origin);
    const x1 = Math.min(sx, ex);
    const x2 = Math.max(sx, ex);
    const y1 = Math.min(sy, ey);
    const y2 = Math.max(sy, ey);
    const [w, h] = [32, 32];
    return new Float32Array([
      -w/2 * scale + x1, -h/2 * scale + y1,  -w/2*scale + x1, -h/2*scale + y1,
       w/2 * scale + x2, -h/2 * scale + y1,   w/2*scale + x2, -h/2*scale + y1,
       w/2 * scale + x2,  h/2 * scale + y2,   w/2*scale + x2,  h/2*scale + y2,
      -w/2 * scale + x1,  h/2 * scale + y2,  -w/2*scale + x1,  h/2*scale + y2
    ])
  }, [start, end, scale, origin]));
  const vao = useVertexBuffer();
  useBindVertexArribArray(vao, useAttribLocation(program, "a_position"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 0);
  useBindVertexArribArray(vao, useAttribLocation(program, "a_tex_coord"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 2 * 4);

  const ebo = useElementArrayBuffer(useMemo(() => new Uint16Array([
    0, 1, 2,
    2, 3, 0
  ]), []));

  const projectionMatrix4f = useMemo(() => new Float32Array(projection), [projection]);
  const viewMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(view)), [view]);
  const modelMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(model)), [model]);
  const start2f = useMemo(() => new Float32Array(Vector2.subtract(start, origin)), [start, origin]);
  const end2f = useMemo(() => new Float32Array(Vector2.subtract(end, origin)), [end, origin]);
  const color4f = useMemo(() => new Float32Array(Color.toRGBA(color)), [color]);

  const canvas = useRenderingContext().canvas as HTMLCanvasElement;
  const onMouseDown = useCallback((event: MouseEvent) => {
    if (!onClick) return;
    const mousePos = getWorldPositionFromScreenPosition(view, getScreenPosition(canvas, [event.clientX, event.clientY]));
    const t = Vector2.pointIntersect(start, end, mousePos);
    const intersection = Vector2.lerp(start, end, t);
    if (t < 0.001 || t > 0.999) return false;
    const dist = Vector2.distance(intersection, mousePos);
    if (dist <= 16 * scale) {
      return onClick(event);
    }
  }, [onClick, canvas, view, start, end, scale]);

  return (<interaction onMouseDown={onMouseDown}>
    <program value={program}>
      <uniformMat4fv location={projectionLocation} transpose={true} data={projectionMatrix4f}/>
      <uniformMat4fv location={viewLocation} transpose={true} data={viewMatrix4f}/>
      <uniformMat4fv location={modelLocation} transpose={true} data={modelMatrix4f}/>
      <uniform1f location={scaleLocation} data={scale}/>
      <uniform2fv location={startLocation} data={start2f}/>
      <uniform2fv location={endLocation} data={end2f}/>
      <uniform4fv location={colorLocation} data={color4f}/>

      <vertexArray value={vao}>
        <elementArrayBuffer value={ebo}>
          <drawElements mode={WebGL2RenderingContext.TRIANGLES} type={WebGL2RenderingContext.UNSIGNED_SHORT} offset={0} count={6}/>
        </elementArrayBuffer>
      </vertexArray>
    </program>
  </interaction>);
}