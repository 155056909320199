import {useEffect} from "react";
import {authClient} from "#lib/auth/auth-client.ts";
import {useNavigate} from "react-router-dom";

export function AuthLogout() {
  const navigate = useNavigate();
  useEffect(() => {
    authClient.updateToken(undefined);
    navigate("/");
  }, [navigate]);

  return <div className="flex flex-1 justify-center pt-10 w-full">
    <div className="bg-zinc-800 py-12 rounded shadow-xl h-max w-full max-w-screen-sm text-center italic">
      Logging Out...
    </div>
  </div>
}