import {LightShapeGlobal} from "common/legends/node/light/light-shape-global.ts";
import {HSLA} from "common/types/generic/index.ts";
import {GlobalLightShader} from "../../viewport/common/shader/light/global-light-shader.tsx";
import {useMemo} from "react";
import {LightTextureShader} from "./light-texture-shader.tsx";

export function LightShapeGlobalLightPass({value}: {
  value: LightShapeGlobal
}) {
  const color = useMemo(() => [value.color[0], value.color[1], value.color[2], value.intensity] as HSLA, [value.color, value.intensity]);
  return <LightTextureShader>
    <GlobalLightShader color={color} />
  </LightTextureShader>
}
