import {OutlinerView} from "../../common/outliner/outliner-view.tsx";
import {Asset, AssetOperation, Token, TokenOperation, TokenReference, TokenSignals} from "common/legends/index.ts";
import {useMemo} from "react";
import {Outliner, OutlinerOperation, OutlinerSignals} from "../../common/outliner/outliner.ts";
import {useAssetViewportProperties} from "../../viewport/character/use-asset-viewport-properties.ts";
import {useAsset} from "../../common/character/use-asset.ts";
import {AssetViewportPropertiesSignals, useAssetToken, useEditorSelectedNodeIDs} from "../../viewport/character";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {usePresent, useSuspendPresent} from "../../common/use-optional-signal.ts";
import {MutableRef, Ref} from "common/ref";

export type EditorAssetOutlinerViewProps = {
  valueRef: MutableRef<Outliner, OutlinerOperation[]>;
};

export function EditorAssetOutlinerView({valueRef}: EditorAssetOutlinerViewProps) {
  const assetViewportPropertiesRef = useSuspendPresent(useAssetViewportProperties());
  const {tokenReference} = useMemo(() => AssetViewportPropertiesSignals(assetViewportPropertiesRef), [assetViewportPropertiesRef]);
  const {assetID} = useRefValue(tokenReference);
  const asset = useSuspendPresent(useAsset(assetID));
  return <LoadedEditorAssetOutlinerView outliner={valueRef} tokenRef={tokenReference}  asset={asset}/>
}

type LoadedEditorAssetOutlinerViewProps = {
  tokenRef: Ref<TokenReference>;
  outliner: MutableRef<Outliner, OutlinerOperation[]>;
  asset: MutableRef<Asset, AssetOperation[]>;
};
function LoadedEditorAssetOutlinerView({tokenRef, outliner, asset}: LoadedEditorAssetOutlinerViewProps) {
  const token = usePresent(useAssetToken(asset, useComputedValue(tokenRef, ref => ref.tokenID)));
  return (<div className="flex flex-col gap-2">
    {token && <EditorAssetTokenOutlinerView outliner={outliner} token={token} />}
  </div>);
}

function EditorAssetTokenOutlinerView({outliner, token}: {
  outliner: MutableRef<Outliner, OutlinerOperation[]>,
  token: MutableRef<Token, TokenOperation[]>,
}) {
  const {expanded} = useMemo(() => OutlinerSignals(outliner), [outliner]);
  const selectedNodeIDsRef = useEditorSelectedNodeIDs();
  const {children} = useMemo(() => TokenSignals(token), [token]);
  return <OutlinerView valueRef={children} expanded={expanded} selectedNodeIdsRef={selectedNodeIDsRef} />;
}