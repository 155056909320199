import React, {useState} from "react";
import {HSLA, Point} from "common/types/generic/index.ts";
import {Spline} from "common/types/generic/spline/index.ts";
import {BlendFuncSeperate} from "#lib/gl-react/component/opengl/blend-func-seperate.tsx";
import {BlendEquationSeparate} from "#lib/gl-react/component/opengl/blend-equation-separate.tsx";
import {Framebuffer} from "../framebuffer.tsx";
import {TextureShader} from "../texture-shader.tsx";
import {AreaPolyShader} from "../shape/area-shader/area-poly-shader.tsx";
import {DSFLineShader} from "../shape/area-shader/d-s-f-line-shader.tsx";


export function AudioFreeformAreaShader({areas, color, origin, falloff, falloffStrength}: {
  areas: Spline[],
  color: HSLA,
  origin: Point,
  falloff: number,
  falloffStrength: number
}) {
  const [texture, setTexture] = useState<WebGLTexture | null>(null);

  return <binder>
    <Framebuffer ref={setTexture}>
      <BlendEquationSeparate rgb={WebGL2RenderingContext.FUNC_ADD} alpha={WebGL2RenderingContext.MAX}>
        <BlendFuncSeperate
          srcRGB={WebGL2RenderingContext.SRC_COLOR} dstRGB={WebGL2RenderingContext.ONE_MINUS_SRC_COLOR}
          srcAlpha={WebGL2RenderingContext.SRC_ALPHA} dstAlpha={WebGL2RenderingContext.DST_ALPHA}>
          {areas.map((area, index) => <React.Fragment key={index}>
            <AreaPolyShader spline={area} origin={origin} color={color}/>
            <DSFLineShader spline={area} origin={origin} color={color} fallout={falloff} falloffStrength={falloffStrength}/>
          </React.Fragment>)}
        </BlendFuncSeperate>
      </BlendEquationSeparate>
    </Framebuffer>

    {texture && <BlendEquationSeparate rgb={WebGL2RenderingContext.FUNC_ADD} alpha={WebGL2RenderingContext.FUNC_ADD}>
      <BlendFuncSeperate srcRGB={WebGL2RenderingContext.SRC_ALPHA} dstRGB={WebGL2RenderingContext.ONE_MINUS_SRC_ALPHA} srcAlpha={WebGL2RenderingContext.SRC_ALPHA} dstAlpha={WebGL2RenderingContext.ONE_MINUS_SRC_ALPHA}>
        <TextureShader texture={texture} />
      </BlendFuncSeperate>
    </BlendEquationSeparate>}
  </binder>;
}
