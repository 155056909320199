import {Field, FieldLabel} from "#lib/components/panel-header.tsx";
import {InputFile} from "#lib/components/input/input-file.tsx";
import {faFileAudio, faPause, faPlay} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import {MutableRef} from "common/ref";
import {FileReference, FileReferenceOperation} from "common/types/generic/index.ts";
import {IconButton} from "#lib/components/index.ts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useMemo, useState} from "react";
import {useRefValue} from "#lib/signal/index.ts";

export function AudioFileField({valueRef}: {valueRef: MutableRef<FileReference, FileReferenceOperation[]>}) {
  const file = useRefValue(valueRef);

  const audio = useMemo(() => {
    const audio = new Audio(file);
    audio.muted = true;
    return audio;
  }, [file]);

  const [paused, setPaused] = useState<boolean>(true);

  useEffect(() => {
    if (paused) {
      audio.muted = true;
      audio.pause();
      return () => {};
    } else {
      audio.muted = false;
      audio.loop = true;
      const playing = audio.play();
      return () => playing.then(() => audio.pause());
    }
  }, [audio, paused])

  return <Field>
    <FieldLabel>Audio File</FieldLabel>
    <div className="flex flex-row mx-2 rounded-md overflow-hidden">
      <IconButton title={paused ? "Play" : "Pause"} onClick={() => setPaused(prev => !prev)}>
        <FontAwesomeIcon icon={paused ? faPlay : faPause} />
      </IconButton>
      <InputFile className="flex-1 mx-0 rounded-l-none" placeholder="Audio File" icon={faFileAudio} value={valueRef} accept="audio/*" />
    </div>
  </Field>;
}