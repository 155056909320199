import {ListOperation, Transform} from "common/types/index.ts";
import React, {useMemo} from "react";
import {AreaNode} from "common/legends/node/area-node.ts";
import {ModelProvider, usePVM} from "../../viewport/common/context/pvm-context.ts";
import {OpacityProvider, useOpacity} from "../../viewport/common/context/opacity-context.ts";
import {ElementHUDPass} from "../element-h-u-d-pass.tsx";
import {useIsTokenController} from "../../viewport/common/context/use-is-token-controller.ts";
import {useAccess} from "../../../routes/game/model/store-context.tsx";
import {computed} from "common/signal";
import {Spline, SplineOperation} from "common/types/generic/spline/index.ts";
import {useIsActiveSelection} from "../../viewport/common/context/use-is-active-selection.ts";
import {AreasEditor} from "./areas-editor.tsx";

export function AreaElementHUDPass({value}: {
  value: AreaNode;
}) {
  const isSelected = useIsActiveSelection(value.id);
  const isEditing = !useIsTokenController();

  const access = useAccess();
  const areas = useMemo(() => computed(() => {
    const element = access.element(value.id).value;
    if (element?.type === "area") {
      return element.data.areas;
    }
    return [];
  }, (operations: ListOperation<Spline, SplineOperation>[]) => access.element(value.id).apply(prev => {
      if (prev?.type !== "area") return [];
      return [{type: "area", operations: [{
        type: "update-areas", operations
      }]}];
    })
  ), [access, value.id]);

  const valueOpacity = value.opacity * useOpacity();
  const {model} = usePVM();
  let valueModel = Transform.divide(value.transform, model);
  return (<binder>
    <OpacityProvider value={valueOpacity}>
      <ModelProvider value={valueModel}>
        {isEditing && isSelected && <AreasEditor valueRef={areas} origin={value.origin} color={value.color} />}
        {[...value.children].reverse().map((child) => <ElementHUDPass key={child.data.id} value={child} />)}
      </ModelProvider>
    </OpacityProvider>
  </binder>);
}