import {PropsWithChildren} from "react";
import {EditorStackView} from "../editor-stack-view.tsx";
import {EditorStackTabView} from "../editor-stack-tab.tsx";
import {useEditor} from "../editor-context.ts";
import {EditorStackOperation, EditorStackValue} from "../editor-stack.ts";
import {LayoutContentViewProps} from "#lib/container/react/layout/layout-content-view.ts";
import {ContainerView, LayoutPath, LayoutView, StackItem} from "#lib/container/index.ts";
import {EditorGameNavigation} from "./editor-game-navigation.tsx";
import {ErrorBoundary} from "#lib/components/error-boundary.tsx";
import {EditorStacks} from "../editor-stacks.ts";
import {ContainerProvider} from "#lib/container/react/container-context.ts";

const BASE_PATH: LayoutPath = [];
function EditorGameLayoutContentView({dialogId, value, apply}: LayoutContentViewProps<EditorStackValue, EditorStackOperation>) {
  return <LayoutView dialogId={dialogId} layoutPath={BASE_PATH} value={value} apply={apply} StackContentView={EditorStackView} StackTabView={EditorStackTabView} Stacks={EditorStacks} />
}

const labelFor = (item: StackItem<EditorStackValue>) => EditorStacks[item.content.type].label;
export function EditorGameContainer({children}: PropsWithChildren<{}>) {
  const editor = useEditor();
  return (<ContainerProvider value={editor.container}>
    <div className="flex flex-col h-[100vh] max-h-[100vh] overflow-hidden">
      <EditorGameNavigation />
      <div className="flex relative flex-1 overflow-hidden bg-zinc-900">
        {children && <ErrorBoundary>
          {children}
        </ErrorBoundary>}
        <ContainerView containerRef={editor.container} LayoutContentView={EditorGameLayoutContentView} labelFor={labelFor} StackContentView={EditorStackView} StackTabView={EditorStackTabView} Stacks={EditorStacks} />
      </div>
    </div>
  </ContainerProvider>)
}
