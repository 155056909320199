import {Transform} from "common/types/index.ts";
import React, {useCallback, useEffect, useMemo} from "react";
import {LightNode} from "common/legends/node/light/light-node.ts";
import {LightShapeGlobalLightPass} from "./light-shape-global-light-pass.tsx";
import {LightShapeSpotlightLightPass} from "./light-shape-spotlight-light-pass.tsx";
import {LightShapeSpriteLightPass} from "./light-shape-sprite-light-pass.tsx";
import {LightShapeFreeformLightPass} from "./light-shape-freeform-light-pass.tsx";
import {ElementLightPass} from "../element-light-pass.tsx";
import {ModelProvider, useModel} from "../../viewport/common/context/pvm-context.ts";
import {useIsElementAccessible, VisibilityProvider} from "../../viewport/common/context/visibility-context.ts";
import {AccessMaskFn} from "common/legends/visibility/index.ts";
import {Node} from "common/legends/node/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {useElementWalls, WallLineFn} from "../../viewport/scene/scene-view.tsx";
import {useRootNodes} from "../../viewport/common/context/root-context.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {LightShapeFn} from "common/legends/node/light/light-shape.ts";
import {useDistinct} from "#lib/react/hooks/use-distinct.ts";

function useBlink(length: number, interval: number, offset: number) {
  const [isOn, setIsOn] = React.useState(true);

  useEffect(() => {
    if (interval >= 1) {
      setIsOn(true);
    } else if (interval <= 0) {
      setIsOn(false);
    } else {
      const nextOnTime = () => length - (Date.now() - offset * length) % length;
      const turnOn = () => {
        setIsOn(true);
        timeoutA = setTimeout(turnOn, nextOnTime());
      };
      let timeoutA = setTimeout(turnOn, nextOnTime() % length);

      const nextOffTime = () => length + interval * length - (Date.now() - offset * length) % length;
      const turnOff = () => {
        setIsOn(false);
        timeoutB = setTimeout(turnOff, nextOffTime());
      };
      let timeoutB = setTimeout(turnOff, nextOffTime() % length);

      setIsOn(nextOnTime() % length >= nextOffTime() % length);

      return () => {
        clearTimeout(timeoutA);
        clearTimeout(timeoutB);
      };
    }
  }, [length, interval, offset]);

  return isOn;
}

export const LightElementLightPass = function LightElementLightPass({value}: {
  value: LightNode;
}) {
  const model = useModel();
  const valueModel = useMemo(() => Transform.divide(value.transform, model), [value.transform, model]);
  const isElementVisible = useCallback((element: Node) => AccessMaskFn.canSee(value.accessMask ? ["ALL", value.accessMask] : ["ALL"], element.data.visibilityLayer), [value.accessMask]);
  const isLightOn = useBlink(value.blinkLength, value.blinkInterval, value.blinkOffset);

  const walls = useRefValue(useElementWalls(
    useRootNodes(),
    isElementVisible,
    useIsElementAccessible()
  ));
  const bounds = useMemo((): Vector2[] => {
    const o = LightShapeFn.getLightOrigin(value.shape, value.origin);
    const s = LightShapeFn.getLightSize(value.shape);
    const p = ([
      [-o[0]     , -o[1]     ],
      [-o[0]+s[0], -o[1]     ],
      [-o[0]+s[0], -o[1]+s[1]],
      [-o[0]     , -o[1]+s[1]]
    ] as Vector2[]).map((p) => Vector2.multiplyTransform(p, valueModel));
    const minX = Math.min(...p.map(p => p[0]));
    const maxX = Math.max(...p.map(p => p[0]));
    const minY = Math.min(...p.map(p => p[1]));
    const maxY = Math.max(...p.map(p => p[1]));

    return [
      [minX, maxY],
      [maxX, maxY],
      [maxX, minY],
      [minX, minY],
    ];
  }, [value.shape, origin, valueModel]);

  const relevantWalls = useDistinct(useMemo(() => {
    if (value.shape?.type === "global") return [];
    return walls.filter(line => {
      if (Vector2.left(line.start, bounds[0], bounds[3]) && Vector2.left(line.start, bounds[1], bounds[0]) && Vector2.left(line.start, bounds[2], bounds[1]) && Vector2.left(line.start, bounds[3], bounds[2])) return true;
      if (Vector2.left(line.end, bounds[0], bounds[3]) && Vector2.left(line.end, bounds[1], bounds[0]) && Vector2.left(line.end, bounds[2], bounds[1]) && Vector2.left(line.end, bounds[3], bounds[2])) return true;
      if (Vector2.isLineIntersect(bounds[0], bounds[1], line.start, line.end)) return true;
      if (Vector2.isLineIntersect(bounds[1], bounds[2], line.start, line.end)) return true;
      if (Vector2.isLineIntersect(bounds[2], bounds[3], line.start, line.end)) return true;
      if (Vector2.isLineIntersect(bounds[3], bounds[0], line.start, line.end)) return true;
      return false;
    });
  }, [walls, bounds, value.shape?.type]), WallLineFn.listEquals);

  return (<ModelProvider value={valueModel}>
    {isLightOn && <VisibilityProvider value={isElementVisible}>
      {value.shape?.type === "global" && <LightShapeGlobalLightPass value={value.shape.data} />}
      {value.shape?.type === "sprite" && <LightShapeSpriteLightPass value={value.shape.data} origin={value.origin} walls={relevantWalls} bounds={bounds} />}
      {value.shape?.type === "spotlight" && <LightShapeSpotlightLightPass value={value.shape.data} origin={value.origin} walls={relevantWalls} bounds={bounds} />}
      {value.shape?.type === "freeform" && <LightShapeFreeformLightPass value={value.shape.data} origin={value.origin} walls={relevantWalls} bounds={bounds} />}
    </VisibilityProvider>}

    <binder>
      {[...value.children].reverse().map((element) => <ElementLightPass key={element.data.id} value={element}/>)}
    </binder>
  </ModelProvider>);
}
