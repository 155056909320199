import {Point, PointOperation, PointSignals} from "common/types/index.ts";
import {useMemo} from "react";
import {MutableRef} from "common/ref";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";
import {GridWidthNumberField} from "./generic/grid-width-number-field.tsx";
import {GridHeightNumberField} from "./generic/grid-height-number-field.tsx";

export type OriginFieldProps = {
  valueRef: MutableRef<Point, PointOperation[]>;
};

export function OriginField({valueRef}: OriginFieldProps) {
  const {x, y} = useMemo(() => PointSignals(valueRef), [valueRef]);

  return (<Field>
    <FieldLabel>Origin</FieldLabel>
    <div className="flex flex-row gap-0.5 mx-2 rounded-md overflow-hidden">
      <GridWidthNumberField icon="X" pt={{group: "mx-0"}} valueRef={x} />
      <GridHeightNumberField icon="Y" pt={{group: "mx-0"}} valueRef={y} />
    </div>
  </Field>);
}