import {BooleanOperation, booleanType, NumberOperation, numberType, ObjectType} from "common/types/generic/index.ts";
import {Type} from "common/types/type/index.ts";
import {MutableRef} from "common/ref";

export type MasterVolume = {
  muted: boolean;
  volume: number;
};
export type MasterVolumeOperation =
  | {type: "update-muted", operations: BooleanOperation[] }
  | {type: "update-volume", operations: NumberOperation[] }
  ;
export const masterVolumeType: Type<MasterVolume, MasterVolumeOperation> = new ObjectType({
  muted: booleanType,
  volume: numberType
});

export const MasterVolumeFn = {
  expand(valueRef: MutableRef<MasterVolume, MasterVolumeOperation[]>) {
    return ({
      mutedRef: valueRef.map<boolean, BooleanOperation[]>(value => value.muted, (_, operations) => [{type: "update-muted", operations}]),
      volumeRef: valueRef.map<number, NumberOperation[]>(value => value.volume, (_, operations) => [{type: "update-volume", operations}]),
    });
  }
} as const;