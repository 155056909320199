import {LocalNode, LocalNodeOperation, LocalNodeSignals, localNodeTypePropTypes, localNodeUpdater} from "#common/legends/node/local-node.ts";
import {ListOperation, ListType, NumberOperation, numberType, ObjectType, ValueOperation, ValueType} from "#common/types/index.ts";
import {AudioShape, AudioShapeOperation, audioShapeType} from "#common/legends/node/audio/audio-shape.ts";
import {MutableRef} from "#common/ref";
import {Track, TrackOperation, trackType} from "#common/legends/node/audio/track.ts";

export type AudioElement = LocalNode & {
  playlist: Track[];
  volume: number;
  shape: AudioShape;
};

export type AudioElementOperation =
  | LocalNodeOperation
  | {type: "update-playlist", operations: ListOperation<Track, TrackOperation>[]}
  | {type: "update-volume", operations: NumberOperation[]}
  | {type: "update-shape", operations: ValueOperation<AudioShape, AudioShapeOperation>[]}
  ;
export const audioElementType = new ObjectType(() => ({
  ...localNodeTypePropTypes(),
  playlist: new ListType(trackType),
  volume: numberType,
  shape: new ValueType(audioShapeType)
}), v => {
  v = localNodeUpdater(v);
  if (v.audioFile) {v.playlist = [v.audioFile]; delete v["audioFile"];}
  return v;
});

export const AudioElementFn = {
  expand(valueRef: MutableRef<AudioElement, AudioElementOperation[]>) {
    return {
      ...LocalNodeSignals(valueRef),
      playlistRef: valueRef.map<Track[], ListOperation<Track, TrackOperation>[]>(value => value.playlist, (_, operations) => [{type: "update-playlist", operations}]),
      volumeRef: valueRef.map<number, NumberOperation[]>(value => value.volume, (_, operations) => [{type: "update-volume", operations}]),
      shapeRef: valueRef.map<AudioShape, ValueOperation<AudioShape, AudioShapeOperation>[]>(value => value.shape, (_, operations) => [{type: "update-shape", operations}])
    };
  }
} as const;
