import {GroupNode, GroupNodeOperation, GroupNodeSignals} from "common/legends/index.ts";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {NameField} from "../../field/name-field.tsx";
import {VisibleField} from "../../field/visible-field.tsx";
import {OpacityField} from "../../field/opacity-field.tsx";
import {SelectionField} from "../../field/selection-field.tsx";
import {TransformField} from "../../field/transform-field.tsx";
import {useMemo} from "react";
import {OriginField} from "../../field/origin-field.tsx";
import {SizeField} from "../../field/size-field.tsx";
import {MutableRef} from "common/ref";
import {SelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {useElementGrid} from "../../common/use-element-grid.ts";
import {GridProvider} from "../../viewport/common/context/grid-context.ts";
import {Panel, Section} from "#lib/components/panel-header.tsx";

export function GroupElementProperties({value, reference, pinned}: {
  value: MutableRef<GroupNode, GroupNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const {idRef, name, transform, size, origin, visibilityLayer, selectionMask, opacity} = useMemo(() => GroupNodeSignals(value), [value]);
  const elementGrid = useElementGrid(idRef);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <GridProvider value={elementGrid}>
      <Panel title="Group Properties">
        <Section>
          <NameField value={name} reference={reference} pinned={pinned} />
          <VisibleField value={visibilityLayer} />
          <SelectionField value={selectionMask} />
          <OpacityField value={opacity} />
          <TransformField value={transform} />
          <OriginField valueRef={origin} />
          <SizeField valueRef={size} />
        </Section>
      </Panel>
    </GridProvider>
  </div>
}