import {GroupNode} from "common/legends/index.ts";
import React from "react";
import {Color, Transform} from "common/types/index.ts";
import {ElementHUDPass} from "../element-h-u-d-pass.tsx";
import {SelectionIndicator} from "../../viewport/common/selection-indicator/selection-indicator.tsx";
import {OpacityProvider, useOpacity} from "../../viewport/common/context/opacity-context.ts";
import {ModelProvider, useModel} from "../../viewport/common/context/pvm-context.ts";
import {useIsActiveSelection} from "../../viewport/common/context/use-is-active-selection.ts";

export function GroupElementHUDPass({value}: {
  value: GroupNode;
}) {
  const model = useModel();
  const valueOpacity = value.opacity * useOpacity();
  const valueModel = Transform.divide(value.transform, model);
  const isSelected = useIsActiveSelection(value.id);

  return (<binder>
    <OpacityProvider value={valueOpacity}>
      <ModelProvider value={valueModel}>
        {[...value.children].reverse().map((element) => <ElementHUDPass key={element.data.id} value={element} />)}
        {isSelected && <SelectionIndicator origin={value.origin} size={value.size} color={Color.WHITE} />}
      </ModelProvider>
    </OpacityProvider>
  </binder>);
}