import {Optional, Tree} from "common/types/index.ts";
import {NodeId} from "common/legends/index.ts";
import {pipe} from "common/pipe";
import {combine, distinct, from, map, Observable, switchAll} from "common/observable";
import {useMemo} from "react";
import {SheetReference} from "../../../common/sheet/sheet-reference.ts";
import {useObservableRef} from "#lib/signal/react/use-observable-ref.ts";
import {Ref} from "common/ref";
import {QLabDatabase} from "common/qlab/q-lab-database.ts";
import {useDatabase} from "../../../../routes/game/model/store-context.tsx";
import {EditorState} from "../../nav/editor/state";
import {selectionRefObservable} from "../../nav/editor/state/use-selection-ref-observable.ts";
import {SelectionRef} from "../../nav/editor/state/selection-ref.ts";
import {useEditor} from "../../nav/editor/editor-context.ts";
import {SheetReferenceKeyCache} from "common/legends/access/resource/sheet-reference.ts";

export function editorNodeIDObservable(state: Observable<EditorState>): Observable<NodeId | undefined> {
  return pipe(
    selectionRefObservable(state),
    map((ref): Observable<NodeId | undefined> => {
      if (ref?.type === "element") {
        return from(ref?.elementID);
      } else {
        return from(undefined);
      }
    }),
    switchAll()
  );
}

function getSelectionSheetReference(database: QLabDatabase, ref: SelectionRef): Optional<SheetReference> {
  if (ref?.type === "element") {
    for (const [_, resource] of Object.entries(database.resources)) {
      if (resource?.type !== "scene") continue;
      const node = Tree.getItemById(resource.data.children, ref.elementID);
      if (node === undefined) continue;
      if (node?.type !== "token") continue;
      const tokenSheet = node.data.tokenSheets[node.data.tokenReference.tokenID];
      if (tokenSheet === undefined) continue;
      if (tokenSheet.type === "link") return SheetReferenceKeyCache({type: "link", assetID: node.data.tokenReference.assetID, sheetID: tokenSheet.data});
      return SheetReferenceKeyCache({
        type: "copy",
        nodeID: ref.elementID,
        tokenID: node.data.tokenReference.tokenID
      });
    }
    return undefined;
  } else if (ref?.type === "asset-token") {
    const resource = database.resources[ref.assetID];
    if (resource?.type !== "asset") return undefined;
    const token = resource.data.tokens.find(token => token.tokenID === ref.tokenID);
    if (token?.sheetId === undefined) return undefined;
    return SheetReferenceKeyCache({type: "link", assetID: ref.assetID, sheetID: token.sheetId});
  } else {
    return undefined;
  }
}

export function editorSheetIdObservable(
  databaseRef: Ref<QLabDatabase>,
  state: Observable<EditorState>
): Observable<SheetReference | undefined> {
  return pipe(
    combine(databaseRef.observe, selectionRefObservable(state)),
    map(([database, selectionRef]) => getSelectionSheetReference(database, selectionRef)),
    distinct()
  );
}

export function useEditorSheetReference(pinnedReference: Ref<Optional<SheetReference>>): Ref<Optional<SheetReference>> {
  const editor = useEditor();
  const databaseRef = useDatabase();
  return useObservableRef(useMemo(() => pipe(
    combine(pinnedReference.observe, editorSheetIdObservable(databaseRef, editor.state.observe)),
    map(([a, b]) => a === undefined ? b : a)
  ), [editor, databaseRef, pinnedReference])) || pinnedReference;
}
