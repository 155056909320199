import {QLabInstance} from "./q-lab-instance.ts";
import {
  MessageOperation,
  MessageValue,
  QLabDatabase,
  QLabMessageID,
  QLabResourceID,
  QLabStoreID,
  ResourceOperation,
  ResourceValue,
  StoreOperation,
  StoreValue
} from "common/qlab/index.ts";
import {ApplyAction, Optional, ValueOperation} from "common/types/index.ts";
import {UserID} from "common/legends/index.ts";
import {QLabClient, RequestMessagesParams} from "./index.ts";
import {QLabInstanceObserver} from "./q-lab-instance-observer.ts";
import {Observable} from "common/observable";
import {QLabStream} from "common/qlab/stream/q-lab-stream.ts";
import {decodeTime} from "ulid";

export class DefaultQLabInstance implements QLabInstance {
  constructor(private userId: UserID, private readonly client: QLabClient) {}
  private observers: Map<QLabStoreID, QLabInstanceObserver> = new Map<QLabStoreID, QLabInstanceObserver>();
  private getObserver = (storeId: QLabStoreID): QLabInstanceObserver => {
    if (!this.observers.has(storeId)) this.observers.set(storeId, new QLabInstanceObserver(this.userId, this.client, storeId));
    return this.observers.get(storeId)!;
  }

  streamObservable = (storeId: QLabStoreID): Observable<QLabStream> => {
    return observer => this.getObserver(storeId).subscribe({
      onInitial: () => {},
      onUpdate: (event) => {
        if (event.type === "stream") {
          observer.next(event.stream);
        }
      },
      onClose: observer.complete,
      onError: observer.error
    });
  };

  observe = (storeId: QLabStoreID): Observable<QLabDatabase> => {
    let state: QLabDatabase = {
      connected: false,
      store: undefined,
      resources: {},
      messages: {},
      connections: {},
      timeOffset: 0
    };
    return observer => this.getObserver(storeId).subscribe({
      onInitial: (initial) => {
        state = initial;
        observer.next(state);
      },
      onUpdate: (event) => {
        switch (event.type) {
          case "connected": {
            state = {
              ...state,
              "connected": event.value,
              connections: {}
            };
            observer.next(state);
            break;
          }
          case "message": {
            const delta = decodeTime(event.messageId) - (Date.now() + state.timeOffset);
            state = {
              ...state,
              messages: {
                ...state.messages,
                [event.messageId]: event.message
              },
              timeOffset: (delta > 0) ? state.timeOffset + delta : state.timeOffset
            };
            observer.next(state);
            break;
          }
          case "resource": {
            state = {
              ...state,
              resources: {
                ...state.resources,
                [event.resourceId]: event.resource
              }
            };
            observer.next(state);
            break;
          }
          case "store": {
            state = {
              ...state,
              store: event.store
            };
            observer.next(state);
            break;
          }
          case "connection": {
            const connections = {...state.connections};
            if (event.userId) {
              connections[event.connectionId] = event.userId
            } else {
              delete connections[event.connectionId];
            }

            state = {
              ...state,
              connections
            };
            observer.next(state);
            break;
          }
        }
      },
      onClose: () => observer.complete,
      onError: observer.error
    });
  }

  getStore = (storeId: QLabStoreID): Promise<Optional<StoreValue>> => {
    return this.getObserver(storeId).getStore();
  }
  getResource = (storeId: QLabStoreID, resourceId: QLabResourceID): Promise<Optional<ResourceValue>> => {
    return this.getObserver(storeId).getResource(resourceId);
  }
  getMessage = (storeId: QLabStoreID, messageId: QLabMessageID): Promise<Optional<MessageValue>> => {
    return this.getObserver(storeId).getMessage(messageId);
  }

  applyToStore = (storeId: QLabStoreID, fn: ApplyAction<Optional<StoreValue>, ValueOperation<Optional<StoreValue>, StoreOperation>[]>): Promise<Optional<StoreValue>> => {
    return this.getObserver(storeId).applyToStore(fn);
  }
  applyToResource = (storeId: QLabStoreID, resourceId: QLabResourceID, fn: ApplyAction<Optional<ResourceValue>, ValueOperation<Optional<ResourceValue>, ResourceOperation>[]>): Promise<Optional<ResourceValue>> => {
    return this.getObserver(storeId).applyToResource(resourceId, fn);
  }
  applyToMessage = (storeId: QLabStoreID, messageId: QLabMessageID, fn: ApplyAction<Optional<MessageValue>, ValueOperation<Optional<MessageValue>, MessageOperation>[]>): Promise<void> => {
    return this.getObserver(storeId).applyToMessage(messageId, fn);
  }
  stream = (storeId: QLabStoreID, stream: QLabStream) => {
    return this.getObserver(storeId).stream(stream);
  }
  requestMessages = (storeId: QLabStoreID, params: RequestMessagesParams): Promise<void> => {
    return this.getObserver(storeId).requestMessages(params);
  }
}