import {applyAll, Type} from "common/types/type/index.ts";
import {computed, MutableSignal, valueSignal} from "common/signal";
import {useMemo} from "react";

export function useSpeculativeValue<Value, Operation>(type: Type<Value, Operation>, valueRef: MutableSignal<Value, Operation[]>): MutableSignal<Value, Operation[]> {
  return useMemo((): MutableSignal<Value, Operation[]> => {
    const speculativeOperations = valueSignal<Operation[]>([]);
    return computed(
      () => applyAll(type, valueRef.value, speculativeOperations.value),
      (operations: Operation[]) => {
        speculativeOperations.apply(_ => operations);
      }
    );
  }, [type, valueRef]);
}
