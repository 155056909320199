import {QLabDatabaseSignal} from "#common/qlab/index.ts";
import {ResourceCache} from "./resource/resource-cache.ts";
import {SceneCache} from "./resource/scene/scene-cache.ts";
import {SceneIDsCache} from "./resource/scene/scene-ids-cache.ts";
import {SheetCache} from "./resource/sheet-cache.ts";
import {AssetCache} from "./resource/asset/asset-cache.ts";
import {AssetTokenCache} from "./resource/asset/asset-token-cache.ts";
import {ElementCache} from "./resource/element-cache.ts";
import {MessageCache} from "#common/legends/access/message/message-cache.ts";
import {PlayersCache} from "#common/legends/access/store/players-cache.ts";
import {ElementPathCache} from "#common/legends/access/resource/element-path-cache.ts";
import {ElementGridCache} from "#common/legends/access/resource/element-grid-cache.ts";
import {computed} from "#common/signal";
import {GameOperation} from "#common/legends/index.js";

export class LegendsAccess {
  public readonly game;
  public readonly resource;
  public readonly scene;
  public readonly sceneIDs;
  public readonly sheet;
  public readonly asset;
  public readonly assetToken;
  public readonly element;
  public readonly elementPath;
  public readonly elementGrid;
  public readonly message;
  public readonly player;
  constructor(public readonly database: QLabDatabaseSignal) {
    this.game = computed(() => {
      const store = database.value.store;
      if (store?.type === "game") return store.data;
      return undefined;
    }, (actions: GameOperation[]) => {
      database.apply((prev) => {
        if (prev.store?.type !=="game") return [];
        return [{type: "store", operations: [{type: "apply", operations: [{type: "game", operations: actions}]}]}]
      });
    });
    this.resource = ResourceCache(database);
    this.scene = SceneCache(this.resource);
    this.sceneIDs = SceneIDsCache(database);
    this.asset = AssetCache(this.resource);
    this.assetToken = AssetTokenCache(this.asset);
    this.elementPath = ElementPathCache(database, this.resource);
    this.element = ElementCache(database, this.elementPath);
    this.elementGrid = ElementGridCache(this.scene, this.assetToken, this.elementPath);
    this.sheet = SheetCache(this.asset, this.element);
    this.message = MessageCache(database);
    this.player = PlayersCache(database);
  }
}
