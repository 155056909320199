import {TextNode} from "common/legends/index.ts";
import React, {useMemo} from "react";
import {Color, Transform} from "common/types/index.ts";
import {ElementRenderPass} from "../element-render-pass.tsx";
import {TextShader} from "#lib/gl-react/component/text-shader.tsx";
import {ModelProvider, useModel} from "../../viewport/common/context/pvm-context.ts";
import {useOpacity} from "../../viewport/common/context/opacity-context.ts";

export function TextElementRenderPass({value: {children, transform, text, size, hTextAlign, vTextAlign, fillColor, outlineColor}}: {
  value: TextNode;
}) {
  const model = useModel();
  const valueModel = useMemo(() => Transform.divide(transform, model), [transform, model]);

  const rgbFillColor = useMemo(() => Color.toRGB(fillColor), [fillColor]);
  const rgbOutlineColor = useMemo(() => Color.toRGB(outlineColor), [outlineColor]);

  return (<binder>
    <ModelProvider value={valueModel}>
      <TextShader value={text} size={size} opacity={useOpacity()} hTextAlign={hTextAlign} vTextAlign={vTextAlign} color={rgbFillColor} borderColor={rgbOutlineColor} />
      {[...children.reverse()].map((element) => <ElementRenderPass key={element.data.id} value={element} />)}
    </ModelProvider>
  </binder>);
}