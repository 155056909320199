import {AssetID, NodeId, SceneID, TokenID} from "common/legends/index.ts";

export type SceneSelectionRef = {
  type: "scene";
  sceneID: SceneID;
};
export type ElementSelectionRef = {
  type: "element";
  elementID: NodeId;
};
export type ElementEdgeSelectionRef = {
  type: "element-edge",
  elementID: NodeId;
  edgeIndex: number;
};
export type ElementVertexSelectionRef = {
  type: "element-vertex",
  elementID: NodeId;
  vertexIndex: number;
};
export type ElementAreaSelectionRef = {
  type: "element-area";
  elementID: NodeId;
  areaIndex: number;
};
export type ElementAreaVertexSelectionRef = {
  type: "element-area-vertex";
  elementID: NodeId;
  areaIndex: number;
};

export type AssetSelectionRef = {
  type: "asset";
  assetID: AssetID;
};

export type AssetTokenSelectionRef = {
  type: "asset-token";
  assetID: AssetID;
  tokenID: TokenID;
};

export type SelectionRef =
  | SceneSelectionRef
  | AssetSelectionRef
  | AssetTokenSelectionRef
  | NodeSelectionRef
  | undefined
  ;

export type NodeSelectionRef =
  | ElementSelectionRef
  | ElementEdgeSelectionRef
  | ElementVertexSelectionRef
  | ElementAreaSelectionRef
  | ElementAreaVertexSelectionRef
  ;
export function isElementSelectionRef(value: SelectionRef): value is NodeSelectionRef {
  return value?.type === "element" || value?.type === "element-vertex" || value?.type === "element-edge" || value?.type === "element-area" || value?.type === "element-area-vertex";
}

export const SelectionRef = {
  equals: (a: SelectionRef | undefined, b: SelectionRef | undefined) => {
    if (a === undefined && b === undefined) return true;
    if (a === undefined || b === undefined) return false;
    if (a.type === "asset" && b.type === "asset") {
      return (a.assetID === b.assetID);
    } else if (a.type === "asset-token" && b.type === "asset-token") {
      return (a.assetID === b.assetID && a.tokenID === b.tokenID);
    } else if (a.type === "scene" && b.type === "scene") {
      return (a.sceneID === b.sceneID);
    } else if (a.type === "element" && b.type === "element") {
      return (a.elementID === b.elementID);
    } else if (a.type === "element-edge" && b.type === "element-edge") {
      return (a.elementID === b.elementID && a.edgeIndex === b.edgeIndex);
    } else if (a.type === "element-vertex" && b.type === "element-vertex") {
      return (a.elementID === b.elementID && a.vertexIndex === b.vertexIndex);
    } else if (a.type === "element-area" && b.type === "element-area") {
      return (a.elementID === b.elementID && a.areaIndex === b.areaIndex);
    } else {
      return false;
    }
  }
};


