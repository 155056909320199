import {map, Observer, skip, subscribe, Subscription} from "#common/observable";
import {pipe} from "#common/pipe";
import {fromSignal} from "#common/ref/from-signal.js";
import {computed} from "#common/signal";

export class MutableRef<Value, Action> implements MutableRef<Value, Action>{
  constructor(private readonly props: {
    value: () => Value,
    observe: (observer: Observer<Value>) => Subscription,
    apply: (fn: (prev: Value) => Action) => Promise<Value>
  }) {
    this.observe = this.observe.bind(this);
    this.apply = this.apply.bind(this);
  }
  get value(): Value {return this.props.value();}
  observe(observer: Observer<Value>) {
    return this.props.observe(observer);
  }
  apply(fn: (prev: Value) => Action): Promise<Value> { return this.props.apply(fn) }

  // operations
  map<C>(valueFn: (value: Value) => C): Ref<C>;
  map<C, D>(valueFn: (value: Value) => C, applyFn: (prev: Value, operations: D) => Action): MutableRef<C, D>;
  map<C, D>(valueFn: (value: Value) => C, applyFn?: (prev: Value, operations: D) => Action): MutableRef<C, D> {
    return new MutableRef({
      value: () => valueFn(this.value),
      observe: pipe(this.observe, map(valueFn)),
      apply: fn => {
        if (applyFn === undefined) throw new Error("Unsupported.");
        return this.apply(prev => {
          const prevValue = valueFn(prev);
          return applyFn(prev, fn(prevValue));
        }).then(valueFn)
      }
    });
  }

  skip(n: number) {
    return new MutableRef({value: () => this.value, observe: pipe(this.observe, skip(n)), apply: this.apply});
  }

  filter<T extends Value>(predicate: (value: Value) => value is T): MutableRef<T | undefined, Action> {
    return this.map((value): T | undefined => predicate(value) ? value : undefined, (_, operations: Action) => operations);
  }

  distinct(fn: (a: Value, b: Value) => boolean = (a, b) => a === b): MutableRef<Value, Action> {
    let first = true;
    let lastValue: Value | undefined = undefined;
    return fromSignal(computed((): Value => {
      const next = this.value;
      if (first || lastValue === undefined && next !== undefined || lastValue !== undefined && !fn(lastValue, next)) {
        first = false;
        lastValue = next;
        return next;
      } else {
        return lastValue!;
      }
    }, (action) => this.apply(() => action)));
  }

  static all<A>(ref: MutableRef<A, any>): MutableRef<[A], never[]>;
  static all<A, B>(refA: MutableRef<A, any>, refB: MutableRef<B, any>): MutableRef<[A, B], never[]>;
  static all<A, B, C>(refA: MutableRef<A, any>, refB: MutableRef<B, any>, refC: MutableRef<C, any>): MutableRef<[A, B, C], never[]>;
  static all<A, B, C, D>(refA: MutableRef<A, any>, refB: MutableRef<B, any>, refC: MutableRef<C, any>, refD: MutableRef<D, any>): MutableRef<[A, B, C, D], never[]>;
  static all<A, B, C, D, E>(refA: MutableRef<A, any>, refB: MutableRef<B, any>, refC: MutableRef<C, any>, refD: MutableRef<D, any>, refE: MutableRef<E, any>): MutableRef<[A, B, C, D, E], never[]>;
  static all(...refs: MutableRef<any, any>[]): MutableRef<any[], never[]> {
    return new MutableRef({
      value: () => refs.map(ref => ref.value),
      observe: observer => {
        let values = refs.map(ref => ref.value);
        const subscriptions = refs.map((ref, index) => ref.skip(1).observe({
          next: (value) => {
            values = [
              ...values.slice(0, index),
              value,
              ...values.slice(index+1)
            ];
            observer.next(values);
          },
          error: observer.error,
          complete: observer.complete
        }));
        observer.next(values);
        return () => {
          subscriptions.forEach(subscription => subscription());
        };
      },
      apply: () => {throw new Error("Unsupported.")}
    });
  }

  subscribe(next: (value: Value) => void, error?: (error: any) => void): Subscription {
    return pipe(this.observe, subscribe(next, undefined, error));
  }
}
export type Ref<Value> = MutableRef<Value, never[]>;

export const NOOP_SIGNAL: MutableRef<undefined, any[]> = new MutableRef({
  value() { return undefined },
  observe: (observer) => {
    observer.next(undefined);
    return () => {}
  },
  async apply() {return undefined}
});
