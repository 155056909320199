import {Grid, NodeId} from "#common/legends/index.ts";
import {Cache} from "../cache.ts";
import {computed, Signal} from "#common/signal";
import {Optional, Tree} from "#common/types/index.ts";
import {ElementPathCache} from "#common/legends/access/resource/element-path-cache.ts";
import {SceneCache} from "#common/legends/access/resource/scene/scene-cache.ts";
import {AssetTokenCache} from "#common/legends/access/resource/asset/asset-token-cache.ts";

export type ElementGridCache = Cache<Optional<NodeId>, Signal<Grid>>;
export function ElementGridCache(sceneCache: SceneCache, assetTokenCache: AssetTokenCache, elementPath: ElementPathCache): ElementGridCache {
  return (elementID: Optional<NodeId>) => computed(() => {
    if (elementID === undefined) return Grid.DEFAULT;
    const path = elementPath(elementID).value;
    if (!path) return Grid.DEFAULT;
    if (path.type === "scene-node") {
      const scene = sceneCache(path.sceneID).value;
      if (!scene) return Grid.DEFAULT;

      for (let i = path.path.length - 1; i > 0; i --) {
        const elementPath = path.path.slice(0, i);
        const element = Tree.getItemByPath(scene.children, elementPath);
        if (element?.type === "token") {
          const token = assetTokenCache(element.data.tokenReference.assetID, element.data.tokenReference.tokenID).value;
          if (token) return token.grid;
        }
      }
      return scene.grid;
    } else if (path.type === "asset-node") {
      const asset = assetTokenCache(path.assetID, path.tokenID).value;
      if (!asset) return Grid.DEFAULT;
      for (let i = path.path.length - 1; i > 0; i --) {
        const elementPath = path.path.slice(0, i);
        const element = Tree.getItemByPath(asset.children, elementPath);
        if (element?.type === "token") {
          const token = assetTokenCache(element.data.tokenReference.assetID, element.data.tokenReference.tokenID).value;
          if (token) return token.grid;
        }
      }
      return asset.grid;
    }
    return Grid.DEFAULT;
  });
}
