import {twMerge} from "tailwind-merge";
import {Dropdown, DropdownProps} from "primereact/dropdown";

export type InputDropdownSize = "small" | "medium" | "large";
export type InputDropdownProps = Omit<DropdownProps, "size"> & {
  size?: InputDropdownSize;
};
export const InputDropdown = function Input({size = "medium", className, ...props}: InputDropdownProps): JSX.Element {
  return <Dropdown
    pt={{
      root: {
        className: twMerge(
          "group",
          "flex flex-row items-center",
          "border-solid border-zinc-800/40 bg-zinc-900/50 border focus-within:border-blue-600/50 outline-none focus:outline-none focus:ring-0",
          "px-4 gap-4 text-white",
          "disabled:opacity-50",
          size === "medium" && "h-12",
          size === "small" && "h-10"
        )
      },
      input: {
        className: twMerge(
          "flex flex-1 cursor-pointer",
          "text-white placeholder-white/30"
        )
      },
      list: {
        className: "bg-zinc-800 rounded-b-md overflow-hidden text-white"
      },
      item: {
        className: "px-4 py-2 hover:bg-zinc-700 cursor-pointer",
      }
    }}
    className={twMerge(
      className
  )} {...props} />
}
