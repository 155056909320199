import {ListOperation, ListType, NumberOperation, numberType, ObjectType, Type, ZodListOperation} from "../../../types/index.ts";
import {MutableRef} from "#common/ref";
import {z} from "zod";
import {Spline, SplineOperation, splineType} from "#common/types/generic/spline/index.ts";

export const AudioShapeFreeform = z.object({
  areas: z.array(Spline),
  falloff: z.number(),
  falloffStrength: z.number()
});
export type AudioShapeFreeform = z.infer<typeof AudioShapeFreeform>;
export const AudioShapeFreeformOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-areas"), operations: z.array(ZodListOperation(Spline, SplineOperation))}),
  z.object({type: z.literal("update-falloff"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-falloff-strength"), operations: z.array(NumberOperation)})
]);
export type AudioShapeFreeformOperation = z.infer<typeof AudioShapeFreeformOperation>;

export type AudioShapeFreeformType = Type<AudioShapeFreeform, AudioShapeFreeformOperation>;

export const audioShapeFreeformType: AudioShapeFreeformType = new ObjectType({
  areas: new ListType(splineType),
  falloff: numberType,
  falloffStrength: numberType
});

export const AudioShapeFreeformFn = {
  expand(valueRef: MutableRef<AudioShapeFreeform, AudioShapeFreeformOperation[]>) {
    return {
      areasRef: valueRef.map<Spline[], ListOperation<Spline, SplineOperation>[]>(value => value.areas, (_, operations) => [{type: "update-areas", operations}]),
      falloffRef: valueRef.map<number, NumberOperation[]>(value => value.falloff, (_, operations) => [{type: "update-falloff", operations}]),
      falloffStrengthRef: valueRef.map<number, NumberOperation[]>(value => value.falloffStrength, (_, operations) => [{type: "update-falloff-strength", operations}])
    }
  }
} as const;

