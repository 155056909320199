import {Color, Optional, Point, PointFn, PointOperation, Size, SizeFn, SizeOperation} from "common/types/generic/index.ts";
import {Grid} from "common/legends/scene/index.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {MutableSignal} from "common/signal";
import {ModelProvider, usePVM} from "../../viewport/common/context/pvm-context.ts";
import {useSignalValue} from "#lib/signal/index.ts";
import React from "react";
import {useGrid} from "../../viewport/common/context/grid-context.ts";
import {SelectionIndicator} from "../../viewport/common/selection-indicator/selection-indicator.tsx";
import {MouseInteraction} from "../../viewport/mouse-interaction.tsx";
import {getWorldPositionFromScreenPosition} from "../../viewport/tool/use-get-world-pos.ts";

export type TokenSizeEditorValue = {
  pivot: Point;
  origin: Point;
  size: Size;
}
export type TokenSizeEditorOperation =
  | {type: "update-origin", operations: PointOperation[]}
  | {type: "update-pivot", operations: PointOperation[]}
  | {type: "update-size", operations: SizeOperation[]}
  ;

function getResizeBottomLeftOperations(value: TokenSizeEditorValue, delta: Size, grid: Grid, snapToGrid: boolean): TokenSizeEditorOperation[] {
  let snapDelta = snapToGrid ? Vector2.add(Grid.snap(grid, Vector2.subtract(delta, value.origin)), value.origin) : delta;
  snapDelta = [
    Math.min(value.size[0]-16, snapDelta[0]),
    Math.min(value.size[1]-16, snapDelta[1])
  ];
  return getResizeOperations(value, Vector2.multiply(snapDelta, -1), Vector2.multiply(snapDelta, -1));
}

function getResizeTopLeftOperations(value: TokenSizeEditorValue, delta: Size, grid: Grid, snapToGrid: boolean): TokenSizeEditorOperation[] {
  const offset = Vector2.add(
    Vector2.subtract([0, 0], value.origin),
    [0, value.size[1]]
  );
  let snapDelta = snapToGrid ? Vector2.subtract(Grid.snap(grid, Vector2.add(delta, offset)), offset) : delta;
  snapDelta = [
    Math.min(value.size[0]-16, snapDelta[0]),
    Math.max(-value.size[1]+16, snapDelta[1])
  ];
  return getResizeOperations(value, [-snapDelta[0], 0], [-snapDelta[0], snapDelta[1]]);
}

function getResizeTopRightOperations(value: TokenSizeEditorValue, delta: Size, grid: Grid, snapToGrid: boolean): TokenSizeEditorOperation[] {
  const offset = Vector2.add(
    Vector2.subtract([0, 0], value.origin),
    value.size
  );
  let snapDelta = snapToGrid ? Vector2.subtract(Grid.snap(grid, Vector2.add(delta, offset)), offset) : delta;
  snapDelta = [
    Math.max(-value.size[0]+16, snapDelta[0]),
    Math.max(-value.size[1]+16, snapDelta[1])
  ];
  return getResizeOperations(value, [0, 0], snapDelta);
}

function getResizeBottomRightOperations(value: TokenSizeEditorValue, delta: Size, grid: Grid, snapToGrid: boolean): TokenSizeEditorOperation[] {
  const offset = Vector2.add(
    Vector2.subtract([0, 0], value.origin),
    [value.size[0], 0]
  );

  let snapDelta = snapToGrid ? Vector2.subtract(Grid.snap(grid, Vector2.add(delta, offset)), offset) : delta;

  snapDelta = [
    Math.max(-value.size[0]+16, snapDelta[0]),
    Math.min(value.size[1]-16, snapDelta[1])
  ];
  return getResizeOperations(value, [0, -snapDelta[1]], [snapDelta[0], -snapDelta[1]]);
}

function getResizeOperations(prev: TokenSizeEditorValue, originOffset: Point, sizeAdjust: Point): TokenSizeEditorOperation[] {
  const newOrigin = Vector2.add(prev.origin, originOffset);
  const newPivot = Vector2.add(prev.pivot, originOffset);

  return [
    {type: "update-origin", operations: PointFn.set(prev.origin, newOrigin)},
    {type: "update-pivot", operations: PointFn.set(prev.pivot, newPivot)},
    {type: "update-size", operations: SizeFn.set(prev.size, Vector2.add(prev.size, sizeAdjust))}
  ];
}

export function TokenSizeEditor({valueRef, speculativeValueRef}: {
  valueRef: MutableSignal<Optional<TokenSizeEditorValue>, TokenSizeEditorOperation[]>,
  speculativeValueRef: MutableSignal<Optional<TokenSizeEditorValue>, TokenSizeEditorOperation[]>
}) {
  const {view, model} = usePVM();
  const value = useSignalValue(valueRef);
  const speculativeValue = useSignalValue(speculativeValueRef);
  const grid = useGrid();
  if (value === undefined || speculativeValue === undefined) return <></>;

  const speculativeValueModel = model;
  const initialResizeHandleSize: Size = [
    Math.max(8, speculativeValue.size[0] / 8),
    Math.max(8, speculativeValue.size[1] / 8)
  ];
  const resizeHandleSize: Size = [
    Math.min(initialResizeHandleSize[0], initialResizeHandleSize[1]),
    Math.min(initialResizeHandleSize[0], initialResizeHandleSize[1])
  ];


  return <ModelProvider value={speculativeValueModel}>
    <MouseInteraction
      transform={screenPos => Vector2.add(
        Vector2.divideTransform(getWorldPositionFromScreenPosition(view, screenPos), speculativeValueModel), value.origin
      )}
      isInBounds={localPos => localPos[0] >= -resizeHandleSize[0] / 2 && localPos[0] <= resizeHandleSize[0] / 2 && localPos[1] >= -resizeHandleSize[1] / 2 && localPos[1] <= resizeHandleSize[1] / 2}
      draggable
      onDragMove={(event, startPos, endPos) => {
        speculativeValueRef.apply(_ => getResizeBottomLeftOperations(value!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey));
      }}
      onDragEnd={(event, startPos, endPos) => {
        valueRef.apply(prev => getResizeBottomLeftOperations(prev!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey))
        speculativeValueRef.apply(_ => []);
      }}
      onMouseDown={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }} />
    <SelectionIndicator
      origin={Vector2.add(speculativeValue.origin, Vector2.divide(resizeHandleSize, 2))}
      size={resizeHandleSize}
      color={Color.WHITE} />

    <MouseInteraction
      transform={screenPos => Vector2.add(
        Vector2.divideTransform(getWorldPositionFromScreenPosition(view, screenPos), speculativeValueModel), value.origin
      )}
      isInBounds={localPos => localPos[0] >= value.size[0] - resizeHandleSize[0] / 2 && localPos[0] <= value.size[0] + resizeHandleSize[0] / 2 && localPos[1] >= -resizeHandleSize[1] / 2 && localPos[1] <= resizeHandleSize[1] / 2}
      draggable
      onDragMove={(event, startPos, endPos) => {
        speculativeValueRef.apply(_ => getResizeBottomRightOperations(value!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey));
      }}
      onDragEnd={(event, startPos, endPos) => {
        valueRef.apply(prev => getResizeBottomRightOperations(prev!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey));
        speculativeValueRef.apply(_ => []);
      }}
      onMouseDown={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }} />
    <SelectionIndicator
      origin={[
        speculativeValue.origin[0] - speculativeValue.size[0] + resizeHandleSize[0] / 2,
        speculativeValue.origin[1] + resizeHandleSize[1] / 2
      ]}
      size={resizeHandleSize}
      color={Color.WHITE} />

    <MouseInteraction
      transform={screenPos => Vector2.add(
        Vector2.divideTransform(getWorldPositionFromScreenPosition(view, screenPos), speculativeValueModel), value.origin
      )}
      isInBounds={localPos => localPos[0] >= -resizeHandleSize[0]/2 && localPos[0] <= resizeHandleSize[0]/2 && localPos[1] >= value.size[1] - resizeHandleSize[1]/2 && localPos[1] <= value.size[1] + resizeHandleSize[1]/2}
      draggable
      onDragMove={(event, startPos, endPos) => {
        speculativeValueRef.apply(_ => getResizeTopLeftOperations(value!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey));
      }}
      onDragEnd={(event, startPos, endPos) => {
        valueRef.apply(prev => getResizeTopLeftOperations(prev!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey));
        speculativeValueRef.apply(_ => []);
      }}
      onMouseDown={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }} />
    <SelectionIndicator
      origin={[speculativeValue.origin[0] + resizeHandleSize[0] / 2, speculativeValue.origin[1] - speculativeValue.size[1] + resizeHandleSize[1] / 2]}
      size={resizeHandleSize}
      color={Color.WHITE} />

    <MouseInteraction
      transform={screenPos => Vector2.add(
        Vector2.divideTransform(getWorldPositionFromScreenPosition(view, screenPos), speculativeValueModel), value.origin
      )}
      isInBounds={localPos => localPos[0] >= value.size[0] - resizeHandleSize[0] / 2 && localPos[0] <= value.size[0] + resizeHandleSize[0]/2 && localPos[1] >= value.size[1] - resizeHandleSize[1]/2 && localPos[1] <= value.size[1] + resizeHandleSize[1] /2}
      draggable
      onDragMove={(event, startPos, endPos) => {
        speculativeValueRef.apply(_ => getResizeTopRightOperations(value!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey));
      }}
      onDragEnd={(event, startPos, endPos) => {
        valueRef.apply(prev => getResizeTopRightOperations(prev!, Vector2.subtract(endPos, startPos), grid, !event.shiftKey));
        speculativeValueRef.apply(_ => []);
      }}
      onMouseDown={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }} />
    <SelectionIndicator
      origin={[speculativeValue.origin[0] - speculativeValue.size[0] + resizeHandleSize[0]/2, speculativeValue.origin[1] - speculativeValue.size[1] + resizeHandleSize[1]/2]}
      size={resizeHandleSize}
      color={Color.WHITE} />
  </ModelProvider>
}
