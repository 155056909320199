import {WebGL2Node} from "./web-g-l-2-node.ts";
import {KeyboardEvent, MouseEvent} from "react";

export class WebGL2Interaction extends WebGL2Node {
  public onMouseDown?: (event: MouseEvent) => boolean = undefined;
  public onMouseUp?: (event: MouseEvent) => boolean = undefined;
  public onMouseMove?: (event: MouseEvent) => boolean = undefined;
  public onKeyDown?: (event: KeyboardEvent) => boolean = undefined;
  public onKeyUp?: (event: KeyboardEvent) => boolean = undefined;

  constructor(private context: WebGL2RenderingContext) {
    super();
  }

  handleMouseDown(event: MouseEvent) {
    if (this.onMouseDown) {
      if (this.onMouseDown(event)) return true;
    }
    return super.handleMouseDown(event);
  }

  handleMouseUp(event: MouseEvent) {
    if (this.onMouseUp) {
      if (this.onMouseUp(event)) return true;
    }
    return super.handleMouseUp(event);
  }

  handleMouseMove(event: MouseEvent) {
    if (this.onMouseMove) {
      if (this.onMouseMove(event)) return true;
    }
    return super.handleMouseMove(event);
  }

  handleKeyDown(event: KeyboardEvent) {
    if (this.onKeyDown) {
      if (this.onKeyDown(event)) return true;
    }
    return super.handleKeyDown(event);
  }

  handleKeyUp(event: KeyboardEvent) {
    if (this.onKeyUp) {
      if (this.onKeyUp(event)) return true;
    }
    return super.handleKeyUp(event);
  }
}
