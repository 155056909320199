import {MouseEvent, RefObject, useCallback, useRef} from "react";
import {HSLA, Point, Transform, TransformOperation} from "common/types/index.ts";
import {useGetScreenPosition} from "../use-get-screen-pos.ts";
import {useGetWorldPositionFromScreenPosition} from "../use-get-world-pos.ts";
import {MutableRef} from "common/ref";

export function usePingHandlers(
  canvasRef: RefObject<HTMLCanvasElement>,
  view: MutableRef<Transform, TransformOperation[]>,
  ping: (worldPos: Point, focus: boolean, color: HSLA) => void,
  color: HSLA
) {
  const getScreenPos = useGetScreenPosition(canvasRef);
  const getWorldPos = useGetWorldPositionFromScreenPosition(view);

  const pinged = useRef<boolean>(false);
  const pingHandlerRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const onPingMouseDown = useCallback((ev: MouseEvent) => {
    if (ev.button !== 0) return true;
    pinged.current = false;
    const focus = ev.shiftKey;
    const worldPos = getWorldPos(getScreenPos([ev.clientX, ev.clientY]));
    if (pingHandlerRef.current) clearTimeout(pingHandlerRef.current);
    pingHandlerRef.current = setTimeout(() => {
      pinged.current = true;
      ping(worldPos, focus, color);
    }, 200);
    return true;
  }, [pingHandlerRef, pinged, getScreenPos, getWorldPos, ping, color]);

  const onPingMouseMove = useCallback((_: MouseEvent) => {
    if (pingHandlerRef.current) clearTimeout(pingHandlerRef.current);
    return true;
  }, [pingHandlerRef]);

  const onPingMouseUp = useCallback((ev: MouseEvent) => {
    if (ev.button !== 0) return true;
    if (pingHandlerRef.current) clearTimeout(pingHandlerRef.current);
    return !pinged.current;
  }, [pingHandlerRef, pinged]);

  return {
    onPingMouseDown,
    onPingMouseMove,
    onPingMouseUp
  };
}