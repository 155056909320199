import {FileReference, HSLA, Optional, Point, Size} from "common/types/index.ts";
import {distinct, listIdentity, map} from "common/observable";
import {Dnd5eCondition, Sheet} from "common/legends/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {pipe} from "common/pipe";
import {StatusIndicatorModifier} from "common/legends/asset/status-indicator/index.ts";
import {useMemo} from "react";
import {Dnd5eModifierID, generateDnd5eModifierID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {Ref} from "common/ref";
import {useSheetModifiers} from "../../panel/sheet/editor/dnd-5e-character/dnd-5e-action/use-sheet-modifiers.ts";
import {CONDITION_IMAGE} from "../../panel/sheet/editor/dnd-5e/dnd-5e-condition/dnd-5e-conditions-view.tsx";
import {useSourceImageTexture} from "../../viewport/common/context/use-source-image-texture.ts";
import {CircleShader} from "../../viewport/common/shader/circle-shader.tsx";
import {ImageShader} from "../../viewport/common/shader/image-shader.tsx";

function useConditionsIndicator(value: Ref<Optional<Sheet>>): Dnd5eCondition[] {
  return useObservable(pipe(
    value.observe,
    map(sheet => {
      if (sheet === undefined) return [];
      if (sheet.type === "dnd-5e-character") return sheet.data.conditions;
      if (sheet.type === "dnd-5e-stat-block") return sheet.data.conditions;
      return [];
    }),
    distinct(listIdentity)
  ), [], [value]);
}

export type StatusIndicatorsViewProps = {
  value: Ref<Optional<Sheet>>;

  opacity: number;
  origin: Point;
  size: Size;
}

export function StatusIndicatorsView({value, origin, size, ...props}: StatusIndicatorsViewProps) {
  const conditions = useConditionsIndicator(value);
  const modifiers = useSheetModifiers(value.observe);
  const statusIndicators = useObservable(pipe(
    modifiers,
    map(modifiers => {
      return modifiers
        .filter(m => m.type === "status-indicator")
        .flatMap(m => {
          if (m.type !== "status-indicator") return [];
          return m.data;
        });
    }),
    distinct(listIdentity)
  ), [], [modifiers]);

  const columns = Math.max(1, Math.floor(size[0] / 16));

  const displayStatusIndicators = useMemo(() => [
    ...conditions.map(condition => ({
      modifierID: generateDnd5eModifierID() as Dnd5eModifierID,
      color: [0, 0, 0, 0.7] as HSLA,
      file: CONDITION_IMAGE[condition] as FileReference
    }) satisfies StatusIndicatorModifier),
    ...statusIndicators
  ], [conditions, statusIndicators]);

  return (<>
    {displayStatusIndicators.map((indicator, index) => <StatusIndicatorView
      key={index}
      {...props}
      value={indicator}
      origin={[
        origin[0] - size[0] + 15 + (index % columns) * 16,
        origin[1] - size[1] + 15 + Math.floor(index / columns) * 16
      ]}
      size={[14, 14]}
    />)}
  </>);
}

export type IndicatorViewProps = {
  value: StatusIndicatorModifier;

  opacity: number;
  origin: Point;
  size: Size;
};

export function StatusIndicatorView({value, origin, size, opacity, ...props}: IndicatorViewProps) {
  const [isLoaded, image] = useSourceImageTexture(value.file);
  return (<>
    {(value.file === undefined || !isLoaded) && <CircleShader color={value.color} {...props}
                                                              origin={[origin[0]+1,origin[1]+1]}
                                                              size={[size[0]+2, size[1]+2]} />}
    {value.file && isLoaded && <ImageShader {...props} albedoTexture={image} size={size} origin={origin} opacity={opacity} repeatX={1} repeatY={1} />}
  </>);
}
