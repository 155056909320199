import {IconButton, InputGroup, InputGroupRange} from "#lib/components/index.ts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVolumeHigh, faVolumeMedium, faVolumeLow, faVolumeMute} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import {useMasterVolume} from "../hooks/use-master-volume.ts";
import {MasterVolumeFn} from "../data/master-volume.ts";
import {useMemo} from "react";
import {useRefValue} from "#lib/signal/index.ts";
import {BooleanFn, NumberFn, NumberOperation, numberType} from "common/types/generic/index.ts";
import {computed} from "common/signal";
import {fromSignal} from "common/ref";
import {applyAll} from "common/types/type/index.ts";

export function MasterVolumeControl() {
  const masterVolumeRef = useMasterVolume();
  const {mutedRef} = useMemo(() => MasterVolumeFn.expand(masterVolumeRef), [masterVolumeRef]);
  const muteToggleVolumeRef = useMemo(() => fromSignal(computed(
    () => masterVolumeRef.value.muted ? 0 : masterVolumeRef.value.volume,
    (operations: NumberOperation[]) => {
      masterVolumeRef.apply(prev => {
        const nextVolume = applyAll(numberType, prev.volume, operations);
        if (nextVolume === 0) {
          if (prev.muted) return [];
          return [{type: "update-muted", operations: BooleanFn.set(prev.muted, true)}]
        } else {
          if (prev.muted) {
            return [
              {type: "update-muted", operations: BooleanFn.set(prev.muted, false)},
              {type: "update-volume", operations: NumberFn.set(prev.volume, nextVolume)}
            ]
          } else {
            return [
              {type: "update-volume", operations: NumberFn.set(prev.volume, nextVolume)}
            ]
          }
        }
      });
    }
  )), [masterVolumeRef]);

  const volume = useRefValue(muteToggleVolumeRef);

  // Set Volume Icon based on current volume
  let volumeIcon;
  if (volume === 0) volumeIcon = faVolumeMute;
  else if (volume < 0.5) volumeIcon = faVolumeLow;
  else if (volume < 1) volumeIcon = faVolumeMedium;
  else volumeIcon = faVolumeHigh;

  return <div className="flex flex-row group rounded-md overflow-hidden">
    <div className="transition-width ease-in-out duration-200 w-0 group-hover:w-48 overflow-hidden">
      <InputGroup title={`${Math.round(volume * 100)}%`} className="rounded-l-md bg-zinc-900/50">
        <InputGroupRange value={muteToggleVolumeRef} min={0} max={1} step={0.05}/>
      </InputGroup>
    </div>
    <IconButton onClick={() => {
      mutedRef.apply(prev => BooleanFn.set(prev, !prev));
    }}>
      <FontAwesomeIcon icon={volumeIcon}/>
    </IconButton>
  </div>
}