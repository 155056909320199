import {ConstantOperation, constantType, MultiType, Type, ValueOperation, ValueType} from "common/types/index.ts";
import {z} from "zod";
import {Measurement, MeasurementOperation, measurementType} from "./measurement/measurement.ts";

export const Tool = z.discriminatedUnion("type", [
  z.object({type: z.literal("selection"), data: z.object({})}),
  z.object({type: z.literal("measurement"), data: Measurement}),
  z.object({type: z.literal("pan"), data: z.object({})})
]);
export type Tool = z.infer<typeof Tool>;

export const ToolOperation = ValueOperation(Tool, z.discriminatedUnion("type", [
  z.object({type: z.literal("selection"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("pan"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("measurement"), operations: z.array(MeasurementOperation)})
]));
export type ToolOperation = z.infer<typeof ToolOperation>;

export type ToolTypes = {
  selection: Type<object, ConstantOperation>;
  measurement: Type<Measurement, MeasurementOperation>;
  pan: Type<object, ConstantOperation>
};
export const toolType: Type<Tool, ToolOperation> = new ValueType(new MultiType<ToolTypes>({
  selection: constantType,
  measurement: measurementType,
  pan: constantType
}, (v) => {
  if (v.data === undefined) v.data = {};
  return v;
}));
export const ToolFn = {
  DEFAULT: {type: "selection", data: {}} satisfies Tool
};
