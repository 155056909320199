import {useCallback} from "react";
import {Game, GameID, generateGameID, generateSceneID, SceneFn} from "common/legends/index.ts";
import environment from "#env";
import {useCreateStore, useInstance} from "#lib/qlab/index.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {Color, HSLA, ValueFn} from "common/types/index.ts";
import {SenseIDFn} from "common/legends/game/sense/sense-i-d.ts";

export function useCreateGame(): (name: string) => Promise<GameID> {
  const userId = useUserID()!;
  const createStore = useCreateStore();
  const instance = useInstance();
  return useCallback(async (name: string): Promise<GameID> => {
    const user = await instance.getStore(userId);
    if(user?.type !== "user") return Promise.reject("Invalid User");
    const publicKey = user.data.publicKey;

    const gameID = generateGameID();
    const sceneID = generateSceneID();
    await createStore("game", gameID, {
      owner: {
        id: userId,
        name: "Game Master",
        color: [0, 0, 1, 1] as HSLA,
        icon: undefined,
        stageID: undefined,
        publicKey,
      },
      name,
      defaultSceneID: sceneID,
      scenes: [{
        type: "scene",
        data: {
          id: sceneID,
          name: "Default Scene",
          tags: []
        }
      }],
      assets: [],
      invitations: [],
      players: {},
      bots: {
        [environment.botId]: {
          name: "Dice Bot",
          endpoint: environment.botEndpoint,
          publicKey: environment.botPublicKey,
          subscriptions: [{path: "/message/:messageId", messageId: "*", type: "*"}]
        }
      },
      turnTracker: {
        round: 0,
        turns: {},
        currentTurnID: undefined
      },
      systems: {
        dND5e: {
          globalFeatures: []
        }
      },
      globalStageID: "ALL",
      stages: [],
      senses: [{
        senseID: SenseIDFn.generate(),
        name: "Vision",
        color: Color.WHITE
      }, {
        senseID: SenseIDFn.generate(),
        name: "Dark Vision",
        color: Color.RED
      }]
    } satisfies Game);
    await instance.applyToResource(gameID, sceneID, _ => ValueFn.set(undefined, {
      type: "scene",
      data: SceneFn.DEFAULT
    }));
    return gameID;
  }, [instance, createStore, userId]);
}
