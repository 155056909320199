import {HSLA, ListOperation, Point, PointFn} from "common/types/generic/index.ts";
import {MouseInteraction} from "../../viewport/mouse-interaction.tsx";
import {getWorldPositionFromScreenPosition} from "../../viewport/tool/use-get-world-pos.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {Grid} from "common/legends/scene/index.ts";
import {distanceToArea, isPointInArea} from "../../viewport/common/context/is-point-in-area.ts";
import {AreaShader} from "../../viewport/common/shader/shape/area-shader.tsx";
import React, {useMemo, useState} from "react";
import {usePVM} from "../../viewport/common/context/pvm-context.ts";
import {useGrid} from "../../viewport/common/context/grid-context.ts";
import {computed, MutableSignal} from "common/signal";
import {Spline, SplineOperation} from "common/types/generic/spline/index.ts";
import {useSignalValue} from "#lib/signal/index.ts";
import {useOpacity} from "../../viewport/common/context/opacity-context.ts";

export function AreaEditor({
  valueRef,
  origin,
  color,
  draggable
}: {
  origin: Point,
  color: HSLA,
  valueRef: MutableSignal<Spline, SplineOperation[]>,
  draggable?: boolean
}) {
  const [speculative, setSpeculative] = useState<Point | undefined>(undefined);

  const {view, model} = usePVM();
  const grid = useGrid();
  const area = useSignalValue(valueRef);
  return <MouseInteraction
    transform={(screenPos) => Vector2.divideTransform(getWorldPositionFromScreenPosition(view, screenPos), model)}
    isInBounds={localPos => isPointInArea(localPos, area)}
    draggable={draggable}
    onDragStart={(event) => {
      if (event.button !== 0) {
        event.preventDefault();
        event.stopPropagation();
        return true;
      }
    }}
    onDragMove={(event, startPos, endPos) => {
      const delta = Vector2.subtract(endPos, startPos);
      const snapDelta = !event.shiftKey ? Grid.snap(grid, delta) : delta;
      const speculative = Vector2.subtract(origin, snapDelta);
      setSpeculative(speculative);
    }}
    onDragEnd={(event, startPos, endPos) => {
      const delta = Vector2.subtract(endPos, startPos);
      const snapDelta = !event.shiftKey ? Grid.snap(grid, delta) : delta;
      valueRef.apply(prev => [
        {type: "update-start", operations: PointFn.set(prev.start, Vector2.add(prev.start, snapDelta))},
        {type: "update-curves", operations: prev.curves.flatMap((curve, curveIndex) => ListOperation.apply(curveIndex, [{
          type: "update-end",
          operations: PointFn.set(curve.end, Vector2.add(curve.end, snapDelta))
        }]))}
      ]);
      setSpeculative(undefined);
      event.preventDefault();
      event.stopPropagation();
    }}>
    <AreaShader origin={speculative ?? origin} spline={area} scale={1} color={color}/>
  </MouseInteraction>
}

export function AreasEditor({valueRef, origin, color}: {
  origin: Point,
  color: HSLA,
  valueRef: MutableSignal<Spline[], ListOperation<Spline, SplineOperation>[]>
}) {
  const {view, model} = usePVM();
  const grid = useGrid();
  const [areaSelected, setAreaSelected] = useState<number | undefined>(undefined);

  const opacity = useOpacity();

  const areaRefs = useSignalValue(useMemo(() => {
    const indices = computed(() => valueRef.value.map((_, index) => index));
    return computed(() => {
      return indices.value.map(index => computed(
        () => valueRef.value[index],
        (operations: SplineOperation[]) => valueRef.apply(_ => ListOperation.apply(index, operations))
      ));
    });
  }, [valueRef]));

  return (<>
    <MouseInteraction
      transform={(screenPos) => Vector2.divideTransform(getWorldPositionFromScreenPosition(view, screenPos), model)}
      onMouseDown={(event, localPos) => {
      if (event.button !== 0) return;

      if (areaSelected !== undefined || event.ctrlKey) {
        event.preventDefault();
        event.stopPropagation();
      }
      if (valueRef.value.some(area => isPointInArea(localPos, area))) {
        event.preventDefault();
        event.stopPropagation();
      }
    }} onClick={(event, localPos) => {
      if (event.button !== 0) return;
      const snapPos = !event.shiftKey ? Grid.snap(grid, localPos) : localPos;
      if (event.ctrlKey) {
        if (areaSelected === undefined) {
          valueRef.apply(prev => ListOperation.insert(prev.length, {
            start: snapPos,
            controlPoint1: [0, 0],
            controlPoint2: [0, 0],
            curves: [],
            closed: true
          }));
          setAreaSelected(valueRef.value.length - 1);
        } else {
          valueRef.apply(prev => ListOperation.apply(areaSelected, [{
            type: "update-curves",
            operations: [{
              type: "insert",
              index: prev[areaSelected].curves.length,
              item: {
                end: snapPos,
                controlPoint1: [0, 0],
                controlPoint2: [0, 0]
              }
            }]
          }]));
        }
        event.preventDefault();
        event.stopPropagation();
      } else {
        const areas = valueRef.value;
        const selectedArea = areaSelected !== undefined ? areas[areaSelected] : undefined;
        const clickAreas = areas.filter(area => distanceToArea(localPos, area) < 16);
        const nextIndex = (selectedArea ? clickAreas.indexOf(selectedArea) + 1 : 0);

        const nextAreaIndex = areas.indexOf(clickAreas[nextIndex]);
        if (nextAreaIndex !== -1) {
          setAreaSelected(nextAreaIndex);
          event.preventDefault();
          event.stopPropagation();
        } else if (selectedArea) {
          setAreaSelected(undefined);
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }} />
    <interaction onKeyUp={(event) => {
      if (areaSelected !== undefined) {
        if (event.key === "Delete" || event.key === "Backspace") {
          valueRef.apply(prev => ListOperation.delete(areaSelected, prev[areaSelected]));
          setAreaSelected(undefined);
          event.preventDefault();
          event.stopPropagation();
        } else if (event.key === "Enter" || event.key === "Escape") {
          setAreaSelected(undefined);
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }}/>
    {areaRefs.map((areaRef, index) => <AreaEditor key={index} valueRef={areaRef} origin={origin} color={[color[0], color[1], color[2], index === areaSelected ? opacity : opacity * 0.5] as HSLA} draggable={areaSelected === index} />)}
  </>);
}