import {Node, NodeId} from "common/legends/index.ts";
import {Optional, Tree} from "common/types/index.ts";
import {useCallback} from "react";
import {QLabDatabase, QLabResourceID} from "common/qlab/index.ts";
import {useAccess} from "../../../routes/game/model/store-context.tsx";
import {NodePath} from "../../viewport/common/context/get-elements-at-point.ts";

export function getNodeByPath(database: QLabDatabase, nodePath: NodePath): Optional<Node> {
  if (nodePath.type === "scene") {
    const scene = database.resources[nodePath.sceneID];
    if (scene?.type !== "scene") return undefined;
    return Tree.getNode(scene.data.children, nodePath.path);
  } else if (nodePath.type === "asset") {
    const asset = database.resources[nodePath.assetID];
    if (asset?.type !== "asset") return undefined;
    const token = asset.data.tokens.find(token => token.tokenID === nodePath.tokenID);
    if (token === undefined) return undefined;
    return Tree.getNode(token.children, nodePath.path);
  }
  return undefined;
}

export function getNode(database: QLabDatabase, nodeID: Optional<NodeId>) {
  if (nodeID === undefined) return undefined;
  for (const resourceID of (Object.keys(database.resources) as QLabResourceID[])) {
    const resource = database.resources[resourceID];
    if (resource?.type !== "scene") continue;
    const item = Tree.getItemById(resource.data.children, nodeID);
    if (item) return item;
  }
  return undefined;
}

export function useGetNode() {
  const access = useAccess();
  return useCallback((nodeID: NodeId): Optional<Node> => {
    return access.element(nodeID).value;
  }, [access]);
}
