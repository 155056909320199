type Observer = () => void;
let interacted = false;
const listeners: Observer[] = [];
export const playManager = {
  subscribe(fn: () => void): () => void {
    if (interacted) {
      fn();
      return () => {};
    } else {
      listeners.push(fn);
      return () => {
        listeners.splice(listeners.indexOf(fn), 1);
      };
    }
  }
}

const interactionHandler = () => {
  if (!interacted) {
    interacted = true;
    for (const listener of listeners) {
      setTimeout(listener, 0);
    }
  }
};

document.addEventListener("mousedown", interactionHandler, {once: true, capture: true});
